import { Location } from '@remix-run/router'
import { makeAutoObservable } from 'mobx'

export enum EditFormType {
  DIRECTIONS = 'directions',
  COMPANY_EDIT = 'company_edit'
}

class CompanyStore {
  lastAppLocation: Location | null = null
  activeEditForm: EditFormType | null = null

  constructor() {
    makeAutoObservable(this)
  }

}

const companyStore = new CompanyStore()

export {
  companyStore
}
