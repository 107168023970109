import { useLayoutEffect } from 'react'
import gsap from '@roolz/sdk/plugins/gsap'

interface Params {
  enable?: boolean
}

const defaultParams: Params = {
  enable: true
}

type destructor = () => void

export function useGsap(animation: (gsapInstance: typeof gsap) => destructor | void, {
  enable
}: Params = {}) {
  const on = enable ?? defaultParams.enable

  useLayoutEffect(() => {
    if(!on) {
      return
    }

    let destructor

    const ctx = gsap.context(() => {
      destructor = animation(gsap)
    })

    if(destructor) {
      return destructor
    }

    return () => ctx.revert()
  }, [on])
}
