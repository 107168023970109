interface Props {
  className?: string
  size?: number
  color?: string
}

export const TikTokIcon = ({ className, size = 18 }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.8824 6.4976C14.0399 7.32798 15.4579 7.81656 16.9894 7.81656V4.85896C16.6996 4.85902 16.4105 4.82869 16.1269 4.7684V7.09645C14.5955 7.09645 13.1777 6.60787 12.0199 5.77755V11.8132C12.0199 14.8325 9.58096 17.2799 6.57256 17.2799C5.45005 17.2799 4.40673 16.9394 3.54004 16.3553C4.52922 17.3703 5.90872 18 7.43488 18C10.4435 18 12.8825 15.5525 12.8825 12.5331V6.4976H12.8824ZM13.9464 3.51375C13.3549 2.86517 12.9664 2.02701 12.8824 1.10038V0.719971H12.0651C12.2708 1.89771 12.9725 2.9039 13.9464 3.51375ZM5.44286 14.0384C5.11235 13.6035 4.93374 13.0714 4.93454 12.5244C4.93454 11.1435 6.0501 10.0238 7.42639 10.0238C7.68289 10.0238 7.93784 10.0632 8.18228 10.141V7.11733C7.89662 7.07804 7.60832 7.06136 7.32014 7.06748V9.42098C7.07552 9.34314 6.82044 9.30361 6.56389 9.30385C5.18759 9.30385 4.0721 10.4234 4.0721 11.8045C4.0721 12.781 4.62969 13.6265 5.44286 14.0384Z'
        fill='#FF004F'
      />
      <path
        d='M12.0197 5.77749C13.1775 6.60781 14.5954 7.09639 16.1268 7.09639V4.76833C15.2719 4.5856 14.5152 4.1373 13.9462 3.51375C12.9723 2.90384 12.2706 1.89765 12.0649 0.719971H9.91794V12.533C9.91308 13.9101 8.79943 15.0252 7.42609 15.0252C6.61681 15.0252 5.89784 14.6381 5.4425 14.0384C4.62939 13.6265 4.0718 12.781 4.0718 11.8046C4.0718 10.4236 5.18729 9.30392 6.56359 9.30392C6.82728 9.30392 7.08144 9.34512 7.31984 9.42104V7.06754C4.36429 7.12882 1.9873 9.55238 1.9873 12.533C1.9873 14.0209 2.57923 15.3698 3.53993 16.3553C4.40662 16.9394 5.44994 17.28 6.57245 17.28C9.58091 17.28 12.0198 14.8324 12.0198 11.8132V5.77749H12.0197Z'
        fill='black'
      />
      <path
        d='M16.1269 4.76835V4.13886C15.3561 4.14004 14.6004 3.92339 13.9464 3.5137C14.5253 4.1498 15.2877 4.5884 16.1269 4.76835ZM12.065 0.719988C12.0454 0.607434 12.0303 0.494138 12.0199 0.38041V0H9.05551V11.8131C9.05078 13.1901 7.93719 14.3052 6.56373 14.3052C6.1605 14.3052 5.77979 14.2091 5.44264 14.0384C5.89797 14.6381 6.61695 15.0252 7.42623 15.0252C8.79945 15.0252 9.91328 13.9102 9.91808 12.533V0.719988H12.065ZM7.3201 7.06755V6.39742C7.07241 6.36344 6.82268 6.34639 6.57265 6.34652C3.56394 6.34645 1.125 8.79404 1.125 11.8131C1.125 13.7059 2.08354 15.374 3.54013 16.3552C2.57943 15.3697 1.98751 14.0208 1.98751 12.533C1.98751 9.55239 4.36443 7.12883 7.3201 7.06755Z'
        fill='#00F2EA'
      />
    </svg>
  )
}
