import { api } from '@/api'
import { db, DEXIE_STORES } from '@/database'
import { ChatModelFactory } from '@/models/Chat.model'
import { MessageModelFactory } from '@/models/Message.model'
import { addOrUpdateProfiles, getProfileById } from '@/repositories/profiles.repository'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore, messagesDescComparator } from '@/store/chats/chats.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { uploadingService } from '@/store/uploadings/uploading.service'
import { isAddedPcpMessage, isSystemMessage } from '@/types/models/chat'
import { isNumber } from '@/utils/types'
import dayjs from '@roolz/sdk/plugins/dayjs'
import {
  Chat,
  ChatType,
  GetChatMessagesRequest,
  GetChatMessagesResponse,
  Message,
  MessageStatus,
  MessageType
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { groupBy, keyBy } from 'lodash-es'

export async function loadChatMessages(id: Chat['id'], params: GetChatMessagesRequest = {}) {
  async function handleResponse(data: GetChatMessagesResponse) {
    const { message_list, profile_list } = data
    // update in memory
    await Promise.allSettled([
      addOrUpdateProfiles(profile_list),
      addOrUpdateMessages(message_list)
    ])

    const minMessageIndex = Math.min(...message_list.map(item => item.number))

    chatSessionsStore.chatsMinLoadedMessageIndexes[id] ??= Infinity
    chatSessionsStore.chatsMinLoadedMessageIndexes[id] = Math.min(chatSessionsStore.chatsMinLoadedMessageIndexes[id], minMessageIndex)
  }

  try {
    await api.messaging.loadChatMessages(id, params)
      .then(({ data }) => {
        try {
          // update in deep storage
          // TODO update profiles in deep storage too
          addOrUpdateMessages(data.message_list)
        } catch(e) {
          console.error(e)
        }

        return data
      })
      .then(handleResponse)

  } catch(e) {
    console.error(e)

    // TODO probably need to filter which exactly error occurred
    // load from cache

    // liveQuery(() => {
    await db.transaction('r', DEXIE_STORES.MESSAGES, async () => {
      const message_list = await db[DEXIE_STORES.MESSAGES]
        .where('chat_id')
        .equals(id)
        .limit(params.limit ?? Infinity)
        .filter(item => {
          return (
            (params.number_lte === undefined || item.number <= params.number_lte)
            && (params.number_gte === undefined || item.number >= params.number_gte)
          )
        })
        .toArray()

      // TODO add resolving profiles too
      const profile_list: Profile[] = []

      return { message_list, profile_list }
    })
      .then(handleResponse)
  }
}

export async function addOrUpdateMessages(messages: Message[], params?: {
  incrementChatMessagesCountIfNew: boolean
}) {
  if(!messages.length) return

  const profileIdsNecessaryToLoad = []

  // CHECK If messages need to load some profiles
  for(const message of messages) {
    const ids = [
      message?.sender_id,
      message?.forward_from?.sender_id,
      message?.reply_to?.sender_id
    ]

    for(const id of ids) {
      try {
        if(id && !(await getProfileById(id, false))) {
          profileIdsNecessaryToLoad.push(id)
        }
      } catch(e) {
        console.error(e)
      }
    }
  }


  messages.forEach(message => {
    try {
      if(message.type === MessageType.FILE && message.status >= MessageStatus.SENT) {
        uploadingService.deleteMessageUploading(message.chat_id, message.client_message_id)
      }
    } catch(e) {
      console.error(e)
      console.log(messages)
      throw e
    }
  })

  chatsStore.addOrUpdateMessages(messages.map(MessageModelFactory), params)

  db.transaction('rw',
    DEXIE_STORES.MESSAGES,
    DEXIE_STORES.CHATS,
    DEXIE_STORES.OWN_PCPS,
    async () => {
      const messagesByChatId = Object.entries(
        groupBy(messages, ({ chat_id }) => chat_id)
      )

      for(const [chat_id, messages] of messagesByChatId) {
        const chat = await db[DEXIE_STORES.CHATS].get(chat_id)
        const ownPcp = await db.own_pcps.get(chat_id)

        const messagesByClientId = keyBy(messages, ({ client_message_id }) => client_message_id)

        const existingChatMessages = await db[DEXIE_STORES.MESSAGES]
          .where('chat_id').equals(chat_id)
          .toArray()

        for(const message of existingChatMessages) {
          const newMessage = messagesByClientId[message.client_message_id]

          if(newMessage) {
            if(params?.incrementChatMessagesCountIfNew
              && message.status < MessageStatus.SENT
              && chat
            ) {
              chat.count_messages += 1

              await db[DEXIE_STORES.CHATS]
                .update(chat.id, {
                  count_messages: chat.count_messages
                })
            }

            db[DEXIE_STORES.MESSAGES].update(message.id, {
              ...newMessage,
              status: Math.max(message.status, newMessage.status)
            })

            delete messagesByClientId[message.client_message_id]
          }

          // Top up last_read_message_index if necessary
          const messageModel = MessageModelFactory(message)
          if((
            message.status === MessageStatus.READ
            && messageModel.isOwnMessage
            && ownPcp
            && ownPcp?.last_read_message_index < message.number
          )) {
            db[DEXIE_STORES.OWN_PCPS].update(chat_id, {
              last_read_message_index: message.number
            })
          }
        }

        // New messages handling
        for(const message of Object.values(messagesByClientId)) {
          const chat = await db[DEXIE_STORES.CHATS].get(message.chat_id)
          const ownPcp = await db[DEXIE_STORES.OWN_PCPS].get(message.chat_id)
          const messageModel = MessageModelFactory(message)

          if(chat?.type !== ChatType.SELF_CHAT
            && message.status === MessageStatus.SENT
            && !messageModel.isOwnMessage
            && messageModel.version === 0
          ) {
            console.log('sent status 1')

            chatsService.updateMessageStatus({
              chat_id: message.chat_id,
              client_message_id: message.client_message_id,
              message_number: message.number,
              sender_id: message.sender_id
            }, MessageStatus.DELIVERED)
          }

          if(isSystemMessage(messageModel) && isAddedPcpMessage(messageModel)) {
            try {
              // profilesService.loadProfile(message.decodedContent.content.added_profile.id)
              // profilesStore.addOrUpdateProfile(message.decodedContent.content.added_profile)
            } catch(e) {
              console.log(e)
            }
          }

          // Top up last_read_message_index depending on new messages
          if((
            messageModel.isOwnMessage
            && ownPcp?.last_read_message_index
            && ownPcp?.last_read_message_index < message.number
          )
            // || (
            // message.chat?.type === ChatType.CHANNEL
            // && [PcpType.ADMIN, PcpType.OWNER].includes(message.chat?.own_pcp.type)
            // )
          ) {
            db[DEXIE_STORES.OWN_PCPS].update(chat_id, {
              last_read_message_index: message.number
            })
          }

          if(isSystemMessage(messageModel)
            && chat?.type !== ChatType.SELF_CHAT
            && messageModel.isOwnMessage
            && ChatModelFactory(chat as Chat)?.unreadMessagesCount === 0
            && ownPcp
            && ownPcp?.last_read_message_index < message.number
          ) {
            db[DEXIE_STORES.OWN_PCPS].update(chat_id, {
              last_read_message_index: message.number
            })
          }
        }

        if(params?.incrementChatMessagesCountIfNew === true) {
          db[DEXIE_STORES.CHATS].update(chat_id, {
            count_messages: (chat?.count_messages ?? 0) + Object.values(messagesByClientId).length
          })
        }

        existingChatMessages.push(...Object.values(messagesByClientId))

        existingChatMessages.sort(messagesDescComparator)

        db[DEXIE_STORES.MESSAGES].bulkAdd(Object.values(messagesByClientId))

        // this.messages[chat_id] = existingChatMessages
      }
    })

  if(profileIdsNecessaryToLoad.length) {
    await profilesService.loadProfiles(profileIdsNecessaryToLoad)
  }
}

export async function patchMessageByClientMessageId(chatId: Chat['id'], clientMessageId: Message['client_message_id'], data: Partial<Message>) {
  console.log('patch by cmi', chatId, clientMessageId)
  console.trace()
  const id = Math.random()
  console.time('PATH BY CMI TIME' + id)
  chatsStore.updateMessage(chatId, clientMessageId, data)

  await db.transaction('rw', [
      DEXIE_STORES.MESSAGES,
      DEXIE_STORES.CHATS,
      DEXIE_STORES.OWN_PCPS
    ],
    async () => {
      const msg = await db[DEXIE_STORES.MESSAGES]
        .get({ client_message_id: clientMessageId, chat_id: chatId })

      const updateMsgPromises = []

      if(msg) {
        if(data.status && data.status >= MessageStatus.SENDING) {
          updateMsgPromises.push(
            db[DEXIE_STORES.MESSAGES].update(msg.id, {
              ...msg,
              ...data,
              status: Math.max(data.status, msg.status)
            })
          )
        } else {
          updateMsgPromises.push(
            db[DEXIE_STORES.MESSAGES].update(msg.id, data)
          )
        }


        const chat = await db[DEXIE_STORES.CHATS].get(chatId)
        const ownPcp = await db[DEXIE_STORES.OWN_PCPS].get(chatId)

        if(msg && chat && ownPcp && isNumber(msg.number)) {
          if(data?.status === MessageStatus.DELIVERED) {
            const messages = await db[DEXIE_STORES.MESSAGES]
              .where(['chat_id', 'status', 'number'])
              .between(
                [chatId, MessageStatus.SENT, ownPcp.min_message_index],
                [chatId, MessageStatus.SENT, msg.number],
                true, true)
              .toArray()

            updateMsgPromises.push(
              ...messages
                .filter(msg => MessageModelFactory(msg).isOwnMessage)
                .map(msg => {
                  console.log('SET DELIVERED', msg)

                  return db[DEXIE_STORES.MESSAGES].update(msg.id, {
                    status: MessageStatus.DELIVERED
                  })
                })
            )
          } else if(data?.status === MessageStatus.READ && chat.type === ChatType.DIALOG) {
            let newLastRead = ownPcp.last_read_message_index

            const messages = await db[DEXIE_STORES.MESSAGES]
              .where(['chat_id', 'status', 'number'])
              .between(
                [chatId, MessageStatus.DELIVERED, ownPcp.min_message_index],
                [chatId, MessageStatus.DELIVERED, msg.number],
                true, true)
              .toArray()

            updateMsgPromises.push(
              ...messages
                .filter(message => {
                  return MessageModelFactory(message).isOwnMessage
                })
                .map(message => {
                  console.log('SET READ', message)
                  newLastRead = Math.max(ownPcp.last_read_message_index, msg.number)

                  return db[DEXIE_STORES.MESSAGES].update(message.id, {
                    status: MessageStatus.READ
                  })
                })
            )
            // chatsStore.updateMessage(chatId, msg.client_message_id, {
            //   status: MessageStatus.READ
            // })

            if(newLastRead > ownPcp.last_read_message_index) {
              updateMsgPromises.push(
                db[DEXIE_STORES.OWN_PCPS].update(chatId, {
                  last_read_message_index: newLastRead
                })
              )
            }
          }
        }
        await Promise.allSettled(updateMsgPromises)
      } else {
        console.log('NO MESSAGE BY CLIENT ID FOUND')
      }
    })

  // await Promise.all(afterCompleteTasks.map(cb => cb()))
  console.timeEnd('PATH BY CMI TIME' + id)
}

export async function patchMessageInChatByNumber(chatId: Chat['id'], number: Message['number'], data: Partial<Message>) {
  chatsStore.updateChatMessagesByNumber(chatId, number, data)
  await db[DEXIE_STORES.MESSAGES].where({ id: chatId + '_' + number }).modify(data)
}

export function cleanChatMessages(chatId: Chat['id']) {
  chatsStore.cleanChatMessages(chatId)
  return db[DEXIE_STORES.MESSAGES].where({ chat_id: chatId })
    .delete()
}

export async function deleteMessage(chatId: Message['chat_id'], clientMessageId: Message['client_message_id']) {
  chatsStore.deleteMessage(chatId, clientMessageId)
  await db[DEXIE_STORES.MESSAGES].where({
    chat_id: chatId,
    client_message_id: clientMessageId
  }).delete()
}

export async function getChatMessageByNumber(chatId: Chat['id'], number: Message['number']) {
  let msg: Message | undefined = chatsStore.getChatMessageByNumber(chatId, number)

  if(!msg) {
    msg = await db[DEXIE_STORES.MESSAGES].get(`${chatId}_${number}`)
  }

  return msg
    ? MessageModelFactory(msg)
    : msg
}
