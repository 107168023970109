interface Props {
  size?: number
  color?: string
  className?: string
}

export const Info = ({
  size = 10,
  color = '#8E8E93',
  className
}: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.625 7.375H5.375V4.5H4.625V7.375ZM5 3.65C5.11667 3.65 5.2125 3.61033 5.2875 3.531C5.3625 3.452 5.4 3.35417 5.4 3.2375C5.4 3.12917 5.3625 3.03533 5.2875 2.956C5.2125 2.877 5.11667 2.8375 5 2.8375C4.88333 2.8375 4.7875 2.877 4.7125 2.956C4.6375 3.03533 4.6 3.12917 4.6 3.2375C4.6 3.35417 4.6375 3.452 4.7125 3.531C4.7875 3.61033 4.88333 3.65 5 3.65ZM5 9.75C4.34167 9.75 3.723 9.625 3.144 9.375C2.56467 9.125 2.0625 8.7875 1.6375 8.3625C1.2125 7.9375 0.875 7.43533 0.625 6.856C0.375 6.277 0.25 5.65833 0.25 5C0.25 4.34167 0.375 3.72283 0.625 3.1435C0.875 2.5645 1.2125 2.0625 1.6375 1.6375C2.0625 1.2125 2.56467 0.875 3.144 0.625C3.723 0.375 4.34167 0.25 5 0.25C5.65833 0.25 6.27717 0.375 6.8565 0.625C7.4355 0.875 7.9375 1.2125 8.3625 1.6375C8.7875 2.0625 9.125 2.5645 9.375 3.1435C9.625 3.72283 9.75 4.34167 9.75 5C9.75 5.65833 9.625 6.277 9.375 6.856C9.125 7.43533 8.7875 7.9375 8.3625 8.3625C7.9375 8.7875 7.4355 9.125 6.8565 9.375C6.27717 9.625 5.65833 9.75 5 9.75ZM5 9C6.11667 9 7.0625 8.6125 7.8375 7.8375C8.6125 7.0625 9 6.11667 9 5C9 3.88333 8.6125 2.9375 7.8375 2.1625C7.0625 1.3875 6.11667 1 5 1C3.88333 1 2.9375 1.3875 2.1625 2.1625C1.3875 2.9375 1 3.88333 1 5C1 6.11667 1.3875 7.0625 2.1625 7.8375C2.9375 8.6125 3.88333 9 5 9Z'
        fill={color}
      />
    </svg>
  )
}
