import React, { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'

export interface MaskChangeEvent {
  target: {
    name?: string,
    value: string
  }
}

export interface MaskCompleteEvent {
  target: {
    name?: string,
    value: string
  }
}

export interface MaskProps {
  onChange: (event: MaskChangeEvent) => void
  onComplete?: (event: MaskChangeEvent) => void
  mask: any
  name: string
  definitions?: { [key: string]: RegExp | string }
  placeholderChar?: string

  [key: string]: any
}

export const TextMaskCustom = forwardRef<HTMLElement, MaskProps>(
  ({
    onChange,
    onComplete,
    mask,
    name,
    placeholderChar = '_',
    ...rest
  }: MaskProps, ref: any) => {
    const handleAccept = (value: any) => {
      onChange({
        target: {name, value}
      })
    }

    const handleComplete = (value: any) => {
      if(typeof onComplete === 'function') {
        onComplete({
          target: {name, value}
        })
      }
    }
    return (
      <IMaskInput
        lazy={false}
        placeholderChar={placeholderChar}
        mask={mask}
        inputRef={ref}
        onAccept={handleAccept}
        onComplete={handleComplete}
        overwrite
        {...rest}
      />
    )
  }
)
