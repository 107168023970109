import { fireViewOfferStrange, fireViewOwnOffer } from '@/config/events'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { exchangeService } from '@/store/exchange/exchange.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isCargoOffer } from '@roolz/types/api/exchange'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router'

const OfferView = OverlayModalPage(({ onClose }) => {
  const { offer_id } = useParams()

  const globalModals = useGlobalModals()

  async function loadIfNeeded(offer_id: string) {
    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE
      && highestVisibleOverlay?.key === offer_id
    ) {
      return
    }

    // if(globalModals.isVisible(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)) {
    //   const modal = globalModals.getMountedModal(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)
    //   let offer = modal?.props?.offer
    //   if(isPromise(offer)) {
    //     offer = await offer
    //   }
    //
    //   if(offer?._id === offer_id) {
    //     load = false
    //   }
    // }
    const offerId = new Promise((resolve, reject) => {
      exchangeService.loadOffer({ id: offer_id })
        .then(offer => {
          const offer_type = isCargoOffer(offer) ? 'cargo' : 'transport'

          profilesStore.hasAccessToOffer(offer)
            ? fireViewOwnOffer({
              offer_id: offer._id,
              offer_type
            })
            : fireViewOfferStrange({
              is_spa_app: true,
              offer_type,
              offer_id
            })

          resolve(offer._id)
        })
        .catch(e => {
          console.error(e)

          reject(e)
        })
    })

    globalModals.open(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE, {
      key: offer_id,
      props: { id: offerId, onClose },
      config: { onClose }
    })
  }

  useLayoutEffect(() => {
    if(offer_id) {
      loadIfNeeded(offer_id)
    }
  }, [offer_id])

  return null
})

export default OfferView
