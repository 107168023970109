import { useTranslation } from 'react-i18next'
import styles from './MessageUnsupportedContent.module.scss'

interface Props {

}
export const MessageUnsupportedContent = (props: Props) => {
  const { t } = useTranslation('chat/message')

 return (
  <div className={styles.content}>
    {t('unsupported_content')}
  </div>
 )
}
