interface Props {
  className?: string
}

export function FileDark({ className }: Props) {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_28231_63206" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_28231_63206)">
        <path d="M4.20513 14.3336C3.86838 14.3336 3.58333 14.217 3.35 13.9836C3.11667 13.7503 3 13.4652 3 13.1285V2.87213C3 2.53537 3.11667 2.25033 3.35 2.01699C3.58333 1.78366 3.86838 1.66699 4.20513 1.66699H9.00128C9.16196 1.66699 9.31644 1.69819 9.46473 1.76059C9.61302 1.82298 9.74187 1.90888 9.85128 2.01828L12.6487 4.81568C12.7581 4.92509 12.844 5.05394 12.9064 5.20223C12.9688 5.35051 13 5.505 13 5.66568V13.1285C13 13.4652 12.8833 13.7503 12.65 13.9836C12.4166 14.217 12.1316 14.3336 11.7948 14.3336H4.20513ZM9 5.06439C9 5.23618 9.05748 5.37955 9.17245 5.49451C9.28741 5.60948 9.43078 5.66696 9.60257 5.66696H12L9 2.66698V5.06439Z"
              fill="#8E8E93"/>
      </g>
    </svg>
  )
}
