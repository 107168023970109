import { Dayjs } from 'dayjs'
import { isNil } from 'lodash-es'
import { getMaximalDate } from '@roolz/sdk/utils/date'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { State as DatetimePickerState } from '@roolz/sdk/components/ui/fields/DatetimePicker/DatetimePicker'

const getInitDate = (date?: number | null, time?: number | null) => {
  if (isNil(date) && isNil(time)) return null
  if (date) return dayjs(date)

  return dayjs().startOf('day')
}

const getDateTo = (dateFrom?: Dayjs | null, dateTo?: Dayjs | null, timeTo?: number | null) => {
  if (!dateFrom) return null
  if (!isNil(dateTo) && !isNil(timeTo)) return dateTo!.add(timeTo, 'second')

  if (dateTo && isNil(timeTo)) {
    return dateTo.utc()
      .set('hours', 23)
      .set('minutes', 59)
  }

  if (!isNil(timeTo)) {
    return dateFrom.clone()
      .utc()
      .add(timeTo, 'seconds')
  }

  return dateFrom.clone().set('hours', 23)
    .set('minutes', 59)
}

const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

// TODO: in future create utils for date formating
export const pickerDateToString = (datetime: Partial<DatetimePickerState>) => {
  const userTZ = dayjs().tz(dayjs.tz.guess()).utcOffset() / 60
  const utcOffset = (!isNil(datetime?.timezone) ? Number(datetime?.timezone) : userTZ) * -1

  const initDateFrom = getInitDate(datetime?.date_from, datetime?.time_from)

  const dateFrom = initDateFrom ? initDateFrom
    .add(datetime.time_from || 0, 'second')
    .add(utcOffset, 'hour') : null

  const initDateTo = getInitDate(datetime?.date_to || datetime?.date_from, datetime?.time_to)

  const isMaximalDate = dayjs(getMaximalDate()).isSame(datetime?.date_to)

  const isAvailableDate = Boolean(initDateTo || dateFrom) && !isMaximalDate

  const dateTo = isAvailableDate
    ? getDateTo(initDateFrom, initDateTo, datetime.time_to)
    : null

  if(dateTo && !dayjs(getMaximalDate()).isSame(dateTo)) {
    dateTo.add(utcOffset, 'hour')
  }

  // NOTE: if date null, toISOString convert time to string depend from TZ
  // and format work the same like toISOString, but have reverse problem
  return [
    datetime?.date_from ? dateFrom?.toISOString() : dateFrom?.format(ISO_FORMAT),
    (datetime?.date_to || datetime?.date_from)
      ? dateTo?.toISOString()
      : dateTo?.format(ISO_FORMAT),
  ]
}
