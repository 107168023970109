import { ChatItemView } from '@/components/chats/ChatItemView/ChatItemView'
import { SelfChatAvatar } from '@/components/chats/ui/ChatAvatar/ChatAvatar'
import { SearchChatsToForward } from '@/components/modals/SearchChats/ChatsToForward/SearchChatsToForward'
import { GlobalChatsSearch } from '@/components/modals/SearchChats/GlobalChatsSearch/GlobalChatsSearch'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { getProfileLastOnline, getProfileOnlineStatus } from '@/models/Profile.model'
import { DialogContent, InputAdornment, TextField } from '@mui/material'
import { Backspace } from '@roolz/icons/Backspace'
import { Close } from '@roolz/icons/Close'
import { PlaceholderSearch } from '@roolz/icons/PlaceholderSearch'
import { Search } from '@roolz/icons/Search'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Loadable } from '@roolz/sdk/components/ui/Loadable/Loadable'
import { ChatType } from '@roolz/types/api/chats'
import { SearchChatInfo, SearchProfileInfo } from '@roolz/types/api/search'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { forwardRef, Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SearchChats.module.scss'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any

  type: 'global' | 'message_forward'

  Content?: ReactNode

  title: string,
  onChatSelect: (item: ChatItem) => void
}


export type ChatItem = { type: 'chat', chat: SearchChatInfo }
  | { type: 'profile', profile: SearchProfileInfo }

const TRANSITION_DURATION = 500

export const SearchChats = observer(({
  open,
  setOpen,

  type,

  title,
  onChatSelect
}: Props) => {
  const inputRef = useRef<any>()

  const [search, setSearch] = useState<string>('')

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value)
  }, [])


  useEffect(() => {
    if(open) {
      inputRef.current && inputRef.current.focus()
      return
    }

    const timeout = setTimeout(() => {
      setSearch('')
    }, TRANSITION_DURATION)

    return () => clearTimeout(timeout)
  }, [open, inputRef])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{
        root: styles.root,
        paper: styles.paper
      }}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>
          {title}
        </h2>
        <CloseButton onClick={() => setOpen(false)}/>

        <ChatSearchInput
          ref={inputRef}
          value={search}
          onChange={handleSearchChange}
        />
      </div>

      <DialogContent
        sx={{ padding: 0 }}
        className={styles.body}
      >
        {type === 'global' && (
          <GlobalChatsSearch
            search={search}
            onChatSelect={onChatSelect}
          />
        )}
        {type === 'message_forward' && (
          <SearchChatsToForward
            search={search}
            onChatSelect={onChatSelect}
          />
        )}
      </DialogContent>
    </Dialog>
  )
})


// function SegmentedResults({
//   search,
//   results,
//   onChatSelect
// }: {
//   search: string
//   results: Results
//   onChatSelect: Props['onChatSelect']
// }) {
//   const { t } = useTranslation('chat/common')
//
//   const [personalChats, chatsByCompanies, globalChats] = useMemo(() => {
//     const personalChats = []
//     const chatsByCompanies = {}
//     const globalChats = []
//
//     uniqWith(results.chat_list, (a, b) => a.id === b.id)
//       .forEach(chat => {
//         if(!!chat.company_id && myCompaniesStore.companyIds.includes(chat.company_id)) {
//           chatsByCompanies[chat.company_id] ??= []
//           chatsByCompanies[chat.company_id].push({ type: 'chat', chat })
//
//           return
//         }
//         // TODO think if it should look to idb?
//         const ownPcp = chatsStore.getOwnPcp(chat.id)
//         if(ownPcp && ownPcp.status === PcpStatus.ACTIVE) {
//           personalChats.push({ type: 'chat', chat })
//         } else {
//           globalChats.push({ type: 'chat', chat })
//         }
//       })
//
//     uniqWith(results.contact_list, (a, b) => a.id === b.id)
//       .forEach(profile => {
//         if(profile.relationships.is_banned_me) {
//           return
//         }
//
//         const commonCompanies = (profile?.companies || [])
//           .filter(company => myCompaniesStore.companyIds.includes(company.id))
//
//         if(commonCompanies.length) {
//           commonCompanies.forEach(company => {
//             chatsByCompanies[company.id] ??= []
//             chatsByCompanies[company.id].push({ type: 'profile', profile })
//           })
//         } else {
//           // TODO think if it should look to idb?
//           const dialog = chatsStore.getDialogWithUser(profile.id)
//           const ownPcp = ChatModelFactory(dialog).own_pcp
//
//           if((dialog && ownPcp && ownPcp?.status === PcpStatus.ACTIVE)
//             || profile.relationships?.is_personal_contact) {
//             personalChats.push({ type: 'profile', profile })
//           } else {
//             globalChats.push({ type: 'profile', profile })
//           }
//         }
//       })
//
//     return [personalChats, chatsByCompanies, globalChats]
//   }, [search, results.chat_list, results.contact_list])
//
//   const isResultEmpty = useMemo(() => {
//     return !Object.values(chatsByCompanies).length && !Object.values(personalChats).length && !Object.values(globalChats).length
//   }, [chatsByCompanies, personalChats, globalChats])
//
//   const showNothingFoundPlaceholder = search.length
//     && isResultEmpty
//
//   const showAppealPlaceholder = isResultEmpty && !search.length
//
//   return (<>
//     {showAppealPlaceholder ? (
//       <Placeholder
//         title={t('search.appeal.title')}
//         description={t('search.appeal.description')}
//       />
//     ) : showNothingFoundPlaceholder ? (
//       <Placeholder
//         title={t('search.no_results.title', { text: search })}
//         description={t('search.no_results.description')}
//       />
//     ) : (
//       <div className={styles.content}>
//         {Object.entries(chatsByCompanies).map(([company_id, chats]) => (
//           <Fragment key={company_id}>
//             <BlockHeading>
//               {myCompaniesStore.find(company_id)?.name ?? ''}
//               {/*{t('search.sections.private')}*/}
//             </BlockHeading>
//
//             <ChatList
//               chats={chats as any}
//               onChatSelect={onChatSelect}
//             />
//           </Fragment>
//         ))}
//
//         {!!personalChats.length && (
//           <>
//             <BlockHeading>
//               {t('search.sections.private')}
//             </BlockHeading>
//             <ChatList
//               chats={personalChats as any}
//               onChatSelect={onChatSelect}
//             />
//           </>
//         )}
//
//         {!!globalChats.length && (
//           <>
//             <BlockHeading>
//               {t('search.sections.global')}
//             </BlockHeading>
//
//             <ChatList
//               chats={globalChats as any}
//               onChatSelect={onChatSelect}
//             />
//           </>
//         )}
//       </div>
//     )}
//   </>)
// }

// function NonSegmentedResults({
//   search,
//   results,
//   onChatSelect
// }: {
//   search: string
//   results: Results
//   onChatSelect: Props['onChatSelect']
// }) {
//   const { t } = useTranslation('chat/common')
//
//   const [personalChats, chatsByCompanies, globalChats] = useMemo(() => {
//     const personalChats = []
//     const chatsByCompanies = {}
//     const globalChats = []
//
//     uniqWith(results.chat_list, (a, b) => a.id === b.id)
//       .forEach(chat => {
//         if(!!chat.company_id && myCompaniesStore.companyIds.includes(chat.company_id)) {
//           chatsByCompanies[chat.company_id] ??= []
//           chatsByCompanies[chat.company_id].push({ type: 'chat', chat })
//
//           return
//         }
//         // TODO think if it should look to idb?
//         const ownPcp = chatsStore.getOwnPcp(chat.id)
//         if(ownPcp && ownPcp.status === PcpStatus.ACTIVE) {
//           personalChats.push({ type: 'chat', chat })
//         } else {
//           globalChats.push({ type: 'chat', chat })
//         }
//       })
//
//     uniqWith(results.contact_list, (a, b) => a.id === b.id)
//       .forEach(profile => {
//         if(profile.relationships.is_banned_me) {
//           return
//         }
//
//         const commonCompanies = (profile?.companies || [])
//           .filter(company => myCompaniesStore.companyIds.includes(company.id))
//
//         if(commonCompanies.length) {
//           commonCompanies.forEach(company => {
//             chatsByCompanies[company.id] ??= []
//             chatsByCompanies[company.id].push({ type: 'profile', profile })
//           })
//         } else {
//           // TODO think if it should look to idb?
//           const dialog = chatsStore.getDialogWithUser(profile.id)
//           const ownPcp = ChatModelFactory(dialog).own_pcp
//
//           if((dialog && ownPcp && ownPcp?.status === PcpStatus.ACTIVE)
//             || profile.relationships?.is_personal_contact) {
//             personalChats.push({ type: 'profile', profile })
//           } else {
//             globalChats.push({ type: 'profile', profile })
//           }
//         }
//       })
//
//     return [personalChats, chatsByCompanies, globalChats]
//   }, [search, results.chat_list, results.contact_list])
//
//   const isResultEmpty = useMemo(() => {
//     return !Object.values(chatsByCompanies).length && !Object.values(personalChats).length && !Object.values(globalChats).length
//   }, [chatsByCompanies, personalChats, globalChats])
//
//   const showNothingFoundPlaceholder = search.length
//     && isResultEmpty
//
//   const showAppealPlaceholder = isResultEmpty && !search.length
//
//   return (<>
//     {showAppealPlaceholder ? (
//       <Placeholder
//         title={t('search.appeal.title')}
//         description={t('search.appeal.description')}
//       />
//     ) : showNothingFoundPlaceholder ? (
//       <Placeholder
//         title={t('search.no_results.title', { text: search })}
//         description={t('search.no_results.description')}
//       />
//     ) : (
//       <div className={styles.content}>
//         {Object.entries(chatsByCompanies).map(([company_id, chats]) => (
//           <Fragment key={company_id}>
//             <BlockHeading>
//               {myCompaniesStore.find(company_id)?.name ?? ''}
//               {/*{t('search.sections.private')}*/}
//             </BlockHeading>
//
//             <ChatList
//               chats={chats as any}
//               onChatSelect={onChatSelect}
//             />
//           </Fragment>
//         ))}
//
//         {!!personalChats.length && (
//           <>
//             <BlockHeading>
//               {t('search.sections.private')}
//             </BlockHeading>
//             <ChatList
//               chats={personalChats as any}
//               onChatSelect={onChatSelect}
//             />
//           </>
//         )}
//
//         {!!globalChats.length && (
//           <>
//             <BlockHeading>
//               {t('search.sections.global')}
//             </BlockHeading>
//
//             <ChatList
//               chats={globalChats as any}
//               onChatSelect={onChatSelect}
//             />
//           </>
//         )}
//       </div>
//     )}
//   </>)
// }

interface InputProps {
  className?: string

  value?: string | null
  onChange?: (value: string) => void
  onClick?: () => void
}

export const ChatSearchInput = forwardRef(({
  className,

  value,
  onChange,
  onClick
}: InputProps, ref: any) => {
  const { t } = useTranslation('chat/common')

  return (
    <TextField
      inputProps={{
        maxLength: 30
      }}
      className={className}
      classes={{ root: className }}
      InputProps={{
        sx: { height: 40 },

        startAdornment: (
          <InputAdornment
            position='start'
            style={{
              paddingRight: '6px'
            }}
          >
            <Search/>
          </InputAdornment>
        ),

        endAdornment: value?.length ? (
          <InputAdornment
            position='end'
            className={styles.cleanSearch}
            onClick={() => onChange?.('')}
          >
            <Backspace/>
          </InputAdornment>
        ) : null
      }}
      autoFocus
      inputRef={ref}
      value={value}
      placeholder={t('search.placeholder')}
      onChange={event => onChange && onChange(event.target.value)}
      onClick={onClick}
    />
  )
})

interface ChatListProps {
  chats: ChatItem[],
  onChatSelect: (item: ChatItem) => void
}

export function ChatList({
  chats,
  onChatSelect
}: ChatListProps) {
  return <>
    {chats.map(item => (
      item.type === 'chat' ? (
        <ChatItem
          chat={item.chat}
          key={item.chat.id}

          onSelect={() => onChatSelect(item)}
        />
      ) : (
        <ProfileItem
          profile={item.profile}
          key={item.profile.id}

          onSelect={() => onChatSelect(item)}
        />
      )
    ))}
  </>
}


const ProfileItem = observer(function ChatItem({
  profile,
  onSelect
}: {
  profile: SearchProfileInfo,
  onSelect: () => void
}) {
  const {
    avatar,
    first_name,
    last_name
  } = profile.profile_view_info || {}

  const fullName = [first_name, last_name]
    .filter(item => !!item?.length)
    .join(' ')

  const content = `@${profile?.nickname}`

  const isBannedMe = profile.relationships?.is_banned_me


  return (
    <ChatItemView
      style={{ paddingLeft: 16 }}
      nameSlot={fullName}
      avatarSlot={
        <Avatar
          avatarUrl={!isBannedMe ? avatar : undefined}
          first_name={first_name ?? ''}
          last_name={last_name ?? ''}
          color_code={profile?.color ?? ''}
          onlineStatus={!isBannedMe
            ? getProfileOnlineStatus(getProfileLastOnline({
              id: profile.id,
              last_action_time: profile.last_action_time,
              is_banned_me: !!profile.relationships?.is_banned_me
            }))
            : undefined}
          width={40}
        />

      }

      contentSlot={
        <div
          className={styles.chat__content}
          title={content}
        >
          {content}
        </div>
      }

      onChatClick={onSelect}
    />
  )
})

const ChatItem = observer(function ChatItem({
  chat,
  onSelect
}: {
  chat: SearchChatInfo
  onSelect: () => void
}) {
  const { t } = useTranslation('chat/common')

  let content = ''

  if(![ChatType.CHANNEL, ChatType.GROUP_CHAT, ChatType.SELF_CHAT].includes(chat.type)) {
    console.warn('UNEXPECTED NON GROUP NOR SELF CHAT RETURNED BY SEARCH')
    return null
  }

  const name = chat.type === ChatType.SELF_CHAT
    ? t('name.bookmarks')
    : chat.name


  if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
    content = [
      `@${chat.nickname}`,
      t(chat.type === ChatType.CHANNEL ? 'subscribers_count' : 'members_count', { count: chat.count_members })
    ].join(', ')
  }

  return (
    <ChatItemView
      style={{ paddingLeft: 16 }}

      nameSlot={name}
      avatarSlot={
        chat.type === ChatType.SELF_CHAT ? (
          <SelfChatAvatar/>
        ) : (
          <Avatar
            first_name={chat?.name ?? ''}
            last_name={''}
            avatarUrl={chat.avatar ?? ''}
            color_code={chat.color ?? ''}
            width={40}
          />
        )
      }

      contentSlot={
        <div
          className={styles.chat__content}
          title={content}
        >
          {content}
        </div>
      }

      onChatClick={onSelect}
    />
  )
})

export function Loading() {
  return (
    <div className={styles.placeholder}>
      <Loadable loading={true}/>
    </div>
  )
}

function CloseButton(props: any) {
  return (
    <button
      className={styles.close}
      {...props}
    >
      <Close
        className={styles.close__icon}
        color='#8E8E93'
      />
    </button>
  )
}

export function Placeholder({
  title,
  description
}: {
  title: string,
  description: string
}) {
  return (
    <div className={styles.placeholder}>
      <PlaceholderSearch/>

      <div className={styles.placeholder__title}>
        {title}
      </div>
      <div className={styles.placeholder__description}>
        {description}
      </div>
    </div>
  )
}
