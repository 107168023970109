// import { GLOBAL_MODALS_NAMES } from '@/config/global-modals'
import { ModalVisibility } from '@/global-modals/components/GlobalModalContainer'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { uiStore } from '@/store/ui/ui.store'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { isEqual, merge } from 'lodash-es'
import { ComponentType } from 'react'

type MountedModalConfig = {
  closeOnBackdropClick?: boolean
  onClose?: () => void
  onBack?: () => void
  unmountOnClose?: boolean

  // if already opened, close all above
  // closeAllAbove?: boolean,

  // if already opened, raise it to top
  // raise?: boolean
}

const defaultMountedModalConfig: MountedModalConfig = {
  closeOnBackdropClick: true
}

// interface GlobalModalsManagerInterface {
//   registerOverlay: <P extends ModalComponentProps>(id: string, Modal: ComponentType<P>, props?: object) => GlobalModalsManager
//   registerPopup: <P extends ModalComponentProps>(id: string, Modal: ComponentType<P>, props?: object) => GlobalModalsManager
//
//   open: (id: string, modalProps: any, config?: OpenModalProps) => GlobalModalsManager
//
//   raise: (id: string, modalProps?: any) => GlobalModalsManager
//
//   // close: <P>(Modal: ComponentType<P>) => GlobalModalManager
//   close: (id: string) => GlobalModalsManager
//   closeAll: () => GlobalModalsManager
//   closeAllAbove: (id: string) => GlobalModalsManager
//   updateModalConfig: (id: string, config: OpenModalProps) => GlobalModalsManager
//
//   subscribe: (cb: () => void) => GlobalModalsManager
// }

interface ModalsMap {
  [key: string]: RegisteredModal
}

export type MountedModal = {
  modal: RegisteredModal
  key: string
  isVisible: boolean

  props: any
  config: MountedModalConfig
}

export type RegisteredModal = {
  id: string
  Component: ComponentType<any>
  meta?: any
  defaultProps?: { [key: string]: any }
}

export type ModalComponentProps = {
  isShown: boolean,
  setIsShown: (val: boolean, options?: OptionsType) => void
  visibility: ModalVisibility

  [key: string]: any
}

export type OptionsType = {
  unmountOnClose?: boolean
  transitionDuration?: number
}

export class GlobalModalsManager// implements GlobalModalsManagerInterface
{
  private _modals: ModalsMap = {}
  private _mountedModals: MountedModal[] = []

  private subscribers: Array<() => void> = []

  /**
   * All modals that should be mounted with overlays and replace each other
   */
  // get mountedOverlayModals(): ModalInfo[] {
  //   return this._modals.filter(item => item.modal.type === 'overlay')
  // }
  /**
   * Modals that doesn't have overlay, only close button and replace only popups of the same type
   */
  // get mountedPopups(): ModalInfo[] {
  //   return this._modals.filter(item => item.modal.type === 'popup')
  // }

  get visibleModals(): MountedModal[] {
    return this._mountedModals.filter(({ isVisible }) => isVisible)
  }

  get mountedModals() {
    return this._mountedModals
  }

  // get activeModal(): ModalInfo | undefined {
  //   if(!this.visibleModals.length) {
  //     return undefined
  //   }
  //
  //   return this.visibleModals.slice(-1)[0]
  // }

  // get visibleInactiveModals(): ModalInfo[] {
  //   return this.visibleModals.slice(1, this.visibleModals.length - 1)
  // }

  register<Props>(
    id: string,
    Component: ComponentType<Props>,
    params?: {
      defaultProps?: object
      meta?: any
    }) {
    if(id in this._modals) {
      console.warn('Modal with id ' + id + ' is already registered')
    }

    this._modals[id] = {
      id,
      Component,
      ...params
    }
  }

  close(id: string, options?: OptionsType): GlobalModalsManager {
    const {
      unmountOnClose = false,
    } = options ?? {}

    this._mountedModals.forEach(modal => {
      if(modal.modal.id === id && modal.isVisible) {
        modal.isVisible = false
      }
    })

    if(unmountOnClose) {
      this.unmount(id, options)
    }
    this.broadcastChange()

    return this
  }

  unmount(id: string, options?: OptionsType) {
    const { transitionDuration = 300 } = options || {}
    setTimeout(() => {

      this._mountedModals = this._mountedModals.filter(modal => modal.modal.id !== id)
      this.broadcastChange()
    }, transitionDuration)

    return this
  }

  closeAll(): GlobalModalsManager {
    this._mountedModals.forEach(modal => this.close(modal.modal.id))

    return this
  }

  // back(type: 'overlay' | 'popup') {
  //   let last
  //   if(type === 'overlay') {
  //     last = this.overlayHistory.splice(-1, 1)?.[0]
  //   } else {
  //     last = this.popupHistory.splice(-1, 1)?.[0]
  //   }
  //   if(!last) {
  //     return
  //   }
  //   this.open(last.id, last.props, last.config, false)
  // }

  open<Props = any>(id: string, params?: {
    key?: string
    props?: Omit<Props, 'isShown' | 'setIsShown'>,
    config?: MountedModalConfig
  }): GlobalModalsManager {
    chatSessionsStore.isChatExpanded = false

    const modal = this._modals[id]

    if(!modal) {
      console.warn('Modal with id ' + id + ' isn`t registered')
    }

    // if(modalInfo.type === 'popup') {
    //   this.closeAllOfType('popup')
    // }

    if(this.isMounted(id)) {
      this._mountedModals = this._mountedModals
        .filter(modal => modal.modal.id !== id)
    }

    this._mountedModals.push({
      key: params?.key ?? String(Math.random()),
      props: params?.props ?? {},
      config: merge(params?.config, defaultMountedModalConfig),
      isVisible: true,

      modal
    })

    this.broadcastChange()

    return this
  }

  isVisible(id: string): boolean {
    return this.getMountedModal(id)?.isVisible ?? false
  }

  isMounted(id: string): boolean {
    return this.getMountedModal(id) !== undefined
  }

  getMountedModal(id: string) {
    return this._mountedModals.find(({ modal }) => modal.id === id)
  }

  // closeAllAbove(id: string): GlobalModalsManager {
  //   const index = this.getModalIndex(id)
  //
  //   this._mountedModals.slice(index)
  //     .forEach(modal => {
  //       if(modal.isVisible) {
  //         modal.isVisible = true
  //
  //         this.broadcastChange()
  //       }
  //     })
  //
  //
  //   return this
  // }

  // updateModalConfig(id: string, config: OpenModalProps): GlobalModalsManager {
  //   const index = this.getModalIndex(id)
  //
  //   if(index !== -1) {
  //     this._modals[index].config = config
  //
  //     this.broadcastChange()
  //   }
  //
  //   return this
  // }

  subscribe(cb: () => void): GlobalModalsManager {
    if(!this.subscribers.find(item => item === cb)) {
      this.subscribers.push(cb)
    }

    return this
  }

  // makeVisible(id: string) {'
  //   const index = this.getModalIndex(id)
  //
  //   if(index !== -1) {
  //     this._modals[index].visible = true
  //
  //     this.broadcastChange()
  //   }
  // }

  private broadcastChange() {
    this.subscribers.forEach(cb => cb())
  }
}
