import { fireApplyOffersFilterFreight, fireApplyOffersFilterVehicle, fireClearFilter } from '@/config/events'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { OffersFilterStore } from '@/store/exchange/offers-filter.store'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { GetOffersResponse, Offer } from '@roolz/types/api/exchange'
import { isNotUndefined } from '@/utils/types'
import { makeAutoObservable } from 'mobx'

export interface OffersWithPaginationInfo {
  page: number
  total: number
  overall_transport: number
  overall_cargo: number
  offerIds: Array<Offer['_id']>
  newOfferIds: Array<Offer['_id']>
  isLoading: boolean
}

export class PublicExchangeOffersStore {
  exact: OffersWithPaginationInfo = {
    page: 1,
    offerIds: [],
    newOfferIds: [],
    total: 0,
    overall_cargo: 0,
    overall_transport: 0,
    isLoading: false
  }

  non_exact: OffersWithPaginationInfo = {
    page: 1,
    offerIds: [],
    newOfferIds: [],
    total: 0,
    overall_cargo: 0,
    overall_transport: 0,
    isLoading: false
  }

  constructor() {
    makeAutoObservable(this)
  }

  get exactOffers(): Offer[] {
    return this.exact.offerIds
      .map(_id => exchangeStore.findOffer(_id))
      .filter(isNotUndefined)
      .filter(offer => offer.deleted_at === null)
      .sort((a, b) => dayjs(b.published_at).unix() - dayjs(a.published_at).unix())
  }

  get nonExactOffers(): Offer[] {
    return this.non_exact.offerIds
      .map(_id => exchangeStore.findOffer(_id))
      .filter(isNotUndefined)
      .filter(offer => offer.deleted_at === null)
      .sort((a, b) => dayjs(b.published_at).unix() - dayjs(a.published_at).unix())
  }

  fireAmplitudaEvents(data: GetOffersResponse, filterParams: ReturnType<InstanceType<typeof OffersFilterStore>['createParamsByFilter']>) {
    const { offer_type, ...chosen_values } = filterParams

    const filled_fields = Object.entries(chosen_values)
      .filter(([_, value]) => isNotUndefined(value))
      .map(([key]) => key)

    if(filled_fields.length) {
      if(offer_type === 'cargo') {
        fireApplyOffersFilterFreight({
          filled_fields,
          chosen_values,
          exact_result_count: data.count
          // partial_result_count:
        })
      } else if(offer_type === 'transport') {
        fireApplyOffersFilterVehicle({
          filled_fields,
          chosen_values,
          exact_result_count: data.count
          // partial_result_count:
        })
      }
    } else {
      fireClearFilter({
        is_spa_app: true,
        place: 'exchange'
      })
    }
  }

  resetExactOffers() {
    this.exact.page = 1
    this.exact.offerIds = []
    this.exact.total = 0
  }

  resetNonExactOffers() {
    this.non_exact.page = 1
    this.non_exact.offerIds = []
    this.non_exact.total = 0
  }
}

export const publicExchangeOffers = new PublicExchangeOffersStore()
export const publicExchangeOffersFilter = new OffersFilterStore()
