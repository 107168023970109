import { useEffect, useLayoutEffect, useRef } from 'react'

export function useStateRef<T>(state: T) {
  const ref = useRef<T>(state)
  useEffect(() => {
    ref.current = state
  }, [state])

  return ref
}

export function useLayoutStateRef<T>(state: T) {
  const ref = useRef<T>(state)
  useLayoutEffect(() => {
    ref.current = state
  }, [state])

  return ref
}
