import { memo } from 'react'
import { Field } from 'react-final-form'
import { FieldErrorTooltip } from '@roolz/sdk/components/ui/FieldErrorTooltip/FieldErrorTooltip'
import { SelectField, SelectFieldProps } from './SelectField'

interface Props extends SelectFieldProps {
  formName?: string
}

export const Select = memo((props: Props) => {
  const { formName, ...rest } = props

  if (!formName) return <SelectField {...rest} />

  return (
    <Field name={formName}>
      {({ input, meta}) => {
        const isError = meta.error && meta.touched
        const errorMessage = meta.error

        return (
          <FieldErrorTooltip message={isError && errorMessage}>
            <SelectField
              {...input}
              {...rest}
            />
          </FieldErrorTooltip>
        )
      }}
    </Field>
  )
})

// TODO: in future remove this field
export type { SelectItem } from './SelectField'

Select.displayName = 'Select'
