import { Fade } from '@mui/material'
import { useDrop } from 'ahooks'
import { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ChatDroppableArea.module.scss'
import cn from 'classnames'

interface Props {
  children: ReactNode
  enabled?: boolean

  className?: string

  onFilesDropped: (files: File[]) => void
}

export const ChatDroppableArea = ({
  children,
  enabled = true,
  className,

  onFilesDropped
}: Props) => {
  const { t } = useTranslation('chat/common')

  const ref = useRef<any>()

  const [show, setShow] = useState(false)

  useDrop(() => ref.current, {
    onDragEnter: () => {
      if(!enabled) return

      setShow(true)
    },
    onDragLeave: () => {
      if(!enabled) return

      setShow(false)
    },
    onDrop: (e) => {
      if(!enabled) return

      setShow(false)
    },
    onFiles: (files) => {
      console.log('ON FILES', files)
      if(!enabled) return

      setShow(false)

      onFilesDropped(files)
    }
  })

  return (
    <div
      ref={ref}
      className={cn(styles.root, className)}
    >
      {children}

      <Fade in={show}>
        <div className={styles.dropPlaceholder}>
          <div className={styles.dropPlaceholder__content}>
            {t('drop_attachment_area.drop_here')}
            <b>{t('drop_attachment_area.without_compressing')}</b>
          </div>
        </div>
      </Fade>
    </div>
  )
}
