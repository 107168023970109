import { Country } from '@roolz/types/custom'
import { Url } from '@roolz/types/scalars'
import * as yup from 'yup'

export interface EditCompanyForm {
  logo_path?: Url | null
  name?: string
  company_form?: string
  legal_form_short?: string
  legal_name?: string
  tax_number?: string | null
  registration_date?: string | null
  associations?: string[]
  mail_address?: string
  legal_address?: string
  // registration_number?: string
  is_transport_license?: boolean | null
  is_transport_license_company?: boolean | null
  transport_license_number?: string
  is_forwarder_license?: boolean | null
  is_forwarder_license_company?: boolean | null
  forwarder_license_number?: string
  registration_country?: Country
}

export const fullEditCompanySchema = {
  logo_path: yup.string().nullable(),
  name: yup.string().trim().required().min(2).max(55),
  company_form: yup.string().trim().required(),
  registration_country: yup.mixed().required(),
  legal_form_short: yup.string().trim().nullable().max(10),
  legal_name: yup.string().trim().nullable().max(255),
  // registration_number: yup.string().trim().nullable().min(5).max(55),
  tax_number: yup.string().trim().max(55).nullable(),
  registration_date: yup.string().nullable(),
  associations: yup.array().of(yup.string()).nullable(),
  mail_address: yup.string().nullable().max(255),
  legal_address: yup.string().nullable().max(255),
  is_transport_license: yup.boolean().default(false).nullable(),
  is_transport_license_company: yup.boolean().default(false).nullable(),
  transport_license_number: yup.string().when('is_transport_license', {
    is: true,
    then: yup.string().nullable().max(55).required(),
    otherwise: yup.string().nullable()
  }),
  is_forwarder_license: yup.boolean().default(false).nullable(),
  is_forwarder_license_company: yup.boolean().default(false).nullable(),
  forwarder_license_number: yup.string().when('is_forwarder_license', {
    is: true,
    then: yup.string().nullable().max(55).required(),
    otherwise: yup.string().nullable()
  })
}

export interface UpdateCompanyForm {
  description?: string,
  foundation_year?: number
}

export const fullUpdateCompanySchema = {
  description: yup.string().nullable(),
  foundation_year: yup.string().nullable()
}

export interface UpdateCompanyServicesForm {
  transportation_type?: string[]
  cargo_type?: string[]
  logistics_service?: string[]
  customs_service?: string[]
}

export const fullUpdateCompanyServicesSchema = {
  transportation_type: yup.array().of(yup.string()).nullable(),
  cargo_type: yup.array().of(yup.string()).nullable(),
  logistics_service: yup.array().of(yup.string()).nullable(),
  customs_service: yup.array().of(yup.string()).nullable()
}

export interface UpdateCompanyDirectionsForm {
  international_direction?: Country[]
  national_directions?: Country[]
}

export const fullUpdateCompanyDirectionsSchema = {
  international_direction: yup.array().of(yup.string()),
  national_directions: yup.array().of(yup.string())
}

export interface UpdateCompanyContactsForm {
  email?: string
  phone?: string
  website?: string
}

export const fullUpdateCompanyContactsSchema = {
  email: yup.string().trim().required().email().max(255),
  phone: yup.string().matches(/^\+?[\s0-9()-]{0,30}$/, 'invalid_phone').max(25).nullable(),
  website: yup.string()
    .trim()
    .matches(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
      excludeEmptyString: true, message: 'url'
    })
    .max(255)
}

export interface UpdateCompanyMainForm {
  momo?: string
  exchange_role: string
  order_amount: string
  employee_amount?: string | null
  fleet_amount?: string | null
}

export const fullUpdateCompanyMainSchema = {
  momo: yup.string().nullable().max(255),
  // TODO exclude translation of 'exchange_role_min_items' to validation namespace
  exchange_role: yup.string().nullable().required(),
  employee_amount: yup.string().nullable(),
  fleet_amount: yup.string().nullable().required()
}
