import { useEventListener } from 'ahooks'
import { useRef } from 'react'

export const IosSafariFocusBoxesFix = () => {
  const initialScrollPos = useRef(0)

  useEventListener('focusin', () => {
      initialScrollPos.current = window.scrollY
      document.body.classList.add('no-scroll')
    }, {
      target: document
    }
  )

  useEventListener('focusout', () => {
    setTimeout(() => {
      document.body.classList.remove('no-scroll')
      window.scrollTo(0, initialScrollPos.current)
    }, 300) // Delay to allow keyboard to fully close
  }, {
    target: document
  })

  return <></>
}
