import { FilePreviewList } from '@/components/chats/ui/FilePreviewList/FilePreviewList'
import { MAX_FILES_FOR_MESSAGE_COUNT } from '@/config/const'
import { useConfirmation } from '@/confirmation/ConfirmationContext'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { uploadingService } from '@/store/uploadings/uploading.service'
import { uploadingStore } from '@/store/uploadings/uploading.store'
import Button, { Color, Variant } from '@roolz/sdk/components/ui/buttons/Button/Button'
import { MB } from '@roolz/sdk/const'
import { MessageType } from '@roolz/types/api/chats'
import { FileContent } from '@roolz/types/api/messaging/message'
import { UploadingStatus, UploadingType } from '@roolz/types/client/uploadings'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'

export function useSendMedia() {
  const { t } = useTranslation('chat/common')
  const { confirm, close } = useConfirmation()
  // const [files, setFiles] = useState<File[]>([])

  const handleClose = useCallback(() => {
    totalFiles.current = []
    close()
  }, [close])

  const totalFiles = useRef<File[]>([])

  function handleFilesChange(files: File[]) {
    totalFiles.current = files
    if(!files.length) {
      handleClose()
    }
  }

  const onFilesSelected = useCallback((items: File[]) => {
    if(chatsStore.activeChatId && uploadingStore.getNonCompletedUploadingsByChat(chatsStore.activeChatId).length) {
      return toastError(t('chat/common:wait_for_chat_uploadings_complete', {
        count: MAX_FILES_FOR_MESSAGE_COUNT
      }))
    }

    if(items.length > MAX_FILES_FOR_MESSAGE_COUNT) {
      toastWarning(t('chat/common:max_files_count_error', {
        count: MAX_FILES_FOR_MESSAGE_COUNT
      }))
    }

    items = items.slice(0, MAX_FILES_FOR_MESSAGE_COUNT)

    for(const file of items) {
      if(file.size > MB * 500) {
        toastWarning(t('chat/common:max_file_size_error', { size: 500 }))

        return
      }
    }

    totalFiles.current = items

    showConfirmation()
  }, [chatsStore.activeChatId])

  const onKeydown = useCallback((event: any) => {
    event.stopPropagation()

    if(event.key === 'Enter') {
      return handleConfirmed()
    }

    if(event.key === 'Escape') {
      return handleClose()
    }
  }, [])

  const showConfirmation = useCallback(() => {
    confirm({
      onClose: () => {
        window.removeEventListener('keydown', onKeydown, { capture: true })
        totalFiles.current = []
      },

      title: t('send_file_confirmation.title'),
      content: (
        <FilePreviewList
          files={totalFiles.current}
          onFilesChange={handleFilesChange}
        />
      ),
      actions: (<>
        <Button
          variant={Variant.text}
          color={Color.danger}
          onClick={handleClose}
        >
          {t('send_file_confirmation.abort')}
        </Button>
        <Button onClick={handleConfirmed}>{t('send_file_confirmation.confirm')}</Button>
      </>),
      onConfirm: handleConfirmed
    })

    window.addEventListener('keydown', onKeydown, { capture: true })
  }, [])

  const handleConfirmed = () => {
    // document.removeEventListener('keydown', onKeydown, { capture: true })

    totalFiles.current.forEach(async file => {
      const clientMessageId = uuidv4()// + ':' + Date.now()

      if(!chatsStore.activeChat?.id) return

      // TODO think if it should lay here, on in submitMessage method
      await uploadingService.createUploading({
        status: UploadingStatus.Waiting,
        file_type: 'file',
        is_avatar: false,
        is_public: false,
        upload_offset: 0,

        file,
        created_at: new Date().toString(),
        type: UploadingType.Message,

        chat_id: chatsStore.activeChat?.id,
        client_message_id: clientMessageId,
      })

      chatsService.submitMessage({
        created_at: new Date().toString(),
        chat_id: chatsStore.activeChat?.id,
        client_message_id: clientMessageId,
        type: MessageType.FILE,

        content: JSON.stringify({
          content: {
            id: '',
            duration: 0,
            height: 0,
            width: 0,
            name: file.name,
            size: file.size,
            thumbnail: null,
            url: ''
          }
        } as FileContent),
      })
    })
    // if(appWorker) {
    //   // TODO REPLACE BY safePostMessage
    //   files.forEach(file => {
    //     appWorker.postMessage({
    //       type: CLIENT_MESSAGES.SEND_FILE_MESSAGE,
    //       body: { file }
    //     })
    //   })
    // }

    handleClose()
  }

  return {
    onFilesSelected
  }
}
