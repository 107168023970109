import { api } from '@/api'
import { Company } from '@roolz/types/api/companies'
import { GetCompanyMembersRequest } from '@roolz/types/api/companyMembers'
import { companyMembersStore } from '@/store/companyMembers/companyMembers.store'
import { runInAction } from 'mobx'

class CompanyMembersService {
  resetMembersAndInvitations() {
    companyMembersStore.resetMembersAndInvitations()
  }

  loadCompanyMembers(companyId: Company["id"], params: GetCompanyMembersRequest) {
    return api.companies.getCompanyMembers(companyId, params).then(({ data }) => {
      const {
        items,
        total,
        ...statuses
      } = data

      runInAction(() => {
        companyMembersStore.setMembers(items)
        companyMembersStore.setMembersTotalCount(total)
        // companyMembersStore.setMemberStatuses(statuses)
      })

      return data
    })
  }

  loadMoreMembers(companyId: Company["id"], params: GetCompanyMembersRequest) {
    return api.companies.getCompanyMembers(companyId, params).then(({ data }) => {
      const {
        items,
        total,
        ...statuses
      } = data

      runInAction(() => {
        companyMembersStore.setMembers([...companyMembersStore.members, ...items])
        companyMembersStore.setMembersTotalCount(total)
        // companyMembersStore.setMemberStatuses(statuses)
      })

      return data
    })
  }

  loadCompanyInvitations(companyId: Company["id"], params: GetCompanyMembersRequest) {
    return api.companies.getCompanyInvitations(companyId, params).then(({ data }) => {
      const {
        items,
        total,
      } = data

      runInAction(() => {
        companyMembersStore.setInvitations(items)
        companyMembersStore.setInvitationsTotalCount(total)
      })

      return data
    })
  }

  loadMoreInvitations(companyId: Company["id"], params: GetCompanyMembersRequest) {
    return api.companies.getCompanyInvitations(companyId, params).then(({ data }) => {
      const {
        items,
        total,
      } = data

      runInAction(() => {
        companyMembersStore.setInvitations([...companyMembersStore.invitations, ...items])
        companyMembersStore.setInvitationsTotalCount(total)
      })

      return data
    })
  }

  loadStatuses = (companyId: Company["id"]) => {
    return api.companies.getInvitationsStatuses(companyId)
      .then(({ data }) => {
        companyMembersStore.setMemberStatuses(data)
      })
  }
}

export const companyMembersService = new CompanyMembersService()
