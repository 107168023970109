import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import styles from './Card.module.scss'
import React, { FC, memo, ReactNode } from 'react'

interface Props {
  Icon: ReactNode

  title: ReactNode
  description: string
}

export const Card = memo(({
  Icon,
  title,
  description
}: Props) => {
  return (
    <div className={styles.root}>
      <MaterialSymbolIcon icon={Icon} size={20} className={styles.icon}/>
      <h2 className={styles.title}>
        {title}
      </h2>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
})
