import { ROUTE_NAMES } from '@/config/routes'
import { useEffect } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router'

export default function Fallback() {
  const navigate = useNavigate()
  const location = useLocation()
  const match = useMatch(location.pathname)

  useEffect(() => {
    console.log('FALLBACK MATCH', match)
  }, [match])

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(ROUTE_NAMES.PUBLIC_EXCHANGE)
      clearTimeout(timeout)
    }, 500)
  }, [])

  return <></>
}
