import { lazyWithSuspense } from '@/components/utils/LazyWithSuspense'
import { GlobalModalContainer } from '@/global-modals/components/GlobalModalContainer'
import { GlobalModalsContext } from '@/global-modals/GlobalModalsContext'
import { GlobalModalsManager } from '@/global-modals/GlobalModalsManager'
import { uiStore } from '@/store/ui/ui.store'
import { ComponentType, memo, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ModalType } from './const'

const ContactList = lazyWithSuspense(() => import('@/components/contactList/ContactList'), null)
const OfferViewAside = lazyWithSuspense(() => import('@/components/offers/view/OfferViewAside/OfferViewAside'), null)
const ProfileOffersAside = lazyWithSuspense(() => import('@/components/profile/ProfileOffersAside/ProfileOffersAside'), null)
const AsideCompanyPage = lazyWithSuspense(() => import('@/components/companies/View/CompanyViewAside'), null)
const CargoFormAside = lazyWithSuspense(() => import('@/components/offers/form/CargoFormAside/CargoFormAside'), null)
const TransportFormAside = lazyWithSuspense(() => import('@/components/offers/form/TransportFormAside/TransportFormAside'), null)
const ProfileViewAside = lazyWithSuspense(() => import('@/components/profile/ProfileViewAside/ProfileViewAside'), null)
const OfferMatchesViewAside = lazyWithSuspense(() => import('@/components/offers/view/MatchesViewAside/MatchesViewAside'), null)
const GroupAside = lazyWithSuspense(() => import('@/components/privateExchange/widgets/groups/GroupAside/GroupAside'), null)
const ManagePartnersAside = lazyWithSuspense(() => import('@/components/privateExchange/widgets/partners/ManagePartnersAside/ManagePartnersAside'), null)
const GroupDetailsAside = lazyWithSuspense(() => import('@/components/privateExchange/widgets/groups/GroupDetailsAside/GroupDetailsAside'), null)
const SendersAside = lazyWithSuspense(() => import('@/components/privateExchange/widgets/partners/PartnersSelectionAside/PartnersSelectionAside'), null)
const RepresentativesAside = lazyWithSuspense(() => import('@/components/companies/Cards/RepresentativesAside'), null)
const ResponsibleAside = lazyWithSuspense(() => import('@/components/privateExchange/widgets/partners/ResponsibleAside/ResponsibleAside'), null)

interface GlobalModalsProviderProps {
  manager?: GlobalModalsManager,
  children: ReactNode
}

/**
 * Modal names
 */
export enum GLOBAL_MODALS_NAMES {
  CREATE_CARGO = 'createCargo',
  CREATE_TRANSPORT = 'createTransport',
  CONTACT_LIST = 'contactList',
  OFFER_VIEW_ASIDE = 'offerView',
  OFFER_MATCHES_VIEW_ASIDE = 'offerMatches',
  PROFILE_VIEW = 'profileView',
  COMPANY_VIEW = 'companyViewAside',
  PROFILE_OFFERS_ASIDE = 'profileOffersAside',
  GROUP_ASIDE = 'groupAside',
  MANAGE_PARTNERS_ASIDE = 'managePartnersAside',
  GROUP_DETAILS_ASIDE = 'groupDetailsAside',
  SENDERS_ASIDE = 'sendersAside',
  RESPONSIBLE_ASIDE = 'responsibleAside',
  REPRESENTATIVES_ASIDE = 'representativesAside'
}

const registerOverlay = (manager: GlobalModalsManager, name: string, Component: ComponentType<any>) => {
  manager.register(name, Component, { meta: { type: ModalType.OVERLAY } })
}

const registerPopup = (manager: GlobalModalsManager, name: string, Component: ComponentType<any>) => {
  manager.register(name, Component, { meta: { type: ModalType.POPUP } })
}

export const GlobalModalsProvider = memo((props: GlobalModalsProviderProps) => {
  const { manager, children } = props

  const navigate = useNavigate()
  const location = useLocation()

  const _manager = useMemo(() => {
    return manager || new GlobalModalsManager()
  }, [manager])

  useLayoutEffect(() => {
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.CREATE_CARGO, CargoFormAside)
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.CREATE_TRANSPORT, TransportFormAside)
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE, OfferViewAside)
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.OFFER_MATCHES_VIEW_ASIDE, OfferMatchesViewAside)
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.COMPANY_VIEW, AsideCompanyPage)
    registerOverlay(_manager, GLOBAL_MODALS_NAMES.PROFILE_OFFERS_ASIDE, ProfileOffersAside)

    registerPopup(_manager, GLOBAL_MODALS_NAMES.CONTACT_LIST, ContactList)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.PROFILE_VIEW, ProfileViewAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.GROUP_ASIDE, GroupAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.MANAGE_PARTNERS_ASIDE, ManagePartnersAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.GROUP_DETAILS_ASIDE, GroupDetailsAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.SENDERS_ASIDE, SendersAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.RESPONSIBLE_ASIDE, ResponsibleAside)
    registerPopup(_manager, GLOBAL_MODALS_NAMES.REPRESENTATIVES_ASIDE, RepresentativesAside)
  }, [_manager])

  const closeAll = useCallback(() => {
    if(_manager) {
      _manager.closeAll()

      if(location.state?.backgroundLocation) {
        navigate(location.state?.backgroundLocation)
      }
    }
  }, [_manager, location])

  useEffect(() => {
    uiStore.closeAllGlobalModals = closeAll
  }, [_manager, location])

  return (
    <GlobalModalsContext.Provider value={{ modalManager: _manager }}>
      <GlobalModalContainer/>
      {children}
    </GlobalModalsContext.Provider>
  )
})
