interface Props {
  color?: string
  size?: number
  style?: any
}

export const LockIcon = ({ style, color = '#8E8E93', size = 16 }: Props) => {
  return (
    <svg style={style} width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.99984 14.6665C3.63317 14.6665 3.31928 14.5359 3.05817 14.2748C2.79706 14.0137 2.6665 13.6998 2.6665 13.3332V6.6665C2.6665 6.29984 2.79706 5.98595 3.05817 5.72484C3.31928 5.46373 3.63317 5.33317 3.99984 5.33317H4.6665V3.99984C4.6665 3.07762 4.9915 2.2915 5.6415 1.6415C6.2915 0.991504 7.07761 0.666504 7.99984 0.666504C8.92206 0.666504 9.70817 0.991504 10.3582 1.6415C11.0082 2.2915 11.3332 3.07762 11.3332 3.99984V5.33317H11.9998C12.3665 5.33317 12.6804 5.46373 12.9415 5.72484C13.2026 5.98595 13.3332 6.29984 13.3332 6.6665V13.3332C13.3332 13.6998 13.2026 14.0137 12.9415 14.2748C12.6804 14.5359 12.3665 14.6665 11.9998 14.6665H3.99984ZM7.99984 11.3332C8.3665 11.3332 8.68039 11.2026 8.9415 10.9415C9.20261 10.6804 9.33317 10.3665 9.33317 9.99984C9.33317 9.63317 9.20261 9.31928 8.9415 9.05817C8.68039 8.79706 8.3665 8.6665 7.99984 8.6665C7.63317 8.6665 7.31928 8.79706 7.05817 9.05817C6.79706 9.31928 6.6665 9.63317 6.6665 9.99984C6.6665 10.3665 6.79706 10.6804 7.05817 10.9415C7.31928 11.2026 7.63317 11.3332 7.99984 11.3332ZM5.99984 5.33317H9.99984V3.99984C9.99984 3.44428 9.80539 2.97206 9.4165 2.58317C9.02761 2.19428 8.55539 1.99984 7.99984 1.99984C7.44428 1.99984 6.97206 2.19428 6.58317 2.58317C6.19428 2.97206 5.99984 3.44428 5.99984 3.99984V5.33317Z'
        fill={color}
      />
    </svg>
  )
}
