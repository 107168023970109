import { ProfileModelFactory } from '@/models/Profile.model'
import { Avatar, Props as AvatarProps } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Profile } from '@roolz/types/api/profiles'
import { observer } from 'mobx-react-lite'

type Props = {
  profile: Profile
  showOnline?: boolean
} & Omit<AvatarProps, 'avatarUrl' | 'first_name' | 'last_name' | 'color_code'>

export const ProfileAvatar = observer(({
  profile,
  showOnline,
  ...rest
}: Props) => {
  const isBannedMe = profile?.relationships?.is_banned_me

  const profileModel = ProfileModelFactory(profile)

  return (
    <Avatar
      avatarUrl={!isBannedMe ? profile?.profile_view_info?.avatar : undefined}
      first_name={profile?.profile_view_info?.first_name ?? ''}
      last_name={profile?.profile_view_info?.last_name ?? ''}
      color_code={profile?.color ?? ''}
      onlineStatus={showOnline === true && profileModel?.isActive && !isBannedMe
        ? profileModel?.onlineStatus
        : undefined}
      {...rest}
    />
  )
})
