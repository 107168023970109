import { api } from '@/api'
import { CLIENT_MESSAGES } from '@/config/workers'
import { db, DEXIE_STORES } from '@/database'
import { profilesStore } from '@/store/profiles/profiles.store'
import { uploadingStore } from '@/store/uploadings/uploading.store'
import { appWorker } from '@/workers/app-worker/useAppWorker'
import { Message } from '@roolz/types/api/chats'
import { uploadFileMultipartRequest } from '@roolz/types/api/files'
import { Uploading } from '@roolz/types/client/uploadings'
import { v4 as uuidv4 } from 'uuid'

class UploadingService {
  uploadFileByMultipart(data: Omit<uploadFileMultipartRequest, 'profile_id'>) {
    return api.fileServer.uploadFileMultipart({
      ...data,
      profile_id: profilesStore.my_profile?.id ?? ''
    })
      .then(({ data }) => {
        return data
      })
  }

  async createUploading(data: Omit<Uploading, 'id'>) {
    console.log('UPLOADINGS: create', data)

    const model: Uploading = {
      id: uuidv4(),
      ...data
    }

    const { file, ...rest } = model

    await db[DEXIE_STORES.UPLOADINGS].put(rest)
    uploadingStore.addUploading(model)

    appWorker?.postMessage({
      type: CLIENT_MESSAGES.ADD_UPLOADING,
      body: model
    })

    return model
  }

  async deleteMessageUploading(chatId: Message['chat_id'], clientMessageId: Message['client_message_id']) {
    console.log('UPLOADINGS: delete', chatId, clientMessageId)

    const uploadings = await db[DEXIE_STORES.UPLOADINGS]
      .filter(item => (
        item.type === 'message'
        && item.client_message_id === clientMessageId
        && item.chat_id === chatId
      )).toArray()

    if(uploadings.length) {
      uploadings.forEach(({ id }) => {
        this.cancelUploading(id)
      })
    }
  }

  async cancelUploading(id: Uploading['id']) {
    console.log('UPLOADINGS: cancel', id)

    await db[DEXIE_STORES.UPLOADINGS].delete(id)
    uploadingStore.removeUploading(id)

    appWorker?.postMessage({
      type: CLIENT_MESSAGES.CANCEL_UPLOADING,
      body: id
    })

    return id
  }
}

export const uploadingService = new UploadingService()
