import { Chat } from "@roolz/types/api/chats"

export function getChatAvatarLetters(chat: Chat) {
  return chat.name
    ? chat.name?.trim()?.[0]
    : ''
}

export function padNumber(number: string | number, width: number, symbol: string | number = '0') {
  if(typeof number === 'number') number = String(number)
  if(typeof symbol === 'number') symbol = String(symbol)

  return number.length >= width
    ? number
    : new Array(width - number.length + 1).join(symbol) + number;
}

export function recoursiveArraySome(a: Array<unknown>, comparator: (item: any) => boolean): boolean {
  return Object.values(a).some(function check(item) {
    try {
      if(comparator(item)) return true
    } catch(e) {
      // console.log(e)
    }

    if(Array.isArray(item)) {
      if(recoursiveArraySome(item, comparator)) return true
    }
    else if(item && typeof item === 'object' && recoursiveArraySome(Object.values(item), comparator)) {
      return true
    }
    return false
  })
}

export function getTextColorForBackground(backgroundColor: string) {
  // Convert the background color to RGB
  const r = parseInt(backgroundColor.slice(1, 3), 16)
  const g = parseInt(backgroundColor.slice(3, 5), 16)
  const b = parseInt(backgroundColor.slice(5, 7), 16)

  // Calculate the relative luminance using the formula for sRGB
  const relativeLuminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255)

  // Choose the text color based on luminance
  return relativeLuminance > 0.7 ? '#000000' : '#ffffff'
}

export function rgbaToHex<T extends number>(r: T, g: T, b: T, a: T) {
  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');
  const alpha = Math.round(a / 100 * 255).toString(16).padStart(2, '0');

  return `#${red}${green}${blue}${alpha}`;
}

export function hexaToRGBAObject(hexa: string) {
  const r = parseInt(hexa.slice(1, 3), 16)
  const g = parseInt(hexa.slice(3, 5), 16)
  const b = parseInt(hexa.slice(5, 7), 16)
  const a = Math.round(parseInt(hexa.slice(7, 9), 16) / 255 * 100)

  const rgba = { r, g, b, a }

  return Object.entries(rgba).reduce((acc, [key, value]) => {
    const res = Number.isNaN(value) ? 0 : value
    acc[key as keyof typeof rgba] = res
    return acc
  }, {} as typeof rgba)
}
