export const BottomLeftArrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='currentColor'>
      <path
        d='M2.04671 12H9.95329C10.6355 12 11.1461 11.8305 11.4851 11.4916C11.8284 11.157 12 10.6529 12 9.97936V2.02064C12 1.34709 11.8284 0.84302 11.4851 0.508419C11.1461 0.169473 10.6355 0 9.95329 0H2.04671C1.36448 0 0.851711 0.169473 0.508419 0.508419C0.169473 0.84302 0 1.34709 0 2.02064V9.97936C0 10.6529 0.169473 11.157 0.508419 11.4916C0.851711 11.8305 1.36448 12 2.04671 12ZM3.89136 4.32156C4.04345 4.32156 4.16513 4.36936 4.25638 4.46496C4.34764 4.55622 4.39326 4.68441 4.39326 4.84954V6.00326L4.29549 7.04617L5.2667 6.0163L7.72406 3.5459C7.77186 3.4981 7.82835 3.46116 7.89354 3.43509C7.95872 3.40467 8.03259 3.38946 8.11516 3.38946C8.2629 3.38946 8.38023 3.43509 8.46714 3.52634C8.55839 3.6176 8.60402 3.74145 8.60402 3.89788C8.60402 4.02825 8.5497 4.14775 8.44106 4.25638L5.97719 6.72026L4.92776 7.69799L5.92504 7.5937H7.13743C7.29821 7.5937 7.4264 7.63933 7.522 7.73058C7.62194 7.82184 7.67192 7.94134 7.67192 8.08908C7.67192 8.23683 7.62412 8.35633 7.52852 8.44758C7.43292 8.53884 7.3069 8.58447 7.15046 8.58447H3.96306C3.78925 8.58447 3.65236 8.54101 3.55242 8.4541C3.45247 8.36719 3.4025 8.22379 3.4025 8.0239V4.86257C3.4025 4.70179 3.44595 4.57143 3.53286 4.47148C3.62412 4.37154 3.74362 4.32156 3.89136 4.32156Z'
        fill='currentColor'
      />
    </svg>
  )
}
