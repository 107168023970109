import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { exchangeService } from '@/store/exchange/exchange.service'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router'

const OfferMatches = OverlayModalPage(({ onClose }) => {
  const { offer_id } = useParams()
  const globalModals = useGlobalModals()

  async function loadIfNeeded(offer_id: string) {
    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === GLOBAL_MODALS_NAMES.OFFER_MATCHES_VIEW_ASIDE
      && highestVisibleOverlay?.key === offer_id
    ) {
      return
    }

    // if(globalModals.isVisible(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)) {
    //   const modal = globalModals.getMountedModal(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)
    //   let offer = modal?.props?.offer
    //   if(isPromise(offer)) {
    //     offer = await offer
    //   }
    //
    //   if(offer?._id === offer_id) {
    //     load = false
    //   }
    // }

    const offerId = new Promise((resolve, reject) => {
      if(exchangeStore.findOffer(offer_id)) resolve(offer_id)

      exchangeService.loadOffer({ id: offer_id })
        .then(offer => {
          resolve(offer._id)
        })
        .catch(e => {
          console.error(e)

          reject(e)
        })
    })

    globalModals.open(GLOBAL_MODALS_NAMES.OFFER_MATCHES_VIEW_ASIDE, {
      key: offer_id,
      props: { id: offerId, onClose },
      config: { onClose }
    })
  }

  useLayoutEffect(() => {
    if(offer_id) {
      loadIfNeeded(offer_id)
    }
  }, [offer_id])

  return null
})

export default OfferMatches
