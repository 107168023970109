import { CircularProgress, Fade } from '@mui/material'
import { Close } from '@roolz/icons/Close'
import { Download } from '@roolz/icons/Download'
import { Error } from '@roolz/icons/Error'
import { File } from '@roolz/icons/File'
import { usePrevious } from 'ahooks'
import cn from 'classnames'
import { isNumber } from 'lodash-es'
import { ReactNode, useRef } from 'react'
import styles from './FileView.module.scss'

interface Props {
  name: ReactNode
  meta?: ReactNode
  progress?: number

  icon?: 'default' | 'download' | 'abort' | 'loading'
  isError?: boolean

  showDelete?: boolean

  onDelete?: () => void
  onIconClick?: () => void

  className?: string
  style?: any
}

export const FileView = ({
  name,
  meta,

  icon = 'default',
  isError = false,
  progress,
  showDelete,

  onDelete,
  onIconClick,

  className,
  style
}: Props) => {
  const prevIcon = usePrevious(icon)

  const ANIMATION_TIMEOUT = prevIcon ? 500 : 0

  return (
    <div
      className={cn(styles.file, className, {
        // [styles.fileWithAction]: !!onDownload,
      })}
      style={style}
    >

      {isError ? (
        <button className={styles.iconError}>
          <Error size={20}/>
        </button>
      ) : (
        <button
          className={cn(styles.icon, {
            [styles.iconPointer]: icon !== 'default'
          })}
          onClick={onIconClick}
        >
          <Fade in={icon === 'abort'} unmountOnExit exit={false} timeout={ANIMATION_TIMEOUT}>
            <span>
              <Close color='white' size={16}/>
            </span>
          </Fade>
          <Fade in={icon === 'download'} unmountOnExit exit={false} timeout={ANIMATION_TIMEOUT}>
            <span>
              <Download color='white'/>
            </span>
          </Fade>
          <Fade in={icon === 'default'} unmountOnExit exit={false} timeout={ANIMATION_TIMEOUT}>
            <span>
              <File color='white'/>
            </span>
          </Fade>
          <Fade in={icon === 'loading'} unmountOnExit exit={false} timeout={ANIMATION_TIMEOUT}>
            <span style={{ color: 'white' }}>
              <CircularProgress
                color='inherit'
                size={22}
              />
            </span>
          </Fade>

          {isNumber(progress) && (
            <div className={styles.loading}>
              <div
                className={styles.loading__value}
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
        </button>

      )}

      <div className={styles.info}>
        <div
          className={styles.title}
          title={typeof name === 'string' ? name : undefined}
        >
          {name}
        </div>
        <div className={styles.meta}>
          {meta}
        </div>
      </div>
    </div>
  )
}
