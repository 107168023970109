import { Expand } from '@roolz/icons/Expand'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import styles from './ExpandButton.module.scss'

interface Props {
  className?: string
  size?: number
  expanded: boolean
}

export const ExpandButton = observer(function ExpandButton(props: Props) {
  const { className, size = 12, expanded } = props

  return (
    <div className={cn(styles.root, { [styles.expanded]: expanded }, className)}>
      <Expand size={size}/>
    </div>
  )
})
