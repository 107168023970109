interface Props {

}

export const Quotes = (props: Props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9075 2.1665C11.6059 2.1665 11.322 2.28679 11.1106 2.50213L6.06382 7.64237C5.62819 8.08607 5.62819 8.80455 6.06382 9.24825L8.06993 11.2915C8.28135 11.5069 8.56529 11.6271 8.86684 11.6271C9.16839 11.6271 9.45233 11.5069 9.66375 11.2915L11.0854 9.84352L16.0636 14.9139C16.3218 15.1769 16.6681 15.3237 17.0361 15.3237C17.4041 15.3237 17.7504 15.1769 18.0087 14.9139L18.2667 14.6511C18.5247 14.3883 18.6666 14.0384 18.6666 13.6692C18.6666 13.3 18.5247 12.9502 18.2667 12.6874L13.28 7.6083L14.7105 6.15128C15.1461 5.70758 15.1461 4.9891 14.7105 4.5454L12.7044 2.50213C12.493 2.28679 12.209 2.1665 11.9075 2.1665ZM17.5513 13.3986L12.5731 8.32825L11.7923 9.12357L16.779 14.2027C16.8491 14.2741 16.9396 14.3117 17.0361 14.3117C17.1327 14.3117 17.2231 14.2741 17.2932 14.2027L17.5513 13.9398C17.6216 13.8682 17.6608 13.7732 17.6608 13.6692C17.6608 13.5653 17.6216 13.4702 17.5513 13.3986ZM11.826 3.21334C11.8494 3.18946 11.8776 3.17827 11.9075 3.17827C11.9379 3.17827 11.966 3.1899 11.989 3.21334L13.9951 5.25661C14.044 5.3064 14.044 5.39056 13.9951 5.44035L13.1546 6.29637L10.9767 4.07812L11.826 3.21334ZM10.5042 8.99619L8.32626 6.77791L10.2704 4.79775L12.4483 7.01603L10.5042 8.99619ZM6.77924 8.35359L7.61969 7.49757L9.79763 9.71585L8.94861 10.5806C8.92516 10.6045 8.89701 10.6157 8.86712 10.6157C8.83675 10.6157 8.80858 10.604 8.78564 10.5806L6.77953 8.53732C6.73038 8.48727 6.73062 8.40311 6.77924 8.35359ZM2.17482 14.4552C1.70449 14.4552 1.33325 14.8427 1.33325 15.3062V16.9822C1.33325 17.4456 1.70449 17.8332 2.17482 17.8332H11.3702C11.8405 17.8332 12.2117 17.4456 12.2117 16.9822V15.3062C12.2117 14.8427 11.8405 14.4552 11.3702 14.4552H2.17482ZM2.33874 16.8214V15.4669H11.2062V16.8214H2.33874Z'
      />
    </svg>
  )
}
