import { DEFAULT_LANGUAGE } from '@/config/locales'
import { IS_DEV } from '@roolz/sdk/utils/env'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { BackendModule, ReadCallback, Services } from 'i18next'

type ResourceFetcher = () => Promise<{
  __esModule?: true
  default?: Record<string, unknown>
  [key: string]: unknown
}>

export interface AsyncBackendOptions {
  resources?: {
    [language: string]: ResourceFetcher | Record<string, ResourceFetcher>
  }
}

class AsyncBackend implements BackendModule<AsyncBackendOptions> {
  // i18next is dumb as TypeScript requires the class property for `type`
  // but the runtime requires the static `type` property.
  static type = 'backend'
  type = 'backend' as const

  private options: AsyncBackendOptions = null!

  constructor(services: Services, options: AsyncBackendOptions) {
    this.init(services, options)
  }

  init(_: Services, options: AsyncBackendOptions): void {
    this.options = { ...this.options, ...options }
  }

  read(lng: string, ns: string, callback: ReadCallback) {
    const resourceFetcher = this.getResourceFetcher(lng, ns)

    if (resourceFetcher) {
      resourceFetcher()
        .then((res) => {
          let result: any = res.__esModule ? res.default : res

          result = ns ? result[ns] : {}

          callback(null, result)
        })
        .catch((err) => callback(err, false))
    } else {
      callback(new Error('resource not found'), false)
    }
  }

  private getResourceFetcher(lng: string, ns: string) {
    // Languages can specify a function if they only have a single namespace
    // or an object if they have multiple namespaces.
    const fetcher = this.options.resources?.[lng]

    return typeof fetcher === 'function' ? fetcher : fetcher?.[ns]
  }
}


const resources: Record<string, any> = {
  es: () => import('@roolz/i18n/es_ES/index.js').then(mod => mod.default),
  ru: () => import('@roolz/i18n/ru/index.js').then(mod => mod.default),
  en: () => import('@roolz/i18n/en/index.js').then(mod => mod.default),
  de: () => import('@roolz/i18n/de/index.js').then(mod => mod.default),
  fr: () => import('@roolz/i18n/fr_FR/index.js').then(mod => mod.default),
  hi: () => import('@roolz/i18n/hi_IN/index.js').then(mod => mod.default),
  pl: () => import('@roolz/i18n/pl_PL/index.js').then(mod => mod.default),
  tr: () => import('@roolz/i18n/tr/index.js').then(mod => mod.default),
  uk: () => import('@roolz/i18n/uk/index.js').then(mod => mod.default),
  zh: () => import('@roolz/i18n/zh_CN/index.js').then(mod => mod.default),
}

if(IS_DEV) resources.dev = () => Promise.resolve({})

export type SupportedLocale = keyof typeof resources
export const LOCALES = Object.fromEntries(Object.keys(resources).map(lang => [lang, lang]))


i18n
  .use(AsyncBackend)
  // .use(LazyImportPlugin)
  // .use(Backend) // or any other backend implementation

  .use(initReactI18next) // passes i18n down to react-i18next
  .use({
    type: 'postProcessor',
    name: 'dev',
    process: function (value: any, key: string, options: { ns: string }, translator: { language: string }) {
      if(translator.language === 'dev') {
        return options.ns + ':' + key
      }

      return value
    }
  })
  .init({
    defaultNS: false,
    backend: { resources },
    // resources: messages,
    //  lng: uiStore.language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: Object.values(LOCALES),
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    postProcess: ['dev']
  })

export default i18n
