import { Message } from '@roolz/types/api/chats'
import { FileType, uploadFileResponse } from '@roolz/types/api/files'

export enum UploadingType {
  Message = 'message'
}

export enum UploadingStatus {
  Waiting = 0,
  Uploading = 1,
  Commitment = 2,
  PostAction = 3,
  Complete = 4
}

export type Uploading = {
  id: string
  status: UploadingStatus
  uploading_result?: uploadFileResponse

  fileserver_id?: string
  upload_offset: number

  file: File

  file_type: FileType
  is_avatar: boolean
  is_public: boolean

  created_at: string // Date
} & ({
  type: UploadingType.Message
  chat_id: Message["chat_id"]
  client_message_id: Message["client_message_id"]
})
