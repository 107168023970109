import { db, DEXIE_STORES } from '@/database'
import { ISOString } from '@roolz/types/scalars'
import { liveQuery } from 'dexie'
import { makeAutoObservable } from 'mobx'

// const defaultSettings: ClientSettings = {
//   id: 1,
//
//   lastChatsLoadTime: null,
//   lastContactsLoadTime: null
// }

// export const settings: ClientSettings = makeAutoObservable(defaultSettings)

export interface ClientSettingsInterface {
  isJustSignedUp: boolean
  lastChatsLoadTime: ISOString | null
  lastContactsLoadTime: ISOString | null
  lastPrivateIncomingOfferPublishedAt: ISOString | null
}

export class ClientSettings implements ClientSettingsInterface {
  private data: ClientSettingsInterface = {
    lastChatsLoadTime: null,
    lastContactsLoadTime: null,
    lastPrivateIncomingOfferPublishedAt: null,
    isJustSignedUp: false
  }

  constructor() {
    makeAutoObservable(this)
  }

  // private _lastChatsLoadTime: null | string = null
  get lastChatsLoadTime() {
    return this.data.lastChatsLoadTime
  }

  // private _lastContactsLoadTime: null | string = null
  get lastContactsLoadTime() {
    return this.data.lastContactsLoadTime
  }

  get lastPrivateIncomingOfferPublishedAt() {
    return this.data.lastPrivateIncomingOfferPublishedAt
  }

  get isJustSignedUp() {
    return this.data.isJustSignedUp
  }

  // TODO probably need to just pass init data, but do idb request outside?
  async init() {
    try {
      const data = await db[DEXIE_STORES.CLIENT_SETTINGS].get(1)

      if(data) {
        this.setSettings(data, false)
      }

      liveQuery(() => db[DEXIE_STORES.CLIENT_SETTINGS].get(1))
        .subscribe(data => data && this.setSettings(data, false))
    } catch(e) {
      console.error('CLIENT SETTINGS INITIALIZATION FAILED', e)
    }
  }

  async setSettings(data: Partial<ClientSettingsInterface>, sync = true) {
    Object.entries(data ?? {}).forEach(([key, value]) => {
      // @ts-ignore
      this.data[key] = value
    })

    if(sync) {
      await db[DEXIE_STORES.CLIENT_SETTINGS].put({ id: 1, ...this.data }, 1)
    }
  }
}

export const clientSettings = new ClientSettings()
