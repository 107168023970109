import { CountryMultiSelect } from '@roolz/sdk/components/ui/fields/CountryMultiSelect/CountryMultiSelect'
import { CountryMultiSelectCards } from '@roolz/sdk/components/ui/fields/CountryMultiSelect/CountryMultiSelectCards'
import Button from '@/components/ui/buttons/Button/Button'
import { companiesService } from '@/store/companies/companies.service'
import { isTruthy } from '@roolz/sdk/utils/types'
import { Label } from '@/components/ui/typography/Label/Label'
import { useCountries } from '@/hooks/useCountries'
import {
  UpdateCompanyDirectionsForm
} from '@/schemas/companies/update-company.schema'
import { companyStore } from '@/store/companies/company.store'
import { Company } from '@roolz/types/api/companies'
import cn from 'classnames'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { keyBy } from 'lodash-es'
import { toastError } from '@roolz/sdk/components/snackbars'
import styles from './CompanyCard.module.scss'

type ModalDirectionsProps = {
  company: Company
}

export function EditModalDirections({
  company,
}: ModalDirectionsProps) {
  const { t } = useTranslation('company/admin')
  const { t: errorsT } = useTranslation('errors')

  const { countries } = useCountries()

  const {
    international_direction,
    national_direction
  } = company

  const countriesMap = useMemo(() =>
    keyBy(countries, 'alpha2'), [countries])

  const defaultInternationalDirection = useMemo(() =>
      international_direction?.map(alfa2 =>
        countriesMap[alfa2]
      )?.filter(isTruthy)
    , [international_direction, countriesMap])

  const defaultNationalDirections = useMemo(() =>
      national_direction?.map(alfa2 =>
        countriesMap[alfa2]
      )?.filter(isTruthy)
    , [national_direction, countriesMap])

  const defaultValues: UpdateCompanyDirectionsForm = {
    international_direction: defaultInternationalDirection,
    national_directions: defaultNationalDirections
  }

  const form = useForm<UpdateCompanyDirectionsForm>({
    mode: 'onBlur',
    defaultValues
  })

  const {
    formState: { errors },
    control,
  } = form

  const onSubmit = () => {
    form.trigger().then(() => {
      if(!Object.keys(form.formState.errors).length) {
        const values = form.getValues()
        const params = {
          international_direction: values?.international_direction?.map(item => item.alpha2) || [],
          national_direction: values?.national_directions?.map(item => item.alpha2) || []
        }

        companiesService.updateCompany(company.id, params).then(() => {
          companyStore.activeEditForm = null
        }).catch(({ response }) => {
          toastError(response?.data?.error_msg ?? errorsT('insufficient_request'))
        })
      } else {
        console.log('error', form.formState.errors)
      }
    })
  }

  return (
    <div className={styles.modalEditDescription}>
      <p className={styles.modalEditDescription__title}>
        {t('direction.title')}
      </p>
      <div className={cn(styles.formGroup, styles.formGroup_directions)}>
        <Label
          htmlFor='international_direction'
        >
          {t('direction.international_transportation')}
        </Label>
        <Controller
          name='international_direction'
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <>
              <CountryMultiSelect
                multiselect
                items={countries ?? []}
                {...rest}
                id='international_direction'
                onChange={onChange}
                value={value ?? []}
                placeholder={t('direction.placeholder_international_transportation')}
                error={!!errors?.international_direction?.message}
                helperText={errors?.international_direction?.message ?? ''}
                text={t('direction.can_specify_up_destinations')}
                search
              />
              <CountryMultiSelectCards
                selectedCountries={value ?? []}
                onDelete={(country) => {
                  onChange(value?.filter(item => item.alpha2 !== country.alpha2))
                }}
              />
            </>
          )}
        />
      </div>
      <div className={cn(styles.formGroup, styles.formGroup_directions)}>
        <Label
          htmlFor='national_directions'
        >
          {t('direction.transportation_within_the_country')}
        </Label>
        <Controller
          name='national_directions'
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <>
              <CountryMultiSelect
                multiselect
                items={countries ?? []}
                {...rest}
                id='national_directions'
                value={value ?? []}
                onChange={onChange}
                placeholder={t('direction.placeholder_transportation_within_the_country')}
                error={!!errors?.national_directions?.message}
                helperText={errors?.national_directions?.message ?? ''}
                text={t('direction.can_specify_up_destinations')}
                search
              />
              <CountryMultiSelectCards
                selectedCountries={value ?? []}
                onDelete={(country) => {
                  onChange(value?.filter(item => item.alpha2 !== country.alpha2))
                }}
              />
            </>
          )}
        />
      </div>
      <div className={styles.modalEditDescription__buttons}>
        <div
          className={styles.modalEditDescription__cancel}
          onClick={() => companyStore.activeEditForm = null}
        >
          {t('edit.cancel')}
        </div>
        <Button onClick={onSubmit}>
          {t('edit.save')}
        </Button>
      </div>
    </div>
  )
}
