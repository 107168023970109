import { OfferStatus } from '@roolz/types/api/exchange'

export const MY_OFFERS_FILTER = 'MY_OFFERS_FILTER'
export const IS_FILTER_VISIBLE_KEY = 'IS_FILTER_VISIBLE_KEY'

export const offerStatuses = [
  OfferStatus.NOT_PUBLISHED,
  OfferStatus.PUBLISHED,
  OfferStatus.AWAIT_CONFIRM,
  OfferStatus.CONFIRMATION,
  OfferStatus.DEAL_MADE,
  OfferStatus.CLOSED,
]
