import styles from './NewMessagesCount.module.scss'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export const NewMessagesCount = observer(function NewMessagesCount({
  count,
  isMuted = false,
  withBorder = false
}: {
  count: number,
  isMuted?: boolean
  withBorder?: boolean
}) {
  return (
    <span
      className={cn(styles.newMessagesCount, {
        [styles.newMessagesCount__muted]: isMuted,
        [styles.newMessagesCount__withBorder]: withBorder
      })}
    >
      {count >= 1000 ? '999+' : count > 0 && count}
    </span>
  )
})
