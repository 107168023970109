import { FilePreview } from '@/components/chats/ui/FilePreview/FilePreview'
import { useState } from 'react'
import styles from './FilePreviewList.module.scss'

interface Props {
  files: File[]
  onFilesChange: (files: File[]) => any
}

export const FilePreviewList = ({ files, onFilesChange }: Props) => {
  const [filteredFiles, setFilteredFiles] = useState(files)

  const handleFileDelete = (file: File) => {
    setFilteredFiles(files => {
      const newFiles = files.filter(item => item !== file)

      onFilesChange(newFiles)

      // To not show empty list while modal is closing
      if(!newFiles.length) {
        return files
      }

      return newFiles
    })
  }

  return (
    <div className={styles.list}>
      {filteredFiles.map((file, i) => (
        <FilePreview
          key={i}
          file={file}

          showDelete={true}
          onDelete={() => handleFileDelete(file)}
        />
      ))}
    </div>
  )
}
