import { getCookiesDomain } from '@roolz/sdk/utils/auth'
import Cookies from 'cookies-ts'

const AFTER_EMAIL_INVITE_KEY = 'after_email_invite_key'

export const setCompanyAfterInvite = (companyId: string) => {
  const cookies = new Cookies()

  cookies.set(AFTER_EMAIL_INVITE_KEY, companyId, { expires: '0', domain: getCookiesDomain() })
}

export const getCompanyAfterInvite = () => {
  const cookies = new Cookies()

  return cookies.get(AFTER_EMAIL_INVITE_KEY) || null
}

export const removeCompanyAfterInvite = () => {
  const cookies = new Cookies()

  cookies.remove(AFTER_EMAIL_INVITE_KEY, { domain: getCookiesDomain() })
}
