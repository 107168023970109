import { Events, bus } from '@/events'
import { useEffect } from 'react'

export function useGlobalEvent<Key extends keyof Events>(name: Key | Key[], handler: (payload: Events[Key]) => void) {
  useEffect(() => {
    const nameList = !Array.isArray(name) ? [name] : name

    const unsubscribers = nameList.map(eventItem => bus.on(eventItem, handler))

    return () => {
      unsubscribers.forEach(unsubscriber => unsubscriber())
    }
  }, [handler, name])
}
