import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { BottomLeftArrow } from '@roolz/icons/partners/BottomLeftArrow'
import styles from './IncomingOffersIcon.module.scss'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

interface Props {
  className?: string
  withBadge?: boolean
}

export const IncomingOffersIcon = observer(function IncomingOffersIcon(props: Props) {
  const { className, withBadge = false } = props

  return <MaterialSymbolIcon
    className={cn(className, { [styles.withBadge]: withBadge })}
    size={12}
    icon={<BottomLeftArrow/>}
  />
})
