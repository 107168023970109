import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { companyStore } from '@/store/companies/company.store'
import { getNow } from '@/utils/date'
import { useTranslation } from 'react-i18next'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { useNavigate } from 'react-router'

export const useDate = () => {
  const { t } = useTranslation('company/users')

  const getDate = (strDate?: string | null) => {
    if(!strDate) return ''
    return dayjs(strDate).format('DD.MM.YYYY')
  }

  const getDateTime = (strDate?: string | null) => {
    if (!strDate) return ''
    return `${getDate(strDate)} ${t('in')} ${dayjs(strDate).format('HH:mm')}`
  }

  const getExpires = (strDate?: string | null) => {
    if (!strDate) return ''

    const dateNow = getNow()
    const date = dayjs(strDate)
    const days = date.diff(dateNow, 'days')
    const hours = date.diff(dateNow, 'hours')
    const minutes = date.diff(dateNow, 'minutes')

    if (date.isSameOrBefore(dateNow)) {
      return -1
    } // t('overdue')

    if (days) {
      return `${t('expires.expires_after')}: ${t('expires.days', { count: days })}`
    }

    if (hours) {
      return `${t('expires.expires_after')}: ${t('expires.hours', { count: hours })}`
    }

    return `${t('expires.expires_after')}: ${t('expires.minutes', { count: minutes })}`
  }

  return {
    getDateTime,
    getDate,
    getExpires,
  }
}

export function useBackToAppFromCompanyAdmin() {
  const lastLocation = companyStore.lastAppLocation

  const navigate = useNavigate()

  return () => {
    navigate(lastLocation
      ? lastLocation.pathname
      : resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
  }
}
