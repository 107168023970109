import cn from 'classnames'
import { ReactNode } from 'react'
import styles from './Label.module.scss'

type Props = {
  children: ReactNode
  className?: string
  required?: boolean
  [key: string]: any
};
export const Label = ({
  children,
  className,
  required = false,
  ...rest
}: Props) => {
  return (
    <label
      className={cn(styles.label, className)}
      {...rest}
    >
      {children}

      {required && <span className={styles.star}> *</span>}
    </label>
  )
}
