import cn from 'classnames'
import React, { ForwardedRef, forwardRef, ReactNode, useRef } from 'react'
import styles from './AsideContent.module.scss'

interface Props {
  children: ReactNode

  [key: string]: any
}

export const AsideContent = forwardRef(({ children, className, ...rest }: Props, ref: ForwardedRef<any>) => {
  return (
    <div
      ref={ref}
      className={cn(styles.root, className)}
      {...rest}
    >
      {children}
    </div>
  )
})
