import { isFileMessageFailed } from '@/components/chats/utils'
import { FileMessageModel } from '@/types/models/chat'
import { FileDark } from '@roolz/icons/FileDark'
import { getReadableFileSize } from '@roolz/sdk/utils/file-size'
import { MessageStatus } from '@roolz/types/api/chats'
import { observe } from 'mobx'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './FileMessagePreview.module.scss'

interface Props {
  message: FileMessageModel
}

export const FileMessagePreview = observer(({
  message
}: Props) => {
  const { t } = useTranslation()

  const isError = isFileMessageFailed(message)

  const size = (() => {
    let result = ''

    if(message.uploading && !isError) {
      result += getReadableFileSize(message.uploading.upload_offset ?? 0, t) + '/'
    }

    result += getReadableFileSize(message.decodedContent.content?.size ?? 0, t)

    return result
  })()

  let content = message.decodedContent.content.name
  if(!isError && message.status === MessageStatus.SENDING) {
    content = size
  }

  return (
    <span className={styles.root}>
    <FileDark
      className={styles.icon}
    /> {content}
  </span>
  )
})
