import { ApiClients } from '@roolz/api/clients'
import {
  ContactInfo, GetAllContactsResponse,
  GetContactListRequest,
  GetContactListResponse,
  GetSearch,
  Profile
} from '@roolz/types/api/profiles'
import { AxiosInstance } from 'axios'

export class ContactsApi {
  identity: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
  }

  getContactsBySearch(params: GetSearch) {
    return this.identity.get<ContactInfo[]>('/v1/device/contact/search', { params })
  }

  getContactList(params: GetContactListRequest) {
    return this.identity.get<GetContactListResponse>('/v1/private/contacts',
      {
        params
      }
    )
  }

  getAllContactIds() {
    return this.identity.get<GetAllContactsResponse>('/v1/private/contacts/all/ids')
  }

  getContactsByIds(profile_ids: Profile['id'][]) {
    return this.identity.post<Profile[]>('/v1/private/profile/list/by_ids', {
      profile_ids
    })
  }

  getBannedList(params: GetContactListRequest) {
    return this.identity.get<GetContactListResponse>('/v1/private/contacts/banned',
      {
        params
      }
    )
  }

  addPersonalContact(profile_id: string) {
    return this.identity.post<Profile>(`/v1/private/contacts/personal/add`, {
      profile_id
    })
  }

  unbanAndAddPersonalContact(profile_id: string) {
    return this.identity.post<Profile>(`/v1/private/contacts/personal/unban_and_add`, {
      profile_id
    })
  }

  deletePersonalContact(profile_id: string) {
    return this.identity.post<Profile>(`/v1/private/contacts/personal/delete`, {
      profile_id
    })
  }

  banContact(profile_id: string) {
    return this.identity.post<Profile>(`/v1/private/contacts/personal/ban`, {
      profile_id
    })
  }

  unbanContact(profile_id: string) {
    return this.identity.post<Profile>(`/v1/private/contacts/personal/unban`, {
      profile_id
    })
  }
}
