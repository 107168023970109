import { ChatAvatar } from '@/components/chats/ui/ChatAvatar/ChatAvatar'
import { ChatName } from '@/components/chats/ui/ChatName/ChatName'
import { FileMessagePreview } from '@/components/chats/ui/messagePreviews/FileMessagePreview/FileMessagePreview'
import { NewMessagesCount } from '@/components/chats/ui/NewMessagesCount/NewMessagesCount'
import { Typing } from '@/components/chats/ui/Typing/Typing'
import { isFileMessageFailed } from '@/components/chats/utils'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import {
  ChatModel,
  isAddedPcpMessage,
  isAddedPcpsMessage, isFileMessage,
  isSystemMessage,
  isTextMessage,
  MessageModel
} from '@/types/models/chat'
import { Delete } from '@roolz/icons/Delete'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import {
  ChatType,
  MessageState,
  MessageStatus,
  MessageType,
  PcpChatState,
  SystemMessageEvent
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ChatItemView } from '../ChatItemView/ChatItemView'
import styles from './ChatItem.module.scss'
import { MessageDate } from './MessageDate/MessageDate'

interface Props {
  chat: ChatModel
  showInfo?: boolean

  onChatClick?: () => void
  onRightClick?: (event: any) => void

  style?: any
}

export const ChatItem = observer(({
  chat,
  showInfo = true,

  onChatClick,
  onRightClick,

  style
}: Props) => {
  const ref = useRef<any>()

  const { t, i18n: { language } } = useTranslation('chat/common')
  const { t: messageT } = useTranslation('chat/message')

  const [hover, setHover] = useState(false)
  const onPointerEnter = useCallback(() => setHover(true), [])
  const onPointerLeave = useCallback(() => setHover(false), [])

  const getProfileName = useCallback((profile: Profile | undefined): string => {
    if(!profile) return ''

    const { first_name, last_name } = profile.profile_view_info || {}

    const parts = [UCFirst(first_name ?? '')]


    if(last_name?.length) {
      parts.push(UCFirst(last_name.substring(0, 1)) + (last_name.length > 1 ? '.' : ''))
    }

    return parts.join(' ')
  }, [])

  const isActive = chat?.id === chatsStore.activeChat?.id
  const isTyping = ![ChatType.CHANNEL, ChatType.SELF_CHAT].includes(chat.type)
    ? !!chatSessionsStore.getChatTypingUsers(chat).length
    : null
  const isMuted = chat.own_pcp.is_muted ?? false
  const isPinned = chat.isPinned

  const isLastMessageSelf = chat.last_message?.isOwnMessage
  const lastMessageDeliverStatus = (() => {
    const message = chat.last_message

    if(message && isFileMessage(message)) {
      const isError = isFileMessageFailed(message)

      return isError ? MessageStatus.ERROR : message.status
    }

    return message?.status
  })()

  const showLastMessageStatus = isLastMessageSelf
    && chat.type !== ChatType.SELF_CHAT
    && chat.last_message?.type !== MessageType.SYSTEM
    && !chat.draft

  const showLastMessageDate = !!chat.last_message && !chat.draft

  // const showLastMessageSender = !chat.draft
  //   && !!chat.last_message
  //   && !!getMessageSender(chat.last_message)?.length


  function showUnreadCount() {
    if(chat.own_pcp.chat_state === PcpChatState.READ) return false
    if(chat.own_pcp.chat_state === PcpChatState.UNREAD) return true

    if(chat.type === ChatType.SELF_CHAT) {
      return chat?.own_pcp?.last_read_message_index === 0
    }

    return chat.unreadMessagesCount > 0
  }

  const showUnreadMessagesCount = showUnreadCount()

  // const hideUnreadMessagesCount = chat.own_pcp.chat_state === PcpChatState.READ
  //   || (chat.own_pcp.chat_state === PcpChatState.NORMAL && (
  //     chat.type === ChatType.SELF_CHAT || chat?.own_pcp?.max_message_index === 1
  //   ))

  // console.log(chat.own_pcp.chat_state === PcpChatState.READ, chat.own_pcp.chat_state === PcpChatState.NORMAL )

  // const showUnreadMessagesCount =
  //   chat.own_pcp.chat_state !== PcpChatState.READ
  //   && (chat.own_pcp.chat_state === PcpChatState.UNREAD
  //     || (
  //       chat.type !== ChatType.SELF_CHAT
  //       && chat.unreadMessagesCount > 0
  //     )
  //   )

  const lastMessageContent = useMemo((): {
    sender_id: null | Profile['id'],
    message: ReactNode
  } => {
    try {
      if(chat.draft?.length) {
        return {
          sender_id: null,
          message: (
            <div className={styles.messagePreviewDraft}>
            <span className={styles.messagePreviewDraft__label}>
              {t('chat/common:message_type.draft')}:
            </span>
              <span className={styles.messagePreviewDraft__content}>
              {chat.draft}
            </span>
            </div>
          )
        }
      }

      if(isTyping) {
        const sender_id = [ChatType.CHANNEL, ChatType.GROUP_CHAT].includes(chat.type)
          ? chatSessionsStore.getChatTypingUsers(chat)?.[0] || null
          : null

        return {
          sender_id,
          message: <Typing/>
        }
      }

      const message = chat.last_message

      if(!message) return { sender_id: null, message: null }

      let sender_id: Profile['id'] | null = (
        ![ChatType.SELF_CHAT, ChatType.DIALOG].includes(chat.type)
        && !isLastMessageSelf
      )
        ? message.sender_id
        : null
      let content = null

      if(message.state === MessageState.DELETED) {
        content = (
          // <span className={styles.messagePreviewDeleted}>
          <>
            <Delete
              color='#8E8E93'
              size={12}
              className={styles.messagePreviewDeletedIcon}
            />{messageT('message_state.deleted')}
          </>
          // </span>
        )
      } else if(isSystemMessage(message)) {
        sender_id = null

        switch(message.decodedContent.event) {
          case SystemMessageEvent.JOINED_PCP:
            if(!message.owner) {
              content = null
              break
            }

            if(message.isOwnMessage) {
              content = (
                <div className={styles.messagePreviewSystem}>
                  {messageT('system_messages.you_joined_group')}
                </div>
              )
            } else {
              content = (
                <div className={styles.messagePreviewSystem}>
                  <Trans
                    i18nKey='chat/message:system_messages.joined_group'
                    values={{ name: getProfileName(message.owner) }}
                    components={{
                      profileLink: <span/>
                    }}
                  />
                </div>
              )
            }

            break
          case SystemMessageEvent.CHAT_CREATED: {
            let text = ''

            if(chat.type === ChatType.GROUP_CHAT) {
              text = messageT('system_messages.chat_created')
            } else if(chat.type === ChatType.CHANNEL) {
              text = messageT('system_messages.channel_created')
            }
            content = (
              <div className={styles.messagePreviewSystem}>
                {text}
              </div>
            )
            break
          }
          case SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE:
            content = (
              <div className={styles.messagePreviewSystem}>
                {messageT('self_chat_placeholder.title', {
                  name: profilesStore.my_profile && getProfileName(profilesStore.my_profile)
                })}
              </div>
            )
            break
          // case SystemMessageEvent.JOINED_BY_INVITE:
          //   content = (
          //     <div className={styles.messagePreviewSystem}>
          //       {messageT('system_messages.joined_by_invite')}
          //     </div>
          //   )
          //   break
          case SystemMessageEvent.PINNED_MESSAGE:
            content = (
              <div className={styles.messagePreviewSystem}>
                {messageT('system_messages.pinned')}
              </div>
            )
            break
        }

        if(isAddedPcpsMessage(message)) {
          content = (
            <div className={styles.messagePreviewSystem}>
              {messageT('system_messages.many_added_to_group')}: {
              message.decodedContent.content.added_profiles.length
            }
              {/*{message.decodedContent.content.added_profiles.map((profile, i) => (*/}
              {/*))}*/}
            </div>
          )
        } else if(isAddedPcpMessage(message)) {
          const addedProfile: any = message.decodedContent.content?.added_profile

          if(addedProfile) {
            content = (
              <div className={styles.messagePreviewSystem}>
                {addedProfile.id === profilesStore.my_profile?.id ? (
                  <Trans
                    i18nKey='chat/message:system_messages.you_were_added_to_group'
                    values={{ name: getProfileName(message.owner) }}
                    components={{
                      profileLink: <span/>
                    }}
                  />
                ) : (
                  message.isOwnMessage ? (
                    <Trans
                      i18nKey='chat/message:system_messages.you_added_to_group_one'
                      values={{
                        invitedName: getProfileName(addedProfile)
                      }}
                      components={{
                        profileLink: <span/>
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey='chat/message:system_messages.one_added_to_group_one'
                      values={{
                        invitedName: getProfileName(addedProfile),
                        invitingName: getProfileName(message.owner)
                      }}
                      components={{
                        invitingLink: <span/>,
                        invitedLink: <span/>,
                      }}
                    />
                  )
                )}
              </div>
            )
          }
        }
      } else if(isTextMessage(message)) {
        try {
          content = message.decodedContent.content.text
        } catch(e) {
          console.error(e, message)
        }
      } else if(isFileMessage(message)) {
        try {
          content = <FileMessagePreview message={message}/>
        } catch(e) {
          console.error(e, message)
        }
      } else {
        content = messageT('unsupported_content')
      }

      return {
        sender_id,
        message: content
      }
    } catch(e) {
      console.log(e)

      return {
        sender_id: null,
        message: messageT('unsupported_content')
      }
    }
  }, [
    chat.last_message,
    chat.last_message?.type,
    chat.last_message?.decodedContent,
    chat.last_message?.state,
    isTyping, language,
    chat.draft
  ])


  const LastMessage: FC = () => {
    const lastMessageProfileId = chat.type !== ChatType.CHANNEL
      ? lastMessageContent.sender_id
      : null
    let lastMessageProfile = null

    if(lastMessageProfileId) {
      lastMessageProfile = profilesStore.findProfile(lastMessageProfileId)
    }

    if(lastMessageContent.message !== null && lastMessageContent.message !== '') {
      return (
        <div
          className={styles.messagePreview}
          // title={lastMessageContent ?? ''}
        >
          {lastMessageProfile && (
            <span className={styles.messagePreview__name}>
              {getProfileName(lastMessageProfile)}:
            </span>
          )}

          <span className={styles.messagePreview__content}>
            {lastMessageContent.message}
          </span>
        </div>
      )
    }

    // Yes, it's crunch, but it works perfectly for all cases
    return null //<div className={styles.contentEmpty}/>
    // return <>&nbsp; </>
  }


  return (
    <ChatItemView
      ref={ref}

      lastMessageDeliverStatus={showLastMessageStatus ? lastMessageDeliverStatus : undefined}
      showUnreadMessagesCount={showUnreadMessagesCount}
      unreadMessagesCount={chat.unreadMessagesCount}

      avatarSlot={
        <ChatAvatar chat={chat}>
          {!showInfo && showUnreadMessagesCount && (
            <div className={styles.avatarUnreadMessageCount}>
              <NewMessagesCount
                count={chat.unreadMessagesCount}
                isMuted={isMuted}
                withBorder
              />
            </div>
          )}
        </ChatAvatar>
      }
      lastMessageDateSlot={
        <>
          {showLastMessageDate && (
            <MessageDate
              message={chat.last_message as MessageModel}
            />
          )}
        </>
      }
      contentSlot={<>
        {/*{String(showUnreadMessagesCount)} {chat.unreadMessagesCount},*/}
        {/*{chat.own_pcp.last_read_message_index} {chat.count_messages}*/}
        {/*({chat.own_pcp.min_message_index}, {chat.own_pcp.max_message_index})*/}
        <LastMessage/>
      </>}
      nameSlot={<ChatName chat={chat}/>}

      isActive={isActive}
      isPinned={isPinned}
      isMuted={isMuted}

      showInfo={showInfo}
      onChatClick={onChatClick}
      onRightClick={onRightClick}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}

      style={style}
    />
  )

  // return (
  //   <button
  //     style={style}
  //     className={cn(styles.chat, {
  //       [styles.chatPined]: isPinned,
  //       [styles.chatActive]: isActive
  //     })}
  //     ref={ref}
  //     onContextMenu={onRightClick}
  //     onClick={onChatClick}
  //   >
  //     {/*<Avatar*/}
  //     {/*  first_name={profile.first_name ?? ''}*/}
  //     {/*  last_name={profile.last_name ?? ''}*/}
  //     {/*  avatarUrl={profile.avatar}*/}
  //     {/*  color_code={profile.color ?? ''}*/}
  //     {/*  width={32}*/}
  //     {/*/>*/}
  //
  //     <div className={styles.avatar}>
  //       <ChatAvatar chat={chat}/>
  //     </div>
  //
  //     <div
  //       className={cn(styles.content, {
  //         [styles.contentHidden]: !showInfo
  //       })}
  //     >
  //       <div className={styles.content__header}>
  //         <div className={styles.name__container}>
  //           <div className={styles.name}>
  //             <ChatName chat={chat}/>
  //           </div>
  //         </div>
  //
  //         {isPinned && (
  //           <Icon><Pin/></Icon>
  //         )}
  //         {isMuted && (
  //           <Icon><Silent color={iconColor}/></Icon>
  //         )}
  //
  //
  //         <Spacer/>
  //
  //
  //         {showLastMessageStatus && (
  //           <Icon>
  //             <MessageDeliverStatus status={lastMessageDeliverStatus}/>
  //           </Icon>
  //         )}
  //
  //         {showLastMessageDate && (
  //           <MessageDate
  //             message={chat.last_message as MessageModel}
  //           />
  //         )}
  //       </div>
  //
  //       <div className={styles.content__footer}>
  //         <LastMessage/>
  //
  //         {showUnreadMessagesCount && (
  //           <NewMessagesCount
  //             count={chat.unreadMessagesCount}
  //             isMuted={isMuted}
  //           />
  //         )}
  //       </div>
  //     </div>
  //   </button>
  // )
})
