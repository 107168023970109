import { api } from '@/api'
import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { MessageModelFactory } from '@/models/Message.model'
import i18n from '@/plugins/i18n'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { toastError } from '@roolz/sdk/components/snackbars'
import { Chat, ChatType, Message, OwnPcp, PcpStatus } from '@roolz/types/api/chats'
import { ProfileRole } from '@roolz/types/api/profiles'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ForwardedFrom.module.scss'

interface Props {
  forwardedMessage: Message
}

export const ForwardFrom = observer(({
  forwardedMessage
}: Props) => {
  const { t } = useTranslation('chat/message')

  const { openProfile } = useProfileLink()

  const messageModel = useMemo(() => {
    return MessageModelFactory(forwardedMessage)
  }, [forwardedMessage])

  // TODO probably on receive message need to check if profiles is loaded and load if not

  // Bullshit, but we bound by backend implementation...
  async function handleForwardClick() {
    const chatId = messageModel.chat_id

    if(chatsStore.activeChatId === chatId) {
      return
    }

    // Detect that message from dialog
    if(chatId.includes(':')) {
      if(chatsStore.getDialogWithUser(messageModel?.sender_id)) {
        return chatsService.setActiveChatAsDialog(messageModel.sender_id)
      }
      return openProfile(messageModel.sender_id)
    }

    chatsService.setActiveChatAsGroup(chatId)
      // .catch(e => {
      //   // debugger
      //   if(e.response.status === 403 && messageModel.owner?.role !== ProfileRole.System) {
      //     if(chatsStore.getDialogWithUser(messageModel?.sender_id)) {
      //       return chatsService.setActiveChatAsDialog(messageModel.sender_id)
      //     }
      //     return openProfile(messageModel.sender_id)
      //   }
      //
      //   throw e
      // })
      .catch(e => {
        // debugger
        console.error(e)
        toastError(e?.response?.data?.error_msg ?? i18n.t('insufficient_request'))
      })
  }

  async function handleSenderClick() {
    const chatId = messageModel.chat_id

    if(messageModel.owner?.role === ProfileRole.System) {
      try {
        await chatsService.setActiveChatAsGroup(chatId)
      } catch(e) {
        console.error(e)
        toastError(e?.response?.data?.error_msg ?? i18n.t('insufficient_request'))
      }
      return
    }

    if(chatsStore.getDialogWithUser(messageModel?.sender_id)) {
      return chatsService.setActiveChatAsDialog(messageModel.sender_id)
    }
    return openProfile(messageModel.sender_id)

    //
    // let chat: Chat
    // // let ownPcp: OwnPcp
    //
    // try {
    //   const { data } = await api.messaging.getPcpOrCreateObserver(chatId)
    //   chat = data.chat
    //   // ownPcp = data.own_pcp
    // } catch(e) {
    //   console.error(e)
    //   toastError(e?.response?.data?.error_msg ?? i18n.t('insufficient_request'))
    //   return
    // }
    //
    // if(chat.type === ChatType.GROUP_CHAT) {
    //   if(chatsStore.getDialogWithUser(messageModel?.sender_id)) {
    //     return chatsService.setActiveChatAsDialog(messageModel.sender_id)
    //   }
    //   return openProfile(messageModel.sender_id)
    // }
    //
    // chatsService.setActiveChat(chatId)
  }

  const { first_name, last_name } = messageModel.owner?.profile_view_info || {}

  // TODO add logic for channel forwarding
  return (
    <div
      className={styles.root}
    >
      {/*{profile.id}*/}

      <span
        className={styles.label}
        onClick={handleForwardClick}
      >
        {t('forward_from')}:
      </span>
      <div
        className={styles.profile}
        onClick={handleSenderClick}
      >
        <div className={styles.profile}></div>
        {first_name} {last_name}
      </div>
    </div>
  )
})
