import { matchEmails, matchPhones } from '@/utils/matches'
import { Profile } from '@roolz/types/api/profiles'

export const isProfileMatches = (query: string, profile: Profile) => {
  if(profile.phone && matchPhones(query, profile.phone)
    || profile.profile_view_info?.own_phone && matchPhones(query, profile.profile_view_info?.own_phone)
    || profile.profile_view_info?.work_phone && matchPhones(query, profile.profile_view_info?.work_phone)
  ) {
    return true
  }

  if(profile.email && matchEmails(query, profile.email)
    || profile.profile_view_info?.own_email && matchEmails(query, profile.profile_view_info?.own_email)
    || profile.profile_view_info?.work_email && matchEmails(query, profile.profile_view_info?.work_email)
  ) {
    return true
  }

  const first_name = (profile.profile_view_info?.first_name ?? '').toLowerCase()
  const last_name = (profile.profile_view_info?.last_name ?? '').toLowerCase()

  const parts = query.split(' ')
  for(let part of parts) {
    part = part.toLowerCase()

    if(first_name.includes(part) || last_name.includes(part)) {
      return true
    }
  }

  let nicknameQuery = query
  if(/^@.*/.test(nicknameQuery)) {
    nicknameQuery = nicknameQuery.replaceAll(/^@+/g, '')
  }

  if(nicknameQuery.length &&
    profile?.nickname?.length &&
    profile.nickname.includes(nicknameQuery)) {
    return true
  }


  return false
}
