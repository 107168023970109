import { UploadAvatar } from '@/components/modals/UploadAvatar/UploadAvatar'
import { Avatar as AvatarImage } from '@roolz/sdk/components/ui/Avatar/Avatar'
import Button from '@/components/ui/buttons/Button/Button'
import { IconButton } from '@/components/ui/buttons/IconButton/IconButton'
import { Delete } from '@roolz/icons/Delete'
import { UploadNew } from '@roolz/icons/avatar/UploadNew'
import { MAX_AVATAR_SIZE_BYTES } from '@/config/ui'
import { uploadFileResponse } from '@roolz/types/api/files'
import { Url } from '@roolz/types/scalars'
import cn from 'classnames'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastError } from '@roolz/sdk/components/snackbars'
import styles from './AvatarUpload.module.scss'

export type AvatarFieldProps = {
  avatarUrl: Url | null
  first_name?: string
  last_name?: string
  color?: string
  onDelete: () => void
  onChange: ({ url }: { url: string }) => void
  className?: string

  type?: 'profile' | 'company'
}
export const AvatarUpload = ({
  type = 'profile',
  ...props
}: AvatarFieldProps) => {
  const { t } = useTranslation('ui')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>()

  const handleUpload = useCallback((event: any) => {
    event.preventDefault()
    inputRef.current?.click()
  }, [])

  const handleFileSelected = useCallback((e: any) => {
    if(e.target.files.length) {
      if(!/^image\/.*/.test(e.target?.files[0].type)) {
        toastError(t('upload_avatar.wrong_file_type'))
        return
      }
      if(/svg/i.test(e.target?.files[0].type)) {
        toastError(t('upload_avatar.wrong_file_type'))
        return
      }
      if(e.target?.files[0].size > MAX_AVATAR_SIZE_BYTES) {
        toastError(t('upload_avatar.exceeding_file_size'))
        return
      }
      setFile(e.target?.files[0])

      setOpen(true)
    }
  }, [])

  const onLoaded = useCallback((data: uploadFileResponse) => {
    props.onChange({ url: data.url })
  }, [props.onChange])

  useEffect(() => {
    if(inputRef?.current?.value && !open) {
      inputRef.current.value = ''
    }
  }, [open])

  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={styles.imageWrapper}>
        <AvatarImage
          first_name={props?.first_name ?? ''}
          last_name={props?.last_name ?? ''}
          color_code={props?.color ?? ''}
          avatarUrl={props.avatarUrl ?? ''}
          width={160}
          type={type ?? 'profile'}
        />
        <div
          className={cn(styles.backdrop, {
            [styles[type]]: type,
          })}
          onClick={handleUpload}
        >
          <UploadNew/>
          <span>
            {props.avatarUrl
              ? t('upload_avatar.backdrop_edit')
              : t('upload_avatar.backdrop_add')}
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          {type === 'profile' && t('upload_avatar.title')}
          {type === 'company' && t('upload_avatar.title_company')}
        </div>
        <div className={styles.description}>
          {type === 'profile' && t('upload_avatar.description')}
          {type === 'company' && t('upload_avatar.description_company')}
        </div>
        <div className={styles.actions}>
          {!props.avatarUrl && (
            <Button
              className={styles.loadButton}
              onClick={handleUpload}
            >
              {type === 'profile' && t('upload_avatar.add')}
              {type === 'company' && t('upload_avatar.add_company')}
            </Button>
          )}
          {props.avatarUrl && <>
            <Button onClick={handleUpload}>
              {t('upload_avatar.edit') ?? ''}
            </Button>
            <IconButton onClick={props.onDelete}>
              <Delete color='#8E8E93'/>
            </IconButton>
          </>}
        </div>
      </div>
      <input
        type='file'
        ref={inputRef}
        className={styles.input}
        accept='.png, .jpg, .jpeg'
        onInput={handleFileSelected}
      />
      <UploadAvatar
        open={open}
        setOpen={setOpen}
        file={file}
        onLoaded={onLoaded}
        shape={type === 'profile' ? 'circle' : 'rectangle'}
      />
    </div>
  )
}
