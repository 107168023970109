import { api } from '@/api'
import { FileView } from '@/components/chats/ui/FileView/FileView'
import { isFileMessageFailed } from '@/components/chats/utils'
import { deleteMessage } from '@/repositories/messages.repository'
import { uploadingService } from '@/store/uploadings/uploading.service'
import { FileMessageModel } from '@/types/models/chat'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'
import { getFileserverFileLinkByToken } from '@roolz/sdk/utils/cdns'
import { downloadFileToPc } from '@roolz/sdk/utils/downloads'
import { getReadableFileSize } from '@roolz/sdk/utils/file-size'
import { MessageStatus } from '@roolz/types/api/chats'
import { UploadingStatus } from '@roolz/types/client/uploadings'
import { useHover } from 'ahooks'
import { observer } from 'mobx-react-lite'
import { ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MessageFileContent.module.scss'

interface Props {
  // content: FileContent['content']
  // uploading?: Uploading | undefined
  message: FileMessageModel

  attributes?: ReactNode
}

export const MessageFileContent = observer(({
  message,

  attributes
}: Props) => {
  const { t } = useTranslation()

  const fileRef = useRef<any>()

  const content = message.decodedContent?.content
  const uploading = message.uploading

  const handleIconClick = () => {
    if(message.status < MessageStatus.SENT && uploading) {
      if(uploading.status > UploadingStatus.Commitment) {
        return
      }

      uploadingService.cancelUploading(uploading.id)
      deleteMessage(message.chat_id, message.client_message_id)
    }
  }

  const handleFileClick = async () => {
    if(content.url && content?.id) {
      try {
        const { data: { token } } = await api.fileServer.generateFileToken(message.decodedContent.content?.id, message.client_message_id)

        downloadFileToPc(getFileserverFileLinkByToken(token), content.name)

        console.log('token')
      } catch(e) {
        const { response } = e

        const message = response?.data?.error_msg
        if(message) {
          toastError(message)
        }

        toastWarning(t('errors:insufficient_request'))
      }

    }
  }

  const isFileHover = useHover(() => fileRef.current)
  const iconType = (() => {
    if(uploading) {
      if(uploading.status < UploadingStatus.Commitment) {
        return 'abort'
      }
      return 'loading'
    }

    return isFileHover ? 'download' : 'default'
  })()

  let progress = 0

  try {
    progress = (uploading?.upload_offset ?? 0) / (content?.size ?? 0)
  } catch(e) {
  //
  }

  const isError = isFileMessageFailed(message)

  const size = (() => {
    let result = ''

    if(uploading && !isError) {
      result += getReadableFileSize(uploading.upload_offset ?? 0, t) + '/'
    }

    result += getReadableFileSize(content?.size ?? 0, t)

    return result
  })()

  return (
    <div className={styles.content}>
      <div
        className={styles.file}
        ref={fileRef}
        onClick={handleFileClick}
      >
        <FileView
          icon={iconType}
          name={content?.name ?? ''}
          progress={uploading && uploading.status <= UploadingStatus.Uploading
            ? progress * 100
            : undefined}
          meta={
            <div style={{ paddingRight: 55 }}>
              {size}
            </div>
          }
          onIconClick={handleIconClick}
          isError={isError}
        />
      </div>

      {/*{message.status >= MessageStatus.SENT && (*/}
        <div className={styles.attributes}>
          {attributes}
        </div>
      {/*)}*/}
    </div>
  )
})

