import { sortByIndex } from '@/utils/arrays'
import { persistAndSyncStore } from '@/utils/mobx'
import {
  AdrClass,
  AdrClassSlug,
  CargoType,
  CargoTypeSlug,
  Currency,
  ExtraEquipment,
  ExtraEquipmentSlug,
  LoadingType,
  LoadingTypeSlug,
  PaymentCondition,
  PaymentConditionSlug,
  PaymentMethod,
  PaymentMethodSlug,
  PointType,
  PointTypeSlug,
  PublicationAutocancelReason,
  PublicationAutocancelReasonSlug,
  ShippingMode,
  ShippingModeSlug,
  TransportType,
  TransportTypeSlug,
  TruckBody,
  TruckBodySlug
} from '@roolz/types/api/knowledge/exchange'
import { makeAutoObservable } from 'mobx'

class ExchangeKnowledgeStore {
  // is all options are loaded and ready to be used
  isReady = false
  allCurrencies: Currency[] = []
  allCargoTypes: CargoType[] = []
  allTruckBodies: TruckBody[] = []
  allAdrClasses: AdrClass[] = []
  allPaymentMethods: PaymentMethod[] = []
  allShippingModes: ShippingMode[] = []
  allTransportTypes: TransportType[] = []
  allExtraEquipment: ExtraEquipment[] = []
  allLoadingTypes: LoadingType[] = []
  allPointTypes: PointType[] = []
  allPaymentConditions: PaymentCondition[] = []
  allPublicationAutocancelReasons: PublicationAutocancelReason[] = []
  constructor() {
    makeAutoObservable(this)
  }
  get supportedCurrencies(): Currency[] {
    return sortByIndex(this.allCurrencies.filter(({ slug }) => !!slug))
  }
  get supportedCargoTypes(): CargoType[] {
    return sortByIndex(this.allCargoTypes.filter(({ slug }) => Object.values(CargoTypeSlug).includes(slug)))
  }
  get supportedTruckBodies(): TruckBody[] {
    return sortByIndex(this.allTruckBodies.filter(({ slug }) => Object.values(TruckBodySlug).includes(slug)))
  }
  get supportedAdrClasses(): AdrClass[] {
    return sortByIndex(this.allAdrClasses.filter(({ slug }) => Object.values(AdrClassSlug).includes(slug)))
  }
  get supportedAdrClassesForCargo(): AdrClass[] {
    return this.supportedAdrClasses.filter(({ cargo }) => cargo)
  }
  get supportedAdrClassesForTransport(): AdrClass[] {
    return this.supportedAdrClasses.filter(({ transport }) => transport)
  }
  get supportedDangerousAdrClasses(): AdrClass[] {
    return this.supportedAdrClasses
      .filter(({ slug }) => ![AdrClassSlug.no].includes(slug))
  }
  get cargoDefaultAdrClass(): AdrClass | undefined {
    return this.supportedAdrClasses.find(({ cargo_default }) => cargo_default)
  }

  get supportedPaymentMethods(): PaymentMethod[] {
    return sortByIndex(this.allPaymentMethods.filter(({ slug }) => Object.values(PaymentMethodSlug).includes(slug)))
  }
  get supportedShippingModes(): ShippingMode[] {
    return sortByIndex(this.allShippingModes.filter(({ slug }) => Object.values(ShippingModeSlug).includes(slug)))
  }
  get cargoDefaultShippingMode(): ShippingMode | undefined {
    return this.supportedShippingModes.find(({ cargo_default }) => cargo_default)
  }
  get supportedTransportTypes(): TransportType[] {
    return sortByIndex(this.allTransportTypes.filter(({ slug }) => Object.values(TransportTypeSlug).includes(slug)))
  }
  get supportedTransportTypesForTransport(): TransportType[] {
    return this.supportedTransportTypes.filter(({ transport }) => transport)
  }
  get supportedTransportTypesForCargo(): TransportType[] {
    return this.supportedTransportTypes.filter(({ cargo }) => cargo)
  }

  get cargoDefaultTransportType(): TransportType | undefined {
    return this.supportedTransportTypes.find(({ cargo_default }) => cargo_default)
  }
  get supportedExtraEquipment(): ExtraEquipment[] {
    return sortByIndex(this.allExtraEquipment.filter(({ slug }) => Object.values(ExtraEquipmentSlug).includes(slug)))
  }
  get supportedLoadingTypes(): LoadingType[] {
    return sortByIndex(this.allLoadingTypes.filter(({ slug }) => Object.values(LoadingTypeSlug).includes(slug)))
  }
  get supportedPointTypes(): PointType[] {
    return sortByIndex(this.allPointTypes.filter(({ slug }) => Object.values(PointTypeSlug).includes(slug)))
  }
  get supportedPaymentConditions(): PaymentCondition[] {
    return sortByIndex(this.allPaymentConditions.filter(({ slug }) => Object.values(PaymentConditionSlug)
      .includes(slug)))
  }
  get supportedPublicationAutocancelReasons(): PublicationAutocancelReason[] {
    return sortByIndex(this.allPublicationAutocancelReasons.filter(({ slug }) => Object.values(PublicationAutocancelReasonSlug)
      .includes(slug)))
  }
  findSupportedCurrencyByCountry(alpha2: string): Currency | undefined {
    return this.supportedCurrencies.find(({ countries }) => !!countries?.length && countries.includes(alpha2))
  }
  findSupportedCurrencyBySlug(slug: Currency['slug']): Currency | undefined {
    return this.supportedCurrencies.find(currency => currency.slug === slug)
  }
  findSupportedAdrClassBySlug(slug: string): AdrClass | undefined {
    return this.supportedAdrClasses.find(adr => adr.slug === slug)
  }
  findSupportedCargoType(sl: string): CargoType | undefined {
    return this.supportedCargoTypes.find(({ slug }) => slug === sl)
  }
  findSupportedTruckBody(sl: TruckBodySlug): TruckBody | undefined {
    return this.supportedTruckBodies.find(({ slug }) => slug === sl)
  }

  findTruckBodiesBySupportedTransportType(transportType: TransportTypeSlug) {
    return this.supportedTruckBodies.filter(truckBody => truckBody.transport_types.includes(transportType))
  }

  findSupportedShippingMode(sl: string): ShippingMode | undefined {
    return this.allShippingModes.find(({ slug }) => slug === sl)
  }
  findSupportedTransportType(sl: string): TransportType | undefined {
    return this.supportedTransportTypes.find(({ slug }) => slug === sl)
  }
  findExtraEquipment(sl: ExtraEquipmentSlug): ExtraEquipment | undefined {
    return this.supportedExtraEquipment.find(({ slug }) => slug === sl)
  }
  findSupportedPointType(sl: string): PointType | undefined {
    return this.supportedPointTypes.find(({ slug }) => slug === sl)
  }

  reset() {
    const instance = new ExchangeKnowledgeStore()
    // @ts-ignore
    for(const [key, value] in Object.entries(instance)) {
      // @ts-ignore
      this[key] = value
    }
  }
}

const exchangeKnowledgeStore = persistAndSyncStore(new ExchangeKnowledgeStore(), 'exchangeKnowledge')

export {
  ExchangeKnowledgeStore,
  exchangeKnowledgeStore
}
