import { Offer } from './api/exchange'
import { LoadingTypeSlug, PointTypeSlug } from './api/knowledge/exchange'
import z from 'zod'

/**
 * Types of internal frontend entities that not exist or have any belongings with backend
 */

export const CountrySchema = z.object({
  id: z.optional(z.string()),
  name: z.string(),
  phone_code: z.union([z.string(), z.number()]),
  alpha2: z.string(),
  flag: z.string().url()
})

// TODO think where to move it?
export type SeoItem = {
  slug: string
  path: string
  indexing: Array<string>
  filter: string

  options?: CategoryOptions
}

// TODO think where to move it?
export type CategoryOptions = {
  slug: string
  index: number
  group: string,
  is_country_subcategories: boolean,
  is_image_label: boolean,
  is_infoblock: boolean,
  is_exchange_role: boolean,
  is_shipping_modes: boolean,
  is_fleet_amount: boolean,
  is_reg_country: boolean,
  is_order_amount: boolean,
  is_employee_amount: boolean,
  is_cargo_type: boolean,
  is_transportation_type: boolean
  is_logistics_service: boolean,
  is_customs_service: boolean,
  is_international_direction: boolean,
  is_national_direction: boolean,
  is_company_form: boolean
}

export type IndexingConfig = {
  'company-view': boolean
}

export type Country = z.infer<typeof CountrySchema>

export interface UserCountry extends Country {
  latitude: number
  longitude: number
  languages: string[]
}

export enum Mimetypes {
  PNG = 'image/png'
}

export type Coordinates = {
  x: number
  y: number
}

export type Dimensions = {
  width: number,
  height: number
}

export interface RoutePoint {
  location: RoutePointLocation | null
  datetime: {
    date_from: number | null
    date_to: number | null
    time_from: number | null
    time_to: number | null
    timezone: string | null
  },
  radius?: number | null
  type?: PointTypeSlug | null
  loading_type?: LoadingTypeSlug[] | null
}

export interface RoutePointLocation {
  disputed?: boolean
  osm_id: string | null
  osm_type: string | null
  position_type: string | null
  is_radius?: boolean | null
  lat: number | null
  lon: number | null
  address: Offer['route'][number]['location']['address'] | null
}

export interface BaseChangeEvent {
  target: {
    name: string
    value: string
  }
}

export enum Direction {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum OnlineStatus {
  Online = 'online',
  Recently = 'recently',
  Offline = 'offline'
}

export interface MetaTag {
  property: string
  content: string
}

export enum VALIDATION_STATUS_CODES {
  INVALID_EMAIL = 'INVALID_EMAIL',
  SPACE_LIMIT_EXCEEDED = 'SPACE_LIMIT_EXCEEDED',
  ENTITY_ALREADY_EXISTS = 'ENTITY_ALREADY_EXISTS'
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
