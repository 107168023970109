import * as React from 'react'

interface Props {
  color?: string
}

export function Download({ color = '#4778EE' }: Props) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.3 15.4992C1.8 15.4992 1.375 15.3242 1.025 14.9742C0.675 14.6242 0.5 14.1992 0.5 13.6992V10.9992H2V13.6992C2 13.7659 2.03333 13.8326 2.1 13.8992C2.16667 13.9659 2.23333 13.9992 2.3 13.9992H13.7C13.7667 13.9992 13.8333 13.9659 13.9 13.8992C13.9667 13.8326 14 13.7659 14 13.6992V10.9992H15.5V13.6992C15.5 14.1992 15.325 14.6242 14.975 14.9742C14.625 15.3242 14.2 15.4992 13.7 15.4992H2.3ZM8 11.6242L3.725 7.34922L4.775 6.24922L7.25 8.72422V0.324219H8.75V8.72422L11.225 6.24922L12.275 7.34922L8 11.6242Z'
        fill={color}
      />
    </svg>
  )
}
