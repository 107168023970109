import { Close } from '@roolz/icons/Close'
import cn from 'classnames'
import styles from './CloseButton.module.scss'

interface Props {
  className?: string
  [key: string]: any
}

export const CloseButton = (props: Props) => {
  const { className, ...rest } = props

  return (
    <button
      className={cn(styles.close, className)}
      {...rest}
    >
      <Close className={styles.icon} color='#8E8E93'/>
    </button>
  )
}
