import { isMessageVisibleForMe } from '@/components/chats/utils'
import { ChatModelFactory } from '@/models/Chat.model'
import { OwnPcpModelFactory } from '@/models/OwnPcp.model'
import { PcpModelFactory } from '@/models/Pcp.model'
import { chatsService } from '@/store/chats/chats.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel, isAddedPcpMessage, isSystemMessage, MessageModel, OwnPcpModel, PcpModel } from '@/types/models/chat'
import { mergeModels } from '@/utils/arrays'
import { getNow } from '@/utils/date'
import { injectStores } from '@mobx-devtools/tools'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { isNumber } from '@roolz/sdk/utils/types'
import {
  Chat,
  ChatType,
  Message,
  MessageStatus,
  OwnPcp,
  Pcp,
  PcpStatus,
  PinGroup,
  SpecialChatGroupsTypes
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { groupBy, keyBy, sortedIndexBy } from 'lodash-es'
import { makeAutoObservable, observable, runInAction } from 'mobx'

export function messagesDescComparator(a: Message, b: Message) {
  const getMessageTime = (message: Message): Date => {
    // if(message.type === MessageType.FILE) {
    //   const clientId = message.client_message_id
    //   if(/^.*:\d+$/.test(clientId)) {
    //     return new Date(clientId.replaceAll(/.*:/g, ''))
    //   }
    // }
    return new Date(message.created_at)
  }

  return getMessageTime(a).getTime() - getMessageTime(b).getTime()
}

type PcpsByChatId = Record<Chat['id'],
  Record<Profile['id'], PcpModel>>


export type ChatMessagePanelMode = {
  type: 'reply'
  message: MessageModel
} | {
  type: 'edit'
  message: MessageModel
}

class ChatsStore {
  // TODO probably sort chats according to group
  activeGroup: string | SpecialChatGroupsTypes = SpecialChatGroupsTypes.ALL

  activeChatId: Chat['id'] | null = null
  activeChatMessage = ''
  chatMessagePanelModes: Record<Chat['id'], ChatMessagePanelMode> = {}
  forwardingMessage: MessageModel | null = null

  chats: Record<Chat['id'], ChatModel> = {}
  readyChats: Array<Chat['id']> = []


  pcps: PcpsByChatId = {}
  ownPcps: Record<Chat['id'], OwnPcpModel> = {}
  messages: Record<Chat['id'], MessageModel[]> = {}

  get messagesByNumbers() {
    const res: Record<Chat['id'], any> = {}

    Object.entries(this.messages).map(([chatId, messages]) => {
      res[chatId] = keyBy(messages, 'number')
    })

    return res
  }

  drafts: Record<Chat['id'], string> = {}

  constructor() {
    makeAutoObservable(this, {
      messages: observable.deep,
      chats: observable.deep,
      pcps: observable.deep,
      ownPcps: observable.deep
    })
  }

  getChatMessageByNumber(chatId: Chat['id'], number: Message['number']) {
    return this.messagesByNumbers[chatId][number]
    // return this.messages[chatId].find(item => item.number === number)
  }

  get activeChat(): ChatModel | undefined {
    if(this.activeChatId) {
      return this.chats[this.activeChatId]
    }
    return undefined
  }

  isChatReady(chatId: Chat['id']): boolean {
    return this.readyChats?.includes(chatId)
  }

  setChatReady(chatId: Chat['id']): void {
    this.readyChats.push(chatId)
  }

  setChatNotReady(chatId: Chat['id']): void {
    this.readyChats = this.readyChats.filter(item => item !== chatId)
  }

  // setActiveChat({ id }: Pick<Chat, 'id'>) {
  //   this.activeChatId = id
  //
  //   // TODO think if it should lay here
  //   chatsService.loadChatMessages(id)
  // }

  get activeChatMessagePanelMode() {
    return this.activeChatId
      ? this.chatMessagePanelModes[this.activeChatId]
      : null
  }


  get visibleChats() {
    // TODO ask all conditions
    return Object.values(this.chats).filter(chat => {
      if(chat.type !== ChatType.SELF_CHAT && chat.totalMessagesCount < 1) {
        return false
      }

      if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)
        && chat.count_members < 1
      ) {
        return false
      }

      return !!chat.own_pcp
        && ![PcpStatus.DELETED, PcpStatus.GONE].includes(chat.own_pcp.status)
        && chat.is_active
        && chat.own_pcp.is_banned !== true
    })
  }

  get sortedVisibleChats() {
    const visibleChats = [...this.visibleChats]

    visibleChats.sort((a: ChatModel, b: ChatModel) => {
      // if(a.type === ChatType.SELF_CHAT) return -1
      // if(b.type === ChatType.SELF_CHAT) return 1

      const aPin = a.own_pcp?.pins_data?.find(pin => pin.pin_group === this.activeGroup)
      const bPin = b.own_pcp?.pins_data?.find(pin => pin.pin_group === this.activeGroup)

      if(aPin?.pinned_at && bPin?.pinned_at) {
        return new Date(bPin.pinned_at).getTime() - new Date(aPin.pinned_at).getTime()
      }

      if(aPin) return -1
      if(bPin) return 1


      // let aUnread = false
      // let bUnread = false

      // if(a.own_pcp?.last_read_message_index < a?.count_messages) aUnread = true
      // if(b.own_pcp?.last_read_message_index < b?.count_messages) bUnread = true
      //
      // if(aUnread && !bUnread) return -1
      // if(!aUnread && bUnread) return 1

      const getChatDate = (chat: ChatModel): string => {
        return [
          chat?.last_message?.created_at,
          ChatType.SELF_CHAT ? chat.updated_at : undefined,
          chat.created_at
        ].filter(val => !!val)?.[0] ?? '0'
      }

      return new Date(getChatDate(b)).getTime() - new Date(getChatDate(a)).getTime()
    })

    return visibleChats
  }

  getChat(id: Chat['id']): ChatModel | undefined {
    return this.chats[id]
  }

  getOwnPcp(id: Chat['id']): OwnPcp | undefined {
    return this.ownPcps[id]
  }

  addOrUpdateChats(chats: Chat[]) {
    chats.forEach(item => {
      const chat = ChatModelFactory(item)

      if(this.chats[chat.id]) {
        mergeModels(this.chats[chat.id], chat)
      } else {
        this.chats[chat.id] = chat
      }
    })
  }

  updateChatsIfExist(chats: Chat[]) {
    chats.forEach(chat => {
      if(this.chats[chat.id]) {
        mergeModels(this.chats[chat.id], ChatModelFactory(chat))
      }
    })
  }

  addOrUpdatePcps(items: Pcp[]) {
    try {
      const pcps = items.map(PcpModelFactory)

      const pcpByChatId = groupBy(pcps, ({ chat_id }) => chat_id)

      Object.keys(pcpByChatId).forEach(chat_id => {
        const chatPcpsByProfileId = keyBy(pcpByChatId[chat_id], ({ profile_id }) => profile_id)

        Object.keys(chatPcpsByProfileId).forEach(profile_id => {
          this.pcps[chat_id] ??= {}
          const existingPcp = this.pcps?.[chat_id]?.[profile_id] ?? {}

          this.pcps[chat_id][profile_id] = mergeModels(existingPcp, chatPcpsByProfileId[profile_id])
        })
      })
    } catch(e) {
      console.log(e)
    }
  }

  addOrUpdateOwnPcps(items: OwnPcp[]) {
    const pcps = items.map(OwnPcpModelFactory)

    const pcpByChatId = keyBy(pcps, ({ chat_id }) => chat_id)

    Object.keys(pcpByChatId).forEach(chat_id => {
      const pcp = pcpByChatId[chat_id]

      const existingPcp = this.ownPcps[chat_id]

      if(existingPcp?.last_read_message_index > pcp.last_read_message_index) {
        pcp.last_read_message_index = existingPcp.last_read_message_index
      }

      this.ownPcps[chat_id] = mergeModels(existingPcp ?? {}, pcp)
    })
  }

  addOrUpdateChatOwnPcp(chatId: Chat['id'], data: Partial<OwnPcp>) {
    if(this.ownPcps[chatId]) {
      mergeModels(this.ownPcps[chatId], data)
    }
  }

  updateChat(chatId: Chat['id'], data: Partial<Chat>) {
    if(this.chats[chatId]) {
      mergeModels(this.chats[chatId], data)
    }
  }

  // updateChatById(id: Chat["id"], data: Partial<Chat>) {
  //   merge(this.chats[id], data)
  // }

  addOrUpdateMessages(messages: MessageModel[], params?: {
    incrementChatMessagesCountIfNew: boolean
  }) {
    Object.entries(
      groupBy(messages, ({ chat_id }) => chat_id)
    ).forEach(([chat_id, messages]) => {
      const messagesByClientId = keyBy(messages, ({ client_message_id }) => client_message_id)

      const existingChatMessages = this.messages[chat_id] ?? []

      existingChatMessages.forEach(message => {
        if(messagesByClientId[message.client_message_id]) {
          const newMessage = messagesByClientId[message.client_message_id]

          if(params?.incrementChatMessagesCountIfNew
            && message.status < MessageStatus.SENT
            && this.chats[chat_id]
          ) {
            this.chats[chat_id].count_messages += 1
          }

          mergeModels(message, {
            ...newMessage,
            status: Math.max(newMessage.status, message.status)
          })

          delete messagesByClientId[message.client_message_id]
        }

        // Top up last_read_message_index if necessary
        if((
          message.status === MessageStatus.READ
          && message.isOwnMessage
          && message.chat?.own_pcp
          && message.version === 0
          && message.chat?.own_pcp?.last_read_message_index < message.number
        )) {
          message.chat.own_pcp.last_read_message_index = message.number
        }
      })

      messages.forEach(message => {
        if(message.chat?.type !== ChatType.SELF_CHAT
          && message.status === MessageStatus.SENT
          && !message.isOwnMessage
          && message.version === 0
        ) {
          chatsService.updateMessageStatus({
            chat_id: message.chat_id,
            client_message_id: message.client_message_id,
            message_number: message.number,
            sender_id: message.sender_id
          }, MessageStatus.DELIVERED)
        }
      })

      // New messages handling
      Object.values(messagesByClientId).forEach(message => {
        if(isSystemMessage(message) && isAddedPcpMessage(message)) {
          try {
            // profilesService.loadProfile(message.decodedContent.content.added_profile.id)
            // profilesStore.addOrUpdateProfile(message.decodedContent.content.added_profile)
          } catch(e) {
            console.log(e)
          }
        }

        // Top up last_read_message_index depending on new messages
        if((
          message.isOwnMessage
          && message.chat?.own_pcp?.last_read_message_index
          && message.chat?.own_pcp?.last_read_message_index < message.number
        )
          // || (
          // message.chat?.type === ChatType.CHANNEL
          // && [PcpType.ADMIN, PcpType.OWNER].includes(message.chat?.own_pcp.type)
          // )
        ) {
          message.chat.own_pcp.last_read_message_index = message.number
        }

        if(isSystemMessage(message)
          && message.chat?.type !== ChatType.SELF_CHAT
          && message.isOwnMessage
          && message.chat?.unreadMessagesCount === 0
          && message.chat?.own_pcp?.last_read_message_index < message.number
        ) {
          message.chat.own_pcp.last_read_message_index = message.number
        }
      })

      if(params?.incrementChatMessagesCountIfNew === true) {
        if(this.chats[chat_id]) {
          this.chats[chat_id].count_messages += Object.values(messagesByClientId).length
        }
      }

      existingChatMessages.push(...Object.values(messagesByClientId))

      existingChatMessages.sort(messagesDescComparator)

      this.messages[chat_id] = existingChatMessages
    })
  }

  updateMessage(chatId: Chat['id'], clientMessageId: Message['client_message_id'], data: Partial<Message>) {
    runInAction(() => {
      // debugger
      const existingChatMessages = this.messages[chatId]
      if(existingChatMessages?.length) {
        // debugger
        const message = existingChatMessages.find(msg => msg.client_message_id === clientMessageId)

        const chat = this.chats[chatId]

        if(message) {
          console.groupCollapsed('UP MSG ' + message.number)
          console.time('whole ' + message.number)

          console.time('1')

          let newStatus = data.status

          if(newStatus && newStatus >= MessageStatus.SENDING) {
            newStatus = Math.max(newStatus, message.status)
          }

          mergeModels(message, {
            ...data,
            status: newStatus
          })

          if(newStatus === MessageStatus.READ) {
            chat.own_pcp.last_read_message_index = Math.max(chat.own_pcp.last_read_message_index, message.number)
          }
          console.timeEnd('1')
          console.time('2')
        }

        if(message && isNumber(message.number) && chat.own_pcp) {
          console.timeEnd('2')
          const before =  performance.now()
          console.time('3')

          for(let i = message.number - 1; i >= chat.own_pcp.min_message_index; i--) {
            // console.time('msg ' + i)
            const start = performance.now()

            const msg = this.getChatMessageByNumber(chatId, i)

            if(!msg
              || (chat.type === ChatType.DIALOG && !msg.isOwnMessage)
              || msg.version !== 0
            ) {
              if(performance.now() - start > 0) {
                console.log('msg ' + i, performance.now() - start)
              }
              // console.timeEnd('msg ' + i)

              continue
            }

            switch(data?.status) {
              case MessageStatus.DELIVERED: {
                if(msg.status === MessageStatus.SENT) {
                  mergeModels(msg, { status: MessageStatus.DELIVERED })
                }
                break
              }
              case MessageStatus.READ: {
                if(msg.status === MessageStatus.DELIVERED) {
                  mergeModels(msg, { status: MessageStatus.READ })
                }
                break
              }
            }

            if(data?.status === MessageStatus.READ) {
              chat.own_pcp.last_read_message_index = Math.max(chat.own_pcp.last_read_message_index, msg.number)
            }
            if(performance.now() - start > 0) {
              console.log('msg ' + i, performance.now() - start)
            }
          }
          console.timeEnd('3')
          console.log('3', performance.now() - before)

        }
        console.timeEnd('whole ' + message?.number)

        console.groupEnd()
      }
    })
  }

  updateChatOwnPcp(chatId: Chat['id'], data: Partial<OwnPcp>) {
    const ownPcp = this.chats[chatId]?.own_pcp

    if(data.last_read_message_index !== undefined) {
      data.last_read_message_index = Math.max(ownPcp?.last_read_message_index, data.last_read_message_index)
    }

    if(ownPcp) {
      mergeModels(ownPcp, data)
    }
  }

  getLastVisibleChatMessage(chat_id: Chat['id']): MessageModel | undefined {
    const messagesCount = this.messages[chat_id]?.length

    if(!messagesCount) {
      return undefined
    }
    for(let i = messagesCount - 1; i >= 0; i--) {
      const message = this.messages[chat_id][i]

      if(isMessageVisibleForMe(message)) {
        return message
      }
    }
    return undefined
  }


  updateChatMessagesByNumber(chat_id: Chat['id'], number: Message['number'], data: Partial<Message>) {
    const i = sortedIndexBy(this.messages[chat_id], { number } as any, msg => msg.number)

    if(i !== -1) {
      mergeModels(this.messages[chat_id][i], data)
    }
  }

  setChatPinned(chatId: Chat['id'], group: PinGroup) {
    const ownPcp = this.getOwnPcp(chatId)
    if(!ownPcp || !ownPcp?.pins_data) {
      return
    }

    const hasPin = ownPcp.pins_data.find(pin => pin.pin_group === group)
    if(!hasPin) {
      ownPcp.pins_data.push({
        pin_group: group,
        pinned_at: getNow().toString()
      })
    }
  }


  setChatUnpinned(chatId: Chat['id'], group: PinGroup) {
    const ownPcp = chatsStore.getOwnPcp(chatId)
    if(!ownPcp || !ownPcp?.pins_data) {
      return
    }

    ownPcp.pins_data = ownPcp.pins_data.filter(pin => pin.pin_group !== group)
  }

  getDialogWithUser(id: Profile['id']): Chat | undefined {
    const myId = profilesStore.my_profile?.id ?? ''

    return this.chats[myId + ':' + id] ?? this.chats[id + ':' + myId]
  }

  cleanChatMessages(id: Chat['id']) {
    delete this.messages[id]
  }

  setEditingMessage(chatId: Chat['id'], message: MessageModel) {
    this.chatMessagePanelModes[chatId] = {
      type: 'edit',
      message
    }
  }

  setReplyingMessage(chatId: Chat['id'], message: MessageModel) {
    this.chatMessagePanelModes[chatId] = {
      type: 'reply',
      message
    }
  }

  resetChatMessagePanelMode(chatId: Chat['id']) {
    delete this.chatMessagePanelModes[chatId]
  }

  deleteMessage(chatId: Chat['id'], clientMessageId: Message['client_message_id']) {
    const i = this.messages[chatId].findIndex(({ client_message_id }) => client_message_id === clientMessageId)

    if(i > -1) {
      this.messages[chatId].splice(i, 1)
    }
  }

  get updatesCount() {
    return this.sortedVisibleChats.reduce((cnt: number, item: ChatModel) => {
      return cnt + Number(item.unreadMessagesCount > 0 && !item.own_pcp?.is_muted)
    }, 0)
  }
}

export const chatsStore = new ChatsStore()

injectStores({ chatsStore })
