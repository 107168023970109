import { CompanyMember, Statuses } from '@roolz/types/api/companyMembers'
import { CompanyInvitation } from '@roolz/types/api/profiles'
import { makeAutoObservable } from 'mobx'

const getStatusesDefault = (): Statuses => ({
  with_status_pending: {
    emails: [],
    total: 0
  },
  with_status_await: {
    emails: [],
    total: 0
  },
  with_status_email_errors: {
    emails: [],
    total: 0
  }
})

class CompanyMembersStore {
  members: CompanyMember[] = []
  invitations: CompanyInvitation[] = []
  membersTotalCount = 0
  invitationsTotalCount = 0
  statuses: Statuses = getStatusesDefault()

  constructor() {
    makeAutoObservable(this, undefined, {deep: true})
  }

  setMembers(members: CompanyMember[]) {
    this.members = members
  }

  setMembersTotalCount(totalCount = 0) {
    this.membersTotalCount = totalCount
  }

  setInvitations(invitations: CompanyInvitation[]) {
    this.invitations = invitations
  }

  setInvitationsTotalCount(totalCount = 0) {
    this.invitationsTotalCount = totalCount
  }

  setMemberStatuses(statuses: Statuses) {
    this.statuses = { ...statuses }
  }

  resetMembersAndInvitations() {
    this.members = []
    this.membersTotalCount = 0
    this.invitations = []
    this.invitationsTotalCount = 0
  }
}

const companyMembersStore = new CompanyMembersStore()

export {
  CompanyMembersStore,
  companyMembersStore
}
