import gsap from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { TextPlugin } from "gsap/dist/TextPlugin"
import { SplitText } from "gsap/dist/SplitText"
import { ScrambleTextPlugin } from "gsap/dist/ScrambleTextPlugin"

import { ScrollSmoother } from "gsap/dist/ScrollSmoother"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(TextPlugin)
gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrambleTextPlugin)
gsap.registerPlugin(ScrollSmoother)

export default gsap
