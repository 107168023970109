import React from 'react'

interface Props {

}

export const Forward = (props: Props) => {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.03411 7.76673L11.8008 4.00007L8.03411 0.233398L7.33411 0.933399L9.90078 3.50007H0.20078V4.50007H9.90078L7.33411 7.06673L8.03411 7.76673Z'
        fill='#8E8E93'
      />
    </svg>
  )
}
