import { db, DEXIE_STORES } from '@/database'
import { chatsStore } from '@/store/chats/chats.store'
import { getNow } from '@/utils/date'
import { Chat, OwnPcp, PinGroup } from '@roolz/types/api/chats'
import { groupBy, merge } from 'lodash-es'

export async function addOrUpdateOwnPcps(pcps: Array<OwnPcp>) {
  const byChatIds = groupBy(pcps, 'chat_id')

  return db.transaction('rw', db.own_pcps, async () => {
    const existing = await db.own_pcps.bulkGet(Object.keys(byChatIds))

    existing.forEach(item => {
      if(item) merge(item, byChatIds[item.chat_id][0])
    })
    await db.own_pcps.bulkPut(Object.values(byChatIds).map(c => c[0]))
  })
}

export async function patchOwnPcp(chatId: Chat["id"], data: Partial<OwnPcp>) {
  try {
    db[DEXIE_STORES.OWN_PCPS].update(chatId, data)
  } catch(e) {
    console.error(e)
  }

  chatsStore.updateChatOwnPcp(chatId, data)
}

export async function setChatPinned(chatId: Chat["id"], group: PinGroup) {
  chatsStore.setChatPinned(chatId, group)

  return db.transaction('rw', DEXIE_STORES.OWN_PCPS, async () => {
    const ownPcp = await db[DEXIE_STORES.OWN_PCPS].get(chatId)

    if(!ownPcp) {
      console.error('NO OWN PCP', ownPcp)

      return
    }

    const hasPin = ownPcp.pins_data.find(pin => pin.pin_group === group)
    if(!hasPin) {
      await db[DEXIE_STORES.OWN_PCPS].update(chatId, {
        pins_data: [
          ...ownPcp.pins_data,
          { pin_group: group, pinned_at: getNow().toString() }
        ]
      })
    }
  })
}

export async function setChatUnpinned(chatId: Chat["id"], group: PinGroup) {
  chatsStore.setChatUnpinned(chatId, group)

  db.transaction('rw', DEXIE_STORES.OWN_PCPS, async () => {
    const ownPcp = await db[DEXIE_STORES.OWN_PCPS].get(chatId)

    if(!ownPcp) {
      console.error('NO OWN PCP', ownPcp)

      return
    }

    const hasPin = ownPcp.pins_data.find(pin => pin.pin_group === group)
    if(hasPin) {
      await db[DEXIE_STORES.OWN_PCPS].update(chatId, {
        pins_data: ownPcp.pins_data.filter(item => item.pin_group !== group)
      })
    }
  })
}
