import { wsService } from '@/store/ws/ws.service'
import { getNow } from '@/utils/date'
import { persistAndSyncStore } from '@/utils/mobx'
import { makeAutoObservable, runInAction } from 'mobx'

class AuthStore {
  loading = false
  token = ''
  expires_at?: string

  constructor() {
    makeAutoObservable(this)
  }

  get isAuthed(): boolean {
    return !!(this.token
      && this.expires_at
      && new Date(this.expires_at).getTime() > getNow().getTime())
  }

  setCredentials({
    token,
    expires_at
  }: {
    token: string,
    expires_at: string
  }) {
    this.token = token
    this.expires_at = expires_at

    if(token) {
      wsService.init()
    }
  }

  clean() {
    runInAction(() => {
      this.token = ''
      this.expires_at = new Date(0).toString()
    })
  }
}

const authStore = persistAndSyncStore(new AuthStore(), 'auth')

export {
  AuthStore,
  authStore
}
