import { Company } from '@roolz/types/api/companies'
import {
  GetPartnershipInvitationsRequest,
  Partnership,
  PartnershipInvitation,
  PartnershipType
} from '@roolz/types/api/partnership'
import { mergeWith } from 'lodash-es'
import { autorun, makeAutoObservable, runInAction } from 'mobx'

type PartnershipMapByCompanyId = Record<Company['id'], Partnership>

export enum FiltersTypes {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}

export const INVITATIONS_LIMIT = 20

class PartnershipStore {
  partners: Record<Partnership['id'], Partnership> = {}
  partnersLoading = false

  invitations: Record<PartnershipInvitation['id'], PartnershipInvitation> = {}
  invitationsTotal = 0
  invitationsLoading = false
  incomingInvitesCount = 0
  invitationsPage = 0
  invitationsActiveFilter?: FiltersTypes = FiltersTypes.INCOMING

  lastInvitesRequestFilter: null | GetPartnershipInvitationsRequest = null

  constructor() {
    makeAutoObservable(this)
  }

  get partnersList() {
    return Object.values(this.partners)
  }

  get invitationsList() {
    return Object.values(this.invitations)
  }

  get partnerByCompanyId(): PartnershipMapByCompanyId {
    return this.partnersList.reduce((result, partner) => {
      result[partner.company_id] ??= partner

      return result
    }, {} as PartnershipMapByCompanyId)
  }

  get partnersICanSend() {
    return this.partnersList.filter((partner) => partner.partnership_type !== PartnershipType.INCOMING)
  }

  addOrUpdatePartner(partner: Partnership) {
    const existing = this.partners[partner.id]

    if(existing) {
      return mergeWith(existing, partner, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })
    }

    this.partners[partner.id] = partner
  }

  addOrUpdateInvites(invite: PartnershipInvitation) {
    const existing = this.invitations[invite.id]

    if(existing) {
      return mergeWith(existing, invite, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })
    }

    this.invitations[invite.id] = invite
  }

  addInvites(invites: PartnershipInvitation[]) {
    invites.forEach(invite => {
      this.invitations[invite.id] = invite
    })
  }

  findPartner = (id: Partnership['id']): Partnership | undefined => {
    return this.partners?.[id]
  }

  deletePartner = (id: Partnership['id']) => {
    delete this.partners[id]
  }

  resetInvites() {
    this.invitations = {}
    this.invitationsTotal = 0
    this.invitationsLoading = false
    this.invitationsPage = 0
  }

  resetState() {
    this.partners = {}
    this.resetInvites()
  }
}

export const partnershipStore = new PartnershipStore()
