import { ChatItem, SearchChats } from '@/components/modals/SearchChats/SearchChats'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { getNow } from '@/utils/date'
import { cloneDeep } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { MessageType, Chat } from '@roolz/types/api/chats'
import { v4 as uuidv4 } from 'uuid'
import { toastSuccess } from '@roolz/sdk/components/snackbars'

export const MessageForwardProvider = observer(() => {
  const { t } = useTranslation('chat/common')

  function setOpen(v: boolean) {
    if(!v) {
      chatsStore.forwardingMessage = null
    }
  }


  function handleForwardChatSelected(item: ChatItem) {
    let chat: Partial<Chat> | undefined

    if(item.type === 'profile') {
      chat = chatsStore.getDialogWithUser(item.profile?.id)
    } else if(item.type === 'chat') {
      chat = item.chat
    }

    if(!chat?.id) {
      console.warn('UNSUPPORTED TYPE OF FORWARDING DESTINATION')
      return
    }


    let forwardMessage = cloneDeep(chatsStore.forwardingMessage)
    let type = MessageType.TEXT

    const sender_id = forwardMessage?.forward_from?.sender_id || forwardMessage?.sender_id
    if(sender_id === profilesStore.my_profile?.id && forwardMessage) {
      type = forwardMessage.type
      forwardMessage = null
    }

    chatsService.sendNewMessage({
      chat_id: chat.id,
      client_message_id: uuidv4(),
      type,

      content: chatsStore.forwardingMessage?.content ?? '{}',
      forward_from: forwardMessage?.forward_from || forwardMessage || undefined,
      forward_from_id: forwardMessage?.forward_from_id || forwardMessage?.id,
      created_at: getNow().toISOString()
    })

    // chat.chat?.id && chatsService.setActiveChatAsGroup(chat.chat?.id)
    chatSessionsStore.resetChatViewInfo(chat?.id)

    toastSuccess(t('chat/message:action_toasts.messages_forwarded'))

    chatsStore.forwardingMessage = null
  }

  return (
    <SearchChats
      title={t('search.forward_title')}
      type='message_forward'

      open={!!chatsStore.forwardingMessage}
      setOpen={setOpen}

      onChatSelect={handleForwardChatSelected}
    />
  )
})
