import { GlobalModalLink } from '@/global-modals/components/ModalLink'
import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { ReactNode } from 'react'
import { Profile } from '@roolz/types/api/profiles'

interface Props {
  profileId: Profile["id"]
  onOpen?: (id: Profile["id"]) => void
  replace?: boolean
  className?: string
  children?: ReactNode
}
export const ProfileLink = ({
  profileId,
  onOpen,
  replace,
  className,
  children,
}: Props) => {
  const { getLink, isAnotherProfileOpened } = useProfileLink()

 return (
   <GlobalModalLink
     onOpen={() => onOpen?.(profileId)}
     to={getLink(profileId)}
     replace={replace ? replace : isAnotherProfileOpened}
     className={className}
   >
     {children}
   </GlobalModalLink>
 )
}
