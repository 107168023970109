import { Dialog } from '@/components/ui/Dialog/Dialog'
import { SupportedLocale } from '@/plugins/i18n'
import { LockIcon } from '@roolz/icons/Lock'
import { AlertPanel2 } from '@roolz/sdk/components/ui/AlertPanel2/AlertPanel2'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { IS_DESKTOP, IS_SAFARI } from '@roolz/sdk/utils/device'
import { ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './EnableNotificationsGuide.module.scss'
import { Rocket } from '@roolz/icons/Rocket'

import WebsiteSettingsImageEn from '@/assets/images/notifications/en/website_settings.png'
import ToggleImageEn from '@/assets/images/notifications/en/toggle.png'

import SafariAllowImageEn from '@/assets/images/notifications/en/safari/allow.png'
import SafariNotificationsImageEn from '@/assets/images/notifications/en/safari/notifications.png'
import SafariPreferencesImageEn from '@/assets/images/notifications/en/safari/preferences.png'
import SafariWebsitesImageEn from '@/assets/images/notifications/en/safari/websites.png'

import WebsiteSettingsImageRu from '@/assets/images/notifications/ru/website_settings.png'
import ToggleImageRu from '@/assets/images/notifications/ru/toggle.png'

import SafariAllowImageRu from '@/assets/images/notifications/ru/safari/allow.png'
import SafariNotificationsImageRu from '@/assets/images/notifications/ru/safari/notifications.png'
import SafariPreferencesImageRu from '@/assets/images/notifications/ru/safari/preferences.png'
import SafariWebsitesImageRu from '@/assets/images/notifications/ru/safari/websites.png'

interface Props {
  open: boolean
  onClose: () => void
}

export const EnableNotificationsGuide = (props: Props) => {
  const { open, onClose } = props

  const { t } = useTranslation('modals')

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      onClose={handleClose}
      scroll='body'
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Rocket/>
          <h2 className={styles.header__text}>
            { t('enable_notifications.title') }
          </h2>

          <CloseButton
            className={styles.close}
            onClick={onClose}
          />
        </div>

        <div className={styles.content}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: t('enable_notifications.description').replaceAll('\n', '<br/>')
            }}
          >
          </div>

          <Steps/>

          <AlertPanel2
            content={t('enable_notifications.system_notification_settings_alert')}
          />
        </div>
      </div>
    </Dialog>
  )
}

const Steps = () => {
  const { t, i18n: { language } } = useTranslation('modals')

  const ruLocales: SupportedLocale[] = ['ru', 'uk']

  const isRu = ruLocales.includes(language as SupportedLocale)

  interface Step {
    title: ReactNode
    img?: string
  }

  const steps: Step[] = useMemo(() => {
    if(IS_DESKTOP && IS_SAFARI) {
      return [
        {
          title: t('enable_notifications.safari_steps.open_preferences'),
          img: isRu ? SafariPreferencesImageRu : SafariPreferencesImageEn,
        },
        {
          title: (
            <Trans
              t={t}
              i18nKey={'enable_notifications.safari_steps.open_websites'}
              components={{ bold: <b/> }}
            />
          ),
          img: isRu ? SafariWebsitesImageRu : SafariWebsitesImageEn,
        },
        {
          title: (
            <Trans
              t={t}
              i18nKey={'enable_notifications.safari_steps.select_notifications'}
              components={{ bold: <b/> }}
            />
          ),
          img: isRu ? SafariNotificationsImageRu : SafariNotificationsImageEn,
        },
        {
          title: (
            <Trans
              t={t}
              i18nKey={'enable_notifications.safari_steps.select_allow'}
              components={{ bold: <b/> }}
            />
          ),
          img: isRu ? SafariAllowImageRu : SafariAllowImageEn,
        },
      ]
    }

    return [
      {
        title: (
          <Trans
            t={t}
            i18nKey={'enable_notifications.default_steps.open_site_settings'}
            components={{
              Icon: <LockIcon style={{ position: 'relative', top: 2 }}/>
            }}
          />
        ),
        img: isRu ? WebsiteSettingsImageRu : WebsiteSettingsImageEn
      },
      {
        title: (
          <Trans
            t={t}
            i18nKey={'enable_notifications.default_steps.enable_toggle'}
            components={{ bold: <b/> }}
          />
        ),
        img: isRu ? ToggleImageRu : ToggleImageEn
      },
      {
        title: t('enable_notifications.default_steps.reload_page'),
      }
    ]
  }, [t, language])

  return <>
    {steps.map((step, i) => (
      <div
        className={styles.step}
        key={i}
      >
        <div className={styles.step__text}>
          <span className={styles.step__number}>
            {t('enable_notifications.step_n', { number: i + 1 })}:
          </span> {step.title}
        </div>
        {step.img && (
          <img src={step.img} alt='' className={styles.step__image}/>
        )}
      </div>
    ))}
  </>
}
