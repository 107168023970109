import { api } from '@/api'
import { db, DEXIE_STORES } from '@/database'
import i18n from '@/plugins/i18n'
import { addOrUpdateProfiles, getProfileById, patchProfile } from '@/repositories/profiles.repository'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import dayjs from '@roolz/sdk/plugins/dayjs'
import {
  changePasswordRequest,
  DeleteAuthPhoneRequest,
  deleteOwnEmailRequest,
  deleteOwnPasswordRequest,
  deleteOwnProfileRequest,
  PatchProfileRequest,
  Profile,
  updateOwnEmailRequest,
  updateOwnPhoneRequest
} from '@roolz/types/api/profiles'
import { throttle } from 'lodash-es'
import sha256 from 'sha256'
import { profilesStore } from './profiles.store'

class ProfilesService {
  bindToProfilesOnlineChanges = throttle(() => {
    const ids = [...profilesStore.presenceSubscriptionQueue]
    profilesStore.presenceSubscriptionQueue = []

    return api.wsProxy.bindToPresence({ user_ids: ids })
  }, 3000)

  loadProfile(id: Profile['id'], allowDeleted = true) {
    return api.profiles.getById(id, { allow_deleted: allowDeleted })
      .then(async ({ data }) => {
        await addOrUpdateProfiles([data])

        return data
      })
      // .catch(async e => {
      //   if(e?.response?.status === 404) {
      //     const existingProfile = await getProfileById(id)
      //     if(existingProfile) {
      //       patchProfile(id, {
      //         deleted_at: (new Date().toString())
      //       })
      //     }
      //   }
      // })
  }

  private loadingProfileIds: Profile['id'][] = []

  loadProfiles(ids: Array<Profile['id']>) {
    ids = ids.filter(id => !this.loadingProfileIds.includes(id))
    this.loadingProfileIds.push(...ids)

    if(!ids.length) {
      return
    }

    return Promise.all(ids.map(id => {
      return api.profiles.getById(id)
        .then(({ data }) => addOrUpdateProfiles([data]))
    }))

    // const requests: Array<Promise<any>> = []

    // const LIMIT = 50
    // let offset = 0
    //
    // do {
    //   const request = api.profiles.getByIds({
    //     profile_ids: ids.slice(offset, offset + LIMIT),
    //     limit: LIMIT,
    //     offset
    //   }).then(({ data }) => {
    //     return addOrUpdateProfiles(data)
    //   })
    //
    //   requests.push(request)
    //   offset += LIMIT
    // } while(offset + LIMIT < ids.length)

    // return Promise.all(requests)
  }

  retrieveMyProfile() {
    return api.profiles.retrieveMe()
      .then(({ data }) => {
        profilesStore.my_profile_id = data.id

        addOrUpdateProfiles([data])
        myCompaniesStore.companies = data?.companies || []

        if(data.lang_code) {
          i18n.changeLanguage(data.lang_code)
        }

        return data
      })
  }

  retrieveMyDevice() {
    return api.profiles.retrieveMyDevice()
      .then(({ data }) => {
        profilesStore.setMyDevice(data)

        return data
      })
  }


  updateMyProfile(data: PatchProfileRequest) {
    return api.profiles.updateMyProfile(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  deleteOwnProfile(data: deleteOwnProfileRequest) {
    return api.profiles.deleteOwnProfile(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  cancelOwnProfileDeletion() {
    return api.profiles.deleteOwnProfileDeletion()
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }
  //
  // setOwnPassword(data: changePasswordRequest) {
  //   return api.profiles.changePassword(data)
  //     .then(({ data }) => {
  //       profilesStore.setMyProfile(data)
  //
  //       return data
  //     })
  // }

  changeOwnPassword(data: changePasswordRequest) {
    return api.profiles.changePassword(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  deleteOwnEmail(data: deleteOwnEmailRequest) {
    return api.profiles.deleteOwnEmail(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  deleteOwnPassword(data: deleteOwnPasswordRequest) {
    return api.profiles.deleteOwnPassword(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  updateOwnEmail(data: updateOwnEmailRequest) {
    return api.profiles.updateOwnEmail(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  sendSmsToChangePhone({ phone }: { phone: string }) {
    phone = '+' + phone.replaceAll(/\D/g, '')
    const hash = sha256(`${phone}_${process.env.REACT_APP_SECRET_SMS_KEY}`)

    return api.system.sendSmsToChangePhone({
      new_phone: phone,
      hash
    }).then(({ data }) => data)
  }

  updateOwnPhone(data: updateOwnPhoneRequest) {
    return api.profiles.updateOwnPhone(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])
        return data
      })
  }

  deleteOwnPhone(data: DeleteAuthPhoneRequest) {
    return api.profiles.deleteOwnPhone(data)
      .then(({ data }) => {
        addOrUpdateProfiles([data])


        return data
      })
  }

  async topUpProfileLastAction(profile_id: Profile['id'], date?: string): Promise<void> {
    // const profile = await getProfileById(profile_id)
    const newDate = date || (new Date()).toString()

    // if(profile &&
    //   (!profile.last_action_time || dayjs(profile.last_action_time).isBefore(newDate))
    // ) {
      db[DEXIE_STORES.CLIENT_PROFILE_INFOS].put({
        id: profile_id,
        last_action_time: newDate
      })

      profilesStore.addOrUpdateClientProfileInfo({
        id: profile_id,
        last_action_time: newDate
      })

      // patchProfile(profile.id, {
      //   last_action_time: newDate
      // })
    // }
  }

  // updateProfile(data: updateProfileRequest) {
  //   return profiles.updateProfile(data)
  //     .then(({ data }) => {
  //       console.log(data)
  //     })
  // }
}

export const profilesService = new ProfilesService
