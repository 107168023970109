import { systemService } from '@/store/system/system.service'
import { systemStore } from '@/store/system/system.store'
import { useState } from 'react'

let processing = false

export function useCountries() {
  const [loading, setLoading] = useState<boolean>(processing)

  if(!processing) {
    processing = true
    setLoading(true)

    systemService.loadCountries()
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    loading,
    countries: systemStore.countries
  }
}
