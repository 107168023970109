import { HTMLAttributes, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

type Props = {
  to: string
  children: ReactNode
} & HTMLAttributes<HTMLElement>

export const AppLink = observer(({
  to,
  children,
  ...rest
}: Props) => {
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
})
