import { Forward } from '@roolz/icons/chats/Forward'
import { SelfChatPlaceholder as Icon } from '@roolz/icons/chats/SelfChatPlaceholder'
import { Clarify } from '@roolz/icons/Clarify'
import { Profile } from '@roolz/types/api/profiles'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SelfChatPlaceholder.module.scss'

interface Props {
  profile: Profile
}

export const SelfChatPlaceholder = ({
  profile
}: Props) => {
  const { t } = useTranslation('chat/message')

  const items: Array<{
    icon: ReactNode
    text: string
  }> = [
    {
      icon: <Clarify size={22}/>,
      text: t('self_chat_placeholder.save_ideas')
    },
    {
      icon: <Forward/>,
      text: t('self_chat_placeholder.forward')
    }
    // {
    //   icon: <Attachment/>,
    //   text: t('self_chat_placeholder.save_files')
    // },
  ]

  const { first_name, last_name } = profile.profile_view_info || {}

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Icon/>

        <h2 className={styles.title}>
          {t('self_chat_placeholder.title', {
            name: first_name + ' ' + last_name
          })}
        </h2>

        <div className={styles.list}>
          {items.map(({ icon, text }) => (
            <p
              key={text}
              className={styles.list__item}
            >
            <span className={styles.list__item__icon}>
              {icon}
            </span>
              <span className={styles.list__item__text}>
              {text}
            </span>
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}
