import { ApiClients } from '@roolz/api/clients'
import { GlobalSearchResponse, SearchRequest } from '@roolz/types/api/search'
import { AxiosInstance } from 'axios'

export class SearchApi {
  search: AxiosInstance

  constructor(clients: ApiClients) {
    this.search = clients.search
  }

  global(params: SearchRequest) {
    return this.search.get<GlobalSearchResponse>('/global', { params })
  }

  profiles(params: SearchRequest) {
    return this.search.get<GlobalSearchResponse>('/profiles', { params })
  }

  chats(params: SearchRequest) {
    return this.search.get<GlobalSearchResponse>('/chats', { params })
  }

  companies(params: SearchRequest) {
    return this.search.get<GlobalSearchResponse>('/companies', { params })
  }
}
