import { Fade } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import data from './data/matches_loader.json'
import Lottie, { LottieComponentProps, LottieRef, LottieRefCurrentProps } from 'lottie-react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const MatchesLoader = (props: Props) => {
  const size = 24

  const [isShow, setIsShow] = useState(true)

  const lottieRef = useRef<LottieRefCurrentProps>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsShow(true)

      lottieRef.current?.goToAndPlay(0)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Fade in={isShow}>
      <div>
        <Lottie
          lottieRef={lottieRef}
          width={size}
          height={size}
          style={{ width: size, height: size }}
          animationData={data}
          loop={false}
          onComplete={() => setIsShow(false)}
          {...props}
        />
      </div>
    </Fade>
  )
}
