import { staticClient } from '@/api/static/staticClient'
import { Axios } from 'axios'

class StaticApi {
  constructor(private client: Axios) {}

  getCompanyCategories() {
    return this.client.get('/api/company/categories')
  }
  getCompanyCategoryFilters() {
    return this.client.get('/api/company/filters')
  }
}

export const staticApi = new StaticApi(staticClient)
