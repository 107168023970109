import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { ChatModel } from '@/types/models/chat'
import { changePageFavicon, FaviconType } from '@/utils/favicon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const BLINKING_INTERVAL = 1000

export const ChatsPageTitleController = observer(() => {
  const { t, i18n: { language } } = useTranslation('business-meta')

  const originalPageTitle = useRef<string>(document.title)
  const isCurrentTitleOriginal = useRef<boolean>(true)

  const interval = useRef<any>(null)

  const updatesCount = chatsStore.updatesCount

  const toggleTitle = useCallback(() => {
    isCurrentTitleOriginal.current
      ? document.title = t('titles.have_new_messages_title', { count: updatesCount })
      : document.title = originalPageTitle.current ?? ''

    isCurrentTitleOriginal.current = !isCurrentTitleOriginal.current
  }, [language, updatesCount])

  useEffect(() => {
    if(chatSessionsStore.highlightTitle) {
     if(updatesCount > 0) {
       changePageFavicon(FaviconType.withNews)

       if(interval.current === null) {
         originalPageTitle.current = document.title
         toggleTitle()

         interval.current = setInterval(toggleTitle, BLINKING_INTERVAL)
       }
     }
    } else {
      if(originalPageTitle.current) {
        isCurrentTitleOriginal.current = true
        document.title = originalPageTitle.current ?? ''

        clearInterval(interval.current)
        interval.current = null

        changePageFavicon(FaviconType.default)
      }
    }
  }, [chatSessionsStore.highlightTitle])

  return null
})
