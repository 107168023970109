import { useLockScrolling } from '../../../hooks/useLockScrolling'
import styles from './Aside.module.scss'
import { ReactNode, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { Fade, FadeProps, Slide } from '@mui/material'

const TRANSITION_DURATION_IN = 350
const TRANSITION_DURATION_OUT = 250

interface Props {
  isShown: boolean

  fullWidth?: boolean
  scrollVisible?: boolean
  // setIsShown: (isVisible: boolean) => void
  onClose?: (reason: 'escapeKeyDown') => void
  showBackdrop?: boolean
  transparentBackdrop?: boolean
  noShadow?: boolean
  onBackdropClick?: () => void

  children?: ReactNode

  rootTransitionProps?: Partial<FadeProps>

  ContentTransitionComponent?: any
  contentTransitionProps?: any
}

export const Aside = (props: Props) => {
  const {
    isShown,
    fullWidth= false ,
    onClose,
    children,
    showBackdrop = false,
    transparentBackdrop = false,
    noShadow = false,
    onBackdropClick,
    rootTransitionProps,
    ContentTransitionComponent = Slide,
    contentTransitionProps,
    scrollVisible
  } = props

  const { lock, unlock } = useLockScrolling()

  useEffect(() => {
    isShown ? lock() : unlock()
  }, [isShown])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const keyCode = event.keyCode || event.which

    if(keyCode === 27) {
      if(onClose) {
        onClose('escapeKeyDown')
      }
      event.stopPropagation()
      event.preventDefault()
    }
  }, [onClose])

  const cleanUp = useCallback(() => {
    document.removeEventListener('keydown', handleKeyDown, { capture: true })
  }, [handleKeyDown])

  useEffect(() => {
    if(isShown) {
      document.addEventListener('keydown', handleKeyDown, { capture: true })
    } else {
      cleanUp()
    }

    return cleanUp
  }, [isShown])

  return (
    <Fade
      in={isShown}
      timeout={isShown ? TRANSITION_DURATION_IN : TRANSITION_DURATION_OUT}
      {...rootTransitionProps}
    >
      <div
        className={cn(styles.aside, {
          [styles.asideNoBackdrop]: !showBackdrop,
          [styles.asideWithShadow]: !noShadow
        })}
      >
        {showBackdrop && (
          <div
            className={cn(styles.backdrop, {
              [styles.backdropNonInteractive]: !isShown,
              [styles.backdropTransparent]: transparentBackdrop,
            })}
            onClick={onBackdropClick}
          ></div>
        )}

        <ContentTransitionComponent
          direction='left'
          timeout={isShown ? TRANSITION_DURATION_IN : TRANSITION_DURATION_OUT}

          {...contentTransitionProps}
          in={isShown}
        >
          <div className={cn(styles.contentContainer, {
            [styles.contentContainerFullWidth]: fullWidth,
            [styles.contentContainerVisibleScroll]: scrollVisible
          })}>
            {children}
          </div>
        </ContentTransitionComponent>
      </div>
    </Fade>
  )
}
