import { useGsap } from '@roolz/sdk/hooks/useGsap'
import gsap from '@roolz/sdk/plugins/gsap'
import { isClient } from '@roolz/sdk/utils/ssr'
import { useHover, useToggle } from 'ahooks'
import cn from 'classnames'
import { memo, useEffect, useRef } from 'react'
import styles from './Question.module.scss'

interface Props {
  title: string
  description: string

  theme?: 'light' | 'dark' | 'dark-outline' | 'light-outline'

  mode?: 'normal' | 'compact'

  className?: string
}

export const Question = memo(({
  title,
  description,

  theme = 'light',
  mode = 'normal',
  className
}: Props) => {
  const [open, { toggle }] = useToggle(false)

  useEffect(() => {
    if(!answerRef.current) {
      return
    }

    if(open) {
      gsap.fromTo(answerWrapperRef.current, {
        opacity: 0
      }, {
        height: answerRef.current.clientHeight,
        opacity: 1,
        duration: 0.3,
        ease: 'in-out'
      })
    } else {
      gsap.fromTo(answerWrapperRef.current, {
        opacity: 1
      }, {
        height: '0px',
        opacity: 0,
        duration: 0.3,
        ease: 'in-out'
      })
    }
  }, [open])

  const answerWrapperRef = useRef<HTMLDivElement>(null)
  const answerRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const hover = useHover(ref)

  const lettersRef = useRef([])

  if(isClient()) {
    useGsap(gsap => {
      gsap.fromTo(ref.current, {
        duration: 0.2,
        '--mask-to-color': 'rgba(1,1,1,0)'
      }, {
        '--mask-to-color': 'rgba(1,1,1,1)'
      })
    }, {
      enable: hover || open
    })

    useGsap(gsap => {
      gsap.fromTo(ref.current, {
        duration: 0.2,
        '--mask-to-color': 'rgba(1,1,1,1)'
      }, {
        '--mask-to-color': 'rgba(1,1,1,0)'
      })
    }, {
      enable: !open && !hover
    })
  }


  return (
    <div
      ref={ref}
      className={cn(styles.root, {
        [styles.rootOutline]: theme === 'light-outline',
        [styles.rootDark]: theme === 'dark',
        [styles.rootDarkOutline]: theme === 'dark-outline',

        [styles.rootCompact]: mode === 'compact',

        [styles.rootOpen]: open
      }, className)}
      itemScope
      itemProp='mainEntity'
      itemType='https://schema.org/Question'
    >
      <div
        className={styles.title}
        onClick={toggle}
        title={title}
      >
        <span
          className={styles.title__text}
          itemProp='name'
        >
          {title}
          {/*{title.split('').map((letter, i) => (*/}
          {/*  <pre style={{ display: 'inline-block' }} ref={el => lettersRef.current[i] = el}>{letter}</pre>*/}
          {/*))}*/}
        </span>
        <Icon open={open}/>
      </div>

      <div
        className={styles.descriptionWrapper}
        ref={answerWrapperRef}
      >
        <div
          itemScope
          itemProp='acceptedAnswer'
          itemType='https://schema.org/Answer'
          className={cn(styles.description, {
            [styles.descriptionVisible]: open
          })}
          ref={answerRef}
        >
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            itemProp='text'
          />
        </div>
      </div>
    </div>
  )
})

const Icon = ({ open }: {
  open: boolean
}) => {
  return (
    <div
      className={cn(styles.icon, {
        [styles.iconOpen]: open
      })}
    />
  )
}
