import { padNumber } from '@roolz/sdk/utils/helpers'
import { memoize } from 'lodash-es'
import dayjs from '@roolz/sdk/plugins/dayjs'
export const SECOND_MS = 1000
export const MINUTE_MS = 60 * SECOND_MS
export const HOUR_MS = 60 * MINUTE_MS
export const DAY_MS = 24 * HOUR_MS

export const HOUR_SECS = 60 * 60

export function getUTCDayStart(date: Date): Date {
  return dayjs(date).utc().startOf('day').toDate()
}

export const getTodayUTC = (() => {
  return dayjs.utc().startOf('day').toDate()
})

export function getDayAndMonth(date: Date, divider = '.') {
  return padNumber(date.getDate(), 2) + divider + padNumber(date.getMonth() + 1, 2)
}

export const getDayStart = (date: Date): Date => {
  const newDate = new Date(date)

  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)

  return newDate
}

export function getTime(date: Date, divider = ':'): string {
  return padNumber(date.getHours(), 2) + divider + padNumber(date.getMinutes(), 2)
}

// @ts-ignore
// @ts-ignore
export const getMaximalDate = memoize((): Date => {
  return new Date(8640000000000000)
})

export function getYearMonthDay(date: Date, divider = '.'): string {
  return [
    padNumber(date.getUTCFullYear(), 2),
    padNumber(date.getUTCMonth() + 1, 2),
    padNumber(date.getUTCDate(), 2)
  ].join(divider)
}
