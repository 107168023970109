import { commaSeparatedNumber, commaSeparatedNumberInString } from '@roolz/sdk/utils/formatting'

export function getReadableFileSize(bytes: number, t: any) {
  if(bytes < 1000) {
    return bytes + ' ' + t('shared:file_size.bytes', { count: bytes })
  }

  const kb = (bytes / 1024)
  if(kb < 1000) {
    return commaSeparatedNumberInString(kb.toFixed(2)) + ' ' + t('shared:file_size.kb')
  }

  const mb = (kb / 1000)
  if(mb < 1000) {
    return commaSeparatedNumberInString(mb.toFixed(2)) + ' ' + t('shared:file_size.mb')
  }

  const gb = (mb / 1000)
  return commaSeparatedNumberInString(gb.toFixed(2)) + ' ' + t('shared:file_size.gb')
}
