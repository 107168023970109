import { ApiClients } from '@roolz/api/clients'
import { BindToPresenceRequest, TypingRequest } from '@roolz/types/api/ws-proxy'
import { AxiosInstance } from 'axios'

export class WsProxyApi {
  wsProxy: AxiosInstance

  constructor(clients: ApiClients) {
    this.wsProxy = clients.wsProxy
  }

  bindToTyping(data: TypingRequest) {
    return this.wsProxy.post('/v1/bind/typing', data)
  }

  bindToPresence(data: BindToPresenceRequest) {
    return this.wsProxy.post('/v1/bind/presence', data)
  }

}
