import { Checkbox } from '@roolz/sdk/components/ui/fields/Checkbox/Checkbox'
import { Add } from '@roolz/icons/Add'
import { Backspace } from '@roolz/icons/Backspace'
import { Delete } from '@roolz/icons/Delete'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { getFlagUrl } from '@/utils/cdns'
import { InputAdornment, Popper, TextField } from '@mui/material'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AssociationsMultiSelect.module.scss'

interface Association {
  countries: string
  slug: string
  index: number
  name: string
}

interface Props {
  alpha2?: string
  value: string[]
  onChange?: (association: string[]) => void
}

function AssociationItem({
  association,
  remove
}: {
  association: Association,
  remove: (association: Association) => void
}) {
  const { t } = useTranslation('company/admin')
  return (
    <div className={styles.association}>
      <span className={styles.association__title}>
        { association.name }
      </span>
      <div className={styles.association__delete} onClick={() => remove(association)}>
        <Delete size={12} color='#8E8E93'/>
      </div>
    </div>
  )
}

export const AssociationsMultiSelect = ({
  alpha2 = 'BY',
  value,
  onChange
}: Props) => {
  const { t } = useTranslation('company/admin')
  const popperRef = useRef<any>(null)
  const refParent = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [search, setSearch] = useState('')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : refParent?.current)
  }
  const handleClickOutside = (event: any) => {
    if(!popperRef.current?.contains(event.target)) {
      setSearch('')
      setAnchorEl(null)
    }
  }
  const open = Boolean(anchorEl)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const [selectedAssociations, setSelectedAssociations] = useState<Association[]>([])

  const select = (association: Association) => {
    const newSelectedAssociations: Association[] = selectedAssociations.includes(association)
      ? selectedAssociations.filter(item => association !== item)
      : [...selectedAssociations, association]
    setSelectedAssociations(newSelectedAssociations)

    if(typeof onChange === 'function') {
      onChange(newSelectedAssociations.map(association => association.slug))
    }
  }

  useEffect(() => {
    const newSelected = value.map(item =>
      companyKnowledgeStore.supportedCompanyAssociations
        .find(association => item === association.slug)
    ).filter(item => item)
    if (newSelected) {
      // @ts-ignore
      setSelectedAssociations(newSelected)
    }
  }, [])

  const companyAssociations = useMemo(() =>
    companyKnowledgeStore.supportedCompanyAssociations
      .filter(association => !search || association.name
        .toLowerCase().includes(search.toLowerCase())
      ), [companyKnowledgeStore.supportedCompanyAssociations, search])

  const companyAssociationsMy = useMemo(() =>
      companyAssociations.filter(association => association.countries === alpha2)
    , [companyAssociations])
  const companyAssociationsOther = useMemo(() =>
      companyAssociations.filter(association => association.countries !== alpha2)
    , [companyAssociations])

  return (
    <div className={styles.associations} ref={refParent}>
      <div className={styles.association__items}>
        {selectedAssociations.map(association =>
          <AssociationItem
            remove={select}
            key={association.slug}
            association={association}
          />
        )}
        <div className={styles.association__add} onClick={handleClick}>
          <Add/>
        </div>
      </div>
      <Popper
        open={open}
        className={styles.associations__menu}
        anchorEl={anchorEl}
        ref={popperRef}
      >
        <div className={styles.menu__search}>
          <TextField
            value={search}
            onChange={e => setSearch(e.target.value)}
            fullWidth
            className={styles.menu__searchInput}
            placeholder={ t('associations_placeholder') }
            variant='outlined'
            InputProps={{
              endAdornment: search && (
                <InputAdornment
                  position='end'
                  onClick={() => setSearch('')}
                  className={styles.menu__searchClear}
                >
                  <Backspace/>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.menu__items}>
          {!companyAssociationsMy?.length && !companyAssociationsOther.length &&
            <span className={styles.menu__title}>
              {t('associations.country_is_not_finded')}
            </span>
          }
          {!!companyAssociationsMy?.length &&
            <span className={styles.menu__title}>
              {t('associations.country')}
            </span>
          }
          {companyAssociationsMy.map(association =>
            <MenuItem
              isChecked={selectedAssociations.includes(association)}
              association={association}
              select={select}
              key={association.slug}
              hideFlag
            />
          )}
          {!!companyAssociationsOther.length &&
            <span className={styles.menu__title}>
              {t('associations.other_country')}
            </span>
          }
          {companyAssociationsOther.map(association =>
            <MenuItem
              isChecked={selectedAssociations.includes(association)}
              association={association}
              select={select}
              key={association.slug}
            />
          )}
        </div>
        {/*</div>*/}
      </Popper>
    </div>
  )
}

function CountryTag({ alpha2, slug, className, ...rest }: { alpha2: string, [key: string]: any }) {

  const flagUrl = useMemo(() => {
    return getFlagUrl(alpha2)
  }, [alpha2])

  return (
    <img src={flagUrl} alt='' className={styles.countryTag__flag}/>
  )
}

function MenuItem({
  association,
  select,
  isChecked,
  hideFlag = false
}: {
  association: Association,
  select: (association: Association) => void,
  isChecked: boolean
  hideFlag?: boolean
}) {
  const [checked, setChecked] = useState(isChecked)
  const onChange = () => {
    select(association)
    setChecked(!checked)
  }
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      className={styles.menu__item}
    >
      {!hideFlag &&
        <CountryTag
          alpha2={association.countries}
          slug={association.slug}
          hideFlag
        />
      }
      <span className={styles.countryTag__label}>
        { association.name }
      </span>
    </Checkbox>
  )
}
