import { ApiClients } from '@roolz/api/clients'
import { AxiosInstance } from 'axios'
import {
  PatchOwnPcpRequest,
  GetChatsDataRequest,
  SendMessageRequest,
  SendMessageResponse,
  GetChatsDataResponse,
  DeleteMessagesByNumbersRequest,
  DeleteMessagesByNumbersResponse,
  PublicChat,
  Chat,
  OwnPcp,
  GetChatResponse,
  PinChatRequest,
  UnpinChatRequest,
  CleanChatResponse,
  GetOrCreateDialogResponse,
  GetPcpOrCreateObserverResponse,
  EditMessageRequest,
  Message,
  GetChatMessagesRequest,
  GetChatMessagesResponse,
  GetChatByNicknameRequest
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'

export class MessagingApi {
  messaging: AxiosInstance

  constructor(clients: ApiClients) {
    this.messaging = clients.messaging
  }

  async loadChatMessages(id: Chat['id'], params: GetChatMessagesRequest) {
    return this.messaging.get<GetChatMessagesResponse>(`/v1/message/list/${id}`, { params })
  }

  // TODO RENAME
  async getChatByNickname({ nickname }: GetChatByNicknameRequest) {
    return this.messaging.get<PublicChat>('/v1/chat/nickname/' + nickname)
  }

  async loadChats(params: GetChatsDataRequest) {
    return this.messaging.get<GetChatsDataResponse>('/v1/chats_data', { params })
  }

  async getChat(chatId: Chat['id']) {
    return this.messaging.get<GetChatResponse>('/v1/chat/id/' + chatId)
  }

  async sendMessage(body: SendMessageRequest) {
    return this.messaging.post<SendMessageResponse>('/v1/message/send', body)
  }

  async patchOwnPcp(chatId: Chat["id"], body: PatchOwnPcpRequest) {
    return this.messaging.patch<OwnPcp>('/v1/participant/' + chatId + '/own', body)
  }

  async deleteChat(chatId: Chat["id"]) {
    return this.messaging.delete('/v1/chat/id/' + chatId)
  }
  async deleteMessagesByNumbers(chatId: Chat["id"], body: DeleteMessagesByNumbersRequest) {
    return this.messaging.post<DeleteMessagesByNumbersResponse>(`/v1/message/delete/${chatId}`, body)
  }

  async cleanSelfChat() {
    return this.messaging.post<CleanChatResponse>('/v1/chat/self_chat/clean')
  }

  async leaveChat(chatId: Chat["id"]) {
    return this.messaging.post(`/v1/chat/id/${chatId}/leave`)
  }

  async joinChat(chatId: Chat["id"]) {
    return this.messaging.post(`/v1/chat/id/${chatId}/join`)
  }

  async pinChat(chatId: Chat["id"], body: PinChatRequest) {
    return this.messaging.post<OwnPcp>(`/v1/chat/id/${chatId}/pin`, body)
  }

  async unpinChat(chatId: Chat["id"], body: UnpinChatRequest) {
    return this.messaging.post<OwnPcp>(`/v1/chat/id/${chatId}/unpin`, body)
  }

  async getOrCreateDialog(profile_id: Profile["id"]) {
    return this.messaging.post<GetOrCreateDialogResponse>('/v1/chat/dialog/get_or_create', { profile_id })
  }

  async getPcpOrCreateObserver(chat_id: Chat["id"]) {
    return this.messaging.post<GetPcpOrCreateObserverResponse>(`/v1/chat/id/${chat_id}/get_participant_or_create_observer`)
  }

  async bindToChat(chat_id: Chat["id"]) {
    return this.messaging.post(`/v1/chat/id/${chat_id}/bind`)
  }


  async unbindFromChat(chat_id: Chat["id"]) {
    return this.messaging.post(`/v1/chat/id/${chat_id}/unbind`)
  }


  async editMessage(chatId: Chat["id"], number: Message["number"], body: EditMessageRequest) {
    return this.messaging.patch<Message>(`/v1/message/update/${chatId}/${number}`, body)
  }
}
