import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { getNow } from '@/utils/date'
import { toast, toastSuccess, toastWarning } from '@roolz/sdk/components/snackbars'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast as toastify } from 'react-toastify'

export const StaticToastsController = observer(() => {
  const { t } = useTranslation('floating-snackbars')

  const profileDeleteToastId = useRef<any>()

  const myProfile = profilesStore.my_profile

  const cancelAction = (name: string, callback: () => Promise<any>) => ({
    title: name,
    onClick: ({ closeToast }: any) => {
      callback().then(() => closeToast())
      //
    }
  })

  function showAccountIsOnDeletion() {
    if(IS_MOBILE) {
      return
    }

    const handleAbort = () => {
      return profilesService.cancelOwnProfileDeletion()
        .then(() => {
          toastSuccess(t('profile_will_be_deleted.aborted'))
        })
        .catch(() => {
          toastWarning(t('errors:insufficient_request'))
        })
    }

    const days = dayjs(myProfile?.must_delete_at).diff(getNow(), 'days')

    profileDeleteToastId.current = toast({
      message: t('profile_will_be_deleted.content_days', { count: days }),
      icon: 'deleteTimer',
      autoClose: false,
      closeOnClick: false,
      onClick: () => {
        // navigate(resolvePathByName(ROUTE_NAMES.MY_PROFILE))
        // toastify.dismiss(id)
      },
      actions: [cancelAction(t('profile_will_be_deleted.abort'), handleAbort)]
    })
  }

  useEffect(() => {
    if(profilesStore.my_profile?.must_delete_at) {
      showAccountIsOnDeletion()
    } else {
      toastify.dismiss(profileDeleteToastId.current)
    }
  }, [profilesStore.my_profile?.must_delete_at])

  return null
})
