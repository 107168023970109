import { checkIfProfileCanWriteToChat } from '@/components/chats/utils'
import { ChatItem, ChatList, Placeholder } from '@/components/modals/SearchChats/SearchChats'
import { ChatModelFactory } from '@/models/Chat.model'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isProfileMatches } from '@/utils/search'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { Chat, ChatType } from '@roolz/types/api/chats'
import { SearchChatInfo, SearchProfileInfo } from '@roolz/types/api/search'
import { debounce, pick } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SearchChatsToForward.module.scss'

interface Props {
  search: string
  onChatSelect: (item: ChatItem) => void
}

type Results = {
  chat_list: SearchChatInfo[]
  contact_list: SearchProfileInfo[]
}

const DEFAULT_RESULTS = (): Results => ({
  chat_list: [],
  contact_list: []
})

export const SearchChatsToForward = observer(({
  search,
  onChatSelect
}: Props) => {
  const searchRef = useStateRef(search)

  const { t } = useTranslation('chat/common')

  const [result, setResult] = useState<Chat[]>(chatsStore.sortedVisibleChats)

  const cleanQuery = (q: string) => q
    .replace(/^[@ ]+/, '')
    .replace(/[@ ]+$/, '')

  useLayoutEffect(() => {
    if(!open) {
      return
    }

    if(search.length < 1) {
      return setResult(chatsStore.sortedVisibleChats)
    } else {
      runSearch(search)
    }
  }, [search, open, chatsStore.sortedVisibleChats])


  const runSearch = useCallback(debounce(async (query: string) => {
    runLocalSearch(query)
  }, 0), [])

  const runLocalSearch = useCallback(async (query: string) => {
    const chats = chatsStore.sortedVisibleChats

    if(!query.length) {
      return setResult(chats)
    }

    setResult(
      chats.filter(chat => {
        if(!profilesStore.my_profile) return false
        if(!checkIfProfileCanWriteToChat(profilesStore.my_profile, chat)) {
          return false
        }

        const nickname = chat.nickname || chat.companion?.nickname || ''

        if(/^@.*/.test(query)) {
          const pureQuery = query.replaceAll('@', '')

          return nickname.includes(pureQuery)
        }

        if(nickname.includes(query)) return true
        if(chat.name && chat.name.toLowerCase().includes(query.toLowerCase())) return true

        switch(chat.type) {
          case ChatType.SELF_CHAT:
            return query.length && t('name.bookmarks').toLowerCase().includes(query.toLowerCase())
          case ChatType.DIALOG: {
            const companion = chat.companion

            if(!companion || companion.deleted_at || !companion.is_filled || !companion.relationships) {
              return false
            }

            return isProfileMatches(query, companion)
          }
        }

        return false
      })
    )
  }, [chatsStore.sortedVisibleChats, profilesStore.my_profile])

  // TODO remove "as" later
  const flattenedItems: ChatItem[] = useMemo(() => {
    return result.filter(chat => {
      if(chat.type !== ChatType.DIALOG) {
        return true
      }

      const profile = ChatModelFactory(chat)?.companion
      return !profile?.deleted_at && profile?.is_filled
    })
      .map(chat => {
        if(chat.type === ChatType.DIALOG) {
          return {
            type: 'profile',
            profile: ChatModelFactory(chat).companion
          } as ChatItem
        }

        return {
          type: 'chat',
          chat
        } as ChatItem
      })
  }, [result])

  const isResultEmpty = !result.length

  const showNothingFoundPlaceholder = search.length && isResultEmpty
  const showAppealPlaceholder = !search.length && isResultEmpty

  if(showAppealPlaceholder) {
    return (
      <Placeholder
        title={t('search.appeal.title')}
        description={t('search.appeal.description')}
      />
    )
  }

  if(showNothingFoundPlaceholder) {
    return (
      <Placeholder
        title={t('search.no_results.title', { text: search })}
        description={t('search.no_results.description')}
      />
    )
  }

  return (
    <div className={styles.content}>
      <ChatList
        chats={flattenedItems}
        onChatSelect={onChatSelect}
      />
    </div>
  )
})

