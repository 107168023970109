import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { exchangeService } from '@/store/exchange/exchange.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { toastError } from '@roolz/sdk/components/snackbars'
import { isCargoOffer, Offer } from '@roolz/types/api/exchange'
import { useLayoutEffect, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

const OfferEdit = OverlayModalPage(({ onClose }) => {
  const { offer_id } = useParams()

  const globalModals = useGlobalModals()
  const navigate = useNavigate()
  const { t } = useTranslation('offer/shared')

  async function loadIfNeeded(offer_id: string) {
    let offer

    try {
      offer = await exchangeService.loadOffer({ id: offer_id })
    } catch(e) {
      console.error(e)
      return onClose()
    }
    if(!profilesStore.hasAccessToOffer(offer)) {
      toastError(t('edit_errors.has_no_permission_to_edit'))
      return navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
    }

    const editModalType = isCargoOffer(offer)
      ? GLOBAL_MODALS_NAMES.CREATE_CARGO
      : GLOBAL_MODALS_NAMES.CREATE_TRANSPORT

    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === editModalType
      && highestVisibleOverlay?.key === offer_id
    ) {
      return
    }

    globalModals.open(editModalType, {
      key: offer_id,
      props: { offer, onClose },
      config: { onClose }
    })
  }

  useLayoutEffect(() => {
    if(offer_id) {
      loadIfNeeded(offer_id)
    }
  }, [offer_id])

  return null
}, {
  // saveToModalsHistory: false
})

export default OfferEdit
