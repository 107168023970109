import { Company } from '@roolz/types/api/companies'
import { Profile } from '@roolz/types/api/profiles'
import { ItemsResponse, Pagination } from '@roolz/types/api/utils'

export enum PartnershipType {
  TWO_SIDED = 'two-sided',
  OUTGOING = 'outgoing',
  INCOMING = 'incoming'
}

export enum PartnershipInvitationStatus {
  PENDING = 'pending',
  CANCELED = 'cancelled',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export type PartnershipInvitation = {
  id: string
  company_id: Company['id']
  creator_profile_id: Profile['id']
  company: Company | null // not null in invitation list
  is_sender: boolean
  state: PartnershipInvitationStatus
  partnership_type: PartnershipType
  type: InvitationType
  created_at: string
  updated_at: string
}

export type Partnership = {
  company: Company | null
  company_id: Company['id']
  id: string
  is_inviter: boolean
  partnership_type: PartnershipType
  updated_at: string
  created_at: string
  offers_counters: {
    total_incoming_offers: number
    total_outgoing_offers: number
  }
}

export type PartnershipInvitationsCounters = {
  total_sent_pending: number
  total_received_pending: number
}

export enum InvitationType {
  create = 'creation',
  update = 'change_request'
}

export type SendPartnershipInviteRequest = {
  invited_company_id: Company['id'],
  partnership_relations_type: PartnershipType
  invitation_type: InvitationType
}

export type GetPartnershipInvitations = ItemsResponse<PartnershipInvitation>

export type GetPartnershipInvitationsRequest = {
  companyId: Company['id'],
  params: Pagination,
  body?: {
    states?: PartnershipInvitationStatus[],
    invitation_side?: 'sender' | 'receiver'
  }
}
