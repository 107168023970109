import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel, isSystemMessage, MessageModel } from '@/types/models/chat'
import { isNumber } from '@roolz/sdk/utils/types'
import { Chat, ChatType, MessageStatus, PcpStatus, SystemMessageEvent } from '@roolz/types/api/chats'
import { clone } from 'lodash-es'

export function ChatModelFactory(chat: Chat): ChatModel {
  return ({
    ...clone(chat),

    get messages() {
      return chatsStore.messages?.[this.id] ?? []
    },

    get nonSentMessages() {
      return this.messages.filter((item: { status: MessageStatus }) => item.status < MessageStatus.SENT)
    },

    get sentMessages() {
      return this.messages.filter((item: { status: MessageStatus }) => item.status >= MessageStatus.SENT)
    },

    get visibleMessages() {
      const list: MessageModel[] = [...this.messages]

      const firstMessage = list?.[0]

      // TODO filter deleted messages
      if(this.type === ChatType.SELF_CHAT
        && firstMessage
        && isSystemMessage(firstMessage)
        && firstMessage?.decodedContent?.event === SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE
      ) {
        return list.splice(0)
      }

      return list
    },

    get last_message() {
      return chatsStore.getLastVisibleChatMessage(this.id)
    },

    get pcps() {
      const pcps = chatsStore.pcps[this.id]

      return Object.values(pcps ?? {})
    },

    get own_pcp() {
      return chatsStore.ownPcps[this.id]
    },

    get companion() {
      if(this.type !== ChatType.DIALOG || !this.companionId) {
        return
      }

      return profilesStore.findProfile(this.companionId)
    },

    get onlineUsersCount() {
      return this.online_members_count ?? 0
      // console.log('PCPS', this.pcps)
      // return this.pcps.filter(pcp => pcp.profile_id !== profilesStore.my_profile?.id)
      //   .reduce((count, pcp) => {
      //     console.log('PCP', pcp)
      //     return count + Number(pcp?.profile?.onlineStatus === OnlineStatus.Online)
      //   }, 0)
    },

    get companionId() {
      const ids = this.id.split(':')

      return ids.find((id: string) => id !== profilesStore.my_profile?.id) as string
    },

    get totalMessagesCount() {
      return this.count_messages + this.nonSentMessages.length
    },

    get unreadMessagesCount() {
      if(this.own_pcp?.status !== PcpStatus.ACTIVE) {
        return 0
      }
      // TODO think if it should use this.nonSend MessagesCount instead?
      const cnt = this.totalMessagesCount - Math.floor(this.own_pcp.last_read_message_index)

      // console.log(this.count_messages, Math.floor(this.own_pcp.last_read_message_index))

      const deletedNewMsgNumbers = this.deleted_message_numbers.filter((number: number) => number > this.own_pcp.last_read_message_index)

      return cnt - deletedNewMsgNumbers.length - this.nonSentMessages.length
    },

    // get nonSendMessagesCount() {
    //   // TODO Probably implement it other way(handy increment/decrements on send),
    //   // because it may be slow always recalculate it
    //   return this.messages.filter(message => [MessageStatus.SENDING, MessageStatus.ERROR].includes(message.status)
    // }

    get draft() {
      return chatsStore.drafts?.[this.id] ?? ''
    },

    get isPinned() {
      /* TODO think if it should be implemented this way?
        Should such logic really lay here?
      */
      return !!this.own_pcp.pins_data.find((pin: { pin_group: string }) => pin.pin_group === chatsStore.activeGroup)
    },

    get messagesInViewport() {
      const info = chatSessionsStore.chatViewInfos[this.id]
      if(!info || !isNumber(info?.minViewportMessageIndex)) {
        // console.log('FILTER 1', this.messages)
        return [...this.messages]
      }

      // console.log('filter', this.messages, info, this.messages.filter(item => {
      //   // @ts-ignore
      //   return item.number >= info?.minViewportMessageIndex
      //     && (!info?.maxViewportMessageIndex || item.number <= info?.maxViewportMessageIndex)
      // }))
      //

      return this.messages.filter((item: { number: number }) => {
        return item.number >= (info?.minViewportMessageIndex ?? 0) && (!info?.maxViewportMessageIndex || item.number <= info?.maxViewportMessageIndex)
      })
    }
  })
}
