// import locales from '@roolz/i18n'

// export enum LOCALES {
//   ENGLISH = 'en',
//   RUSSIAN = 'ru',
//   CHINESE = 'zh-cn',
//   FRENCH = 'fr-fr',
//   GERMAN = 'de',
//   HINDI = 'hi-in',
//   POLISH = 'pl-pl',
//   SPANISH = 'es-es',
//   TURKISH = 'tr',
//   UKRAINIAN = 'uk'
// }

export const LOCALE_FLAGS: any = {
  de: 'de',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  hi: 'in',
  pl: 'pl',
  ru: 'ru',
  tr: 'tr',
  uk: 'ua',
  zh: 'cn',
}

export const DEFAULT_LANGUAGE = 'en'
