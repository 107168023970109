import { api } from '@/api'
import { cleanDatabase, db, DEXIE_STORES } from '@/database'
import { MY_OFFERS_FILTER } from '@/pages/home/offers/MyOffers/constants'
import i18n from '@/plugins/i18n'
import { authStore } from '@/store/auth/auth.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { getNow, MINUTE_MS, SECOND_MS } from '@/utils/date'
import { appWorker } from '@/workers/app-worker/useAppWorker'
import { clean, persist, retrieveClientside } from '@roolz/sdk/utils/auth'
import { cookiesUtil } from '@roolz/sdk/utils/cookie'

class AuthService {
  sendRestorePasswordEmail({ email }: { email: string }) {
    return api.auth.getResetPasswordLink({
      auth_email: email
    })
  }

  /**
   * Take auth credentials from storage (p.e. cookie) and load it to application
   */
  restoreAuth() {
    return retrieveClientside()
      .then(credentials => {
        if(credentials === null) {
          throw new Error('Auth credentials restoring failed')
        }
        console.log('CRE', credentials)

        authStore.setCredentials({
          token: credentials.token,
          expires_at: (credentials.expires_at ?? new Date(0).toString())
        })

        return true
      })
  }

  private isRefreshing = false
  async refreshTokenIfNecessary() {
    if(this.isRefreshing) return

    if(authStore.expires_at) {
      const expTime = new Date(authStore.expires_at).getTime()
      const now = getNow().getTime()
      const timeRemains = expTime - now

      if(timeRemains < 5 * MINUTE_MS) {
        this.isRefreshing = true
        return this.refreshTokens()
          .catch(async e => {
            await this.clientsideLogout()
            const redirectUrl = process.env.REACT_APP_ROOLZ_STATIC_URL + '/' + i18n.language + window.location.pathname

            window.location.href = redirectUrl
          })
          .finally(() => {
            this.isRefreshing = false
          })
      }
    }
    return false
  }

  async fullLogout() {
    return api.auth.logout()
      .finally(() => this.clientsideLogout())
  }

  async clientsideLogout() {
    systemStore.isLoggingOut = true
    return clean()
      .then(async () => {
        localStorage.clear()
        sessionStorage.clear()
        // in future change cookiesUtil to sessionStorage
        cookiesUtil.remove(MY_OFFERS_FILTER)

        try {
          await cleanDatabase()
        } catch(e) {
          console.log(e)
        }
        appWorker?.close()
        authStore.clean()
      })
  }

  async syncCredentialsWithIdb() {
    try {
      await this.cleanIdbDataFromAnotherUserIfNecessary()

      await db[DEXIE_STORES.AUTH].put({
        id: profilesStore.my_profile?.id ?? 1,
        token: authStore.token ?? '',
        expires_at: authStore.expires_at ?? new Date(0).toString()
      })
    } catch(e) {
      console.error('CANT SAVE CREDS TO IDB', e)
    }
  }

  async cleanIdbDataFromAnotherUserIfNecessary() {
    try {
      const creds = (await db[DEXIE_STORES.AUTH].toArray())?.[0]

      // Logged to another profile, need to clean idb\n'
      if(!creds || creds?.id !== profilesStore.my_profile?.id) {
        console.log('ANOTHER USER: FOUND', creds, profilesStore.my_profile?.id)

        await cleanDatabase()
        await db.open()
        appWorker?.close()
      } else {
        console.log('ANOTHER USER: NOT FOUND')
      }
    } catch(e) {
      console.log('CLEAN OLD IDB: ', e)
    }
  }

  protected async refreshTokens() {
    return api.auth.refreshToken({
      token: authStore.token
    })
      .then(({ data }) => {
        console.log('refresh res', data)
        persist({
          token: data?.token,
          expires_at: data?.expires_at
        })

        authStore.setCredentials({
          token: data?.token,
          expires_at: data?.expires_at
        })

        return this.syncCredentialsWithIdb()
      })
      .catch(e => {
        if(e?.response) {
          this.clientsideLogout()

          const redirectUrl = process.env.REACT_APP_ROOLZ_STATIC_URL + '/' + i18n.language + window.location.pathname
          window.location.href = redirectUrl
        }
      })
  }
}

export const authService = new AuthService
