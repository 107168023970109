import { useNavigateToModal } from '@/config/routes'
import { ReactNode, MouseEvent, forwardRef } from 'react'

interface Props {
  to: string
  onOpen?: () => void
  className?: string
  style?: any
  children?: ReactNode
  replace?: boolean
}
export const GlobalModalLink = forwardRef(({
  to,
  onOpen,
  className,
  style,
  children,
  replace = false
}: Props, ref: any) => {
  const navigate = useNavigateToModal()

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()

    navigate(to, { replace: replace })
    onOpen?.()
  }

  return (
   <a
     ref={ref}
     href={to}
     onClick={handleClick}

     className={className}
     style={style}
   >
     {children}
   </a>
 )
})
