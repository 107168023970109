import { api } from '@/api'
import styles from '@/components/company-admin/forms/CompanyEditForm/CompanyEditForm.module.scss'
import Button from '@/components/ui/buttons/Button/Button'
import { AssociationsMultiSelect } from '@/components/ui/fields/AssociationsMultiSelect/AssociationsMultiSelect'
import { AvatarUpload } from '@/components/ui/fields/AvatarUpload/AvatarUpload'
import { CountrySelect } from '@/components/ui/fields/CountrySelect/CountrySelect'
import { Label } from '@/components/ui/typography/Label/Label'
import { fireClickDeleteCompany } from '@/config/events'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { EditCompanyForm, fullEditCompanySchema } from '@/schemas/companies/update-company.schema'
import { companiesService } from '@/store/companies/companies.service'
import { companyStore } from '@/store/companies/company.store'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Delete } from '@roolz/icons/Delete'
import { Loadable } from '@roolz/sdk/components'
import { toastError, toastSuccess } from '@roolz/sdk/components/snackbars'
import { Checkbox } from '@roolz/sdk/components/ui/fields/Checkbox/Checkbox'
import { Select } from '@roolz/sdk/components/ui/fields/Select/Select'
import { TextMaskCustom } from '@roolz/sdk/components/ui/fields/TextMaskCustom/TextMaskCustom'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { Company, CompanyRoles, UpdateCompanyRequest } from '@roolz/types/api/companies'
import { CompanyEmployeeAmountSlug, CompanyFormSlug } from '@roolz/types/api/knowledge/companies'
import cn from 'classnames'
import { Dayjs } from 'dayjs'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IMask } from 'react-imask'
import { Navigate } from 'react-router'
import * as yup from 'yup'

interface ListItem {
  value: string
  label: string
}

interface ModalDeleteProps {
  onSubmit: any
  setShowModal: (show: boolean) => void
}

type TCheckbox =
  'is_forwarder_license'
  | 'is_transport_license'
  | 'is_transport_license_company'
  | 'is_forwarder_license_company'

export const CompanyEditForm = ({
  company
}: {
  company: Company,
}) => {
  if(!Object.keys(company).length) {
    return null
  }
  return (
    <Form company={company}/>
  )
}

const randomId = () => String(Math.random())

const ids = {
  name: randomId(),
  company_form: randomId(),
  registration_country: randomId(),
  legal_form_short: randomId(),
  legal_name: randomId(),
  registration_number: randomId(),
  tax_number: randomId(),
  registration_date: randomId(),
  legal_address: randomId(),
  mail_address: randomId(),
  associations: randomId()
}

function Form({
  company
}: {
  company: Company,
}) {
  const { t: errorsT } = useTranslation('errors')
  const { t: validationT } = useTranslation('validation')
  const { t: companyT } = useTranslation('company/create')
  const { t } = useTranslation('company/admin')
  const companyId = company?.id

  const [actionsLoading, setActionsLoading] = useState(false)

  const localeDateFormat = dayjs().localeData().longDateFormat('L').replaceAll(/\W/g, '.')

  if(!companyId) {
    return null
  }

  const defaultValues: EditCompanyForm = {
    logo_path: company.logo_path,
    name: company.name,
    company_form: company.company_form,
    registration_country: company.registration_country,
    legal_form_short: company.legal_form_short,
    legal_name: company.legal_name,
    // registration_number: company.registration_number,
    tax_number: company.tax_number,
    registration_date: company.registration_date ? dayjs.utc(company.registration_date).format(localeDateFormat) : null,
    associations: company.associations,
    mail_address: company.mail_address,
    legal_address: company.legal_address,
    is_transport_license: company.is_transport_license,
    transport_license_number: company.transport_license_number,
    is_transport_license_company: company.is_transport_license_company,
    is_forwarder_license: company.is_forwarder_license,
    forwarder_license_number: company.forwarder_license_number,
    is_forwarder_license_company: company.is_forwarder_license_company
  }

  const form = useForm<EditCompanyForm>({
    mode: 'onBlur',
    resolver: yupResolver(yup.object(fullEditCompanySchema)),
    defaultValues
  })

  const submit = (data: EditCompanyForm) => {
    const date = data.registration_date === '__.__.____' || !data.registration_date ? null : dayjs.utc(data.registration_date, localeDateFormat)

    if(date && !date.isValid()) {
      toastError('invalid_date')
      return
    }

    const employee_amount = data.company_form === CompanyFormSlug.self_employed
      ? CompanyEmployeeAmountSlug['1 - 15']
      : (company.employee_amount ?? CompanyEmployeeAmountSlug['1 - 15'])

    const params: UpdateCompanyRequest = {
      logo_path: data.logo_path,
      name: (data.name ?? '').trim(),
      company_form: data.company_form,
      registration_country_id: data.registration_country?.id,
      legal_form_short: data.legal_form_short,
      legal_name: data.legal_name,
      tax_number: data.tax_number ?? undefined,
      employee_amount,
      // registration_number: data.registration_number,
      registration_date: date ? date.utc().toISOString() : null,
      associations: data.associations,
      mail_address: (data.mail_address ?? '').trim(),
      legal_address: (data.legal_address ?? '').trim(),
      is_transport_license: data.is_transport_license ?? false,
      is_transport_license_company: data.is_transport_license ? data.is_transport_license_company : null,
      transport_license_number: data.is_transport_license ? data.transport_license_number : null,
      is_forwarder_license: data.is_forwarder_license ?? false,
      is_forwarder_license_company: data.is_forwarder_license ? data.is_forwarder_license_company : null,
      forwarder_license_number: data.is_forwarder_license ? data.forwarder_license_number : null
    }

    setActionsLoading(true)
    companiesService.updateCompany(companyId, params)
      .then(() => {
        toastSuccess(t('save'))
        companyStore.activeEditForm = null
        profilesService.retrieveMyProfile()
      }).catch(({ response }) => {
      toastError(response?.data?.error_msg ?? errorsT('insufficient_request'))
    }).finally(() => setActionsLoading(false))
  }

  const setCheckbox = (key: TCheckbox, value: boolean) => {
    form.setValue(key, value)
  }

  const {
    register,
    formState: { errors },
    control,
    setValue
  } = form

  const is_transport_license = useWatch({ control, name: 'is_transport_license' })
  const is_forwarder_license = useWatch({ control, name: 'is_forwarder_license' })
  const is_transport_license_company = useWatch({ control, name: 'is_transport_license_company' })
  const is_forwarder_license_company = useWatch({ control, name: 'is_forwarder_license_company' })

  const showForwarderLicenseNumber = useMemo(() => is_forwarder_license, [is_forwarder_license])
  const showTransportLicenseNumber = useMemo(() => is_transport_license, [is_transport_license])
  const logo_path = useWatch({ control, name: 'logo_path' })
  const registrationCountry = useWatch({ control, name: 'registration_country' })

  const initialValues = form.getValues()

  const handleChangeAvatar = useCallback(({ url }: { url: string }) => {
    setValue('logo_path', url, { shouldDirty: true })
  }, [])
  const handleDeleteAvatar = useCallback(() => {
    setValue('logo_path', null, { shouldDirty: true })
  }, [])

  const companyForms = useMemo((): ListItem[] =>
    companyKnowledgeStore.supportedCompanyForms.map(item => ({
        value: item.slug,
        label: companyT(`form.company_forms.${item.slug}`)
      })
    ), [companyT, companyKnowledgeStore.supportedCompanyForms])

  const legal_form_short = useWatch({ control, name: 'legal_form_short' })
  useEffect(() => {
    form.setValue('legal_form_short', legal_form_short?.toUpperCase())
  }, [legal_form_short])

  const tax_number = useWatch({ control, name: 'tax_number' })
  useEffect(() => {
    form.setValue('tax_number', tax_number?.toUpperCase())
  }, [tax_number])

  // const tax_number = useWatch({ control, name: 'tax_number' })
  // useEffect(() => {
  //   form.setValue('tax_number', tax_number?.toUpperCase())
  // }, [tax_number])

  const transport_license_number = useWatch({ control, name: 'transport_license_number' })
  useEffect(() => {
    form.setValue('transport_license_number', transport_license_number?.toUpperCase())
  }, [transport_license_number])

  const forwarder_license_number = useWatch({ control, name: 'forwarder_license_number' })
  useEffect(() => {
    form.setValue('forwarder_license_number', forwarder_license_number?.toUpperCase())
  }, [forwarder_license_number])

  const onSubmit = () => {
    form.trigger()
      .then(data => {
        if(data) {
          submit(form.getValues())
        } else {
          console.log(form.formState.errors)
          toastError(companyT('error_fields'))
        }
      })
  }

  const [showModal, setShowModal] = useState(false)

  return (
    <div className={styles.content}>
      <div className={styles.loading}>
        <AvatarUpload
          className={styles.loading__logo}
          avatarUrl={logo_path ?? null}
          onChange={handleChangeAvatar}
          onDelete={handleDeleteAvatar}
          first_name={company.name ?? ''}
          color={company.color ?? ''}
          type='company'
        />
      </div>
      <div className={styles.formBlock}>
        <div className={styles.formGroup}>
          <Label
            htmlFor={ids.name}
            required={true}
          >
            {companyT('form.name')}
          </Label>
          <TextField
            tabIndex={-1}
            id={ids.name}
            fullWidth
            placeholder={companyT('form.name_placeholder')}
            variant='outlined'
            {...register('name')}
            error={!!errors?.name?.message}
            helperText={validationT(errors?.name?.message ?? '')}
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <Label htmlFor={ids.company_form} required={true}>
            {companyT('form.company_form')}
          </Label>
          <Controller
            control={control}
            name='company_form'
            render={({ field }) => (
              <Select
                id={ids.company_form}
                fullWidth
                items={companyForms}
                allowEmpty={false}
                placeholder={companyT('form.company_form_placeholder')}
                error={!!errors?.company_form?.message}
                helperText={validationT(errors?.company_form?.message ?? '')}
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.formGroup}>
          <Label htmlFor={ids.registration_country} required={true}>
            {companyT('form.registration_country')}
          </Label>
          <Controller
            control={control}
            name='registration_country'
            render={({ field: { ref, onChange, value, ...rest }, fieldState: { error } }) => (
              <CountrySelect
                onChange={onChange}
                value={value as any}
                error={!!error?.message}
                helperText={validationT(errors?.registration_country?.message ?? '')}
                {...rest}
              />
            )}
          />
        </div>
        {/* Temporary removed for new create company flow */}

        {/*<div className={styles.formGroup}>*/}
        {/*  <Label*/}
        {/*    htmlFor={ids.legal_form_short}*/}
        {/*    required={true}*/}
        {/*  >*/}
        {/*    {companyT('form.legal_form_short')}*/}
        {/*  </Label>*/}
        {/*  <TextField*/}
        {/*    id={ids.legal_form_short}*/}
        {/*    fullWidth*/}
        {/*    placeholder={companyT('form.legal_form_short_placeholder')}*/}
        {/*    variant='outlined'*/}
        {/*    {...register('legal_form_short')}*/}
        {/*    error={!!errors?.legal_form_short?.message}*/}
        {/*    helperText={validationT(errors?.legal_form_short?.message ?? '')}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.formGroup}>*/}
        {/*  <Label*/}
        {/*    htmlFor={ids.legal_name}*/}
        {/*    required={true}*/}
        {/*  >*/}
        {/*    {companyT('form.legal_name')}*/}
        {/*  </Label>*/}
        {/*  <TextField*/}
        {/*    id={ids.legal_name}*/}
        {/*    fullWidth*/}
        {/*    placeholder={companyT('form.legal_name_placeholder')}*/}
        {/*    variant='outlined'*/}
        {/*    {...register('legal_name')}*/}
        {/*    error={!!errors?.legal_name?.message}*/}
        {/*    helperText={validationT(errors?.legal_name?.message ?? '')}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.formGroup}>*/}
        {/*  <Label htmlFor={ids.registration_number} required={true}>*/}
        {/*    {companyT('form.registration_number')}*/}
        {/*  </Label>*/}
        {/*  <TextField*/}
        {/*    id={ids.registration_number}*/}
        {/*    fullWidth*/}
        {/*    placeholder={companyT('form.registration_number_placeholder')}*/}
        {/*    variant='outlined'*/}
        {/*    {...register('registration_number')}*/}
        {/*    error={!!errors?.registration_number?.message}*/}
        {/*    helperText={validationT(errors?.registration_number?.message ?? '')}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={styles.inputsDouble}>
          <div className={styles.inputShort}>
            <Label htmlFor={ids.tax_number}>
              {companyT('form.tax_number')}
            </Label>
            <TextField
              id={ids.tax_number}
              placeholder={companyT('form.tax_number')}
              variant='outlined'
              {...register('tax_number')}
              error={!!errors?.tax_number?.message}
              helperText={validationT(errors?.tax_number?.message ?? '')}
            />
          </div>
          <div className={styles.inputShort}>
            <Controller
              name={'registration_date'}
              control={control}
              render={({ field }) => (
                <>
                  <Label htmlFor={ids.registration_date}>
                    {t('edit.registration_date')}
                  </Label>
                  <TextField
                    id={ids.registration_date}
                    placeholder={t('edit.placeholder_registration_date')}
                    variant='outlined'
                    error={!!errors?.registration_date?.message}
                    helperText={validationT(errors?.registration_date?.message ?? '')}
                    InputProps={{
                      inputComponent: TextMaskCustom as any,
                      inputProps: {
                        mask: Date,
                        pattern: localeDateFormat,
                        format: (date: Dayjs) => dayjs.utc(date).format(localeDateFormat),
                        parse: (str: Dayjs) => dayjs.utc(str, localeDateFormat),
                        blocks: {
                          YYYY: {
                            mask: IMask.MaskedRange,
                            from: 1970,
                            to: new Date().getFullYear()
                          },
                          MM: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 12
                          },
                          DD: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 31
                          },
                        },
                        max: new Date(),
                        placeholder: t('edit.placeholder_registration_date'),
                        ...field
                      }
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <Label htmlFor={ids.legal_address}>
            {t('edit.legal_address')}
          </Label>
          <TextField
            id={ids.legal_address}
            fullWidth
            placeholder={companyT('form.placeholder_legal_address')}
            variant='outlined'
            {...register('legal_address')}
            error={!!errors?.legal_address?.message}
            helperText={validationT(errors?.legal_address?.message ?? '')}
          />
        </div>
        <div className={styles.formGroup}>
          <Label
            htmlFor={ids.mail_address}
          >
            {t('edit.postal_address')}
          </Label>
          <TextField
            id={ids.mail_address}
            fullWidth
            placeholder={companyT('form.placeholder_mail_address')}
            variant='outlined'
            {...register('mail_address')}
            error={!!errors?.mail_address?.message}
            helperText={validationT(errors?.mail_address?.message ?? '')}
          />
        </div>
        <div className={styles.formGroup}>
          <Label htmlFor='associations'>
            {t('edit.associations')}
          </Label>
          <Controller
            control={control}
            name='associations'
            render={({ field: { ref, value, ...rest } }) => (
              <AssociationsMultiSelect
                {...rest}
                value={value ?? []}
                alpha2={registrationCountry?.alpha2}
              />
            )}
          />
        </div>
        <div className={styles.formGroup}>
          <div className={styles.transportLicense}>
            <Checkbox
              onChange={() => setCheckbox('is_transport_license', !is_transport_license)}
              checked={is_transport_license ?? false}
            >
              {t('edit.transport_license')}
            </Checkbox>
          </div>
          {showTransportLicenseNumber &&
            <>
              <div className={styles.transportLicense}>
                <Checkbox
                  onChange={() => setCheckbox('is_transport_license_company', !is_transport_license_company)}
                  checked={is_transport_license_company ?? false}
                >
                  {t('edit.belongs_legal_entity')}
                </Checkbox>
              </div>
              <div className={styles.formGroup_checkbox}>
                <TextField
                  id='transport_license_number'
                  fullWidth
                  placeholder={t('edit.placeholder_transport_license_number')}
                  variant='outlined'
                  {...register('transport_license_number')}
                  error={!!errors?.transport_license_number?.message}
                  helperText={validationT(errors?.transport_license_number?.message ?? '')}
                />
              </div>
            </>
          }
        </div>
        <div className={styles.formGroup}>
          <div className={styles.forwarderLicense}>
            <Checkbox
              checked={is_forwarder_license ?? false}
              onChange={() => setCheckbox('is_forwarder_license', !is_forwarder_license)}
            >
              {t('edit.forwarding_license')}
            </Checkbox>
          </div>
          {showForwarderLicenseNumber &&
            <>
              <div className={styles.transportLicense}>
                <Checkbox
                  onChange={() => setCheckbox('is_forwarder_license_company', !is_forwarder_license_company)}
                  checked={is_forwarder_license_company ?? false}
                >
                  {t('edit.belongs_legal_entity')}
                </Checkbox>
              </div>
              <div className={styles.formGroup_checkbox}>
                <TextField
                  id='forwarder_license_number'
                  fullWidth
                  placeholder={t('edit.placeholder_forwarder_license_number')}
                  variant='outlined'
                  {...register('forwarder_license_number')}
                  error={!!errors?.forwarder_license_number?.message}
                  helperText={validationT(errors?.forwarder_license_number?.message ?? '')}
                />
              </div>
            </>
          }
        </div>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <div
              className={styles.cancel}
              onClick={() => companyStore.activeEditForm = null}
            >
              {t('edit.cancel')}
            </div>
            <Loadable loading={actionsLoading}>
              <Button onClick={onSubmit}>
                {t('edit.save')}
              </Button>
            </Loadable>
          </div>
          {company.my_role === CompanyRoles.owner && (
            <>
              <div className={styles.deleteIcon} onClick={() => setShowModal(true)}>
                <Delete color='#8E8E93'/>
              </div>
              {showModal &&
                <DeleteCompany
                  setShowModal={setShowModal}
                  companyId={companyId}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  )
}

function DeleteCompany({
  setShowModal,
  companyId
}: {
  setShowModal: any,
  companyId: string
}) {
  const { t: errorsT } = useTranslation('errors')
  const { t: companyT } = useTranslation('company/create')
  const [reason, setReason] = useState('')
  const [redirect, setRedirect] = useState<boolean>(false)

  const deleteCompany = () => {
    fireClickDeleteCompany()
    api.companies.deleteCompany(companyId, { reason })
      .then(() => {
        companiesService.loadMyCompanies().then(() => {
          profilesService.retrieveMyProfile()
        })
        setRedirect(true)
      }).catch(({ response }) => {
      toastError(response?.data?.error_msg ?? errorsT('insufficient_request'))
    })
  }

  if(redirect) {
    return <Navigate to={resolvePathByName(ROUTE_NAMES.HOME)} replace/>
  }

  const getReason = (newReason?: string) => {
    if(!newReason) {
      toastError(companyT('validation.specify_reason'))
    } else {
      setReason(newReason)
    }
  }

  return (
    reason
      ? <ModalDelete
        setShowModal={setShowModal}
        onSubmit={deleteCompany}
      />
      : <ModalDeleteReasons
        setShowModal={setShowModal}
        onSubmit={getReason}
      />
  )
}

export const ModalDeleteReasons = ({
  setShowModal,
  onSubmit
}: ModalDeleteProps) => {
  const { t } = useTranslation('company/admin')
  const [reason, setReason] = useState('')

  useEffect(() => {
    if(reason.length > 320) {
      setReason(reason.slice(0, 320))
    }
  }, [reason])
  return (
    <Dialog
      open={true}
      onClose={() => setShowModal(false)}
      maxWidth='xs'
      sx={{ borderRadius: 0 }}
      className={styles.modal}
    >
      <DialogTitle style={{ background: '#FFF', paddingBottom: 0 }}>
        {t('edit.why_want_delete')}
      </DialogTitle>
      <DialogContent style={{ background: '#FFF' }}>
        <p className={styles.dialog__description}>
          {t('edit.describe_the_reasons')}
        </p>
        <div className={styles.formGroup}>
          <Label
            htmlFor='reasons'
            required={true}
          >
            {t('edit.reasons')}
          </Label>
          <TextField
            id='reasons'
            fullWidth
            placeholder={t('edit.specify_the_reason')}
            variant='outlined'
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </div>
      </DialogContent>
      <div className={styles.dialog__actions}>
        <div
          tabIndex={1}
          onClick={() => setShowModal(false)}
          className={styles.dialog__action}
        >
          {t('edit.cancel')}
        </div>
        <div
          tabIndex={2}
          onClick={() => onSubmit(reason)}
          className={styles.dialog__action}
        >
          {t('edit.continue')}
        </div>
      </div>
    </Dialog>
  )
}

export const ModalDelete = ({
  setShowModal,
  onSubmit
}: ModalDeleteProps) => {
  const { t } = useTranslation('company/admin')
  return (
    <Dialog
      open={true}
      onClose={() => setShowModal(false)}
      maxWidth='xs'
      sx={{ borderRadius: 0 }}
      className={styles.modal}
    >
      <DialogTitle style={{ background: '#FFF', paddingBottom: 0 }}>
        {t('edit.deleting_a_company')}
      </DialogTitle>
      <DialogContent style={{ background: '#FFF' }}>
        <p className={styles.dialog__description}>
          {t('edit.are_you_sure')}
        </p>
      </DialogContent>
      <div className={styles.dialog__actions}>
        <div
          tabIndex={1}
          onClick={() => setShowModal(false)}
          className={styles.dialog__action}
        >
          {t('edit.no')}
        </div>
        <div
          tabIndex={2}
          onClick={onSubmit}
          className={cn(styles.dialog__action, styles.dialog__actionRed)}
        >
          {t('edit.yes')}
        </div>
      </div>
    </Dialog>
  )
}
