import Cookies, { CookiesOption } from 'cookies-ts'

const cookies = new Cookies()

export function get(key: string): string | null {
  return cookies.get(key)
}

export function set(key: string, value: string, option: CookiesOption = {}): void {
  cookies.set(key, value, option)
}

export function remove(key: string, option?: CookiesOption): void {
  cookies.remove(key, option)
}

export const cookiesUtil = {
  get,
  set,
  remove
}
