/*
 Lock on html tag, not on body, because of conflicting with Mui scroll locking. (pc in mui Dialog component, which in the moment is used somewhere)
 Mui locks only body, and in case of our unlock gets executed faster than mui-s,
 mui blocks scroll again

 We cant use only mui locks, because locking only body\html isn't working on IOS safari
 */

import { OS } from '@roolz/types/api'
import { getDeviceInfo } from './device'

let scrollPosTop: number

const LOCK_CLASS = 'scroll-locked'

export function lockPageScroll() {
  const html = document.querySelector('html')
  const deviceInfo = getDeviceInfo()

  if(html?.classList.contains(LOCK_CLASS)) {
    return
  }

  const top = document.documentElement.scrollTop
  scrollPosTop = top

  if(html) html.style.position = 'fixed'
  if(html) html.style.top = `-${top}px`
  if(html) html.style.width = '100%'
  if(html) html.classList.add(LOCK_CLASS)

  if(deviceInfo.os_type === OS.Ios) {
    const nextRoot = document.querySelector('#__next') as HTMLDivElement
    const reactRoot = document.querySelector('.app') as HTMLDivElement

    if(nextRoot) nextRoot.style.overflow = 'hidden'
    if(reactRoot) reactRoot.style.overflow = 'hidden'
  }
}

export function unlockPageScroll() {
  const html = document.querySelector('html')
  const deviceInfo = getDeviceInfo()

  if(!html?.classList.contains(LOCK_CLASS)) {
    return
  }


  if(html) html.style.position = ''
  if(html) html.style.top = ''
  document.documentElement.scrollTop = scrollPosTop
  html?.classList.remove(LOCK_CLASS)

  if(deviceInfo.os_type === OS.Ios) {
    const nextRoot = document.querySelector('#__next') as HTMLDivElement
    const reactRoot = document.querySelector('.app') as HTMLDivElement

    if(nextRoot) nextRoot.style.overflow = ''
    if(reactRoot) reactRoot.style.overflow = ''
  }
}

