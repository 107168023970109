export async function downloadFileToPc(url: string, filename: string) {
  // const link = document.createElement('a')
  // link.setAttribute('href', url)
  // link.setAttribute('download', undefined)
  // link.click()

  const link = document.createElement('a')

  console.log('DOWNLOAD', url, filename)

  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  // link.setAttribute('target', '_blank')
  // link.style.display = 'none'

  console.log(link)

  link.click()
}
