import { ApiClients } from '@roolz/api/clients'
import { ResetPasswordByTokenRequest, VerifyEmailRequest } from '@roolz/types/api/auth'
import {
  AuthResponse,
  getMagicLink,
  getResetPasswordLink,
  LoginByEmailRequest,
  loginByMagicLink,
  LoginBySmsRequest,
  loginBySocialNetwork,
  RefreshTokenRequest,
  RefreshTokenResponse
} from '@roolz/types/api/profiles'
import { AxiosInstance } from 'axios'

export class AuthApi {
  identity: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
  }
  refreshToken(body: RefreshTokenRequest) {
    return this.identity.post<RefreshTokenResponse>('/v1/public/auth/token/refresh', body)
  }

  checkResetPasswordTokenExpiration(token: string) {
    return this.identity.get('/v1/public/auth/reset_password/check_token', { params: { token } })
  }

  loginByEmail(body: LoginByEmailRequest) {
    return this.identity.post<AuthResponse>('/v1/public/auth/login/email', body)
  }

  loginBySms(body: LoginBySmsRequest) {
    return this.identity.post<AuthResponse>('/v1/public/auth/login/phone', body)
  }

  getResetPasswordLink(body: getResetPasswordLink) {
    return this.identity.post('/v1/public/auth/reset_password/send_token', body)
  }

  getMagicLink(body: getMagicLink) {
    return this.identity.post('/v1/public/auth/login/magic_link/send_token', body)
  }

  loginViaMagicLink(body: loginByMagicLink) {
    return this.identity.post<AuthResponse>('/v1/public/auth/login/magic_link', body)
  }

  loginViaSocialNetwork(body: loginBySocialNetwork) {
    return this.identity.post<AuthResponse>('/v1/public/auth/login/social', body)
  }

  logout() {
    return this.identity.post('/v1/private/auth/logout')
  }

  resetPasswordByToken(body: ResetPasswordByTokenRequest) {
    return this.identity.post('/v1/public/auth/reset_password', body)
  }

  verifyEmail(body: VerifyEmailRequest) {
    return this.identity.post('/v1/public/auth/verify_email', body)
  }
}
