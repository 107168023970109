import { Box } from '@mui/material'
import { forwardRef, ReactNode } from 'react'

interface Props {
  className?: string
  size?: number
  icon: ReactNode
  onClick?: () => void

  [key: string]: any
}

export const MaterialSymbolIcon = forwardRef((props: Props, ref) => {
  const { className, size = 20, icon, onClick, ...other } = props

  return (
    <Box
      ref={ref}
      className={className}
      sx={{
        display: 'inline-flex',
        fontSize: `${size}px`,
        '& svg': {
          width: '1em',
          height: '1em',
          fill: 'currentColor',
          // TODO rather delete it from here when will be possibility
          transition: 'all .3s'
        },
        cursor: onClick ? 'pointer' : 'inherit'
      }}
      onClick={onClick}
      {...other}
    >
      {icon}
    </Box>
  )
})
