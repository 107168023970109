import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { ProfileModelFactory } from '@/models/Profile.model'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isAddedPcpMessage, isAddedPcpsMessage, isJoinedPcpMessage, SystemMessageModel } from '@/types/models/chat'
import { ProfileModel } from '@/types/models/profile'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import { ChatType, Message, SystemMessageEvent } from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { FC, Fragment, MutableRefObject } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './SystemMessage.module.scss'

interface Props {
  messages: SystemMessageModel[]
  refsContainer: MutableRefObject<Record<Message['id'], any>> //Record<Message["id"], any>
}

export const SystemMessage = ({
  messages,
  refsContainer
}: Props) => {
  return <>
    {messages.map(message => (
      <div
        ref={(currentRef) => {
          refsContainer.current[message.number] = currentRef
        }}
        className={styles.root}
        key={message.id}
      >
        <Content
          message={message}
        />
      </div>
    ))}
  </>
}

function getProfileName(profile: Profile | undefined): string {
  return [
    UCFirst(profile?.profile_view_info?.first_name ?? ''),
    UCFirst(profile?.profile_view_info?.last_name ?? '')
  ].join(' ')
}

const Content = observer(SystemMessageErrorBoundary(function Content({ message }: {
  message: SystemMessageModel
}) {
  const { t } = useTranslation('chat/message')
  const { openProfile } = useProfileLink()

  const profileButton = (profile: Profile | undefined) => (
    <button
      className={styles.profile}
      onClick={() => profile && openProfile(profile.id)}
    >
      {getProfileName(profile)}
    </button>
  )

  switch(message.decodedContent.event) {
    case SystemMessageEvent.CHAT_CREATED: {
      if(!message.chat) {
        return null
      }

      let text = ''

      if(message?.chat.type === ChatType.GROUP_CHAT) {
        text = t('system_messages.chat_created')
      } else if(message?.chat.type === ChatType.CHANNEL) {
        text = t('system_messages.channel_created')
      }

      return (
        <div className={styles.text}>
          {text}
        </div>
      )
    }

    case SystemMessageEvent.PINNED_MESSAGE:
      return (
        <div className={styles.text}>
          {t('system_messages.pinned')}
        </div>
      )
  }

  if(isAddedPcpMessage(message)) {
    const addedProfile: ProfileModel = ProfileModelFactory(message.decodedContent.content.added_profile)

    if(!addedProfile) return null

    return (
      <div className={styles.text}>
        {addedProfile.id === profilesStore.my_profile?.id ? (
          <Trans
            i18nKey='chat/message:system_messages.you_were_added_to_group'
            values={{ name: getProfileName(message.owner) }}
            components={{
              profileLink: profileButton(message.owner)
            }}
          />
        ) : (
          message.isOwnMessage ? (
            <Trans
              i18nKey='chat/message:system_messages.you_added_to_group_one'
              values={{
                invitedName: getProfileName(addedProfile)
              }}
              components={{
                profileLink: profileButton(addedProfile)
              }}
            />
          ) : (
            <Trans
              i18nKey='chat/message:system_messages.one_added_to_group_one'
              values={{
                invitedName: getProfileName(addedProfile),
                invitingName: getProfileName(message.owner)
              }}
              components={{
                invitedLink: profileButton(addedProfile),
                invitingLink: profileButton(message.owner)
              }}
            />
          )
        )}
      </div>
    )
  }

  if(isAddedPcpsMessage(message)) {
    // TODO take profiles from store, not from the message itself
    return (
      <div className={styles.text}>
        {t('system_messages.many_added_to_group')}:
        {message.decodedContent.content.added_profiles.map((profile, i) => (
          <Fragment key={profile?.id}>
            {i !== 0 ? ', ' : ''}{profileButton(profile)}
          </Fragment>
        ))}
      </div>
    )
  } else if(isJoinedPcpMessage(message)) {
    return (
      <div className={styles.text}>
        {message.isOwnMessage ? (
          t('system_messages.you_joined_group')
        ) : (
          <Trans
            i18nKey='chat/message:system_messages.joined_group'
            values={{ name: getProfileName(message.owner) }}
            components={{
              profileLink: profileButton(message.owner)
            }}
          />
        )}
      </div>
    )
  }

  // if(isJoinedByInvitePcpMessage(message)) {
  //   // TODO take profiles from store, not from the message itself
  //
  //   const invitedProfile = message.decodedContent.content.invited_profile
  //   const invitingProfile = message.decodedContent.content.profile_who_invited
  //
  //   return (
  //     <div className={styles.text}>
  //       <button
  //         className={styles.profile}
  //         onClick={() => profile && openProfile(profile)}
  //       >
  //         {UCFirst(profile?.first_name ?? '')} {UCFirst(profile?.last_name ?? '')}
  //       </button>
  //       {t('system_messages.joined_by_invite')}
  //     </div>
  //   )
  // }

  throw new Error('Unsupported system message', { cause: message })
}))

function SystemMessageErrorBoundary<TProps>(Component: FC<TProps>) {
  return (props: TProps) => {
    const { t } = useTranslation('chat/message')

    return (
      <Sentry.ErrorBoundary
        fallback={(
          <div className={styles.text}>
            {/* @ts-ignore */}
            {t('unsupported_system_message')}
          </div>
        )}
      >
        {/* @ts-ignore */}
        <Component {...props}/>
      </Sentry.ErrorBoundary>
    )
  }
}
