import { MessageErrorBoundary } from '@/components/chats/MessageErrorBoundary'
import {
  MessageDeletedContent
} from '@/components/chats/MessageGroup/Content/MessageDeletedContent/MessageDeletedContent'
import { MessageFileContent } from '@/components/chats/MessageGroup/Content/MessageFileContent/MessageFileContent'
import { MessageTextContent } from '@/components/chats/MessageGroup/Content/MessageTextContent/MessageTextContent'
import { MessageAttributes } from '@/components/chats/MessageGroup/MessageAttributes/MessageAttributes'
import { BoundedMessagePreview } from '@/components/chats/ui/BoundedMessagePreview/BoundedMessagePreview'
import { ForwardFrom } from '@/components/chats/ui/ForwardedFrom/ForwardFrom'
import { checkIfCanReply } from '@/components/chats/utils'
import { ProfileLink } from '@/components/globalModalLinks/ProfileLink'
import { ProfileAvatar } from '@/components/profile/ui/ProfileAvatar'
import { MessageModelFactory } from '@/models/Message.model'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import {
  isFileMessage,
  isForwardMessage,
  isReplyMessage,
  isSystemMessage,
  isTextMessage,
  MessageModel
} from '@/types/models/chat'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { clearSelection } from '@roolz/sdk/utils/selection'
import { Message, MessageState, MessageStatus } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { forwardRef, MutableRefObject, useMemo, useRef, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MessageGroup.module.scss'

interface Props {
  isOwn?: boolean
  isChannel?: boolean
  messages: MessageModel[]
  refsContainer: MutableRefObject<Record<Message['id'], any>>
  highlightedMessageId: Message["id"] | null

  onContextMenu: (message: MessageModel, e: MouseEvent<HTMLDivElement>) => void
  onGoToMessage: (message: MessageModel) => void
}

export const MessageGroup = observer(forwardRef(({
  isOwn = false,
  isChannel = false,
  messages,
  refsContainer,
  highlightedMessageId,

  onContextMenu,
  onGoToMessage,
}: Props, ref: any) => {
  const { t, i18n: { language } } = useTranslation('chat/common')

  const firstMessage = messages[0]
  const sender = firstMessage.owner

  const senderName = useMemo(() => {
    if(!sender?.isActive) {
      return t('name.deleted_profile')
    }

    return [
      firstMessage.owner?.profile_view_info?.first_name,
      firstMessage.owner?.profile_view_info?.last_name
    ].join(' ')
  }, [language,
    sender?.profile_view_info?.first_name,
    sender?.profile_view_info?.last_name,
    sender?.isActive
  ])

  const filteredMessages = useMemo(() => {
    return messages.filter(msg => msg.state !== MessageState.NOT_DISPLAYED)
  }, [messages])

  if(!filteredMessages.length) {
    return null
  }

  return (
    <div
      className={cn(styles.message__block, {
        [styles.message__blockOwn]: isOwn
      })}
      ref={ref}
    >
      <MessageErrorBoundary>
        {!isOwn && !isChannel && sender && (
          <div className={styles.message__prepend}>
            <ProfileLink profileId={sender.id}>
              <ProfileAvatar
                profile={sender}
                is_deleted={!sender.isActive}
                width={32}
                showOnline
              />
            </ProfileLink>
          </div>
        )}

        {/*<div className={styles.message__wrapper}></div>*/}
        <div
          className={cn(styles.message__main, {
            [styles.message__mainOwn]: isOwn
          })}
        >
          {!isOwn && !isChannel && sender && (
            <ProfileLink
              profileId={sender.id}
              className={styles.message__name}
            >
              {senderName}
            </ProfileLink>
          )}

          {filteredMessages.map((message, i) => (
            <MessageContentWrapper
              isHighlighted={highlightedMessageId === message.id}
              onContextMenu={onContextMenu}
              onGoToMessage={onGoToMessage}

              ref={(currentRef) => {
                refsContainer.current[message.number] = currentRef
              }}
              // cleanUp={() => {
              //   if(message.status < MessageStatus.SENT) {
              //     console.log('clean up ')
              //     delete refsContainer.current[message.number]
              //   }
              // }}
              key={message.number}

              isOwn={isOwn}
              isFirst={i === 0}
              isLast={i === messages.length - 1}
              message={message}
            />
          ))}
        </div>
      </MessageErrorBoundary>
    </div>
  )
}))

interface MessageContentWrapperProps {
  isOwn: boolean
  message: MessageModel
  isHighlighted?: boolean

  isFirst: boolean
  isLast: boolean,

  onContextMenu: (message: MessageModel, event: MouseEvent<HTMLDivElement>) => void
  onGoToMessage: (message: MessageModel) => void
}

const MessageContentWrapper = observer(forwardRef(function MessageContentWrapper({
  isOwn,
  message,
  isHighlighted = false,

  isFirst,
  isLast,

  onContextMenu,
  onGoToMessage
  // cleanUp
}: MessageContentWrapperProps, ref: any) {
  const [error, setError] = useState<boolean>(false)

  const contentRef = useRef<any>()
  const prependRef = useRef<any>()

  if(message.state === MessageState.NOT_DISPLAYED) {
    return null
  }

  function getOrderDependentClasses() {
    if(isOwn) {
      if(isFirst) return styles.contentOwnFirst
      if(isLast) return styles.contentOwnLast
      return styles.contentOwnMiddle
    }

    if(isLast) return styles.contentLast
    if(isFirst) return styles.contentFirst
    return styles.contentMiddle
  }

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    if(error) {
      return
    }

    if(IS_MOBILE) {
      if(
        (!prependRef.current || !prependRef.current?.contains(event.target))
        && event.target !== prependRef.current
        && message.state === MessageState.ACTIVE
        && !isSystemMessage(message)
        && event.button === 0
      ) {
        const selected = chatSessionsStore.selectedMessage

        if(selected?.id === message.id) {
          chatSessionsStore.selectedMessage = null
        } else {
          chatsStore.activeChatId && (chatsStore.drafts[chatsStore.activeChatId] = chatsStore.activeChatMessage)
          chatSessionsStore.selectedMessage = message
        }
      }
    }

    if(IS_MOBILE || !checkIfCanReply(message) || !message || !chatsStore.activeChatId) {
      return
    }
    const DOUBLE_CLICK_NUMBER = 2

    // console.log(ref.current.contains(event.target), ref.current === event.target)

    if(event.detail === DOUBLE_CLICK_NUMBER
      // Check that click was outside message content
      && !contentRef.current.contains(event.target)
      && contentRef.current !== event.target
      && message.status >= MessageStatus.SENT
    ) {
      chatsStore.setReplyingMessage(chatsStore.activeChatId, message)

      event.preventDefault()
      clearSelection()
    }
  }

  return (
    <div
      className={cn(styles.content__wrapper, {
        [styles.content__wrapperOwn]: isOwn,
        [styles.content__wrapperHighlighted]: isHighlighted
      })}

      data-number={message.number}
      data-client-message-id={message.client_message_id}
      onClick={handleClick}
      ref={ref}
      onContextMenu={event => {
        if(error) {
          return
        }

        event.preventDefault()
        onContextMenu(message, event)
      }}
    >
      <div
        className={cn(styles.content, getOrderDependentClasses(), {
          [styles.contentOwn]: isOwn
        })}
        ref={contentRef}
      >
        <MessageErrorBoundary onError={() => setError(true)}>
          <MessageContentPrepend
            message={message}
            onGoToMessage={onGoToMessage}
            ref={prependRef}
          />

          <MessageContent
            message={message}
            isOwn={isOwn}
          />
        </MessageErrorBoundary>
      </div>
    </div>
  )
}))

const MessageContent = observer(({
  message,
  isOwn
}: Pick<MessageContentWrapperProps, 'message' | 'isOwn'>) => {
  if(message.state === MessageState.DELETED) {
    return <MessageDeletedContent/>
  }

  if(isTextMessage(message)) {
    return (
      <MessageTextContent
        content={message.decodedContent.content}
        attributes={
          <MessageAttributes
            showStatus={isOwn}
            message={message}
          />
        }
      />
    )
  }
  if(isFileMessage(message)) {
    return (
      <MessageFileContent
        message={message}
        attributes={
          <MessageAttributes
            showStatus={isOwn}
            message={message}
          />
        }
      />
    )
  }

  throw new Error('Unsupported message type', { cause: message })
})

// @ts-ignore
const MessageContentPrepend = observer(forwardRef(({ message, onGoToMessage }: Pick<MessageContentWrapperProps, 'message' | 'onGoToMessage'>, ref: any) => {
  const Content = useMemo(() => {
    if(message.state === MessageState.DELETED) {
      return null
    }

    if(isReplyMessage(message)) {
      // TODO probably on receive message need to check if profiles is loaded and load if not
      const profile = profilesStore.findProfile(message.reply_to.sender_id)

      const title = [
        profile?.profile_view_info?.first_name,
        profile?.profile_view_info?.last_name
      ].join(' ')
      const replyModel = MessageModelFactory(message.reply_to)

      return (
        <BoundedMessagePreview
          title={title}
          message={MessageModelFactory(message.reply_to)}

          onClick={() => onGoToMessage(replyModel)}
        />
      )
    }

    if(isForwardMessage(message)) {
      return (
        <ForwardFrom
          forwardedMessage={message.forward_from}
        />
      )
    }
  }, [message, onGoToMessage])

  if(Content) {
    return (
      <div
        className={styles.content__prepend}
        ref={ref}
      >
        {Content}
      </div>
    )
  }
}))
