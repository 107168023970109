import { useSendMedia } from '@/components/chats/useSendFile'
import { MAX_MESSAGE_LENGTH } from '@/config/const'
import { TextareaAutosize } from '@mui/material'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { useEventListener } from 'ahooks'
import { ChangeEvent, forwardRef, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TextField.module.scss'

interface Props {
  value: string
  isChatFocused: boolean

  onChange: (event: ChangeEvent<any>) => void
  onSend: () => void
}

const KEYBOARD_OPEN_TIME = 500

export const TextField = forwardRef(({
  value,
  isChatFocused,

  onChange,
  onSend
}: Props, ref: any) => {
  const { t } = useTranslation('chat/common')

  const { onFilesSelected } = useSendMedia()

  function handleKeyDown(event: any) {
    if(!IS_MOBILE) {
      if(event.key === 'Enter' && !event.nativeEvent.shiftKey) {
        event.preventDefault()

        onSend()
      }
    }
  }

  if(!IS_MOBILE) {
    useEffect(() => {
      if(ref.current) {
        ref.current.focus()
      }
    }, [ref])


    useEventListener('paste', useCallback((event) => {
      if(isChatFocused && document.activeElement !== ref.current) {
        const clipboard = event.clipboardData

        if(clipboard?.types.find(type => type === 'Files')) {
          const files = []

          if (clipboard?.items.length) {
            for(let i = 0; i < clipboard.items.length; i++) {
              if(clipboard.items[i].kind === 'file') {
                const file = clipboard.items[i].getAsFile()
                file && files.push(file)
              }
            }
          }

          event.preventDefault()
          event.stopPropagation()
          return onFilesSelected(files)
        }

        ref.current.value += clipboard?.getData('text')
        ref.current.focus()
      }
    }, [isChatFocused]), {
      target: document,
      // Necessary catch in capture phase, because of possible DroppableArea -> useDrag hook collisions
      capture: true
    })

    // Set focus if entered edit mode
    useLayoutEffect(() => {
      if(value?.length && ref.current && document.activeElement !== ref.current) {
        ref.current?.focus()
      }
    }, [value, ref])

    useEventListener('keydown', useCallback((event: KeyboardEvent) => {
      if(
        isChatFocused
        && document.activeElement !== ref.current
      ) {
        if(event.ctrlKey || event.altKey || event.metaKey) {
          return
        }

        if(!IS_MOBILE) {
          if(event.key === 'Enter' && !event.shiftKey) {
            onSend()
            ref.current.blur()
            return
          }
        }

        ref.current.focus()
      }
    }, [isChatFocused, onSend]), {
      target: window,
      // capture: true
    })
  }

  // On mount set cursor to the end of input
  useEffect(() => {
    const el = ref.current
    if(el) {
      el.selectionStart = el.selectionEnd = el.value.length
    }
  }, [])

  // if(IS_MOBILE) {
  //   const removeFocus = () => {
  //     if(ref.current) {
  //       ref.current?.blur()
  //       // Crunch, but without it Replying by clicking appropriate button doesn't set focus
  //       setTimeout(() => {
  //         ref.current?.blur()
  //       }, 50)
  //     }
  //   }
  //
  //   useLayoutEffect(removeFocus, [])
  // }

  function handleFocus() {
    // if(IS_MOBILE) {
    //   setTimeout(() => {
    //     if(document.documentElement.scrollTop < window.visualViewport.height / 2
    //       && ref.current === document.activeElement
    //     ) {
    //       document.documentElement.scrollTop = window.visualViewport.height
    //       ref.current.focus()
    //     }
    //   }, KEYBOARD_OPEN_TIME)
    // }
  }

  // useEffect(() => {
  //   if(isChatFocused) {
  //     window.addEventListener('keydown', handleWindowKeyDown, { capture: true })
  //
  //     return () => window.removeEventListener('keydown', handleWindowKeyDown, { capture: true })
  //   } else {
  //     window.removeEventListener('keydown', handleWindowKeyDown, { capture: true })
  //   }
  // }, [isChatFocused])

  return (
    <TextareaAutosize
      autoFocus={false}
      ref={ref}
      value={value}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      className={styles.field}
      minRows={1}
      maxRows={15}
      maxLength={MAX_MESSAGE_LENGTH}
      onFocus={handleFocus}
      placeholder={t('new_message_placeholder')}
    />
  )
})
