import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { usePageTitle } from '@/hooks/usePageTitle'
import { profilesStore } from '@/store/profiles/profiles.store'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router'

export const PrivateExchangeGuard = observer(function PrivateExchangeGuard() {
  const { t, i18n: { language } } = useTranslation('partnership/partners')
  const navigate = useNavigate()
  const isPrivateExchangeAvailable = !!profilesStore.my_profile?.active_space_company_id

  const { setTitle } = usePageTitle()

  useLayoutEffect(() => {
    setTitle(t(`business-meta:titles.private_exchange`))
  }, [language])

  useEffect(() => {
    if(!isPrivateExchangeAvailable) {
      navigate(resolvePathByName(ROUTE_NAMES.HOME))
    }
  }, [isPrivateExchangeAvailable])

  if(!isPrivateExchangeAvailable) {
    return <PageLoading/>
  }

  return (
    <Suspense fallback={<PageLoading/>}>
      <Outlet/>
    </Suspense>
  )
})
