import { CountryCard } from '@roolz/sdk/components/ui/CountryCard/CountryCard'
import { Country } from '@roolz/types/custom'
import style from './CountryMultiSelectCards.module.scss'

type CountryMultiSelectCardsProps = {
  title?: string
  selectedCountries: Country[]
  onDelete?: (country: Country) => void
}

export const CountryMultiSelectCards = ({
  title,
  selectedCountries,
  onDelete
}: CountryMultiSelectCardsProps) => {
  return (
    <>
      {title && (
        <p className={style.text}>
          {title}
        </p>
      )}
      <div className={style.container}>
        {selectedCountries.map(country => (
            <CountryCard key={country.alpha2} country={country} onDelete={onDelete}/>
          )
        )}
      </div>
    </>
  )
}
