import { Error } from '@roolz/icons/Error'
import styles from './FieldErrorTooltipIcon.module.scss'
import cn from 'classnames'

interface Props {
  className?: string
}

export const FieldErrorTooltipIcon = ({
  className
}: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <button className={styles.button}>
        <Error
          className={styles.icon}
          size={10}
        />
      </button>
    </div>
  )
}
