import { ID } from '../scalars'
import { Company } from './companies'
import { Profile } from './profiles'

export enum ChatKind {
  PRIVATE = 'private',
  PUBLIC = 'public',
  OPENED = 'opened',
}

export enum ChatType {
  SELF_CHAT = 'self_chat',
  DIALOG = 'dialog',
  GROUP_CHAT = 'group_chat',
  CHANNEL = 'channel',
  // WALKIE_TALKIE = 'walkie_talkie',
  // CONSULTATION = 'consultation'
}

export enum PcpType {
  MEMBER = 'member',
  ADMIN = 'admin',
  OWNER = 'owner'
}

export enum PcpStatus {
  ACTIVE = 'active',
  GONE = 'gone',
  DELETED = 'deleted'
}

export enum PcpChatState {
  NORMAL = 'normal',
  READ = 'read',
  UNREAD = 'unread'
}

export enum MessageType {
  SYSTEM = 'system',
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  FILE = 'file',
  CONTACT = 'contact',
  STICKER = 'sticker',
  POLL = 'poll',
  GEOLOCATION = 'geolocation'
}

export enum MessageStatus {
  SENDING = 0,
  ERROR = -1,

  // DEFAULT = 0,

  SENT = 1,
  DELIVERED = 2,
  READ = 3
}


export interface Pcp {
  chat_id: Chat['id']
  profile_id: Profile['id']

  is_banned: boolean
  type: PcpType
  status: PcpStatus
  is_wt_on: boolean
}

export enum SpecialChatGroupsTypes {
  ALL = 'all',
  PRIVATE = 'personal'
}

export type PinGroup = Company['id'] | SpecialChatGroupsTypes

export interface OwnPcp {
  chat_id: Chat['id']
  is_banned: boolean
  type: PcpType
  status: PcpStatus
  is_wt_on: boolean
  last_received_message_index: number
  last_read_message_index: number
  max_message_index: number
  min_message_index: number
  is_muted: boolean
  chat_state: PcpChatState
  chat_state_msg_number: number

  pins_data: Array<{
    pin_group: PinGroup
    pinned_at: string
  }>
}

export interface GetChatResponse {
  chat: Chat
  own_pcp: OwnPcp
}

// export type FrontendMessageExtraFields = {
//   _uploading_id?: string
// }
//
// export type Message = FrontendMessage | BackendMessage

export type SystemMessageType = 'chat_pcp_added'

export interface SystemMessageContent {
  event: SystemMessageType
  content: unknown
}


export interface AddPcpSystemMessageContent extends SystemMessageContent {
  event: 'chat_pcp_added'
  content: {
    added_profile: Profile
  }
}

export type GetChatsDataRequest = Partial<{
  pcp_statuses: 'active' | 'gone' | 'deleted'
  chat_updated_at_gt: string
  is_only_personal: boolean
  company_id: Company['id']
  offset: number
  limit: number
}>

export interface GetChatsDataResponse {
  chat_list: Chat[] | null
  own_pcp_list: OwnPcp[] | null
  message_list: Message[] | null
  profile_list: Profile[] | null
  pcp_list: Pcp[] | null

  meta: {
    current_time: string
  }
}

export type GetChatMessagesRequest = Partial<{
  ordering: number
  is_deleted: boolean
  created_at_gt: string
  created_at_lt: string
  message_types: MessageType[]
  number_gte: number
  number_lte: number
  offset: number
  limit: number
}>

export interface GetChatMessagesResponse {
  message_list: Message[]
  profile_list: Profile[]
}

export type PatchOwnPcpRequest = Partial<{
  is_muted: boolean
  chat_state: 'normal' | 'read' | 'unread'
  is_wt_on: boolean
}>

export interface Chat {
  id: string
  name: string | null
  kind: ChatKind
  avatar: string | null
  description: string | null
  invite_id: string | null
  type: ChatType
  company_id: string | null
  nickname: string | null
  is_active: boolean
  count_members: number
  online_members_count: number | null
  wt_active_profile_ids: number | null
  count_messages: number
  color: string
  is_archived: boolean
  deleted_message_numbers: number[]
  pinned_message_number: number

  created_at: string
  updated_at: string
}

export interface PublicChat {
  id: ID
  nickname: string
  count_members: number
  kind: ChatKind
  type: ChatType
  name?: string
  avatar?: string
  invite_id?: string
  color_code?: string
  description?: string
  company_name?: string
  count_online_members?: number
}

export enum SystemMessageEvent {
  SELF_CHAT_FIRST_MESSAGE = 'self_chat_first_message',
  CHAT_CREATED = 'chat_created',
  JOINED_PCP = 'chat_pcp_joined',
  ADDED_PCP = 'chat_pcp_added',
  ADDED_PCPS = 'chat_pcps_added',
  PINNED_MESSAGE = 'message_pinned',
  JOINED_BY_INVITE = 'invite_welcome_message',
}



export interface Message {
  id: string
  sender_id: Profile['id']
  chat_id: Chat['id']
  number: number
  content: string
  client_message_id: number | string
  type: MessageType
  status: MessageStatus
  version: number
  count_views: number
  state: MessageState
  sender_hash?: string
  reply_to_id?: string
  forward_from_id?: string
  sender?: Profile
  reply_to?: Omit<Message, 'reply_to' | 'reply_to_id' | 'forward_from_id' | 'forward_from'>
  forward_from?: Omit<Message, 'reply_to' | 'reply_to_id' | 'forward_from_id' | 'forward_from'>

  created_at: string
  updated_at?: string

  edited_at: string
}

export enum MessageState {
  ACTIVE = 'active',
  DELETED = 'deleted',
  NOT_DISPLAYED = 'not_displayed'
}


export interface SendMessageRequest {
  chat_id: Chat['id']
  content: string
  client_message_id: string | number
  type: MessageType
  forward_from?: Message
  forward_from_id?: Message['id']

  reply_to_id?: Message['id']
  reply_to?: Message
}

export type BuildMessageParams = SendMessageRequest
  & {
  sender_id: Message['sender_id']
  number: Message['number']
  created_at: Message["created_at"]
}

export type SendMessageResponse = Message

export interface PinChatRequest {
  pin_group: PinGroup
}

export type UnpinChatRequest = PinChatRequest

export interface CleanChatResponse {
  own_pcp: OwnPcp
  chat: Chat
}

export enum ContactKind {
  Phone = 'phone',
  Personal = 'personal',
  Company = 'company',
  Global = 'global'
}

export enum SearchedChatKind {
  Company = 'company',
  Personal = 'personal',
  Global = 'global'
}

export interface SearchChatsRequest {
  query: string

  offset?: number
  limit?: number
}

// export interface SearchChatsResponse {
//   chat_list: Array<{
//     company_id?: Company['id']
//     kind: SearchedChatKind
//     chat: Chat
//   }>
//   contact_list: Array<{
//     company_id: Company['id']
//     is_ban: boolean
//     kind: ContactKind
//     name: string
//     phone: string
//     profile: Profile
//   }>
// }

export interface GetOrCreateDialogResponse {
  chat: Chat
  own_pcp: OwnPcp
  pcp: Omit<Pcp, 'profile_id'> & {
    profile: Profile
  }
}

export interface GetPcpOrCreateObserverResponse {
  chat: Chat
  own_pcp: OwnPcp
}

export interface DeleteMessagesByNumbersRequest {
  message_numbers: Array<Message['number']>
}

export interface DeleteMessagesByNumbersResponse {
  deleted_messages_state: Record<Message['number'], MessageState>
}

export interface EditMessageRequest {
  content: any
}

export interface GetChatByNicknameRequest {
  nickname: string
}

export function buildMessage(data: BuildMessageParams): Message {
  return {
    id: data.chat_id + ':' + data.client_message_id,
    sender_id: data.sender_id,
    chat_id: data.chat_id,
    client_message_id: (data.client_message_id) as number,
    number: data.number,
    content: data.content,
    type: data.type,
    status: MessageStatus.SENDING, // chatsStore.activeChat?.type === 'self_chat' ? 1 : 0,
    version: 0,
    count_views: 0,
    state: 'active' as any,
    created_at: data.created_at,
    edited_at: null as any,
    // "sender_hash": chat?.type === ChatType.CHANNEL ? this.generateMessageSenderHash(number) : undefined,
    reply_to_id: data.reply_to_id ?? undefined,
    reply_to: data.reply_to ?? undefined,
    forward_from_id: data.forward_from_id ?? undefined,
    forward_from: data.forward_from ?? undefined
    // "sender": null,
  }
}
