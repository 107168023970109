/**
 * Persists store in local storage and synchronizes between tabs
 */
import { autorun, runInAction, toJS } from 'mobx'
import { KnowledgeStore } from '@/store/knowledge/stores/knowledge.store'
import { debounce, isEqual } from 'lodash-es'

function propagateValuesToStore(values: object, store: object) {
  if(typeof values !== 'object') {
    return
  }

  runInAction(() => {
    for(const [key, value] of Object.entries(values)) {
      // @ts-ignore
      if(!isEqual(store[key], value)) {
        // @ts-ignore
        store[key] = value
      }
    }
  })
}

export function persistAndSyncStore<TStore extends object>(store: TStore, key: string): TStore {
  store = persistStore(store, key)

  function handleOuterChange(e: StorageEvent) {
    if (e.key === key && e.newValue) {
      const newValue = JSON.parse(e.newValue)

      propagateValuesToStore(newValue, store)
    }
  }

  window.addEventListener('storage', handleOuterChange)

  return store
}


export function persistStore<TStore extends object>(store: TStore, key: string): TStore {
  const handleLocalChange = debounce((store: TStore) => {
    const json = JSON.stringify(toJS(store))

    localStorage.setItem(key, json)
  }, 100, { leading: true })

  try {
    const values = JSON.parse(localStorage.getItem(key) ?? '')
    propagateValuesToStore(values, store)
  } catch(e) {
    // console.log(e)
  }

  autorun(() => {
    handleLocalChange(store)
  })

  return store
}
