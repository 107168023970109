import { useMenuListContext } from '@roolz/sdk/components/ui/MenuList/MenuList'
import cn from 'classnames'
import { memo, ReactNode, useCallback } from 'react'
import styles from './MenuListItem.module.scss'

interface Props {
  Component?: any
  prepend?: ReactNode
  append?: ReactNode
  label?: ReactNode
  className?: string
  labelClassName?: string
  disabled?: boolean
  closeOnClick?: boolean
  onClick?: () => void

  [key: string]: any
}

export const MenuListItem = memo((props: Props) => {
  const {
    Component = 'button',
    prepend,
    append,
    label,
    className = '',
    labelClassName = '',
    disabled = false,
    closeOnClick = true,
    onClick,
    ...rest
  } = props

  const { closeMenu } = useMenuListContext() || {}

  const onClickHandler = useCallback(() => {
    if(disabled) return

    onClick?.()
    closeOnClick && closeMenu?.()
  }, [disabled])

  return (
    <Component
      {...rest}
      className={cn(styles.root, className, { [styles.disabled]: disabled, [styles.withAppend]: !!append })}
      onClick={onClickHandler}
    >
      {prepend && <span className={styles.icon}>{prepend}</span>}

      <div className={cn(styles.label, labelClassName)}>
        {label}
      </div>

      {append && <span className={styles.icon}>{append}</span>}
    </Component>
  )
})
