import { api } from '@/api'
import { db, DEXIE_STORES } from '@/database'
import * as chatQueries from '@/database/queries/chats.queries'
import { addOrUpdateMessages } from '@/repositories/messages.repository'
import { addOrUpdateOwnPcps } from '@/repositories/own_pcp.repository'
import { addOrUpdatePcps } from '@/repositories/pcp.repository'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { chatsStore } from '@/store/chats/chats.store'
import { clientSettings } from '@/store/client-settings/client-settings'
import { getNow } from '@/utils/date'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { Chat, GetChatsDataRequest, GetChatsDataResponse, OwnPcp } from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'

export async function loadAllChats(params: GetChatsDataRequest = {}, ignoreChats = false): Promise<any> {
  const fillToStore = (data: Omit<GetChatsDataResponse, 'meta'>) => {
    // runInAction(() => {
    if(!ignoreChats) {
      addOrUpdateProfiles(data.profile_list || [])

      addOrUpdateChats(data.chat_list || [])
      addOrUpdatePcps(data.pcp_list || [])
      addOrUpdateOwnPcps(data.own_pcp_list || [])
    }
    addOrUpdateMessages(data.message_list || [])
    // })
  }

  if(!params.chat_updated_at_gt) {
    try {
      await db.transaction('r',
        DEXIE_STORES.CHATS,
        DEXIE_STORES.PCPS,
        DEXIE_STORES.OWN_PCPS,
        DEXIE_STORES.MESSAGES,
        async () => {
        const chat_list = await db[DEXIE_STORES.CHATS].toArray()
        const message_list: any[] | Promise<any> = Promise.all(chat_list.map(chat => {
          // @ts-ignore
          return db[DEXIE_STORES.MESSAGES]
            .where('chat_id')
            .equals(chat.id)
            .reverse()
            .sortBy('created_at')?.[0]
        })) ?? []

        const own_pcp_list = db[DEXIE_STORES.OWN_PCPS].toArray()
        const pcp_list = db[DEXIE_STORES.PCPS].toArray()
        // TODO Load profiles
        const profile_list: Profile[] = []

        return {
          chat_list,
          message_list: (await message_list) || [],
          own_pcp_list: await own_pcp_list,
          pcp_list: await pcp_list,
          profile_list
        }
      })
        .then(data => {
          // console.log('loaded chats from idb', data)

          if(data.message_list.length && data.message_list?.[0] === undefined) {
            throw new Error('EMPTY MESSAGES')
          }

          fillToStore(data)
        })
    } catch(e) {
      console.error(e)
    }
  }

  try {
    let offset = params.offset ?? 0
    const limit = params.limit ?? 50
    let lastChatsLoadTime = getNow().toISOString()

    let chat_list = []

    do {
      const { data } = await api.messaging.loadChats({
        ...params,
        offset, limit
      })
      fillToStore(data)

      offset += limit
      data.chat_list && (chat_list = data.chat_list)

      let maxMessagesDate: string | null = null
      if(data.message_list?.length) {
        data.message_list.forEach((message) => {
          if(!maxMessagesDate || (message.updated_at && dayjs(message.updated_at).isAfter(maxMessagesDate))
          ) {
            message.updated_at && (maxMessagesDate = message.updated_at)
          }
        })
      }

      if(maxMessagesDate) {
        lastChatsLoadTime = maxMessagesDate
      }
    } while(chat_list?.length >= limit)

    clientSettings.setSettings({ lastChatsLoadTime })
  } catch(e) {
    console.error(e)
  }
}


export async function getChat(chatId: OwnPcp['chat_id']): Promise<Chat | undefined> {
  let res: Chat | undefined = chatsStore.getChat(chatId)

  if(!res) {
    res = await db[DEXIE_STORES.CHATS].get(chatId)
  }

  return res
}

export async function addOrUpdateChats(chats: Chat[]) {
  chatsStore.addOrUpdateChats(chats)
  await chatQueries.addOrUpdateChats(chats)
}

export function patchChat(chatId: Chat['id'], data: Partial<Chat>) {
  chatsStore.updateChat(chatId, data)
  db[DEXIE_STORES.CHATS].update(chatId, data)
}
