import { profilesStore } from '@/store/profiles/profiles.store'
import { Bid, Offer, OfferBidsByCategory } from '@roolz/types/api/exchange'
import { mergeWith } from 'lodash-es'
import { makeAutoObservable, reaction } from 'mobx'

class BidsStore {
  myRelevantBids: Record<Offer['_id'], Bid['id']> = {}
  myCoworkersBids: Record<Offer['_id'], Bid['id'][]> = {}
  bids: Record<Bid['id'], Bid> = {}
  offerBids: Record<Offer['_id'], OfferBidsByCategory> = {}

  constructor() {
    makeAutoObservable(this)
  }

  addOrUpdateBid = (bid: Bid) => {
    if(this.updateBidIfExists(bid)) return

    this.bids[bid.id] = bid
  }

  addOrUpdateMySpaceBid = (bid: Bid) => {
    if(this.isMyBid(bid)) bidsStore.myRelevantBids[bid.origin_offer_id] = bid.id
    else {
      bidsStore.myCoworkersBids[bid.origin_offer_id] ??= []

      if(!bidsStore.myCoworkersBids[bid.origin_offer_id].includes(bid.id)) {
        bidsStore.myCoworkersBids[bid.origin_offer_id].push(bid.id)
      }
    }

    this.addOrUpdateBid(bid)
  }

  addOrUpdateOfferBid = (state: 'won' | 'active' | 'inactive' | 'lost', bid: Bid) => {
    bidsStore.offerBids[bid.origin_offer_id] ??= {
      won: null,
      active: [],
      inactive: [],
      lost: []
    }

    if(state === 'won') bidsStore.offerBids[bid.origin_offer_id][state] = bid.id
    else if(!bidsStore.offerBids[bid.origin_offer_id][state].includes(bid.id)) {
      bidsStore.offerBids[bid.origin_offer_id][state].push(bid.id)
    }

    this.addOrUpdateBid(bid)
  }

  findAndRemoveOfferBid = (bid: Bid) => {
    if(!bidsStore.offerBids[bid.origin_offer_id]) return

    const bidState = (Object.keys(bidsStore.offerBids[bid.origin_offer_id]) as unknown as (keyof OfferBidsByCategory)[]).find(key => {
      if(key === 'won') return bidsStore.offerBids[bid.origin_offer_id].won === bid.id
      return bidsStore.offerBids[bid.origin_offer_id][key].includes(bid.id)
    })

    if(bidState) {
      bidState === 'won'
        ? bidsStore.offerBids[bid.origin_offer_id].won = null
        : bidsStore.offerBids[bid.origin_offer_id][bidState] = bidsStore.offerBids[bid.origin_offer_id][bidState].filter(id => id !== bid.id)
    }
  }

  updateBidIfExists = (bid: Bid) => {
    const existing = this.bids[bid.id]

    if(!existing) return false

    mergeWith(existing, bid, (a: unknown, b: unknown) => {
      if(Array.isArray(b)) return b
    })

    return true
  }

  findBidById = (id: Bid['id']): Bid | undefined => {
    return this.bids[id]
  }

  isMyBid = (bid?: Bid | null): boolean => {
    if(!bid) return false

    return bid.assignee === profilesStore.my_profile_id && bid.company_id === profilesStore.my_profile?.active_space_company_id
  }

  hasAccessToBid = (bid: Bid): boolean => {
    const activeSpace = profilesStore.my_profile?.active_space_company_id

    return bid.company_id
      ? bid.company_id === activeSpace
      : activeSpace ? false : bid.assignee === profilesStore.my_profile_id
  }

  reset() {
    this.myRelevantBids = {}
    this.myCoworkersBids = {}
    this.bids = {}
    this.offerBids = {}
  }
}

export const bidsStore = new BidsStore()

reaction(() => profilesStore.my_profile?.active_space_company_id, () => {
  bidsStore.reset()
})
