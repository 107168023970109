import { ReactNode } from 'react'

export function NoTransition({
  children
}: {
  children: ReactNode
}) {
  return (
    <>{children}</>
  )
}
