import { getNow } from '@/utils/date'
import dayjs from '@roolz/sdk/plugins/dayjs'

const INVITE_MODAL_INTERVAL_MINUTES = 30

export enum MODAL_NAMES {
  createCompanyAppeal = 'create-company-appeal',
  createOffersAppeal = 'create-offers-appeal',
  inviteAppeal = 'invite-appeal',
  invited = 'invited'
}

export class AppealModalManager {
  private static FLAG = '1'

  private static getInviteMembersStorageKeyByCompanyId = (id: string) => `invite_modal_${id}`

  static isModalAllowedToOpen(modalName: MODAL_NAMES): boolean {
    return localStorage.getItem(modalName) !== this.FLAG
  }

  static setIsModalAllowedToOpen(modalName: MODAL_NAMES, value: boolean): void {
    value ? localStorage.removeItem(modalName) : localStorage.setItem(modalName, this.FLAG)
  }

  static setInviteMembersRestricted(id: string) {
    localStorage.setItem(this.getInviteMembersStorageKeyByCompanyId(id), dayjs(getNow()).toString())
  }

  static getIsInviteMembersAllowed(id: string) {
    const prevShowModalDate = localStorage.getItem(this.getInviteMembersStorageKeyByCompanyId(id))
    if(!prevShowModalDate) {
      return true
    }

    const minutes = dayjs(getNow()).diff(prevShowModalDate, 'minutes')

    return minutes >= INVITE_MODAL_INTERVAL_MINUTES
  }
}