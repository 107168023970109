import { Chat, Message } from '@roolz/types/api/chats'
import { Uploading, UploadingStatus, UploadingType } from '@roolz/types/client/uploadings'
import { makeAutoObservable } from 'mobx'

class UploadingStore {
  protected _uploadings: Omit<Uploading, 'file'>[] = []

  constructor() {
    makeAutoObservable(this)
  }

  addOrUpdateUploadings(items: Omit<Uploading, 'file'>[]) {
    items.forEach(item => {
      const existing = this._uploadings.find(({ id }) => id === item.id)
      if(existing) {
        Object.assign(existing, item)
      } else {
        this._uploadings.push(item)
      }
    })

    this._uploadings = [...items]
  }

  addUploading(item: Uploading) {
    this._uploadings.push(item)
  }

  removeUploading(id: Uploading["id"]) {
    this._uploadings = this._uploadings.filter(item => item.id !== id)
  }

  removeMessageUploading(chatId: Message["chat_id"], clientMessageId: Message["client_message_id"]) {
    const existingIndex = this._uploadings.findIndex(item => item.chat_id === chatId && item.client_message_id === clientMessageId)

    if(existingIndex > -1) {
      this._uploadings.splice(existingIndex, 1)
    }
  }

  clearUploadings(id: Uploading["id"]) {
    this._uploadings.length = 0
  }

  findMessageUploading(id: Message["client_message_id"]): Omit<Uploading, 'file'> | undefined {
    return this._uploadings.find(
      item => item.type === UploadingType.Message && item.client_message_id === id
    )
  }

  getNonCompletedUploadingsByChat(chatId: Chat["id"]) {
    return this._uploadings.filter(item => item.status !== UploadingStatus.Complete)
  }
}

const uploadingStore = new UploadingStore()

export {
  UploadingStore,
  uploadingStore
}
