import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react'
import * as serviceWorkerRegistration from "@/workers/service-worker/serviceWorkerRegistration"

let refreshing = false

export const useServiceWorker = () => {
  const [activeWorker, setActiveWorker] = useState<ServiceWorker | null>(null)

  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
  const waitingWorkerRef = useStateRef(waitingWorker)
  const [showReload, setShowReload] = useState<boolean>(false)

  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.

  const onSWSuccess = useCallback((registration: ServiceWorkerRegistration) => {
    setActiveWorker(registration.active ?? null)

    const sw = registration.active || registration.waiting || registration.installing
    if(sw) {
      sw.addEventListener('statechange', (e: any) => {
        if(e.target.state === 'activated') {
          setActiveWorker(sw)
        }

        console.log('WWW: success STATE CHANGE', e)
      })
    }

    console.log('WWW: success', registration, registration.active)
  }, [])

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
    setActiveWorker(registration.active ?? null)
    console.log('WWW: update')
  }, [])

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
    setShowReload(false)

    // show global page loader
    // @ts-ignore
    window.showLoader()
  }, [waitingWorker])
  // register the service worker 1

  useEffect(() => {
    if(!navigator.serviceWorker) {
      return
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess
    })

    // navigator.serviceWorker.getRegistration().then(reg => {
    //   console.log('WWW: check reg', reg, reg?.active)
    //   if(reg && reg.active) {
    //     setActiveWorker(reg.active ?? null)
    //   }
    // })

    navigator.serviceWorker.addEventListener('controllerchange', (event) => {
      if (!refreshing) {
        if(waitingWorkerRef.current) {
          window.location.reload()
        }
        refreshing = true
      }
    })
  }, [onSWUpdate, waitingWorkerRef])

  useLayoutEffect(() => {
    if(!navigator.serviceWorker) {
      return
    }
    navigator.serviceWorker.getRegistration().then(reg => {
      if(reg && reg.active) {
        setActiveWorker(reg.active ?? null)
      } else if(reg) {
        const sw = reg.active || reg.waiting || reg.installing
        if(sw) {
          sw.addEventListener('statechange', (e: any) => {
            if(e.target.state === 'activated') {
              setActiveWorker(sw)
            }

            console.log('WWW: success STATE CHANGE', e)
          })
        }
      }
    })
  }, [])


  return { activeWorker, showReload, waitingWorker, reloadPage }
}
