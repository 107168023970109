import { ExchangeApi } from '@roolz/api/api/exchange.api'
import { FileServerApi } from '@roolz/api/api/file-server.api'
import { HereProxyApi } from '@roolz/api/api/here-proxy.api'
import { AuthApi } from '@roolz/api/api/identity/auth.api'
import { CompaniesApi } from '@roolz/api/api/identity/companies.api'
import { ContactsApi } from '@roolz/api/api/identity/contacts.api'
import { PartnershipApi } from '@roolz/api/api/identity/partnership.api'
import { ProfilesApi } from '@roolz/api/api/identity/profiles.api'
import { SystemApi } from '@roolz/api/api/identity/system.api'
import { KnowledgeApi } from '@roolz/api/api/knowledge.api'
import { MessagingApi } from '@roolz/api/api/messaging.api'
import { NotificationsApi } from '@roolz/api/api/notifications.api'
import { NominatimApi } from '@roolz/api/api/osm/nominatim'
import { SearchApi } from '@roolz/api/api/search.api'
import { SupportApi } from '@roolz/api/api/support.api'
import { WsProxyApi } from '@roolz/api/api/ws-proxy.api'
import { ApiClients } from '@roolz/api/clients'

export function createApi(clients: ApiClients) {
  return {
    auth: new AuthApi(clients),
    profiles: new ProfilesApi(clients),
    companies: new CompaniesApi(clients),
    partnership: new PartnershipApi(clients),
    contacts: new ContactsApi(clients),
    system: new SystemApi(clients),

    messaging: new MessagingApi(clients),
    notifications: new NotificationsApi(clients),
    wsProxy: new WsProxyApi(clients),
    exchange: new ExchangeApi(clients),
    fileServer: new FileServerApi(clients),
    knowledge: new KnowledgeApi(clients),
    support: new SupportApi(clients),
    search: new SearchApi(clients),
    hereProxy: new HereProxyApi(clients),

    nominatim: new NominatimApi(clients)
  }
}

export type Api = ReturnType<typeof createApi>
