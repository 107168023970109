import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styles from './CantWriteToUser.module.scss'

export const CantWriteToUser = observer(() => {
  const { t } = useTranslation('chat/common')

  return (
    <div className={styles.main}>
      {t('cant_write_to_user')}
    </div>
  )
})
