import { Checked } from '@roolz/icons/checkbox/Checked'
import { Default } from '@roolz/icons/checkbox/Default'
import { Indeterminate } from '@roolz/icons/checkbox/Indeterminate'
import { useKeyboardNavigable } from '@roolz/sdk/hooks/useKeyboardNavigable'
import cn from 'classnames'
import { ChangeEvent, ReactNode, useEffect, useId, useState } from 'react'
import styles from './Checkbox.module.scss'

export enum CheckboxColor {
  DEFAULT = 'default',
  LIGHT = 'light'
}

interface Props {
  className?: string
  children?: ReactNode
  onChange?: (event: { target: { name: string, value: boolean } }) => void
  checked?: boolean
  indeterminate?: boolean
  disabled?: boolean
  color?: CheckboxColor

  [key: string]: any
}

export const Checkbox = (props: Props) => {
  const {
    className,
    children,
    checked = false,
    indeterminate = false,
    disabled = false,
    color = CheckboxColor.DEFAULT,
    onChange,
    ...rest
  } = props

  const [isChecked, setIsChecked] = useState<boolean>(checked)
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(indeterminate)
  const id = useId()

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if(disabled) {
      return
    }

    setIsChecked(event.target.checked)
    if(onChange) {
      onChange({
        target: {
          value: event.target.checked,
          name: event.target.name
        }
      })
    }
  }

  const keyboardNavigable = useKeyboardNavigable({
    disabled,
    onSelect: () => {
      setIsChecked(checked => {
        if(onChange) {
          onChange({
            target: {
              value: !checked,
              name: rest?.name ?? ''
            }
          })
        }

        return !checked
      })
    }
  })

  useEffect(() => {
    setIsIndeterminate(indeterminate)
  }, [indeterminate])

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <label
      htmlFor={id}
      // @ts-ignore
      tabIndex={disabled ? -1 : 0}
      className={cn(styles.label, className, { [styles.disabled]: disabled })}
      {...keyboardNavigable}
    >
      <div
        className={cn(styles.checkbox, styles[color], {
          [styles.disabled]: disabled,
          [styles.checked]: isChecked
        })}
      >
        {isIndeterminate ? <Indeterminate/> : isChecked ? <Checked/> : <Default/>}
      </div>

      <input
        id={id}
        checked={isChecked}
        type='checkbox'
        onChange={handleChange}
        className={styles.input}
        disabled={disabled}
        onClick={e => e.stopPropagation()}
        {...rest}
      />

      <div
        className={cn(styles.content, styles[color], {
          [styles.disabled]: disabled,
          [styles.checked]: isChecked
        })}
      >
        {children}
      </div>
    </label>
  )
}
