import { useId } from 'react'

interface Props {
  color?: string
  size?: number

  className?: string
}

export const File = ({
  color = 'white',
  size = 24,
  className
}: Props) => {
  const id = useId()

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='draft'>
        <mask id={id} maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
          <rect id='Bounding box' width='24' height='24' fill={color}/>
        </mask>
        <g mask={`url(#${id})`}>
          <path
            id='draft_2'
            d='M6.3077 21.5C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.3077C4.5 3.80257 4.675 3.375 5.025 3.025C5.375 2.675 5.80257 2.5 6.3077 2.5H13.5019C13.7429 2.5 13.9747 2.5468 14.1971 2.6404C14.4195 2.73398 14.6128 2.86283 14.7769 3.02693L18.973 7.22303C19.1371 7.38714 19.266 7.58042 19.3595 7.80285C19.4531 8.02528 19.5 8.25701 19.5 8.49803V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5H6.3077ZM13.5 7.5961C13.5 7.85378 13.5862 8.06884 13.7587 8.24128C13.9311 8.41373 14.1462 8.49995 14.4039 8.49995H18L13.5 3.99998V7.5961Z'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}
