import { MultiSelect, MultiSelectComponentProps } from '@roolz/sdk/components/ui/fields/Multiselect/MultiSelect'
import { getFlagUrl } from '@roolz/sdk/utils/cdns'
import { Country } from '@roolz/types/custom'
import { forwardRef } from 'react'
import style from './CountryMultiSelect.module.scss'


type CountryMultiSelectProps = Omit<MultiSelectComponentProps<Country>,
  'renderOptionContent'
  | 'compareValue'
>

export const CountryMultiSelect = forwardRef((props: CountryMultiSelectProps, ref: any) => {

  const renderOptionContent = (country: Country) => {
    const flagUrl = getFlagUrl(country?.alpha2 ?? '')

    return (
      <div className={style.optionName}>
          <span className={style.optionNameTag}>
            <img
              loading='lazy'
              width='20'
              src={flagUrl}
              srcSet={flagUrl + ' 2x'}
              alt=''
              className={style.optionIcon}
            />
            <span>
              {country.alpha2}
            </span>
          </span>
        <p className={style.optionText}>
          {country.name}
        </p>
      </div>
    )
  }

  return (
    // @ts-ignore
    <MultiSelect<Country>
      ref={ref}
      compareValue='alpha2'
      renderOptionContent={renderOptionContent}
      {...props}
    />
  )
})
