import { Range } from '@/components/ui/fields/Range/Range'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useKeyboardMove } from '@roolz/sdk/hooks/useKeyboardMove'
import { Coordinates, Dimensions } from '@roolz/types/custom'
import cn from 'classnames'
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState, WheelEvent } from 'react'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from './ImageAreaSelection.module.scss'

const fileReader = new FileReader()

const SCROLL_SCALE_SENSITIVITY = 0.003
// const KEYBOARD_MOVING_SPEED = 1

const MIN_SCALE = 1
const MAX_SCALE = 3
const INITIAL_SCALE = MIN_SCALE

enum SelectionShape {
  Circle = 'circle',
  Rectangle = 'rectangle'
}

interface Props {
  file?: File
  onScale?: (value: number) => void
  onDrag?: (value: { x: number, y: number }) => void
  onImageLoaded?: (value: any) => void
  shape: `${SelectionShape}`

  selectionWidth?: number
  selectionHeight?: number
}

interface State {
  scale: number
  canvasScale: number
  isImageLoaded: boolean
  fileContent: string
  imageDimensions: Dimensions
  offset: Coordinates
}

export const ImageAreaSelection = ({
  file,
  onDrag = () => {
  },
  onScale = () => {
  },
  onImageLoaded = () => {
  },
  shape = SelectionShape.Rectangle,

  selectionWidth = 180,
  selectionHeight = 180
}: Props) => {
  const { t } = useTranslation('ui')

  // const SELECTION_WIDTH = CIRCLE_DIAMETER * selectionRatio
  // const SELECTION_HEIGHT = CIRCLE_DIAMETER

  const [state, setState] = useState<State>({
    scale: INITIAL_SCALE,
    canvasScale: 1,
    isImageLoaded: false,
    fileContent: '',
    imageDimensions: { width: 0, height: 0 },
    offset: { x: 0, y: 0 }
  })
  const stateRef = useStateRef<typeof state>(state)

  const totalScale: number = useMemo(() => state.scale * state.canvasScale, [state.scale, state.canvasScale])
  const totalScaleRef = useStateRef<typeof totalScale>(totalScale)

  const lastDraggingPositionRef = useRef<Coordinates>({ x: 0, y: 0 })

  const handleScaleChange = useCallback((value: number) => setState(
    state => ({ ...state, scale: value })
  ), [])

  const handleFileLoaded = useCallback(() => {
    if(!fileReader.result) {
      // TODO show that couldn't load image
      return
    }

    const imgUrlString = String(fileReader.result)

    const img = new Image()
    img.onload = () => {
      const canvasScale = Math.max((selectionWidth / img.width), (selectionHeight / img.height))

      setState(state => ({
        ...state,
        fileContent: imgUrlString,
        isImageLoaded: true,
        imageDimensions: { width: img.width, height: img.height },
        canvasScale
      }))
    }

    img.src = imgUrlString
  }, [])

  /**
   * On try of drag or scale the image, if new coordinates're gonna be out of permitted bounds,
   * this method will return last allowed position not getting out the bounds
   *
   * @param x
   * @param y
   */
  function amendOffsetCoordinatesIfRequired({ x, y }: Coordinates): Coordinates {
    const realImageWidth = stateRef.current.imageDimensions.width * totalScaleRef.current
    const realImageHeight = stateRef.current.imageDimensions.height * totalScaleRef.current
    const maxXOffset = realImageWidth / 2 - selectionWidth / 2
    const maxYOffset = realImageHeight / 2 - selectionHeight / 2

    if(x < -maxXOffset) x = -maxXOffset
    if(y < -maxYOffset) y = -maxYOffset
    if(x > maxXOffset) x = maxXOffset
    if(y > maxYOffset) y = maxYOffset

    return { x, y }
  }

  const handleDragging = useCallback((event: PointerEvent) => {
    flushSync(() => {
      setState(state => {
        const offset = amendOffsetCoordinatesIfRequired({
          x: state.offset.x + (event.clientX - lastDraggingPositionRef.current.x),
          y: state.offset.y + (event.clientY - lastDraggingPositionRef.current.y)
        })

        // console.log(state.offset.x, (event.clientX - lastDraggingPositionRef.current.x))

        return { ...state, offset }
      })
    })

    lastDraggingPositionRef.current = ({
      x: event.clientX,
      y: event.clientY
    })
  }, [])

  const handleDragEnd = useCallback(() => {
    window.removeEventListener('pointerup', handleDragEnd)
    window.removeEventListener('pointermove', handleDragging)
  }, [])

  const handleDragStart = useCallback(function <El>(event: MouseEvent<El>) {
    lastDraggingPositionRef.current = {
      x: event.clientX,
      y: event.clientY
    }

    window.addEventListener('pointerup', handleDragEnd)
    window.addEventListener('pointermove', handleDragging)
  }, [])


  const handleWheel = useCallback(function <El>(event: WheelEvent<El>) {
    setState(state => {
      let scale = state.scale + (event.deltaY * SCROLL_SCALE_SENSITIVITY)
      scale = Math.min(Math.max(scale, MIN_SCALE), MAX_SCALE)

      return { ...state, scale }
    })
  }, [])

  // TODO make moving depending on time delayed, not on FPS
  const handleMove = useCallback((directions: Coordinates): void => {
    setState(state => {
      const offset = amendOffsetCoordinatesIfRequired({
        x: state.offset.x - directions.x,
        y: state.offset.y - directions.y
      })

      return { ...state, offset }
    })
  }, [])

  useKeyboardMove({ onMove: handleMove })

  useEffect(() => {
    fileReader.onload = handleFileLoaded
  }, [])
  useEffect(() => {
    if(file) {
      setState(state => ({
        ...state,
        isImageLoaded: true,
        fileContent: '',
        offset: { x: 0, y: 0 },
        scale: 1
      }))

      if(onDrag) {
        onDrag(state.offset)
      }
      fileReader.readAsDataURL(file)

      return () => {
        fileReader.abort()
      }
    }
  }, [file])

  useEffect(() => {
    if(state.isImageLoaded) {
      const { x, y } = amendOffsetCoordinatesIfRequired(state.offset)
      if(x !== state.offset.x || y !== state.offset.y) {
        setState(state => ({
          ...state, offset: { x, y }
        }))
      }
    }

    onScale(totalScale)
  }, [totalScale])

  useEffect(() => {
    onImageLoaded(state.fileContent)
  }, [state.fileContent])

  useEffect(() => {
    onDrag(state.offset)
  }, [state.offset])

  return (
    <div className={styles.container}>
      <div
        className={styles.preview}
        onPointerDown={handleDragStart}
        onWheel={handleWheel}
      >
        {state.isImageLoaded && <>
          <img
            src={state.fileContent}
            alt=''
            className={styles.previewImage}
            style={{
              transform: `scale(${totalScale}) translate(${state.offset.x / totalScale}px, ${state.offset.y / totalScale}px)`,
              width: state.imageDimensions.width,
              height: state.imageDimensions.height
            }}
          />
          <div
            className={cn(styles.previewFrame, {
              [styles.previewFrameCircle]: shape === SelectionShape.Circle,
              [styles.previewFrameRectangle]: shape === SelectionShape.Rectangle
            })}
            style={{
              width: selectionWidth + 5,
              height: selectionHeight + 5
            }}
          />
        </>}
      </div>
      <div className={styles.scale}>
        <div className={styles.scaleTitle}>
          {t('image_area_selection.scale')} ({state.scale.toFixed(1)}x)
        </div>
        <Range
          min={MIN_SCALE}
          max={MAX_SCALE}
          value={state.scale}
          onChange={handleScaleChange}
        />
      </div>
    </div>
  )
}
