import { ExpandButton } from '@roolz/sdk/components/ui/buttons/ExpandButton/ExpandButton'
import { Collapse } from '@mui/material'
import { ReactNode, useState } from 'react'
import styles from './MenuListItemGroup.module.scss'

interface Props {
  prepend?: ReactNode,
  label?: ReactNode,
  children?: ReactNode

  [key: string]: any
}

export const MenuListItemGroup = ({
  prepend,
  label,
  children,
  ...rest
}: Props) => {
  const [expand, setExpand] = useState<boolean>(false)

  const onClick = () => {
    setExpand(expand => !expand)
  }

  return (<>
    <button
      className={styles.root}
      onClick={onClick}
      {...rest}
    >
      {prepend && (
        <span className={styles.icon}>
          {prepend}
        </span>
      )}
      <div className={styles.label}>
        {label}
      </div>

      <ExpandButton className={styles.arrow} size={8} expanded={expand} />
    </button>

    <Collapse in={expand} timeout={200}>
      <div className={styles.children}>
        {children}
      </div>
    </Collapse>
  </>)
}
