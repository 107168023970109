import { useSendMedia } from '@/components/chats/useSendFile'
import { MAX_FILES_FOR_MESSAGE_COUNT } from '@/config/const'
import { chatsStore } from '@/store/chats/chats.store'
import { uploadingStore } from '@/store/uploadings/uploading.store'
import { Attachment } from '@roolz/icons/chats/Attachment'
import { File } from '@roolz/icons/File'
import { FileCopy } from '@roolz/icons/FileCopy'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'
import { MenuList } from '@roolz/sdk/components/ui/MenuList/MenuList'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import { MenuListItem } from '@roolz/sdk/components/ui/MenuList/MenuListItem/MenuListItem'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AttachButton.module.scss'
import cn from 'classnames'

interface Props {

}

export const AttachButton = (props: Props) => {
  const { t } = useTranslation('chat/common')
  const [open, setOpen] = useState(false)

  const ref = useRef<any>()
  const inputRef = useRef<any>()

  const { onFilesSelected } = useSendMedia()

  const handleSendFile = useCallback(() => {
    if(chatsStore.activeChatId && uploadingStore.getNonCompletedUploadingsByChat(chatsStore.activeChatId).length) {
      return toastError(t('chat/common:wait_for_chat_uploadings_complete', {
        count: MAX_FILES_FOR_MESSAGE_COUNT
      }))
    }


    inputRef.current?.click?.()
  }, [chatsStore.activeChatId])

  function handleFileChanged() {
    if(!inputRef.current) {
      return
    }

    const files: FileList = inputRef.current.files

    if(files.length) {
      onFilesSelected(Array.from(files))
    }

    inputRef.current.value = null
  }

  return (<>
    <button
      className={styles.root}
      ref={ref}
    >
      <Attachment color={open ? '#3667DD' : '#8E8E93'}/>
    </button>

    <MenuList
      offset={[-40, 0]}
      placement='top-start'
      anchorRef={ref}
      disablePortal
      onOpenChange={setOpen}
    >
      <MenuListContent className={styles.menu__content}>
        <MenuListItem
          prepend={<Attachment
            color='#8E8E93'
            className={styles.fileIcon}
          />}
          label={t('attach.files')}
          onClick={handleSendFile}
        />

      </MenuListContent>
    </MenuList>

    <input
      ref={inputRef}
      type="file"
      onChange={handleFileChanged}
      multiple
      hidden
    />
  </>)
}
