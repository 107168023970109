import Button, { Color, Variant } from '@/components/ui/buttons/Button/Button'
import { useConfirmation } from '@/confirmation/ConfirmationContext'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { systemStore } from '@/store/system/system.store'
import { ChatModel, isSystemMessage } from '@/types/models/chat'
import { Popover, PopoverProps } from '@mui/material'
import { ChatBubble } from '@roolz/icons/chats/ChatBubble'
import { Pin } from '@roolz/icons/chats/Pin'
import { Silent } from '@roolz/icons/chats/Silent'
import { Unpin } from '@roolz/icons/chats/Unpin'
import { UnreadChatBubble } from '@roolz/icons/chats/UnreadChatBubble'
import { Volume } from '@roolz/icons/chats/Volume'
import { Delete2 } from '@roolz/icons/Delete2'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars/index'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import { MenuListItem } from '@roolz/sdk/components/ui/MenuList/MenuListItem/MenuListItem'
import { ChatType, PcpChatState, PcpType, SystemMessageEvent } from '@roolz/types/api/chats'
import { useEventListener } from 'ahooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ChatContextMenu.module.scss'

interface Props {
  chat: ChatModel

  show: boolean
  position: {
    left: number
    top: number
  }

  onClose: () => void

  popoverProps?: PopoverProps
}

export const ChatContextMenu = observer(({
  chat,

  show,
  position,

  onClose,

  popoverProps
}: Props) => {
  const { t } = useTranslation('chat/common')

  useEventListener('click', () => {
    if(show) onClose()
  }, { target: window })

  if(!chat) return null

  return (
    <Popover
      disablePortal={true}
      open={show}
      className={styles.root}
      classes={{
        paper: styles.paper
      }}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      anchorPosition={position}
      anchorReference='anchorPosition'
      transitionDuration={{
        enter: 200,
        exit: 0
      }}
      // TransitionProps={{
      //   exit: false
      // }}

      onClose={onClose}
      {...popoverProps}
    >
      <MenuListContent className={styles.content}>
        <ChatContextMenuContent chat={chat}/>
      </MenuListContent>
    </Popover>
  )
})

export function ChatContextMenuContent({
  chat
}: {
  chat: ChatModel
}) {
  const { t } = useTranslation('chat/common')
  const eraseOperation = getEraseOperation(chat)

  const isPinned = chat.isPinned
  const isMuted = chat.own_pcp.is_muted

  const { confirm, close } = useConfirmation()

  const canMarkRead = chat.own_pcp?.chat_state === PcpChatState.UNREAD || (chat.unreadMessagesCount > 0 && chat.own_pcp?.chat_state === PcpChatState.NORMAL)
  // const canMarkUnread = [PcpChatState.READ, PcpChatState.NORMAL].includes(chat.own_pcp?.chat_state) || chat.unreadMessagesCount === 0

  const getDeleteConfirmationText = (chat: ChatModel) => {
    if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
      if(PcpType.OWNER === chat.own_pcp.type) {
        return {
          title: t(`delete_chat_confirmation.${chat.type}.title`),
          description: t(`delete_chat_confirmation.${chat.type}.description`),
          confirm: t(`delete_chat_confirmation.confirm`),
          cancel: t(`delete_chat_confirmation.cancel`),
        }
      } else {
        return {
          title: t(`leave_chat_confirmation.${chat.type}.title`),
          description: t(`leave_chat_confirmation.${chat.type}.description`),
          confirm: t(`leave_chat_confirmation.confirm`),
          cancel: t(`leave_chat_confirmation.cancel`),
        }
      }
    }

    if(chat.type === ChatType.SELF_CHAT) {
      return {
        title: t('clean_chat_confirmation.title'),
        description: t('clean_chat_confirmation.description'),
        confirm: t(`clean_chat_confirmation.confirm`),
        cancel: t(`clean_chat_confirmation.cancel`),
      }
    }

    if(chat.type === ChatType.DIALOG) {
      return {
        title: t(`leave_chat_confirmation.${chat.type}.title`),
        description: t(`leave_chat_confirmation.${chat.type}.description`),
        confirm: t(`leave_chat_confirmation.confirm`),
        cancel: t(`leave_chat_confirmation.cancel`),
      }
    }

    return {}
  }

  const handleLeave = useCallback(() => {
    if(chat?.id) {
      const { title, description, confirm: confirmText, cancel } = getDeleteConfirmationText(chat)
      confirm({
        title,
        content: description,
        actions: (
          <>
            <Button
              variant={Variant.text}
              onClick={close}
            >{cancel}</Button>

            <Button
              variant={Variant.text}
              color={Color.danger}
              onClick={() => {
                close()
                !!chat?.id && chatsService.leaveChat(chat?.id)
                  .catch(({ response }) => {
                    if(response?.data?.error_msg) {
                      return toastError(response?.data?.error_msg)
                    }

                    toastWarning(t('errors:insufficient_request'))
                  })
              }}
            >{confirmText}</Button>
          </>
        )
      })
    }
  }, [chat])

  const handleDelete = useCallback(() => {
    if(chat?.id) {
      const isSelfChat = chat.type === ChatType.SELF_CHAT

      const { title, description, confirm: confirmText, cancel } = getDeleteConfirmationText(chat)

      confirm({
        title,
        content: description,
        actions: (
          <>
            <Button
              variant={Variant.text}
              onClick={close}
            >
              {cancel}
            </Button>

            <Button
              variant={Variant.text}
              color={Color.danger}
              onClick={() => {
                close()

                let deleteAction
                if(chat.type === ChatType.SELF_CHAT) {
                  deleteAction = chatsService.cleanSelfChat(chat.id)
                } else if(chat.type === ChatType.DIALOG) {
                  deleteAction = chatsService.leaveChat(chat.id)
                } else {
                  deleteAction = chatsService.deleteChat(chat.id)
                }

                deleteAction.catch(({ response }) => {
                  if(response?.data?.error_msg) {
                    return toastError(response?.data?.error_msg)
                  }

                  toastWarning(t('errors:insufficient_request'))
                })
              }}
            >
              {confirmText}
            </Button>
          </>
        )
      })
    }
  }, [chat])

  const handleMute = useCallback(() => {
    return !!chat?.id && chatsService.muteChat(chat?.id)
      .catch(({ response }) => {
        if(response?.data?.error_msg) {
          return toastError(response?.data?.error_msg)
        }

        toastWarning(t('errors:insufficient_request'))
      })
  }, [chat])

  const handleUnmute = useCallback(() => {
    return !!chat?.id && chatsService.unmuteChat(chat?.id)
      .catch(({ response }) => {
        if(response?.data?.error_msg) {
          return toastError(response?.data?.error_msg)
        }

        toastWarning(t('errors:insufficient_request'))
      })
  }, [chat])

  const handlePin = useCallback(() => {
    return !!chat?.id && chatsService.pinChat(chat?.id, chatsStore.activeGroup)
      .catch(({ response }) => {
        if(response?.data?.error_msg) {
          return toastError(response?.data?.error_msg)
        }

        toastWarning(t('errors:insufficient_request'))
      })
  }, [chat])

  const handleUnpin = useCallback(() => {
    return !!chat?.id && chatsService.unpinChat(chat?.id, chatsStore.activeGroup)
      .catch(({ response }) => {
        if(response?.data?.error_msg) {
          return toastError(response?.data?.error_msg)
        }

        toastWarning(t('errors:insufficient_request'))
      })
  }, [chat])

  const handleMarkRead = useCallback(() => {
    return !!chat?.id && chatsService.updateChatState(chat?.id, PcpChatState.READ)
  }, [chat])

  const handleMarkUnread = useCallback(() => {
    return !!chat?.id && chatsService.updateChatState(chat?.id, PcpChatState.UNREAD)
  }, [chat])

  return (<>
    {isPinned ? (
      <MenuListItem
        prepend={<Unpin color='#8E8E93' size={16}/>}
        label={t('chat_actions.unpin')}
        disabled={!systemStore.online}
        onClick={handleUnpin}
      />
    ) : (
      <MenuListItem
        prepend={<Pin/>}
        label={t('chat_actions.pin')}
        disabled={!systemStore.online}
        onClick={handlePin}
      />
    )}

    {isMuted ? (
      <MenuListItem
        prepend={<Volume color='#8E8E93' size={16}/>}
        label={t('chat_actions.unmute')}
        disabled={!systemStore.online}
        onClick={handleUnmute}
      />
    ) : (
      <MenuListItem
        prepend={<Silent/>}
        label={t('chat_actions.mute')}
        disabled={!systemStore.online}
        onClick={handleMute}
      />
    )}

    {canMarkRead ? (
      <MenuListItem
        prepend={<ChatBubble color='#8E8E93' size={16}/>}
        label={t('chat_actions.mark_read')}
        disabled={!systemStore.online}
        onClick={handleMarkRead}
      />
    ) : (
      <MenuListItem
        prepend={<UnreadChatBubble color='#8E8E93' size={16}/>}
        label={t('chat_actions.mark_unread')}
        disabled={!systemStore.online}
        onClick={handleMarkUnread}
      />
    )}

    {eraseOperation === 'delete' ? (
      <MenuListItem
        prepend={<Delete2/>}
        label={t('chat_actions.delete')}
        labelClassName={styles.danger}
        disabled={!systemStore.online}
        onClick={handleDelete}
      />
    ) : eraseOperation === 'clean' ? (
      <MenuListItem
        prepend={<Delete2/>}
        label={t('chat_actions.clean')}
        labelClassName={styles.danger}
        disabled={!systemStore.online}
        onClick={handleDelete}
      />
    ) : eraseOperation === 'leave' ? (
      <MenuListItem
        prepend={<Delete2/>}
        label={t('chat_actions.leave')}
        labelClassName={styles.danger}
        disabled={!systemStore.online}
        onClick={handleLeave}
      />
    ) : null}
  </>)
}

function getEraseOperation(chat: ChatModel): 'delete' | 'clean' | 'leave' | null {
  if(chat.type === ChatType.SELF_CHAT) {
    if(chat.totalMessagesCount > 1) {
      return 'clean'
    }
    return (isSystemMessage(chat.messages?.[0]) &&
      chat.messages?.[0]?.decodedContent?.event === SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE
    ) ? null : 'clean'
  }

  return (
    chat.type === ChatType.DIALOG || (
      !chat.company_id && [PcpType.OWNER].includes(chat.own_pcp.type)
    ) ? 'delete' : 'leave'
  )
}
