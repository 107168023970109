import { formattedTextToHtml } from '@roolz/sdk/utils/formatting'
import { TextContent } from '@roolz/types/api/messaging/message'
import { observer } from 'mobx-react-lite'
import { Fragment, ReactNode, useEffect } from 'react'
import styles from './MessageTextContent.module.scss'
import mailgo from '@roolz/sdk/plugins/mailgo.js'

interface Props {
  content: TextContent["content"]
  attributes?: ReactNode
}

export const MessageTextContent = observer(({ content, attributes }: Props) => {
  useEffect(() => {
    mailgo()
  }, [])

  const text = content?.text ? formattedTextToHtml(content.text.trim()) : ''

  return (
    <div className={styles.content}>
      <span
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <div className={styles.attributes}>
        {attributes}
      </div>
    </div>
  )
})
