import { systemStore } from '@/store/system/system.store'
import { memoize } from 'lodash-es'
import { padNumber } from '@roolz/sdk/utils/helpers'
import dayjs from '@roolz/sdk/plugins/dayjs'

export const SECOND_MS = 1000
export const MINUTE_MS = 60 * SECOND_MS
export const HOUR_MS = 60 * MINUTE_MS
export const DAY_MS = 24 * HOUR_MS

export const HOUR_SECS = 60 * 60

export const getToday = (() => {
  return getDayStart(getNow())
})

export const getTodayUTC = (() => {
  return getUTCDayStart(getNow())
})

export const getDayStart = (date: Date): Date => {
  const newDate = new Date(date)

  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)

  return newDate
}

export const getUTCDayStart = (date: Date): Date => {
  const newDate = new Date(date)

  newDate.setUTCHours(0)
  newDate.setUTCMinutes(0)
  newDate.setUTCSeconds(0)
  newDate.setUTCMilliseconds(0)

  return newDate
}

export const getMaximalDate = memoize((): Date => {
  return new Date(8640000000000000)
})

export const getMinimalDate = (): Date => {
  return new Date(0)
}

export function getNow() {
  return new Date(new Date().getTime() - (systemStore.clientServerDateDiffMs || 0))
}

export function getTime(date: Date, divider = ':'): string {
  return padNumber(date.getHours(), 2) + divider + padNumber(date.getMinutes(), 2)
}

export function getMonthAndDay(date: Date, divider = '.') {
  return padNumber(date.getMonth() + 1, 2) + divider + padNumber(date.getDate(), 2)
}

export function getDayMonthYear(date: Date, divider = '.') {
  return [
    padNumber(date.getDate(), 2),
    padNumber(date.getMonth() + 1, 2),
    date.getFullYear()
  ].join(divider)
}

export function getDayAndMonth(date: Date, divider = '.') {
  return padNumber(date.getDate(), 2) + divider + padNumber(date.getMonth() + 1, 2)
}

export function getDayWithShortMonth(date: Date): string {
  return padNumber(date.getDate(), 2) + ' ' + date.toLocaleString('default', { month: 'short' })
}

export function getYearMonthDay(date: Date, divider = '.'): string {
  return [
    padNumber(date.getUTCFullYear(), 2),
    padNumber(date.getUTCMonth() + 1, 2),
    padNumber(date.getUTCDate(), 2)
  ].join(divider)
}


export function yearMonthDayToDateObject(date: string, separator = '.'): Date {
  const d = new Date(0)

  const parts = date.split(separator)
  d.setUTCFullYear(Number(parts[0]))
  d.setUTCMonth(Number(parts[1]) - 1)
  d.setUTCDate(Number(parts[2]))

  return d
}

export function getHoursMinutesSeconds(timeMS: number, separator = ':') {
  const HOURS = padNumber(Math.floor(timeMS / HOUR_MS), 2)
  const MINUTES = padNumber(Math.floor(timeMS % HOUR_MS / MINUTE_MS), 2)
  const SECS = padNumber(Math.floor(timeMS % MINUTE_MS), 2)

  return [HOURS, MINUTES, SECS].join(separator)
}

export function hoursMinutesSecondsToSecs(date: string, separator = ':') {
  const [hours, minutes, seconds] = date.split(separator)
    .map(part => Number.parseInt(part))

  return (hours * 60 + minutes) * 60
}

export function isSameDates(d1: Date, d2: Date): boolean {
  return d1.getTime() === d2.getTime()
}

export function getOffsetMinutesByTZName(tzName: string): number {
  return dayjs().tz(tzName).utcOffset()

  // // Generating the formatted text
// // Setting the timeZoneName to longOffset will convert PDT to GMT-07:00
//   const options = {timeZone: tzName, timeZoneName: "short"}
//   const dateText = Intl.DateTimeFormat([], options as any).format(new Date)
//
// // Scraping the numbers we want from the text
// // The default value '+0' is needed when the timezone is missing the number part. Ex. Africa/Bamako --> GMT
//   const timezoneString = dateText.split(" ")[1].slice(3) || '+0'
//
// // Getting the offset
//   let timezoneOffset = parseInt(timezoneString.split(':')[0])*60
//
// // Checking for a minutes offset and adding if appropriate
//   if (timezoneString.includes(":")) {
//     timezoneOffset = timezoneOffset + parseInt(timezoneString.split(':')[1])
//   }
//
//   return timezoneOffset
}
