import { profilesStore } from '@/store/profiles/profiles.store'
import { ProfileModel } from '@/types/models/profile'
import { Company } from '@roolz/types/api/companies'
import { Profile } from '@roolz/types/api/profiles'
import { sortBy } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

export function sortProfilesByNames(items: Profile[]) {
  sortBy(items, profile => (profile?.profile_view_info?.first_name ?? '') + (profile?.profile_view_info?.last_name ?? ''))
}

class ContactListStore {
  isLoading = false

  // groups: GroupContacts = {}

  invited: Profile['id'][] = []

  private _search = ''

  constructor() {
    makeAutoObservable(this)
  }

  get search(): string {
    return <string>this._search
  }

  set search(value: string) {
    this._search = value
  }

  addInvited(user_id: Profile["id"]) {
    this.invited.push(user_id)
  }

  get personalContacts() {
    const result = Object.values(profilesStore.profiles)
      .filter(profile => !!profile.nickname?.length)
      .filter(({ relationships }) => relationships?.is_personal_contact
        && (relationships.is_colleague || (!relationships?.is_banned && !relationships?.is_banned_me)))

    sortProfilesByNames(result)

    return result
  }

  get contactsByCompanies(): Record<Company['id'], ProfileModel[]> {
    if(!profilesStore.my_profile?.companies) {
      return {}
    }

    return Object.fromEntries(
      profilesStore.my_profile?.companies?.map(company => {
        const contacts = Object.values(profilesStore.profiles)
          .filter(profile => !!profile.nickname?.length)
          .filter(({ companies, id }) =>
            !!companies?.length
            && id !== profilesStore.my_profile?.id
            && companies.find(({ id }) => id === company.id)
          )

        sortProfilesByNames(contacts)

        return [company.id, contacts]
      })
    )
  }

  get bannedProfiles() {
    const result = Object.values(profilesStore.profiles)
      .filter(({ relationships }) => relationships?.is_banned && !relationships.is_colleague)

    sortProfilesByNames(result)

    return result
  }
}

const contactListStore = new ContactListStore()

export {
  contactListStore
}
