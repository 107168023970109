import { contactsService } from '@/store/profiles/contacts.service'
import { ChatModel } from '@/types/models/chat'
import { Loadable } from '@roolz/sdk/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UnbanUser.module.scss'

interface Props {
  chat: ChatModel
}

export const UnbanUser = ({
  chat
}: Props) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation('profile/contact_list')

  async function handleUnban() {
    if(!chat.companion?.id) {
      return
    }

    setLoading(true)
    try {
      await contactsService.unbanProfile(chat.companion.id)
    } catch(e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      className={styles.root}
      onClick={handleUnban}
    >
      <Loadable loading={loading}>
        {t('buttons.unban')}
      </Loadable>
    </button>
  )
}
