import { api } from '@/api'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { companiesService } from '@/store/companies/companies.service'
import { companiesStore } from '@/store/companies/companies.store'
import { FiltersTypes, partnershipStore } from '@/store/partnership/partnership.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Company } from '@roolz/types/api/companies'
import {
  GetPartnershipInvitationsRequest, Partnership,
  PartnershipInvitation, PartnershipInvitationStatus,
  SendPartnershipInviteRequest
} from '@roolz/types/api/partnership'
import { Profile } from '@roolz/types/api/profiles'
import { keyBy, uniq, debounce } from 'lodash-es'
import { runInAction } from 'mobx'

export const FiltersTypesMap = {
  [FiltersTypes.INCOMING]: 'receiver' as const,
  [FiltersTypes.OUTGOING]: 'sender' as const
}

class PartnershipService {
  async getInvitationsProfiles(invitations: PartnershipInvitation[]) {
    const necessaryToLoadProfileIds: Profile['id'][] = []

    invitations.forEach(({ creator_profile_id }) => {
      const exist = !!profilesStore.findProfile(creator_profile_id)

      if(!exist) {
        necessaryToLoadProfileIds.push(creator_profile_id)
      }
    })

    if(necessaryToLoadProfileIds.length) {
      const { data } = await api.contacts.getContactsByIds(uniq(necessaryToLoadProfileIds))

      await addOrUpdateProfiles(data)
    }
  }

  async getInvitations(options: GetPartnershipInvitationsRequest) {
    partnershipStore.invitationsLoading = true

    await this.getIncomingInvitationsCountDebounced(options.companyId)
    const { data } = await api.partnership.getInvitations(options)

    await this.getInvitationsProfiles(data.items)

    runInAction(() => {
      partnershipStore.lastInvitesRequestFilter = options

      partnershipStore.addInvites(data.items)
      partnershipStore.invitationsTotal = data.total
      partnershipStore.invitationsPage += 1
      partnershipStore.invitationsLoading = false
    })

    return data
  }

  async getIncomingInvitationsCount(companyId: Company['id']) {
    const { data } = await api.partnership.getIncomingInvitesCounter(companyId)

    partnershipStore.incomingInvitesCount = data.incoming_count ?? 0

    return data
  }

  getIncomingInvitationsCountDebounced = debounce(this.getIncomingInvitationsCount, 150)

  async getMainPageInvitations(companyId: Company['id']) {
    const options = {
      companyId: companyId,
      params: {
        offset: 0,
        limit: 2
      },
      body: {
        states: [PartnershipInvitationStatus.PENDING]
      }
    }

    partnershipStore.invitationsLoading = true
    const {
      data: {
        items,
        total
      }
    } = await api.partnership.getInvitations(options)

    await this.getInvitationsProfiles(items)

    partnershipStore.lastInvitesRequestFilter = options

    partnershipStore.invitationsTotal = total
    partnershipStore.invitations = keyBy(items, 'id')
    partnershipStore.invitationsLoading = false
  }

  async sendInvitation(companyId: Company['id'], body: SendPartnershipInviteRequest) {
    const { data } = await api.partnership.sendInvitation(companyId, body)
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await this.getIncomingInvitationsCountDebounced(activeSpaceCompanyId)
    }
    await Promise.all([
      this.updateActiveCompanyInvitesCounter(),
      this.reloadPartnersAndInvitations()
    ])

    companiesStore.addOrUpdateCompany(data)

    return data
  }

  async acceptInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    const { data } = await api.partnership.acceptInvitation(companyId, inviteId)
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await this.getIncomingInvitationsCountDebounced(activeSpaceCompanyId)
    }

    await Promise.all([
      this.updateActiveCompanyInvitesCounter(),
      this.reloadPartnersAndInvitations()
    ])

    companiesStore.addOrUpdateCompany(data)

    return data
  }

  async cancelInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    const { data } = await api.partnership.cancelInvitation(companyId, inviteId)
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await this.getIncomingInvitationsCountDebounced(activeSpaceCompanyId)
    }

    await Promise.all([
      this.updateActiveCompanyInvitesCounter(),
      this.reloadPartnersAndInvitations()
    ])

    companiesStore.addOrUpdateCompany(data)

    return data
  }

  async rejectInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    const { data } = await api.partnership.rejectInvitation(companyId, inviteId)

    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await this.getIncomingInvitationsCountDebounced(activeSpaceCompanyId)
    }

    await Promise.all([
      this.updateActiveCompanyInvitesCounter(),
      this.reloadPartnersAndInvitations()
    ])

    companiesStore.addOrUpdateCompany(data)

    return data
  }

  async deletePartner(companyId: Company['id'], partnershipId: Partnership['id']) {
    const { data } = await api.partnership.deletePartner(companyId, partnershipId)

    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await this.getIncomingInvitationsCountDebounced(activeSpaceCompanyId)
    }

    await this.updateActiveCompanyInvitesCounter()
    await this.reloadPartnersAndInvitations()

    companiesStore.addOrUpdateCompany(data)
    partnershipStore.deletePartner(partnershipId)

    return data
  }

  async getPartners(companyId: Company['id']) {
    partnershipStore.partnersLoading = true
    const { data } = await api.partnership.getPartners(companyId)
    await this.getIncomingInvitationsCountDebounced(companyId)

    const partners = data.map((partner) => {
      if(!partner.company) {
        return partner
      }

      const { partnership, ...companyWithoutPartnership } = partner.company

      /**
       * exclude partnership info from company
       * to not override it as null in companies store
       */
      //@ts-ignore
      companiesStore.addOrUpdateCompany(companyWithoutPartnership)
      const company = companiesStore.findCompany(partner.company.id)

      return {
        ...partner,
        company: company ?? null
      }
    })

    partnershipStore.partners = keyBy(partners, 'id')
    partnershipStore.partnersLoading = false

    return data
  }

  async reloadPartnersAndInvitations() {
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id
    if(!activeSpaceCompanyId) {
      return
    }

    partnershipStore.resetState()
    partnershipStore.partnersLoading = true
    partnershipStore.invitationsLoading = true

    const isMainPageInvitations = !partnershipStore?.lastInvitesRequestFilter?.body?.invitation_side

    const reloadInvitations = isMainPageInvitations
      ? () => this.getMainPageInvitations(activeSpaceCompanyId)
      : () => partnershipStore.lastInvitesRequestFilter && this.getInvitations(partnershipStore.lastInvitesRequestFilter)

      await this.getPartners(activeSpaceCompanyId)

      if(partnershipStore.lastInvitesRequestFilter) {
        await reloadInvitations()
      }

    partnershipStore.partnersLoading = false
    partnershipStore.invitationsLoading = false
  }

  async updateActiveCompanyInvitesCounter() {
    const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id

    if(activeSpaceCompanyId) {
      await companiesService.getMyCompanyById({ id: activeSpaceCompanyId })
    }
  }
}

export const partnershipService = new PartnershipService()
