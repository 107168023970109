import { api } from '@/api'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { clientSettings } from '@/store/client-settings/client-settings'
import { contactListStore } from '@/store/contactList/contactList.store'
import { GetSearch, Profile } from '@roolz/types/api/profiles'
import { difference, uniq } from 'lodash-es'

class ContactsService {
  banProfile(id: Profile['id']) {
    return api.contacts.banContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  unbanProfile(id: Profile['id']) {
    return api.contacts.unbanContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  async loadContactsAndBans(onlyChanges = true) {
    if(contactListStore.isLoading) {
      return
    }

    contactListStore.isLoading = true

    try {
      const {
        data: { banned_ids, colleagues_ids, personal_ids }
      } = await api.contacts.getAllContactIds()

      let necessaryToLoadProfileIds: Array<Profile['id']> = []

      if(onlyChanges) {
        const localBannedIds = contactListStore.bannedProfiles.map(profile => profile['id'])
        necessaryToLoadProfileIds.push(...difference(localBannedIds, banned_ids))
        necessaryToLoadProfileIds.push(...difference(banned_ids, localBannedIds))

        const localPersonalContactIds = contactListStore.personalContacts.map(profile => profile['id'])
        necessaryToLoadProfileIds.push(...difference(localPersonalContactIds, personal_ids))
        necessaryToLoadProfileIds.push(...difference(personal_ids, localPersonalContactIds))

        Object.entries(colleagues_ids).forEach(([companyId, profiles]) => {
          const localCompanyContacts = contactListStore.contactsByCompanies[companyId] ?? []
          const localCompanyContactIds = localCompanyContacts.map(profile => profile['id'])

          necessaryToLoadProfileIds.push(...difference(localCompanyContactIds, profiles))
          necessaryToLoadProfileIds.push(...difference(profiles, localCompanyContactIds))
        })

        const extraLocalCompanies = difference(Object.keys(contactListStore.contactsByCompanies), Object.keys(colleagues_ids))
        extraLocalCompanies.forEach(companyId => necessaryToLoadProfileIds.push(
          ...contactListStore.contactsByCompanies[companyId].map(item => item['id'])
        ))
      } else {
        necessaryToLoadProfileIds = [
          ...banned_ids,
          ...personal_ids,
          ...Object.values(colleagues_ids).flat()
        ]
      }

      necessaryToLoadProfileIds = uniq(necessaryToLoadProfileIds)

      if(necessaryToLoadProfileIds.length) {
        const { data } = await api.contacts.getContactsByIds(necessaryToLoadProfileIds)

        addOrUpdateProfiles(data)
      }

      clientSettings.setSettings({
        lastContactsLoadTime: new Date().toISOString()
      })
    } finally {
      contactListStore.isLoading = false
    }
  }

  getContactsBySearch(params: GetSearch) {
    return api.contacts.getContactsBySearch(params)
  }

  addContact(id: Profile['id']) {
    // TODO check necessity of error handling
    return api.contacts.addPersonalContact(id)
      .then(async ({ data }) => {
          addOrUpdateProfiles([data])

          return data
        }
      )
  }

  unbanAndAddContact(id: Profile['id']) {
    return api.contacts.unbanAndAddPersonalContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  deleteContact(id: Profile['id']) {
    return api.contacts.deletePersonalContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }
}

export const contactsService = new ContactsService()
