import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { globalModalsHistory } from '@/global-modals/globalModalsHistory'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useLastLocation } from '@/hooks/useLastLocation'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

type PropFunc<Props> = (props: Props & {
  onClose: () => void
}) => ReactElement | null

export function PopupModalPage<Props>(PageFunc: PropFunc<Props>) {
  return observer((props: Props): ReactElement | null => {
    // Waiting one tick for global modals been registered
    const [ready, setReady] = useState(false)
    useEffect(() => {
      setReady(true)
    }, [])

    const location = useLocation()
    const navigate = useNavigate()

    const { getLastLocation } = useLastLocation()

    const onClose = useCallback(() => {
      if(globalModalsHistory['popup'].length < 2 && !globalModalsHistory['overlay'].length) {
        navigate(location.state.backgroundLocation, {
          state: location.state.backgroundLocation?.state ?? undefined
        })
        return
      }

      navigate(-1)
    }, [location])

    // TODO replace to prop or smth alike

    const fallbackRoute = {
      pathname: resolvePathByName(ROUTE_NAMES.MY_OFFERS)
    }

    useEffect(() => {
      if(globalModalsHistory['popup'].at(-2) === location?.pathname) {
        // Probably fix for click < button in browser
        // If new location equels to previous (not current) location, count it as go back

        globalModalsHistory['popup'].pop()
        return
      }

      if(globalModalsHistory['popup'].at(-1) !== location?.pathname) {
        globalModalsHistory['popup'].push(location?.pathname)
      }

    }, [location])

    useEffect(() => {
      if(!location?.state?.backgroundLocation) {
        const lastLocation = getLastLocation()
        navigate(location, {
          state: {
            backgroundLocation: lastLocation
              ? lastLocation?.state?.backgroundLocation ?? lastLocation
              : fallbackRoute
          },
          replace: true
        })
      }
    }, [])

    if(!ready) return null

    return (
      <PageFunc
        {...props}
        onClose={onClose}
      />
    )
  })
}
