import { AppealModalsContainer } from '@/components/AppealModalsContainer'
import { MessagingControlsProvider } from '@/components/chats/MessagingControlsProvider'
import HomeLayout from '@/components/layouts/HomeLayout/HomeLayout'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
 import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { SmthWentWrong } from '@roolz/icons/lottie/SmthWentWrong'
import { ErrorFallback } from '@roolz/sdk/components/ErrorFallback'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import React, { Suspense, useMemo, memo } from 'react'
import { Outlet, useLocation } from 'react-router'

export const Home = memo(() => {
  const location = useLocation()

  const showSidebar: boolean = useMemo(() => {
    return location.pathname !== resolvePathByName(ROUTE_NAMES.FILL_PROFILE)
  }, [location.pathname])

  return (
    <Suspense fallback={<PageLoading/>}>
      <AppealModalsContainer/>

      <HomeLayout showSidebar={showSidebar}>
        <Suspense fallback={<PageLoading/>}>
          <ErrorFallback fallback={<Fallback/>}>
            <Outlet/>
          </ErrorFallback>
        </Suspense>

      </HomeLayout>

      {!IS_MOBILE && (
        <MessagingControlsProvider/>
      )}
    </Suspense>
  )
})


const Fallback = () => (
  <div style={{  padding: 100, textAlign: 'center' }} >
    <SmthWentWrong size={100} style={{ width: 200, margin: 'auto' }}/>
  </div>
)
