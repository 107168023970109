import { CountryTag } from '@roolz/sdk/components/ui/CountryTag/CountryTag'
import { Country } from '@roolz/types/custom'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import DeleteIcon from '@material-symbols/svg-400/rounded/delete.svg'
import cn from 'classnames'
import style from './CountryCard.module.scss'

type CountryCardProps = {
  variant?: 'filled' | 'bordered'
  country: Country
  onDelete?: (country: Country) => void
}

export const CountryCard = ({ variant = 'filled', country, onDelete }: CountryCardProps) => {
  return (
    <div
      className={cn(style.tag, style[variant])}
      key={country.alpha2}
    >
      <CountryTag alpha2={country.alpha2} />

      <span className={style.name}>
        {country.name}
      </span>

      {onDelete && (
        <MaterialSymbolIcon
          className={style.delete}
          onClick={() => onDelete(country)}
          size={15}
          icon={<DeleteIcon/>}
        />
      )}
    </div>
  )
}
