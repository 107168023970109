import { GeoState, Offer, RouteWay, Template } from '@roolz/types/api/exchange'
import { isEqual, merge, mergeWith } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

type OfferLikeObjToUpdate = Partial<Offer> & Pick<Offer, '_id'>

class ExchangeStore {
  offers: Offer[] = []

  cargoTemplates: Template[] = []
  isCargoTemplatesLoaded = false

  transportTemplates: Template[] = []
  isTransportTemplatesLoaded = false

  constructor() {
    makeAutoObservable(this)
  }

  removeCargoTemplate(id: string): void {
    this.cargoTemplates = this.cargoTemplates.filter(template => template.id !== id)
  }

  removeTransportTemplate(id: string): void {
    this.transportTemplates = this.transportTemplates.filter(template => template.id !== id)
  }

  removeOffer({ _id }: Pick<Offer, '_id'>) {
    this.offers = this.offers.filter(offer => offer._id !== _id)
  }

  addOrUpdateOffer = (offer: Offer) => {
    const existing = this.offers.find(({ _id }) => _id === offer._id)

    if(existing && new Date(existing.updated_at) <= new Date(offer.updated_at)) {
      mergeWith(existing, offer, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) {
          return isEqual(a, b) ? a : b
        }
        if(Array.isArray(a) && (b == null)) {
          return b
        }
      })
      return
    }

    this.offers.push(offer)
  }

  updateOfferPartial(partial: OfferLikeObjToUpdate) {
    const existing = this.offers.find(({ _id }) => _id === partial._id)

    if(existing && (!partial.updated_at || new Date(existing.updated_at) <= new Date(partial.updated_at))) {
      merge(existing, partial)
      return
    }
  }

  updateOfferGeoState({ _id }: Pick<Offer, '_id'>, geoState: GeoState) {
    const offer = this.findOffer(_id)

    offer && (offer.geo_process_state = geoState)
  }

  updateOfferRoute({ _id }: Pick<Offer, '_id'>, routeWay: RouteWay) {
    const offer = this.findOffer(_id)

    offer && (offer.route_way = routeWay)
  }

  findOffer = (_id: Offer['_id']): Offer | undefined => {
    return this.offers.find(offer => offer._id === _id)
  }
}

export const exchangeStore = new ExchangeStore()
