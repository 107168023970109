import {
  MessageUnsupportedContent
} from '@/components/chats/MessageGroup/Content/MessageUnsupportedContent/MessageDeletedContent'
import * as Sentry from '@sentry/react'
import React, { ReactNode } from 'react'

export function MessageErrorBoundary({ children, onError }: {
  children: ReactNode,
  onError?: () => void
}) {
  return (
    <Sentry.ErrorBoundary
      onError={onError}
      fallback={(
        <MessageUnsupportedContent/>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
