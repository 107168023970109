import { Info } from '@roolz/icons/Info'
import { ReactNode } from 'react'
import styles from './AlertPanel2.module.scss'

interface Props {
  variant?: 'info'
  content: ReactNode
}
export const AlertPanel2 = (props: Props) => {
 return (
  <div className={styles.alert}>
    <Info
      className={styles.icon}
      size={16}
      color='#4778EE'
    />

    <div className={styles.content}>
      {props.content}
    </div>
  </div>
 )
}
