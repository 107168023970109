import { api } from '@/api'
import { CHAT_MESSAGES_PER_BUNCH } from '@/config/const'
import { BROADCAST_MESSAGES } from '@/config/workers'
import { db, DEXIE_STORES } from '@/database'
import { patchOwnPcp, setChatPinned, setChatUnpinned } from '@/database/queries/own_pcps.queries'
import { bus } from '@/events'
import { MessageModelFactory } from '@/models/Message.model'
import i18n from '@/plugins/i18n'
import { addOrUpdateChats, loadAllChats, patchChat } from '@/repositories/chats.repository'
import {
  addOrUpdateMessages,
  cleanChatMessages,
  getChatMessageByNumber,
  loadChatMessages,
  patchMessageByClientMessageId,
  patchMessageInChatByNumber
} from '@/repositories/messages.repository'
import { addOrUpdateOwnPcps, getOwnPcp, updateOwnPcp } from '@/repositories/own_pcp.repository'
import { addOrUpdatePcps } from '@/repositories/pcp.repository'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsStore } from '@/store/chats/chats.store'
import { clientSettings } from '@/store/client-settings/client-settings'
import { notificationsStore } from '@/store/notifications/notifications.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { uiStore } from '@/store/ui/ui.store'
import { wsService } from '@/store/ws/ws.service'
import { ChatModel, isFileMessage, isSystemMessage, isTextMessage, MessageModel } from '@/types/models/chat'
import { pickField } from '@/utils/arrays'
import { playNotification } from '@/utils/audio'
import { getNow } from '@/utils/date'
import { broadcastTabsMessage } from '@/utils/tabs'
import { toastError } from '@roolz/sdk/components/snackbars'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import {
  buildMessage,
  BuildMessageParams,
  Chat,
  ChatType,
  GetChatMessagesRequest,
  Message,
  MessageStatus,
  PcpChatState,
  PcpStatus,
  PinGroup,
  SystemMessageEvent
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import {
  ChatUserEventPackage,
  ChatUserEventTypes,
  IncomingPackageType,
  OutgoingPackageType
} from '@roolz/types/ws/packages'
import { cloneDeep, min } from 'lodash-es'
import { runInAction } from 'mobx'

class ChatsService {
  async setActiveChat(id: Chat['id'] | null) {
    if(id === chatsStore.activeChatId) {
      if(IS_MOBILE) {
        uiStore.closeAllGlobalModals?.()
      }

      return
    }

    chatSessionsStore.selectedMessage = null

    if(chatsStore.activeChatId) {
      // Save non sent message as draft
      if(chatsStore.activeChatMessage?.length) {
        chatsStore.drafts[chatsStore.activeChatId] = chatsStore.activeChatMessage
        chatsStore.activeChatMessage = ''
      } else {
        delete chatsStore.drafts[chatsStore.activeChatId]
      }
    }

    const currentChat = chatsStore.activeChat
    if(currentChat?.own_pcp?.status === PcpStatus.GONE
      && [ChatType.CHANNEL, ChatType.GROUP_CHAT].includes(currentChat.type)) {
      this.unbindFromChat(currentChat.id)
    }

    if(id === null) {
      chatsStore.activeChatId = id
      return
    }

    const chat = chatsStore.getChat(id)

    if(!chat?.is_active) {
      return
    }

    if(IS_MOBILE) {
      uiStore.closeAllGlobalModals?.()
    }

    const prevChatId = chatsStore.activeChatId
    chatsStore.activeChatId = id

    // If chat marked as Unread, clean the mark
    // ?Probably necessary to send this request only if chat is ready
    if(chatsStore.activeChat?.own_pcp?.chat_state === PcpChatState.UNREAD) {
      this.updateChatState(id, PcpChatState.NORMAL)
    }

    const loadingRequests: Array<Promise<any>> = []

    if(chat?.type === ChatType.SELF_CHAT && chat.own_pcp.last_read_message_index < 1) {
      this.markSelfChatRead(chat)
    }


    if(chat.type === ChatType.DIALOG) {
      // const profile = profilesStore.findProfile(chat.companionId)
      // if(!profile || !profile.last_action_time) {
      chat?.companionId && loadingRequests.push(profilesService.loadProfile(chat.companionId))
      // }
    }

    if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
      try {
        await this.loadChat(chat.id)
      } catch(e) {
        toastError(e?.response?.data?.error_msg ?? i18n.t('insufficient_request'))
        chatsStore.activeChatId = prevChatId
        return
      }
    }
    if(!chatsStore.isChatReady(id) && chat?.own_pcp) {
      return runInAction(() => {
        if(chat?.own_pcp?.status !== PcpStatus.GONE) {
          // Load necessary for view messages
          const number_gte = Math.max(
            0,
            chat?.own_pcp.max_message_index - CHAT_MESSAGES_PER_BUNCH,
            chat?.own_pcp.last_read_message_index - Math.floor(CHAT_MESSAGES_PER_BUNCH / 2)
          )
          const number_lte = number_gte + chat.unreadMessagesCount + CHAT_MESSAGES_PER_BUNCH - 1

          loadingRequests.push(
            this.loadChatMessagesInNumbersRange(id, {
              number_gte,
              number_lte
            }).then(() => {
              chatSessionsStore.setChatViewInfo(id, {
                minViewportMessageIndex: number_gte
                // maxViewportMessageIndex: number_lte
              })
              chatsStore.setChatReady(id)
            })
          )
        } else {
          // Load necessary for view messages
          loadingRequests.push(
            this.loadLastChatMessages(id)
              .then(() => {
                chatsStore.setChatReady(id)
                // TODO probably better to resolve it from idb
                const messages = chatsStore.getChat(id)?.messages

                const minLoadedMsgIndex = messages?.length
                  ? min(pickField(messages, 'number') as number[]) || 0
                  : 0

                chatSessionsStore.setChatViewInfo(id, {
                  minViewportMessageIndex: minLoadedMsgIndex
                })
              })
          )
        }
      })
    }

    return loadingRequests
  }

  async setActiveChatAsGroup(id: Chat['id']) {
    const ownPcp = await getOwnPcp(id)
    const chat = chatsStore.getChat(id)

    if(!chat || !ownPcp || ownPcp.status !== PcpStatus.ACTIVE) {
      const { data: { own_pcp, chat } } = await api.messaging.getPcpOrCreateObserver(id)
      runInAction(() => {
        addOrUpdateChats([chat])
        addOrUpdateOwnPcps([own_pcp])
      })

      api.messaging.bindToChat(id)
        .catch(console.error)
    }

    this.setActiveChat(id)
  }

  async setActiveChatAsDialog(profileId: Profile['id']) {
    const profile = profilesStore.findProfile(profileId)
    if(!profile || !profile.last_action_time) {
      await profilesService.loadProfile(profileId)
    }

    const chat = chatsStore.getDialogWithUser(profileId)
    if(chat) {
      return this.setActiveChat(chat.id)
    }

    const requests = []


    requests.push(api.messaging.getOrCreateDialog(profileId)
      .then(async ({ data }) => {
        const { profile, ...restPcp } = data.pcp

        await Promise.allSettled([
          addOrUpdateChats([data.chat]),
          addOrUpdateOwnPcps([data.own_pcp]),
          addOrUpdateProfiles([profile]),
          addOrUpdatePcps([{
            ...restPcp,
            profile_id: profile.id
          }])
        ])

        this.setActiveChat(data.chat.id)

        return data
      })
    )

    return Promise.all(requests)
      .then()
  }

  markSelfChatRead(chat: ChatModel) {
    // chatsStore.updateChatOwnPcp(chat.id, {
    //   last_read_message_index: chat.own_pcp.last_read_message_index
    // })

    const firstMsg = chat.messages
      .find(msg => isSystemMessage(msg) && msg.decodedContent.event === SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE)

    if(firstMsg && firstMsg.status !== MessageStatus.READ) {
      this.updateMessageStatus({
        chat_id: chat.id,
        client_message_id: firstMsg.client_message_id,
        message_number: firstMsg.number,
        sender_id: firstMsg.sender_id
      }, MessageStatus.READ)
    }
  }

  loadOrUpdateChats(ignoreChats = false) {
    if(clientSettings.lastChatsLoadTime === null) {
      return loadAllChats(undefined, ignoreChats)
    }

    return loadAllChats({
      chat_updated_at_gt: clientSettings.lastChatsLoadTime
    }, ignoreChats)
  }

  loadNewChatsData() {
    const lastUpdateTime = clientSettings.lastChatsLoadTime ?? undefined

    return loadAllChats({
      chat_updated_at_gt: lastUpdateTime
    })
  }

  async loadChatMessagesInNumbersRange(
    id: Chat['id'],
    params: GetChatMessagesRequest & Required<Pick<GetChatMessagesRequest, 'number_gte' | 'number_lte'>>
  ) {
    const { number_gte, number_lte, ...rest } = params

    const requests = []

    for(let i = number_gte; i <= number_lte; i += CHAT_MESSAGES_PER_BUNCH) {
      i = Math.floor(i)

      requests.push(
        loadChatMessages(id, {
          number_gte: i,
          number_lte: i + CHAT_MESSAGES_PER_BUNCH - 1,
          ...rest
        })
      )
    }

    return Promise.all(requests)
  }

  async loadLastChatMessages(id: Chat['id']) {
    return loadChatMessages(id, {
      number_lte: 10000000000,
      limit: CHAT_MESSAGES_PER_BUNCH,
      // @ts-ignore
      ordering: '-number'
    })
  }

  async loadPreviousMessagesBunch(chat: ChatModel, lteIndex: number) {
    if(chat.own_pcp.min_message_index >= lteIndex) {
      return
    }
    const number_gte = Math.max(0, chat.own_pcp?.min_message_index, lteIndex - CHAT_MESSAGES_PER_BUNCH + 2)

    if(number_gte >= chatSessionsStore.chatsMinLoadedMessageIndexes[chat.id]) {
      return true
    }

    // TODO check if messages in interval already loaded and just take from cache
    return chatsService.loadChatMessagesInNumbersRange(chat.id, {
      number_lte: lteIndex,
      number_gte
    })
  }

  submitMessage(message: Omit<BuildMessageParams, 'number' | 'sender_id'>) {
    if(chatsStore.drafts[message.chat_id]) {
      delete chatsStore.drafts[message.chat_id]
    }

    const mode = chatsStore.activeChatMessagePanelMode

    switch(mode?.type) {
      case 'edit':
        if(!mode?.message) return

        if(mode.message.content !== message.content) {
          this.editMessage(mode.message.chat_id, mode.message.number, message.content)
        }
        break
      case 'reply':
        if(!mode?.message) return

        message.reply_to_id = mode?.message.id
        message.reply_to = cloneDeep(mode?.message)
        if(message.reply_to) {
          message.reply_to.sender = cloneDeep(mode?.message.owner)
          delete message.reply_to.reply_to
          delete message.reply_to.reply_to_id
        }

        this.sendNewMessage(message)
        break
      default:
        this.sendNewMessage(message)
    }

    chatsStore.resetChatMessagePanelMode(message.chat_id)
  }

  sendNewMessage(data: Omit<BuildMessageParams, 'number' | 'sender_id'>) {
    const chat = chatsStore.getChat(data.chat_id)

    const number = (chat?.last_message?.number
      || chat?.own_pcp?.max_message_index
      || chat?.own_pcp?.min_message_index
      || 0) + 0.00001

    const sender_id = chat?.type === ChatType.CHANNEL
      ? chat.id
      : profilesStore.my_profile?.id ?? ''

    const msg = buildMessage({
      ...data,
      number,
      sender_id,
      created_at: getNow().toISOString()
    })

    // const msg = {
    //   'id': message.chat_id + ':' + message.client_message_id,
    //   'sender_id': sender_id as any,
    //   'chat_id': message.chat_id,
    //   'client_message_id': (message.client_message_id) as number,
    //   'number': number,
    //   'content': message.content,
    //   'type': message.type,
    //   'status': MessageStatus.SENDING, // chatsStore.activeChat?.type === 'self_chat' ? 1 : 0,
    //   'version': 0,
    //   'count_views': 0,
    //   'state': 'active' as any,
    //   'created_at': getNow().toISOString(),
    //   'edited_at': null as any,
    //   // "sender_hash": chat?.type === ChatType.CHANNEL ? this.generateMessageSenderHash(number) : undefined,
    //   'reply_to_id': message.reply_to_id ?? undefined,
    //   'reply_to': message.reply_to ?? undefined,
    //   'forward_from_id': message.forward_from_id ?? undefined,
    //   'forward_from': message.forward_from ?? undefined,
    //   _uploading_id: message._uploading_id ?? undefined
    //   // "sender": null,
    // }

    // If message doesn't require any server uploads, send ws package

    const msgModel = MessageModelFactory(msg)

    if(!msgModel.uploading) {
      wsService.sendNewMessagePackage(OutgoingPackageType.NewMessage, msg)
    }

    if(chat) {
      addOrUpdateMessages([msg], {
        incrementChatMessagesCountIfNew: false
      })
    }

    bus.emit('messages/messageCreated', msg)
  }

  async editMessage(chatId: Message['chat_id'], number: Message['number'], content: Message['content']) {
    const messageBeforeEdit = cloneDeep(await getChatMessageByNumber(chatId, number))

    try {
      patchMessageInChatByNumber(chatId, number, {
        content,
        status: MessageStatus.SENDING,
        version: (messageBeforeEdit?.version ?? 0) + 1
      })
    } catch(e) {
      alert(e)
    }

    return api.messaging.editMessage(chatId, number, { content })
      .then(({ data }) => {
        patchMessageInChatByNumber(chatId, number, {
          ...data,
          status: messageBeforeEdit?.status
        })
        // addOrUpdateMessages([data], {
        //   incrementChatMessagesCountIfNew: false
        // })
      })
      .catch(e => {
        if(messageBeforeEdit !== undefined) {
          patchMessageInChatByNumber(chatId, number, {
            ...messageBeforeEdit,
            status: MessageStatus.ERROR
          })
        }

        toastError(e?.response?.data?.error_msg ?? i18n.t('errors:insufficient_request'))
      })
  }

  loadChat(chatId: Chat['id']) {
    return api.messaging.getChat(chatId)
      .then(({ data }) => {
        addOrUpdateChats([data.chat])
        addOrUpdateOwnPcps([data.own_pcp])
      })
  }

  async pinChat(chatId: Chat['id'], group: PinGroup) {
    await setChatPinned(chatId, group)

    return api.messaging.pinChat(chatId, {
      pin_group: group
    })
      .catch(e => {
        // if(e.response) {
        setChatUnpinned(chatId, group)
        // }

        throw e
      })
  }

  async unpinChat(chatId: Chat['id'], group: PinGroup) {
    await setChatUnpinned(chatId, group)

    return api.messaging.unpinChat(chatId, { pin_group: group })
      .catch(e => {
        // if(e.response) {
        setChatPinned(chatId, group)
        // }

        throw e
      })
  }

  updateMessageStatus(params: {
    chat_id: Chat['id']
    client_message_id: Message['client_message_id']
    message_number: Message['number']
    sender_id: Message['sender_id']
  }, status: MessageStatus) {
    const chat = chatsStore.getChat(params.chat_id)
    if(!chat || chat.own_pcp.status !== PcpStatus.ACTIVE) {
      return
    }

    patchMessageByClientMessageId(params.chat_id, params.client_message_id, { status })

    // chatsStore.updateMessage(params.chat_id, params.client_message_id, { status })

    // wsService.sendPackage(OutgoingPackageType.MessageStatus, {
    //   ...params,
    //   message_status: status
    // })
    console.log('SEND STATUS', params.message_number, status)

    wsService.sendPackage(OutgoingPackageType.MessageStatus, {
      ...params,
      message_status: status
    })
  }

  sendMyTyping(): ChatUserEventPackage | null {
    if(!chatsStore.activeChat || !profilesStore.my_profile
      || [ChatType.SELF_CHAT, ChatType.CHANNEL].includes(chatsStore.activeChat.type)
    ) {
      return null
    }
    const pack: ChatUserEventPackage = {
      event: ChatUserEventTypes.Typing,
      chat_id: chatsStore.activeChat?.id,
      user_id: profilesStore.my_profile?.id,
      user_name: [
        profilesStore.my_profile?.profile_view_info?.first_name ?? '',
        profilesStore.my_profile?.profile_view_info?.last_name ?? ''
      ].join(' ')
    }

    wsService.sendPackage(OutgoingPackageType.Presence, pack)

    return pack
  }

  async deleteChat(chatId: Chat['id']) {
    // const originalStatus = (await getOwnPcp(chatId))?.status
    patchChat(chatId, {
      is_active: false
    })
    // updateOwnPcp(chatId, {
    //   status: PcpStatus.DELETED
    // })


    if(chatsStore.activeChatId === chatId) {
      this.setActiveChat(null)
    }

    return api.messaging.deleteChat(chatId)
      .then(() => {
        cleanChatMessages(chatId)

        if(chatSessionsStore?.chatViewInfos?.[chatId]) {
          delete chatSessionsStore.chatViewInfos[chatId]
        }
        if(chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]) {
          delete chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]
        }
        chatsStore.setChatNotReady(chatId)
      })
      .catch(e => {
        patchChat(chatId, {
          is_active: true
        })
        // updateOwnPcp(chatId, {
        //   status: originalStatus
        // })
        throw e
      })
  }

  cleanSelfChat(chatId: Chat['id']) {
    return api.messaging.cleanSelfChat()
      .then(({ data }) => {
        const { chat, own_pcp } = data

        addOrUpdateChats([chat])
        updateOwnPcp(chat.id, own_pcp)
        cleanChatMessages(chatId)

        if(chatSessionsStore.chatViewInfos[chatId]) {
          delete chatSessionsStore.chatViewInfos[chatId]
        }
        if(chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]) {
          delete chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]
        }
      })
  }

  async leaveChat(chatId: Chat['id']) {
    const originalStatus = (await getOwnPcp(chatId))?.status
    await updateOwnPcp(chatId, {
      status: PcpStatus.GONE
    })

    if(chatsStore.activeChatId === chatId) {
      this.setActiveChat(null)
    }

    return api.messaging.leaveChat(chatId)
      .then(() => {
        cleanChatMessages(chatId)

        if(chatSessionsStore?.chatViewInfos?.[chatId]) {
          delete chatSessionsStore.chatViewInfos[chatId]
        }
        if(chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]) {
          delete chatSessionsStore.chatsMinLoadedMessageIndexes[chatId]
        }
        chatsStore.setChatNotReady(chatId)
      })
      .catch(e => {
        updateOwnPcp(chatId, {
          status: originalStatus
        })
        throw e
      })
  }

  async muteChat(chatId: Chat['id']) {
    const originalStatus = (await getOwnPcp(chatId))?.is_muted
    await patchOwnPcp(chatId, {
      is_muted: true
    })

    return api.messaging.patchOwnPcp(chatId, { is_muted: true })
      .then(({ data }) => {
        // Theoretically here shouldn't be any 'jumps' of ui, but some new useful info can income, so update
        addOrUpdateOwnPcps([data])
      })
      .catch(e => {
        console.error(e)
        this.loadChat(chatId)
        patchOwnPcp(chatId, {
          is_muted: originalStatus ?? false
        })
        throw e
      })
  }

  async unmuteChat(chatId: Chat['id']) {
    const originalStatus = (await getOwnPcp(chatId))?.is_muted

    await patchOwnPcp(chatId, {
      is_muted: false
    })

    return api.messaging.patchOwnPcp(chatId, { is_muted: false })
      .then(({ data }) => {
        // Theoretically here shouldn't be any 'jumps' of ui, but some new useful info can income, so update
        addOrUpdateOwnPcps([data])
      })
      .catch(e => {
        this.loadChat(chatId)
        patchOwnPcp(chatId, {
          is_muted: originalStatus ?? true
        })
        throw e
      })
  }

  joinChat(chatId: Chat['id']) {
    return api.messaging.joinChat(chatId)
      .then(({ data }) => {
        addOrUpdateChats([data.chat])
        addOrUpdateOwnPcps([data.own_pcp])
      })
  }

  updateChatState(chatId: Chat['id'], chat_state: PcpChatState) {
    patchOwnPcp(chatId, { chat_state })

    return api.messaging.patchOwnPcp(chatId, { chat_state })
  }

  async unbindFromChat(chatId: Chat['id']) {
    cleanChatMessages(chatId)
    chatsStore.setChatNotReady(chatId)

    await api.messaging.unbindFromChat(chatId)
  }


  async queueNotificationIfNecessary({ body }: {
    body: string
  }) {
    let shouldShow = false

    try {
      const decoded = JSON.parse(body)

      if(decoded.type === IncomingPackageType.NewMessage) {
        const msg = JSON.parse(decoded.body)
        const msgModel = MessageModelFactory(msg)
        const chat = msgModel.chat

        if(!msgModel.isOwnMessage
          && !chat?.own_pcp.is_muted
          && chat?.own_pcp.status === PcpStatus.ACTIVE
          && chat.is_active
          && (systemStore.tabVisibility !== 'visible' || chat.id !== chatsStore.activeChatId)
        ) {
          db.transaction('rw',
            DEXIE_STORES.NOTIFICATIONS_QUEUE,
            DEXIE_STORES.DISPLAYED_NOTIFICATIONS,
            async () => {
              const alreadyDisplayedCount = await db[DEXIE_STORES.DISPLAYED_NOTIFICATIONS]
                .where('id').equals(msg.id + '_' + msg.version)
                .count()

              const sameInQueueCount = await db[DEXIE_STORES.NOTIFICATIONS_QUEUE]
                .where('id').equals(msg.id + '_' + msg.version)
                .count()

              shouldShow = !alreadyDisplayedCount && !sameInQueueCount

              if(shouldShow) {
                await db[DEXIE_STORES.NOTIFICATIONS_QUEUE].add(msg)
              }
            })
        }
      }
    } catch(e) {
      console.log(e)
    }

    return shouldShow
  }

  async showQueuedNotifications({
    haveFocusedTabs
  }: {
    haveFocusedTabs: boolean
  }) {
    const notifications = await db[DEXIE_STORES.NOTIFICATIONS_QUEUE]
      .toArray()

    console.log('SHOW NOTIfiCATIONS')

    for(const message of notifications) {
      try {
        const model = MessageModelFactory(message)

        await Promise.allSettled([
          this.showNotification({
            message: model,
            playAudio: notificationsStore.audioNotifications,
            haveFocusedTabs
          }),
          db[DEXIE_STORES.NOTIFICATIONS_QUEUE].delete(message.id),
          db[DEXIE_STORES.DISPLAYED_NOTIFICATIONS].put({ id: message.id })
        ])

        if(!haveFocusedTabs) {
          broadcastTabsMessage(BROADCAST_MESSAGES.NEW_NOTIFICATION, {
            type: 'message',
            id: message.id
          })
          // TODO remove this

          const reg = await navigator.serviceWorker.getRegistration()

          if(!reg || !reg.active) {
            return false
          }
        }
      } catch(e) {
        console.log('errrr', e)
      }
    }
  }

  async deleteMessage(message: Message) {
    return api.messaging.deleteMessagesByNumbers(message.chat_id, {
      message_numbers: [message.number]
    })
      .then(({ data }) => {
        for(const number in data.deleted_messages_state) {
          patchMessageInChatByNumber(message.chat_id, number as any, {
            state: data.deleted_messages_state[number]
          })
        }
      })
  }

  async showNotification({
    message,
    playAudio = true,
    haveFocusedTabs
  }: {
    message: MessageModel,
    haveFocusedTabs: boolean,
    playAudio?: boolean
  }) {
    if(message.chat_id === chatsStore.activeChatId && document.visibilityState === 'visible') {
      return
    }

    const title = [
      message.owner?.profile_view_info?.first_name ?? '',
      message.owner?.profile_view_info?.last_name ?? ''
    ].join(' ').trim()
    let body = ''
    if(isTextMessage(message)) {
      body = message?.decodedContent?.content?.text ?? ''
    } else if(isFileMessage(message)) {
      body = message.decodedContent.content.name
    }

    if(!body.length) {
      console.log('Empty notification body, break', message)
      return
    }
    // Push
    if('Notification' in window && !haveFocusedTabs && Notification.permission === 'granted') {
      const notification = new Notification(title, {
        tag: 'message.' + message.id,
        body,
        icon: process.env.REACT_APP_CDN_URL + '/web/logo/logo-letter.svg',
        // @ts-ignore
        vibrate: 2
      })

      notification.addEventListener('click', () => {
        notification.close()
        window.parent.parent.focus()

        chatsService.setActiveChat(message.chat_id)
      })
    }

    if(playAudio) {
      playNotification()
    }

    // (new Audio('/sound/notification.wav')).play()

    // Blinking title
    // if(!haveFocusedTabs) {
    //   chatSessionsStore.newHighlightedMessagesCount++
    // }

  }
}

export const chatsService = new ChatsService
