import { Company } from '@roolz/types/api/companies'
import { ExchangeRoleSlug } from '@roolz/types/api/knowledge/companies'
import { SeoItem } from '@roolz/types/custom'

export const getCategoryI18nKey = (slug: SeoItem['slug']) => 'company/categories:categories.' +
  (isCountrySubcategory(slug) ? slug.replace(/[A-Z]+$/, '') + 'country' : slug)

export const isCountrySubcategory = (slug: SeoItem['slug']) => /[A-Z]+$/.test(slug)
export const getCategoryImg = (slug: SeoItem['slug']) => {
  if(isCountrySubcategory(slug)) {
    slug = getCountrySubcategoryParent(slug)
  }

  return (process.env.NEXT_PUBLIC_ROOLZ_STATIC_URL || process.env.REACT_APP_ROOLZ_STATIC_URL)
    + '/images/companies/categories/' + slug + '.webp'
}

// If passed not country subcategory - will just return provided slug
export const getCountrySubcategoryParent = (slug: SeoItem["slug"]) => {
  return slug.replace(/[A-Z]*$/, '')
}

export function getCategoryCountrySlug(slug: SeoItem["slug"]) {
  return slug.replace(/^[^A-Z]*/, '')
}

export const organizeDataPage = (items: Company[]): {
  wideId: Company["id"] | null,
  items: Company[]
} => {
  const newData = [...items]

  const coverItemIndex = newData.findIndex(item => item.custom_cover_path)
  if(coverItemIndex === -1) {
    return {
      wideId: null,
      items
    }
  }

  const [coverItem] = newData.splice(coverItemIndex, 1)

  if(newData.length < 3) {
    return {
      wideId: coverItem.id,
      items: [
        coverItem,
        ...newData
      ]
    }
  }


  return {
    wideId: coverItem.id,
    items: [
      ...newData.splice(0, 3),
      coverItem,
      ...newData
    ]
  }
}

export const getCompanyExchangeRoleCategorySlug = (type: ExchangeRoleSlug) => {
  // TODO rather add it to seo.json
  switch(type) {
    case ExchangeRoleSlug.shipper:
    case ExchangeRoleSlug.shipper_carrier:
      return 'cr2'
    case ExchangeRoleSlug.carrier:
      return 'cr1'
    case ExchangeRoleSlug.forwarder:
    case ExchangeRoleSlug.forwarder_carrier:
      return 'cr3'
    case ExchangeRoleSlug.dispatcher:
      return 'cr4'
    case ExchangeRoleSlug.customs_agent:
      return 'cr5'
    case ExchangeRoleSlug.storage_provider:
      return 'cr6'
    // case ExchangeRoleSlug.other:
    //   return 'cr7'
  }
}

export const specialBannerColorPairs: Record<string, {from: string, to: string}> = {
  cr1: { from: 'E8FEE8', to: 'C4F7C4' },
  cr3: { from: 'E8F1FE', to: 'BCD7FF' },
  cr2: { from: 'FCF0E6', to: 'FFE1C9' }
}

export const getCategorySiblings = (categorySlug: SeoItem["slug"], categories: SeoItem[]) => {
  return categories.filter(item => ![categorySlug, 'c'].includes(item.slug) && !isCountrySubcategory(item.slug))
}
