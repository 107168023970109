import React from 'react'

interface Props {

}

export const Back = (props: Props) => {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.96589 7.76673L0.199219 4.00007L3.96589 0.233398L4.66589 0.933399L2.09922 3.50007H11.7992V4.50007H2.09922L4.66589 7.06673L3.96589 7.76673Z'
        fill='#8E8E93'
      />
    </svg>
  )
}
