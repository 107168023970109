import { ReactNode } from 'react'
import Header from './Header/Header'
import styles from './Home.module.scss'
import Sidebar from './Sidebar/Sidebar'

interface Props {
  showSidebar?: boolean,
  children: ReactNode
}

export default function HomeLayout({
  showSidebar = true,
  children
}: Props) {
  return (
    <main className={styles.company__main}>
      <div className={styles.root}>
        <Header/>

        <div className={styles.main}>
          {showSidebar && <Sidebar/>}

          <div className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </main>
  )
}
