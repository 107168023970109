import { FileView } from '@/components/chats/ui/FileView/FileView'
import Collapse from '@mui/material/Collapse'
import { Close } from '@roolz/icons/Close'
import { getReadableFileSize } from '@roolz/sdk/utils/file-size'
import { useTranslation } from 'react-i18next'
import styles from './FilePreview.module.scss'

interface Props {
  file: File

  showDelete?: boolean
  onDelete?: () => void
}

export const FilePreview = ({
  file,

  showDelete = false,
  onDelete
}: Props) => {
  const { t } = useTranslation('')

  const name = file.name
  const size = getReadableFileSize(file.size, t)

  return (
    <div className={styles.wrapper}>
      <FileView
        name={name}
        meta={size}
      />

      {showDelete && (
        <button
          className={styles.delete}
          onClick={onDelete}
        >
          <Close/>
        </button>
      )}
    </div>
  )
}
