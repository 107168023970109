import { createContext } from 'react'

interface CalendarContextInterface {
  weekDays: {
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
    sunday: string
  }
  months: {
    january: string,
    february: string,
    march: string,
    april: string
    may: string
    june: string
    july: string
    august: string
    september: string
    october: string
    november: string
    december: string
  }
}

const CalendarContext = createContext<CalendarContextInterface | null>(null)

export {
  CalendarContext
}

export type {
  CalendarContextInterface
}
