import cn from 'classnames'
import { ElementType, ReactNode } from 'react'
import styles from './MenuDescription.module.scss'

interface Props {
  Component?: ElementType
  prepend?: ReactNode
  label?: ReactNode
  className?: string
  labelClassName?: string

  [key: string]: any
}

export const MenuDescription = ({
  Component = 'div',
  prepend,
  label,
  className = '',
  labelClassName = '',
  ...rest
}: Props) => {
  return (
    <Component className={cn(styles.root, className)}{...rest}>
      {prepend && (
        <span className={styles.icon}>
          {prepend}
        </span>
      )}

      <div className={cn(styles.label, labelClassName)}>
        {label}
      </div>
    </Component>
  )
}
