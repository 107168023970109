import { ApiClients } from '../../clients'
import { LookupRequest, LookupResponse, SearchRequest, SearchResponse } from '@roolz/types/osm/nominatim'
import { AxiosInstance } from 'axios'

export class NominatimApi {
  nominatim: AxiosInstance

  constructor(clients: ApiClients) {
    this.nominatim = clients.nominatim
  }


  search(params: SearchRequest) {
    return this.nominatim.get<SearchResponse>('search', { params })
  }

  lookupById(params: LookupRequest) {
    return this.nominatim.get<LookupResponse>('lookup', { params })
  }
}
