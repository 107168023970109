import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { useEffect } from 'react'
import { useParams } from 'react-router'

const ProfileOffers = OverlayModalPage(({ onClose }) => {
  const { profile_id } = useParams()
  const globalModals = useGlobalModals()

  useEffect(() => {
    globalModals.open(GLOBAL_MODALS_NAMES.PROFILE_OFFERS_ASIDE, {
      key: Math.random() + '',
      props: {
        profileId: profile_id
      },
      config: {
        onClose,
        unmountOnClose: true
      }
    })
  }, [profile_id])

  return null
})

export default ProfileOffers
