import { usePrevious } from '@roolz/sdk/hooks/helpers/usePrevious'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { Coordinates } from '@roolz/types/custom'
import { useCallback, useEffect, useMemo, useState } from 'react'

// const KEYCODE_DIRECTIONS: { [key: number]: Direction } = {
//   37: Direction.LEFT,
//   38: Direction.UP,
//   39: Direction.RIGHT,
//   40: Direction.DOWN
// }

enum KEYCODE {
  LEFT = 37,
  UP = 38,
  RIGHT = 39,
  DOWN = 40
}

export function useKeyboardMove({
  onMove
}: {
  onMove: (directions: Coordinates) => void
}) {
  const [keysState, setKeysState] = useState<{
    left: boolean,
    right: boolean,
    up: boolean,
    down: boolean
  }>({
    left: false,
    right: false,
    up: false,
    down: false
  })
  const keysStateRef = useStateRef<typeof keysState>(keysState)

  const pressedCoordinates: Coordinates = useMemo(() => {
    return {
      x: -Number(keysState.left) + Number(keysState.right),
      y: -Number(keysState.up) + Number(keysState.down)
    }
  }, [keysState])

  const pressedCoordinatesRef = useStateRef<typeof pressedCoordinates>(pressedCoordinates)
  const pressedCoordinatesPrev = usePrevious<typeof pressedCoordinates>(pressedCoordinates)

  const handleKeyPressed = useCallback(() => {
    if(pressedCoordinatesRef.current.x !== 0 || pressedCoordinatesRef.current.y !== 0) {
      onMove(pressedCoordinatesRef.current)

      requestAnimationFrame(handleKeyPressed)
    }
  }, [])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if(event.keyCode === KEYCODE.LEFT && !keysStateRef.current.left) {
      setKeysState(state => ({ ...state, left: true }))
    }
    if(event.keyCode === KEYCODE.UP && !keysStateRef.current.up) {
      setKeysState(state => ({ ...state, up: true }))
    }
    if(event.keyCode === KEYCODE.RIGHT && !keysStateRef.current.right) {
      setKeysState(state => ({ ...state, right: true }))
    }
    if(event.keyCode === KEYCODE.DOWN && !keysStateRef.current.down) {
      setKeysState(state => ({ ...state, down: true }))
    }
  }, [])

  useEffect(() => {
    if(pressedCoordinatesPrev.x === 0 && pressedCoordinatesPrev.y === 0) {
      requestAnimationFrame(handleKeyPressed)
    }
  }, [pressedCoordinates])

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if(event.keyCode === KEYCODE.LEFT && keysStateRef.current.left) {
      setKeysState(state => ({ ...state, left: false }))
    }
    if(event.keyCode === KEYCODE.UP && keysStateRef.current.up) {
      setKeysState(state => ({ ...state, up: false }))
    }
    if(event.keyCode === KEYCODE.RIGHT && keysStateRef.current.right) {
      setKeysState(state => ({ ...state, right: false }))
    }
    if(event.keyCode === KEYCODE.DOWN && keysStateRef.current.down) {
      setKeysState(state => ({ ...state, down: false }))
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])
}
