import { ClickAwayListener, Fade, Popper } from '@mui/material'
import { createContext, memo, MouseEvent, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import styles from './MenuList.module.scss'

interface Props {
  anchorRef: any
  children: ReactNode
  offset?: [x: number, y: number]
  onOpenChange?: (open: boolean) => void

  [key: string]: any
}

interface IMenuListContext {
  closeMenu: () => void
}

const MenuListContext = createContext<IMenuListContext | null>(null)

export const useMenuListContext = () => useContext(MenuListContext)

export const MenuList = memo((props: Props) => {
  const {
    anchorRef,
    children,
    offset = [0, 0],
    modifiers = [],
    onOpenChange,
    ...rest
  } = props

  const [show, setShow] = useState<boolean>(false)
  const [el, setEl] = useState<null | HTMLElement>()

  const onAnchorClick = useCallback((e: MouseEvent<HTMLElement>) => {
    setEl(e.currentTarget)
    setShow(true)
    onOpenChange?.(true)
  }, [onOpenChange])

  const onClose = useCallback(() => {
    if(!show) return

    setShow(false)
    onOpenChange?.(false)
  }, [show, onOpenChange])

  useEffect(() => {
    anchorRef.current?.addEventListener('click', onAnchorClick)

    return () => {
      anchorRef.current?.removeEventListener('click', onAnchorClick)
    }
  }, [anchorRef, onAnchorClick])

  if(!show) return null

  return (
    <MenuListContext.Provider value={{ closeMenu: onClose }}>
      <ClickAwayListener onClickAway={onClose}>
        <Popper
          open={show}
          anchorEl={el}
          className={styles.root}
          placement='top-end'
          transition
          modifiers={[
            { name: 'offset', options: { offset } },
            ...modifiers
          ]}
          {...rest}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <div>
                {children}
              </div>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </MenuListContext.Provider>
  )
})
