export function getSelectedText() {
  let selectedText: string | undefined = ''

  // window.getSelection
  if (window.getSelection) {
    selectedText = window.getSelection()?.toString()
  }
  // document.getSelection
  else if (document.getSelection) {
    selectedText = document.getSelection()?.toString()
  }
  // @ts-ignore
  else if (document.selection) {
    // @ts-ignore
    selectedText = document.selection.createRange().text
  }

  return selectedText
}

export function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {  // Chrome
      window.getSelection()?.empty()
    } else if (window.getSelection()?.removeAllRanges) {  // Firefox
      window.getSelection()?.removeAllRanges()
    }
    // @ts-ignore
  } else if (document.selection) {  // IE?
    // @ts-ignore
    document.selection.empty()
  }
}
