import { ApiClients } from '@roolz/api/clients'
import { Message } from '@roolz/types/api/chats'
import {
  GenerateFileTokenResponse,
  getAvatarRequest,
  getAvatarRequestWithWidth,
  InitFileRequest,
  InitFileResponse,
  UploadAvatarByUrlRequest,
  uploadFileByUrlBody,
  uploadFileMultipartRequest,
  uploadFileResponse
} from '@roolz/types/api/files'
import { AxiosInstance } from 'axios'

export class FileServerApi {
  fileServer: AxiosInstance
  clients: ApiClients

  constructor(clients: ApiClients) {
    this.clients = clients
    this.fileServer = clients.fileServer
  }

  uploadFileMultipart(data: uploadFileMultipartRequest, config?: any) {
    const formData = new FormData()

    formData.append('profile_id', data.profile_id)
    formData.append('file_size', String(data.file_size))
    formData.append('origin_name', String(data.origin_name))
    formData.append('file_mimetype', String(data.file_mimetype))
    formData.append('file_type', String(data.file_type))
    formData.append('is_avatar', String(data.is_avatar))
    formData.append('file', data.file)

    return this.fileServer.post<uploadFileResponse>('file-single', formData)
  }

  uploadAvatarByUrl({
    profileId,
    avatarUrl
  }: UploadAvatarByUrlRequest) {
    return this.fileServer.post<uploadFileResponse, any, uploadFileByUrlBody>('file-link', {
      profile_id: profileId,
      file_url: avatarUrl,
      file_type: 'image',
      is_avatar: true,
      is_public: true
    }).then(response => response.data.url)
  }

  getAvatar({ file_id }: getAvatarRequest) {
    return this.fileServer.get<Blob>(`avatar/${file_id}`)
  }

  getAvatarWithWidth({ file_id, width }: getAvatarRequestWithWidth) {
    return this.fileServer.get<Blob>(`avatar/${file_id}/${width}`)
  }

  getFile({ url }: { url: string }, clientParams?: any) {
    return this.fileServer.get<Blob>(url.replace(/^\/(fileserver\/)?/, ''), {
      responseType: 'arraybuffer',
      ...clientParams
    })
  }

  initFile(data: InitFileRequest) {
    return this.fileServer.post<InitFileResponse>('file', data)
  }

  uploadFileChunk(fileId: string | number, data: Blob) {
    return fetch(this.fileServer.defaults.baseURL + '/file/' + fileId, {
      method: 'PATCH',
      body: data,
      headers: {
        'X-Roolz-Auth': `Bearer ${this.clients.getConfig().accessToken()}`,
        'Content-Type': 'application/octet-stream',
      }
    })
      .then(r => {
        if(!r.status || !r.ok) {
          throw new Error('Network request failed')
        }

        return r.json() as Promise<uploadFileResponse>
      })

    // return this.fileServer.patch<UploadFileChunkResponse>(`file/${fileId}`, data, {
    //   headers: {
    //     'Content-Type': 'application/octet-stream',
    //   }
    // })
  }
  generateFileToken(fileId: string | number, messageId: Message["client_message_id"]) {
    return this.fileServer.get<GenerateFileTokenResponse>('generate/token/' + fileId, {
      headers: {
        'Roolz-Message-ID': messageId
      }
    })
  }

  commitFile(fileId: string | number) {
    return this.fileServer.post<uploadFileResponse>(`file/${fileId}`, {})
  }
}
