interface Props {
  color?: string
  className?: string
}
export const Attachment = ({
  color = '#8E8E93',
  className
}: Props) => {
 return (
   <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <mask id="mask0_14489_143423" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
       <rect width="24" height="24" fill="#D9D9D9"/>
     </mask>
     <g mask="url(#mask0_14489_143423)">
       <path d="M4.94765 12.4066L12.6021 20.061L13.4506 19.2125L5.79617 11.5581C5.00657 10.7685 4.61484 9.81105 4.62096 8.68581C4.62662 7.56009 5.02425 6.60243 5.81385 5.81283C6.61524 5.01144 7.57856 4.61381 8.7038 4.61994C9.82951 4.6256 10.7872 5.02323 11.5768 5.81283L19.2135 13.4496C19.7439 13.9799 20.009 14.6048 20.009 15.3241C20.009 16.0425 19.7497 16.661 19.2312 17.1796C18.7009 17.7099 18.0763 17.9753 17.3574 17.9758C16.6385 17.9753 16.0139 17.7099 15.4835 17.1796L8.58925 10.2853C8.35354 10.0496 8.23852 9.76956 8.24418 9.44524C8.25031 9.12138 8.37122 8.8416 8.60692 8.6059C8.84263 8.3702 9.12241 8.24928 9.44626 8.24316C9.77059 8.2375 10.0506 8.35252 10.2863 8.58822L17.1806 15.4825L18.0114 14.6517L11.1172 7.75737C10.6457 7.28597 10.0862 7.05027 9.43848 7.05027C8.78983 7.05027 8.2298 7.28597 7.7584 7.75737C7.28699 8.22878 7.05129 8.78881 7.05129 9.43746C7.05129 10.0852 7.28699 10.6447 7.7584 11.1161L14.6527 18.0104C15.4069 18.7647 16.3116 19.139 17.3666 19.1333C18.4211 19.1272 19.3196 18.7529 20.0621 18.0104C20.8045 17.268 21.1788 16.3695 21.1849 15.3149C21.1906 14.2599 20.8163 13.3553 20.0621 12.6011L12.4253 4.9643C11.3882 3.92721 10.1451 3.40867 8.69602 3.40867C7.24598 3.40867 6.00241 3.92721 4.96532 4.9643C3.92823 6.00139 3.40686 7.24166 3.4012 8.6851C3.39508 10.129 3.91056 11.3695 4.94765 12.4066Z"
             fill={color}/>
     </g>
   </svg>
 )
}
