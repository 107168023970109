interface Props {
  color?: string
  size?: number
  style?: any
}

export function LockOpen({
  color = '#8E8E93',
  size = 10,
  style
}: Props) {
  return (
    <svg style={style} width={size} height={size * 1.4} viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.2 13.3333C0.866667 13.3333 0.583333 13.2167 0.35 12.9833C0.116667 12.75 0 12.4667 0 12.1333V5.86668C0 5.53334 0.116667 5.25001 0.35 5.01668C0.583333 4.78334 0.866667 4.66668 1.2 4.66668H7V3.33334C7 2.77779 6.80556 2.30557 6.41667 1.91668C6.02778 1.52779 5.55556 1.33334 5 1.33334C4.52222 1.33334 4.10289 1.48334 3.742 1.78334C3.38067 2.08334 3.15 2.45557 3.05 2.90001C3.01667 3.02223 2.95556 3.1249 2.86667 3.20801C2.77778 3.29157 2.67222 3.33334 2.55 3.33334C2.38333 3.33334 2.25289 3.27779 2.15867 3.16668C2.064 3.05557 2.02778 2.93334 2.05 2.80001C2.18333 2.0889 2.52222 1.50001 3.06667 1.03334C3.61111 0.566677 4.25556 0.333344 5 0.333344C5.83333 0.333344 6.54178 0.624899 7.12533 1.20801C7.70844 1.79157 8 2.50001 8 3.33334V4.66668H8.8C9.13333 4.66668 9.41667 4.78334 9.65 5.01668C9.88333 5.25001 10 5.53334 10 5.86668V12.1333C10 12.4667 9.88333 12.75 9.65 12.9833C9.41667 13.2167 9.13333 13.3333 8.8 13.3333H1.2ZM1.2 12.3333H8.8C8.85556 12.3333 8.90289 12.314 8.942 12.2753C8.98067 12.2362 9 12.1889 9 12.1333V5.86668C9 5.81112 8.98067 5.76379 8.942 5.72468C8.90289 5.68601 8.85556 5.66668 8.8 5.66668H1.2C1.14444 5.66668 1.09733 5.68601 1.05867 5.72468C1.01956 5.76379 1 5.81112 1 5.86668V12.1333C1 12.1889 1.01956 12.2362 1.05867 12.2753C1.09733 12.314 1.14444 12.3333 1.2 12.3333ZM5 10.1667C5.32222 10.1667 5.59733 10.0529 5.82533 9.82534C6.05289 9.59734 6.16667 9.32223 6.16667 9.00001C6.16667 8.67779 6.05289 8.40268 5.82533 8.17468C5.59733 7.94712 5.32222 7.83334 5 7.83334C4.67778 7.83334 4.40267 7.94712 4.17467 8.17468C3.94711 8.40268 3.83333 8.67779 3.83333 9.00001C3.83333 9.32223 3.94711 9.59734 4.17467 9.82534C4.40267 10.0529 4.67778 10.1667 5 10.1667Z'
        fill={color}
      />
    </svg>
  )
}
