import { LinkProps } from '@mui/material'
import { Api } from '@roolz/api/index'
import type { tFunc, tFuncParams, TransProps } from '@roolz/sdk/types'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { CompanyForm, ExchangeRole } from '@roolz/types/api/knowledge/companies'
import { Currency } from '@roolz/types/api/knowledge/exchange'
import { UserCountry } from '@roolz/types/custom'
import { ComponentType, createContext, FC, HTMLAttributes, ReactNode, useContext, useEffect } from 'react'

// TODO: Trans component on 'static'
export interface SdkContextInterface {
  useTranslation: (ns?: string) => {
    t: tFunc,
    lang: string
  },

  Trans: ComponentType<TransProps>
  lang: string

  AppLink: FC<{
    to: string
    children: ReactNode
  } & HTMLAttributes<HTMLElement>>

  isRU?: boolean

  knowledge: {
    supportedCurrencies: Currency[]
    userCountry: UserCountry | null
    supportedCompanyForms: CompanyForm[]
    supportedExchangeRoles: ExchangeRole[]
  }
  getServerDatetime?: () => Date
  api?: Api
}

const SdkContext = createContext<SdkContextInterface>({} as SdkContextInterface)

interface SdkProviderProps {
  children: ReactNode
  value: SdkContextInterface
}

const SdkProvider = ({ children, value }: SdkProviderProps) => {
  useEffect(() => {
    const lang = value.lang.replace(/-.*$/, '')

    dayjs.locale(lang)
  }, [value.lang])

  return (
    <SdkContext.Provider value={value}>
      {children}
    </SdkContext.Provider>
  )
}

const useSdkContext = () => {
  return useContext(SdkContext)
}

const useSdkTranslation = (ns?: string) => {
  const ctx = useSdkContext()

  return ctx.useTranslation(ns)

  // const t = typeof ns === 'string'
  //   ? (key: string, params?: tFuncParams) => {
  //     if(key.indexOf(':') !== -1) {
  //       return ctx.t(key, params)
  //     }
  //
  //     return ctx.t(`${ns}:${key}`, params)
  //   }
  //   : ctx.t
  //
  // return {
  //   t,
  //   lang: ctx.lang
  // }
}

export {
  SdkContext,
  SdkProvider,
  useSdkContext,

  useSdkTranslation
}
