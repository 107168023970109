import { SECOND_MS } from '@/utils/date'
import { OnlineStatus } from '@roolz/types/custom'

export const MAX_PINNED_CHATS_COUNT = 5

export const MAX_COMPANIES_COUNT = 9

export const CHAT_MESSAGES_PER_BUNCH = 50
export const MAX_MESSAGE_LENGTH = 8200
export const MAX_FILES_FOR_MESSAGE_COUNT = 15


export const ONLINE_LAST_ACTION_TIME_MINS = 3
export const ONLINE_RECENTLY_LAST_ACTION_TIME_MINS = 30

// 5 mins
export const OFFERS_AUTOUPDATE_INTERVAL_SEC = 5 * 60 * SECOND_MS

export const MAX_COUNT_COMPANIES = 9
