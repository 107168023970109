import { persistAndSyncStore } from '@/utils/mobx'
import { makeAutoObservable } from 'mobx'
import { UserCompany } from '@roolz/types/api/profiles'
import { Company, CompanyInternalInfo } from '@roolz/types/api/companies'

class MyCompaniesStore {
  // TODO !Replace any by UserCompany
  companies: any[] = []
  currentCompanyInternalInfo: CompanyInternalInfo | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get companyIds() {
    return this.companies.map(item => item.id)
  }

  find(id: Company["id"]): UserCompany | undefined {
    return this.companies.find(item => item.id === id)
  }
}

const myCompaniesStore = persistAndSyncStore(new MyCompaniesStore(), 'my_companies')

export {
  myCompaniesStore,
  MyCompaniesStore
}
