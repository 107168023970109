import { HollowDots } from '@roolz/sdk/components/ui/Spinners/HollowDots/HollowDots'
import { Fade } from '@mui/material'
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress'
import cn from 'classnames'
import { ReactNode, useLayoutEffect, useState } from 'react'
import styles from './Loadable.module.scss'

interface Props extends CircularProgressProps {
  loading: boolean
  delay?: number
  className?: string
  loadingContentClassname?: string
  children?: ReactNode

  dontHideContent?: boolean
  exit?: boolean
}

export function Loadable({
  loading,
  children,
  delay = 0,
  color = 'primary',
  className,
  loadingContentClassname,
  dontHideContent,
  exit = true,
  ...rest
}: Props) {
  // const [isLoading, setIsLoading] = useState<boolean>(false)

  // useLayoutEffect(() => {
  //   if(delay > 0) {
  //     if(loading) {
  //       setTimeout(() => {
  //         setIsLoading(true)
  //       }, delay)
  //     } else {
  //       setIsLoading(false)
  //     }
  //   } else {
  //     setIsLoading(loading)
  //   }
  // }, [loading])

  return (
    <div
      className={cn(styles.wrapper, className)}
      style={loading ? rest.style : {}}
    >
      <Fade
        in={!loading || dontHideContent}
        appear={false}
        enter={!dontHideContent}
        exit={!dontHideContent && exit}
      >
        <div
          className={cn(styles.content, {
            [styles.contentHidden]: !dontHideContent && loading,
            [loadingContentClassname ?? '']: loading
          })}
        >
          {children}
        </div>
      </Fade>

      {loading && (
        <Fade in={loading}>
          <div className={styles.spinner}>
            {/*<Rhombuses/>*/}
            <HollowDots/>
          </div>
        </Fade>
      )}
    </div>
  )
}
