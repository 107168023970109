export function setGlobalCssVariable(name: string , value: string) {
  // @ts-ignore
  return document.querySelector(':root').style.setProperty(name, value)
}

export function getGlobalCssVariable(name: string) {
  // @ts-ignore
  return getComputedStyle(document.querySelector(':root')).getPropertyValue(name)

}
