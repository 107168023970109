import { FileMessagePreview } from '@/components/chats/ui/messagePreviews/FileMessagePreview/FileMessagePreview'
import { isFileMessage, isTextMessage, MessageModel } from '@/types/models/chat'
import { ReactNode, useMemo, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './BoundedMessagePreview.module.scss'
import cn from 'classnames'
interface Props {
  message: MessageModel
  title: ReactNode

  className?: string

  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}
export const BoundedMessagePreview = ({
  title,
  message,
  className,
  onClick
}: Props) => {
  const { t } = useTranslation('chat/common')
  const { t: messageT } = useTranslation('chat/message')

  const content: string | ReactNode = useMemo(() => {
    if(isTextMessage(message)) {
      return message.decodedContent.content.text
    }

    if(isFileMessage(message)) {
      return <FileMessagePreview message={message}/>
    }

    // TODO add other types
    return messageT('unsupported_content')
  }, [message])

 return (
   <div
     className={cn(styles.preview, className)}
     onClick={onClick}
   >
     <div className={styles.title}>
       {title}
     </div>

     <div
       className={styles.messageContent}
       title={typeof content === 'string' ? content : ''}
     >
       {content}
     </div>
   </div>
 )
}
