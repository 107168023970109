interface Props {
  className?: string
  size?: number
}

export const Error = ({
  className,
  size = 10
}: Props) => {
  return (
    <svg
      className={className}
      width={size} height={size} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 7.3625C5.11667 7.3625 5.2125 7.325 5.2875 7.25C5.3625 7.175 5.4 7.07917 5.4 6.9625C5.4 6.84583 5.3625 6.75 5.2875 6.675C5.2125 6.6 5.11667 6.5625 5 6.5625C4.88333 6.5625 4.7875 6.6 4.7125 6.675C4.6375 6.75 4.6 6.84583 4.6 6.9625C4.6 7.07917 4.6375 7.175 4.7125 7.25C4.7875 7.325 4.88333 7.3625 5 7.3625ZM4.625 5.5375H5.375V2.5375H4.625V5.5375ZM5 9.75C4.34167 9.75 3.723 9.625 3.144 9.375C2.56467 9.125 2.0625 8.7875 1.6375 8.3625C1.2125 7.9375 0.875 7.43533 0.625 6.856C0.375 6.277 0.25 5.65833 0.25 5C0.25 4.34167 0.375 3.72283 0.625 3.1435C0.875 2.5645 1.2125 2.0625 1.6375 1.6375C2.0625 1.2125 2.56467 0.875 3.144 0.625C3.723 0.375 4.34167 0.25 5 0.25C5.65833 0.25 6.27717 0.375 6.8565 0.625C7.4355 0.875 7.9375 1.2125 8.3625 1.6375C8.7875 2.0625 9.125 2.5645 9.375 3.1435C9.625 3.72283 9.75 4.34167 9.75 5C9.75 5.65833 9.625 6.277 9.375 6.856C9.125 7.43533 8.7875 7.9375 8.3625 8.3625C7.9375 8.7875 7.4355 9.125 6.8565 9.375C6.27717 9.625 5.65833 9.75 5 9.75Z'
        fill='#E23834'
      />
    </svg>
  )
}
