export function Rocket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="56"
      fill="none"
      viewBox="0 0 46 56"
    >
      <g clipPath="url(#clip0_21617_155783)">
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#FDC172"
                  d="M19.12 41.705c-.034-4.016-3.978-7.474-7.812-6.726-2.698.527-4.746 2.41-5.494 5.028L1.567 54.874c-.2.701.722 1.397 1.347 1.02L15.7 48.157c2.168-1.31 3.442-3.697 3.42-6.453z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint0_linear_21617_155783)"
                  d="M19.12 41.705c-.034-4.016-3.978-7.474-7.812-6.726-2.698.527-4.746 2.41-5.494 5.028L1.567 54.874c-.2.701.722 1.397 1.347 1.02L15.7 48.157c2.168-1.31 3.442-3.697 3.42-6.453z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint1_linear_21617_155783)"
                  d="M19.12 41.705c-.034-4.016-3.978-7.474-7.812-6.726-2.698.527-4.746 2.41-5.494 5.028L1.567 54.874c-.2.701.722 1.397 1.347 1.02L15.7 48.157c2.168-1.31 3.442-3.697 3.42-6.453z"
                ></path>
                <path
                  fill="#FD7C68"
                  d="M16.885 42.151c-.044-2.719-2.729-5.034-5.315-4.526-1.819.357-3.205 1.62-3.72 3.383l-2.982 10.18c-.143.488.485.976.916.715l8.787-5.337c1.482-.9 2.344-2.536 2.314-4.415z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint2_linear_21617_155783)"
                  d="M16.885 42.151c-.044-2.719-2.729-5.034-5.315-4.526-1.819.357-3.205 1.62-3.72 3.383l-2.982 10.18c-.143.488.485.976.916.715l8.787-5.337c1.482-.9 2.344-2.536 2.314-4.415z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint3_linear_21617_155783)"
                  d="M16.885 42.151c-.044-2.719-2.729-5.034-5.315-4.526-1.819.357-3.205 1.62-3.72 3.383l-2.982 10.18c-.143.488.485.976.916.715l8.787-5.337c1.482-.9 2.344-2.536 2.314-4.415z"
                ></path>
              </g>
              <g>
                <g>
                  <path
                    fill="#A49EFF"
                    d="M13.312 26.007s-9.4-4.805-13.14-.561c-.444.503.104 1.128.355 1.342 2.249 1.924 10.552 5.928 10.552 5.928l2.233-6.71v.001z"
                  ></path>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#paint4_linear_21617_155783)"
                    d="M13.316 26.002c-.045-.023-4.57-2.312-8.546-2.312-2.028 0-3.576.589-4.601 1.752-.123.14-.18.3-.167.477.023.334.296.68.523.874.002 0 .003.002.005.003.08.083.168.169.267.259a8.825 8.825 0 00.566.466c.128.098.267.2.414.305.723.517 1.677 1.114 2.834 1.776a92.854 92.854 0 004.41 2.342c1.067.533 1.801.879 1.808.883l.006.002.065-.194.178.087.006.003 2.236-6.72-.004-.002v-.001z"
                  ></path>
                </g>
                <path
                  fill="#6767E5"
                  d="M20.823 41.194c-.88 1.702-1.861 2.9-2.907 3.04-4.059.541-8.653-3.719-9.425-6.627-.58-2.191 1.161-3.854 2.055-4.54.681 2.676 2.977 7.239 10.277 8.127z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint5_radial_21617_155783)"
                  d="M20.823 41.194c-.88 1.702-1.861 2.9-2.907 3.04-4.059.541-8.653-3.719-9.425-6.627-.58-2.191 1.161-3.854 2.055-4.54.681 2.676 2.977 7.239 10.277 8.127z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint6_radial_21617_155783)"
                  d="M20.823 41.194c-.88 1.702-1.861 2.9-2.907 3.04-4.059.541-8.653-3.719-9.425-6.627-.58-2.191 1.161-3.854 2.055-4.54.681 2.676 2.977 7.239 10.277 8.127z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint7_linear_21617_155783)"
                  d="M20.823 41.194c-.88 1.702-1.861 2.9-2.907 3.04-4.059.541-8.653-3.719-9.425-6.627-.58-2.191 1.161-3.854 2.055-4.54.681 2.676 2.977 7.239 10.277 8.127z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint8_radial_21617_155783)"
                  d="M20.811 41.879c-1.269 1.444-4.633.531-7.513-2.04-2.88-2.57-4.188-5.826-2.918-7.272 1.269-1.444 4.633-.531 7.514 2.04 2.88 2.57 4.187 5.826 2.917 7.272z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint9_linear_21617_155783)"
                  d="M33.914 44.294c-.44-.02-1.032-.39-1.929-.952l-.005-.004a36.106 36.106 0 00-2.954-1.703 30.288 30.288 0 00-1.598-.755 32.985 32.985 0 00-2.04-.811c-.226-.082-.318-.235-.27-.455.062-.296.376-.72.933-1.261.506-.492 1.082-.99 1.712-1.479.75-.583 1.409-1.021 1.762-1.257l.029-.02c.022-.014.343-.239.627-.437.24-.167 1.093-2.392 1.103-2.399l.043-.028.003.002c.003.002-.308 2.31.173 2.704.93.762 2.604 2.32 3.364 4.19.523 1.285.536 2.518.039 3.662a2.407 2.407 0 01-.52.757c-.168.16-.327.246-.458.246h-.014z"
                ></path>
                <path
                  fill="#7A77E6"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint10_linear_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint11_linear_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint12_radial_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#paint13_linear_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint14_linear_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint15_linear_21617_155783)"
                  d="M28.623 38.354c-3.016 1.964-5.58 3.021-6.763 2.939a20.484 20.484 0 01-1.035-.099c-7.3-.888-9.595-5.45-10.277-8.127-.264-1.034-.286-1.787-.264-1.937.32-2.143.83-4.197 1.49-6.149-.466 2.235 1.179 6.223 4.335 9.306 1.36 1.328 3 2.488 4.874 3.267 2.24.932 4.814 1.32 7.64.8z"
                ></path>
                <g>
                  <mask
                    id="mask0_21617_155783"
                    style={{ maskType: "luminance" }}
                    width="33"
                    height="34"
                    x="11"
                    y="5"
                    maskUnits="userSpaceOnUse"
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M43.417 20.818c-3.334 6.844-9.967 14.392-14.795 17.536-3.005.554-5.724.08-8.062-.983a14.402 14.402 0 01-1.31-.68c-5.157-3.006-8.079-8.822-7.477-11.71C14.24 17.695 19.49 9.724 24.5 5.748c-5.412 6.321 12.725 22.655 18.917 15.07z"
                      ></path>
                    </g>
                  </mask>
                  <g mask="url(#mask0_21617_155783)">
                    <g>
                      <path
                        fill="#C4C6F2"
                        d="M43.417 20.818c-3.334 6.844-9.967 14.392-14.795 17.536-3.005.554-5.724.08-8.062-.983a14.402 14.402 0 01-1.31-.68c-5.157-3.006-8.079-8.822-7.477-11.71C14.24 17.695 19.49 9.724 24.5 5.748c-5.412 6.321 12.725 22.655 18.917 15.07z"
                      ></path>
                    </g>
                  </g>
                </g>
                <path
                  fill="#8F8DF2"
                  d="M45.963 10.359c0 3.724-.98 7.243-2.547 10.459-6.192 7.585-24.33-8.749-18.917-15.07C30.172 1.248 36.4-.904 40.6.356c4.727 1.416 5.362 5.829 5.362 10.003z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint16_linear_21617_155783)"
                  d="M43.417 20.818c-3.334 6.844-9.967 14.392-14.795 17.536-3.005.554-5.724.08-8.062-.983a14.402 14.402 0 01-1.31-.68c-5.157-3.006-8.079-8.822-7.477-11.71C14.24 17.695 19.49 9.724 24.5 5.748c-5.412 6.321 12.725 22.655 18.917 15.07z"
                ></path>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#paint17_linear_21617_155783)"
                  d="M43.417 20.818c-3.334 6.844-9.967 14.392-14.795 17.536-3.005.554-5.724.08-8.062-.983a14.402 14.402 0 01-1.31-.68c-5.157-3.006-8.079-8.822-7.477-11.71C14.24 17.695 19.49 9.724 24.5 5.748c-5.412 6.321 12.725 22.655 18.917 15.07z"
                ></path>
                <g style={{ mixBlendMode: "screen" }}>
                  <g>
                    <mask
                      id="mask1_21617_155783"
                      style={{ maskType: "luminance" }}
                      width="33"
                      height="34"
                      x="11"
                      y="5"
                      maskUnits="userSpaceOnUse"
                    >
                      <g>
                        <path
                          fill="#fff"
                          d="M43.417 20.818c-3.334 6.844-9.967 14.392-14.795 17.536-3.005.554-5.724.08-8.062-.983a14.402 14.402 0 01-1.31-.68c-5.157-3.006-8.079-8.822-7.477-11.71C14.24 17.695 19.49 9.724 24.5 5.748c-5.412 6.321 12.725 22.655 18.917 15.07z"
                        ></path>
                      </g>
                    </mask>
                    <g mask="url(#mask1_21617_155783)">
                      <g>
                        <path
                          fill="#A6A6A6"
                          d="M43.417 5.748H11.172v33.16h32.245V5.747z"
                        ></path>
                        <path
                          fill="#A6A6A6"
                          d="M36.008 32.63a72.94 72.94 0 01-6.832 6.077c-.232.181-.464.36-.696.536v-.282H11.118V6.571h-.294l.335-.33c.223-.22.447-.438.672-.653v.106h31.641v17.94h.118a68.248 68.248 0 01-2.656 3.506 73.088 73.088 0 01-4.926 5.49z"
                        ></path>
                        <path
                          fill="#A1A1A1"
                          d="M35.995 32.615a72.87 72.87 0 01-6.824 6.072c-.232.18-.463.358-.695.534v-.26H11.12V6.587h-.28l.334-.33c.224-.22.447-.438.672-.653v.09h31.628V23.63h.095a68.253 68.253 0 01-2.653 3.502 72.907 72.907 0 01-4.92 5.483z"
                        ></path>
                        <path
                          fill="#9C9C9C"
                          d="M35.978 32.6a72.811 72.811 0 01-5.433 4.956 69.577 69.577 0 01-2.078 1.643v-.237h-17.35V6.602h-.265c.11-.111.222-.221.333-.331.223-.22.447-.437.671-.651v.073h31.615v17.933h.072a68.108 68.108 0 01-2.65 3.498 72.871 72.871 0 01-4.916 5.476z"
                        ></path>
                        <path
                          fill="#989898"
                          d="M35.965 32.586a72.743 72.743 0 01-6.809 6.057c-.23.18-.462.358-.693.534v-.215H11.119V6.616h-.252a63.13 63.13 0 011.003-.98v.057h31.603v17.928h.049a66.38 66.38 0 01-2.647 3.495 72.746 72.746 0 01-4.91 5.47z"
                        ></path>
                        <path
                          fill="#939393"
                          d="M35.952 32.572a72.575 72.575 0 01-6.8 6.05c-.231.18-.462.358-.694.533v-.193H11.121V6.631h-.238a100.651 100.651 0 011.003-.98v.041h31.588v17.925h.026a67.792 67.792 0 01-2.644 3.49 72.775 72.775 0 01-4.904 5.465z"
                        ></path>
                        <path
                          fill="#8E8E8E"
                          d="M35.936 32.557a72.517 72.517 0 01-6.794 6.043c-.23.18-.46.358-.691.533v-.171H11.119V6.646h-.224a98.854 98.854 0 011.001-.979v.026h31.576v17.92h.003a67.563 67.563 0 01-2.641 3.487 72.677 72.677 0 01-4.898 5.457z"
                        ></path>
                        <path
                          fill="#8A8A8A"
                          d="M35.922 32.542a72.567 72.567 0 01-6.785 6.037c-.23.18-.46.357-.691.532v-.15H11.12v-32.3h-.21a62.725 62.725 0 011-.977v.01h31.563v17.565h.226l-.245.35a67.123 67.123 0 01-2.638 3.483 72.538 72.538 0 01-4.894 5.45z"
                        ></path>
                        <path
                          fill="#868686"
                          d="M35.906 32.528a72.51 72.51 0 01-5.402 4.927 68.874 68.874 0 01-2.066 1.634v-.127h-17.32V6.676h-.196a98.223 98.223 0 01.999-.976l.335-.319v.312H43.47v17.562h.202l-.245.349a67.675 67.675 0 01-2.635 3.478 72.372 72.372 0 01-4.888 5.445z"
                        ></path>
                        <path
                          fill="#828282"
                          d="M35.893 32.513a72.383 72.383 0 01-5.395 4.922 70.187 70.187 0 01-2.064 1.632v-.105H11.119V6.69h-.181a100.674 100.674 0 01.997-.975l.335-.32v.297h31.203v17.558h.178a71.805 71.805 0 01-.744 1.046 67.963 67.963 0 01-2.132 2.777 72.433 72.433 0 01-4.882 5.44z"
                        ></path>
                        <path
                          fill="#7E7E7E"
                          d="M35.88 32.499a72.21 72.21 0 01-5.39 4.915 66.487 66.487 0 01-2.061 1.63v-.083H11.12V6.706h-.168l.331-.328a71.191 71.191 0 011-.964v.279h31.19v17.554h.156l-.245.348a67.38 67.38 0 01-2.629 3.47A72.477 72.477 0 0135.88 32.5z"
                        ></path>
                        <path
                          fill="#7A7A7A"
                          d="M35.863 32.484a72.043 72.043 0 01-6.755 6.009c-.23.179-.459.355-.688.53v-.061H11.118V6.72h-.153c.11-.11.22-.22.33-.327a77.68 77.68 0 011-.964v.263h31.177v17.202h.373a67.73 67.73 0 01-3.11 4.163 72.31 72.31 0 01-4.871 5.427z"
                        ></path>
                        <path
                          fill="#767676"
                          d="M35.85 32.47a72.143 72.143 0 01-5.377 4.905 70.095 70.095 0 01-2.057 1.627v-.04H11.12V6.737h-.14l.33-.328a77.406 77.406 0 01.998-.962v.247h31.165v17.198h.35a67.571 67.571 0 01-3.107 4.158 72.193 72.193 0 01-4.866 5.421z"
                        ></path>
                        <path
                          fill="#727272"
                          d="M35.834 32.455a71.933 71.933 0 01-6.74 5.995c-.229.179-.457.355-.686.529v-.017h-17.29V6.75h-.126l.33-.327a84.513 84.513 0 01.996-.962v.231h31.153v17.194h.326a65.747 65.747 0 01-3.104 4.153 71.995 71.995 0 01-4.86 5.415z"
                        ></path>
                        <path
                          fill="#6E6E6E"
                          d="M35.82 32.44a71.77 71.77 0 01-6.73 5.989 64.652 64.652 0 01-1.03.786v-.254H11.12V6.766h-.112a88.574 88.574 0 01.992-.97l.333-.316v.213h31.14v17.191h.302a66.854 66.854 0 01-3.1 4.148 72.126 72.126 0 01-4.854 5.409z"
                        ></path>
                        <path
                          fill="#6B6B6B"
                          d="M35.804 32.426a71.701 71.701 0 01-6.724 5.982 65.43 65.43 0 01-1.027.786v-.232H11.117V6.78h-.098a64.462 64.462 0 01.991-.968l.333-.317v.198H43.47v17.188h.278a67.569 67.569 0 01-3.097 4.144 71.87 71.87 0 01-4.848 5.401z"
                        ></path>
                        <path
                          fill="#676767"
                          d="M35.791 32.412a71.738 71.738 0 01-5.352 4.882 67.296 67.296 0 01-2.391 1.877v-.21h-16.93V6.796h-.083a65.517 65.517 0 01.99-.967l.332-.316v.181h31.115v17.184h.255a67.05 67.05 0 01-3.093 4.139 71.941 71.941 0 01-4.842 5.395h-.001z"
                        ></path>
                        <path
                          fill="#646464"
                          d="M35.779 32.397a71.71 71.71 0 01-6.709 5.968 67.073 67.073 0 01-1.025.785v-.187H11.12V6.81h-.07a98.786 98.786 0 01.99-.966l.331-.315v.164h31.103v17.18h.231a67.18 67.18 0 01-3.09 4.134 71.785 71.785 0 01-4.837 5.39z"
                        ></path>
                        <path
                          fill="#616161"
                          d="M35.762 32.383a71.652 71.652 0 01-5.34 4.87 66.635 66.635 0 01-2.044 1.616l-.342.258v-.165H11.118V6.825h-.056a95.15 95.15 0 01.988-.965c.11-.106.22-.21.332-.315v.148h31.09V22.87h.207A66.956 66.956 0 0140.593 27a71.713 71.713 0 01-4.831 5.383z"
                        ></path>
                        <path
                          fill="#5D5D5D"
                          d="M35.747 32.368a71.504 71.504 0 01-6.693 5.954 66.8 66.8 0 01-1.023.783v-.142H11.118V7.167h-.368a74.993 74.993 0 011.644-1.606v.131h31.078v17.174h.184c-.316.46-.64.92-.974 1.379a67.468 67.468 0 01-2.108 2.745 71.698 71.698 0 01-4.826 5.378z"
                        ></path>
                        <path
                          fill="#5A5A5A"
                          d="M35.735 32.354a71.3 71.3 0 01-5.328 4.86c-.903.742-1.81 1.45-2.72 2.122v-.374H11.118V7.182h-.353a72.69 72.69 0 011.311-1.29l.33-.315v.116h31.066v17.17h.16a66.88 66.88 0 01-3.08 4.12 71.619 71.619 0 01-4.819 5.37z"
                        ></path>
                        <path
                          fill="#575757"
                          d="M35.721 32.339a71.378 71.378 0 01-5.321 4.854 66.363 66.363 0 01-2.718 2.12v-.35H11.12V7.195h-.339c.434-.437.871-.867 1.31-1.288l.33-.314v.099h31.053v17.166h.137a67.121 67.121 0 01-3.075 4.115 71.462 71.462 0 01-4.815 5.364v.001z"
                        ></path>
                        <path
                          fill="#545454"
                          d="M35.704 32.325a71.317 71.317 0 01-5.315 4.848c-.9.74-1.806 1.447-2.715 2.117v-.328H11.118V7.211h-.325c.433-.437.87-.866 1.309-1.287l.33-.314v.082h31.04v17.163h.113a66.838 66.838 0 01-3.072 4.11 71.376 71.376 0 01-4.809 5.359z"
                        ></path>
                        <path
                          fill="#515151"
                          d="M35.69 32.31a71.177 71.177 0 01-5.309 4.843c-.9.74-1.804 1.445-2.711 2.115v-.306H11.119V7.226h-.31a74.314 74.314 0 011.966-1.91v.376h30.697v17.16h.09a66.42 66.42 0 01-3.068 4.106 71.341 71.341 0 01-4.804 5.352z"
                        ></path>
                        <path
                          fill="#4F4F4F"
                          d="M35.678 32.295a71.212 71.212 0 01-5.303 4.837 66.062 66.062 0 01-2.708 2.113v-.283H11.12V7.24h-.296a72.475 72.475 0 011.965-1.907v.36h30.684v17.155h.067a64.697 64.697 0 01-3.065 4.1 71.411 71.411 0 01-4.798 5.347z"
                        ></path>
                        <path
                          fill="#4C4C4C"
                          d="M35.66 32.28a70.822 70.822 0 01-5.297 4.832c-.897.738-1.8 1.442-2.704 2.11v-.26H11.117V7.255h-.281a72.336 72.336 0 011.963-1.905v.343H43.47v17.152h.043a64.304 64.304 0 01-3.061 4.096 71.116 71.116 0 01-4.793 5.34z"
                        ></path>
                        <path
                          fill="#494949"
                          d="M35.647 32.266a70.895 70.895 0 01-5.29 4.827 65.904 65.904 0 01-2.702 2.107v-.238H11.118V7.27h-.266a71.8 71.8 0 011.96-1.903v.327h30.66v17.148h.02a64.024 64.024 0 01-3.057 4.091 71.085 71.085 0 01-4.788 5.334z"
                        ></path>
                        <path
                          fill="#474747"
                          d="M35.634 32.252a70.815 70.815 0 01-5.285 4.82 66.222 66.222 0 01-2.698 2.106v-.216H11.119V7.284h-.252a73.6 73.6 0 011.958-1.9v.31h30.648v16.802h.23a59.161 59.161 0 01-1.2 1.708 66.598 66.598 0 01-2.088 2.72 71.028 71.028 0 01-4.782 5.328z"
                        ></path>
                        <path
                          fill="#444"
                          d="M35.62 32.237a70.757 70.757 0 01-5.278 4.815 65.538 65.538 0 01-2.695 2.103v-.193H11.12V7.298h-.238A71.81 71.81 0 0112.838 5.4v.293h30.636v16.8h.206l-.233.34a63.49 63.49 0 01-3.05 4.083 70.817 70.817 0 01-4.776 5.321z"
                        ></path>
                        <path
                          fill="#424242"
                          d="M35.603 32.222a70.628 70.628 0 01-5.272 4.81 65.451 65.451 0 01-2.692 2.1v-.17H11.118V7.313h-.223a71.607 71.607 0 011.953-1.896v.276h30.624v16.796h.181c-.077.114-.154.228-.233.341a63.702 63.702 0 01-3.046 4.077 70.763 70.763 0 01-4.77 5.315z"
                        ></path>
                        <path
                          fill="#3F3F3F"
                          d="M35.59 32.208a70.591 70.591 0 01-5.266 4.803 65.71 65.71 0 01-2.69 2.098v-.147H11.12V7.328h-.209a71.108 71.108 0 011.95-1.894v.26h30.613v16.792h.158l-.233.341a65.893 65.893 0 01-3.043 4.072 70.723 70.723 0 01-4.764 5.31v-.001z"
                        ></path>
                        <path
                          fill="#3D3D3D"
                          d="M35.577 32.193a70.411 70.411 0 01-5.26 4.799 65.68 65.68 0 01-2.686 2.095v-.125H11.12V7.342h-.195a72.377 72.377 0 011.948-1.892v.243h30.6v16.45h.363a59.992 59.992 0 01-1.423 2.04 65.997 65.997 0 01-2.078 2.708 70.609 70.609 0 01-4.76 5.303z"
                        ></path>
                        <path
                          fill="#3B3B3B"
                          d="M35.56 32.179a70.357 70.357 0 01-5.254 4.792c-.89.732-1.786 1.43-2.683 2.093v-.102H11.118V7.357h-.18a69.144 69.144 0 011.945-1.89v.226h30.588V22.14h.339a60.987 60.987 0 01-1.42 2.038 65.842 65.842 0 01-2.077 2.705 70.397 70.397 0 01-4.754 5.296z"
                        ></path>
                        <path
                          fill="#393939"
                          d="M35.547 32.164a70.267 70.267 0 01-5.248 4.787 64.86 64.86 0 01-2.68 2.09v-.08h-16.5V7.372h-.166a72.263 72.263 0 011.944-1.887v.209h30.576v16.444h.314a66.017 66.017 0 01-1.418 2.036 66.289 66.289 0 01-2.074 2.702 70.458 70.458 0 01-4.748 5.29z"
                        ></path>
                        <path
                          fill="#373737"
                          d="M35.533 32.15a70.177 70.177 0 01-5.241 4.78 65.836 65.836 0 01-2.677 2.089v-.057H11.12V7.386h-.151A70.137 70.137 0 0112.91 5.5v.193h30.564v16.442h.29a58.69 58.69 0 01-1.418 2.032 65.192 65.192 0 01-2.07 2.698 70.403 70.403 0 01-4.743 5.284z"
                        ></path>
                        <path
                          fill="#353535"
                          d="M35.516 32.135a70.2 70.2 0 01-5.235 4.775c-.887.73-1.78 1.425-2.673 2.086v-.034h-16.49V7.4h-.138a69.969 69.969 0 011.94-1.882v.175h30.55v16.439h.267a59.437 59.437 0 01-1.415 2.03 65.541 65.541 0 01-2.07 2.695 70.245 70.245 0 01-4.736 5.278z"
                        ></path>
                        <path
                          fill="#333"
                          d="M35.503 32.12a70.1 70.1 0 01-5.229 4.77 65.658 65.658 0 01-2.67 2.083v-.011H11.12V7.415h-.123a72.101 72.101 0 011.937-1.88v.158h30.54V22.13h.242a66.65 66.65 0 01-1.414 2.028c-.655.9-1.344 1.798-2.067 2.692a70.184 70.184 0 01-4.731 5.271z"
                        ></path>
                        <path
                          fill="#313131"
                          d="M35.49 32.106a70.07 70.07 0 01-5.223 4.764 64.99 64.99 0 01-3.001 2.326v-.234H11.12V7.43h-.108a70.167 70.167 0 011.934-1.88v.143h30.528v16.433h.218a63.363 63.363 0 01-3.476 4.714 70.03 70.03 0 01-4.726 5.265v.001z"
                        ></path>
                        <path
                          fill="#2F2F2F"
                          d="M35.473 32.091a69.991 69.991 0 01-5.217 4.759 65.737 65.737 0 01-2.998 2.323v-.211h-16.14V7.444h-.095a75.362 75.362 0 011.933-1.876v.125h30.515v16.43h.194a62.863 62.863 0 01-3.472 4.709 69.939 69.939 0 01-4.72 5.26z"
                        ></path>
                        <path
                          fill="#2D2D2D"
                          d="M35.46 32.077a69.846 69.846 0 01-5.211 4.753 65.066 65.066 0 01-2.995 2.32v-.188H11.118V7.459h-.079a72.935 72.935 0 011.93-1.875v.109h30.503v16.428h.17a60.439 60.439 0 01-1.408 2.02 65.18 65.18 0 01-2.06 2.682 69.901 69.901 0 01-4.714 5.253z"
                        ></path>
                        <path
                          fill="#2B2B2B"
                          d="M35.446 32.062a69.697 69.697 0 01-5.205 4.748 64.565 64.565 0 01-2.99 2.317v-.166H11.12V7.473h-.065a73.1 73.1 0 011.928-1.872v.092h30.49v16.425h.146a62.072 62.072 0 01-1.407 2.019 65.108 65.108 0 01-2.056 2.679 69.97 69.97 0 01-4.71 5.247z"
                        ></path>
                        <path
                          fill="#2A2A2A"
                          d="M35.429 32.048a69.44 69.44 0 01-5.199 4.742 65.02 65.02 0 01-2.654 2.07c-.111.083-.222.164-.333.245v-.143H11.117V7.488h-.05a71.25 71.25 0 011.925-1.87v.075h30.479v16.422h.121a59.776 59.776 0 01-1.405 2.016 64.747 64.747 0 01-2.054 2.676 69.99 69.99 0 01-4.704 5.241z"
                        ></path>
                        <path
                          fill="#282828"
                          d="M35.416 32.034a69.597 69.597 0 01-5.193 4.736 64.341 64.341 0 01-3.317 2.553v-.36H11.118V7.502h-.036a71.077 71.077 0 011.923-1.868v.058h30.467v16.42h.097a56.328 56.328 0 01-1.403 2.013 64.745 64.745 0 01-2.052 2.673 69.784 69.784 0 01-4.698 5.235z"
                        ></path>
                        <path
                          fill="#262626"
                          d="M35.403 32.019a69.41 69.41 0 01-5.187 4.73 64.639 64.639 0 01-3.313 2.55v-.337H11.12V7.516h-.021a70.571 70.571 0 011.92-1.865v.042h30.455V22.11h.073a58.18 58.18 0 01-1.401 2.01 64.94 64.94 0 01-2.05 2.67 69.703 69.703 0 01-4.692 5.23v-.001z"
                        ></path>
                        <path
                          fill="#252525"
                          d="M35.39 32.005a69.32 69.32 0 01-5.18 4.724 64.46 64.46 0 01-3.31 2.547v-.314H11.12V7.531h-.007a70.108 70.108 0 011.92-1.863v.025h30.441v16.414h.05a63.513 63.513 0 01-3.447 4.675 69.627 69.627 0 01-4.687 5.223z"
                        ></path>
                        <path
                          fill="#232323"
                          d="M35.374 31.99a69.28 69.28 0 01-5.174 4.719 64.32 64.32 0 01-3.305 2.544v-.29H11.12V7.864h-.307l.314-.319a72.057 72.057 0 011.917-1.86v.007h30.43v16.41h.025A65.183 65.183 0 0142.1 24.11c-.647.892-1.329 1.78-2.044 2.664a69.46 69.46 0 01-4.681 5.216z"
                        ></path>
                        <path
                          fill="#222"
                          d="M35.358 31.975a69.287 69.287 0 01-5.168 4.714 64.56 64.56 0 01-3.301 2.541v-.268H11.117V7.88h-.293l.315-.32a71.813 71.813 0 012.237-2.158v.291H43.47v16.408h.001a63.489 63.489 0 01-1.397 2.005 64.562 64.562 0 01-2.042 2.66 69.4 69.4 0 01-4.675 5.21z"
                        ></path>
                        <path
                          fill="#212121"
                          d="M35.344 31.96a69.215 69.215 0 01-5.162 4.709 64.61 64.61 0 01-3.297 2.538v-.245H11.118V7.894h-.278l.314-.32a70.238 70.238 0 012.234-2.155v.273h30.084v16.073h.198a68.775 68.775 0 01-.674 1.001 64.846 64.846 0 01-2.983 3.991 69.353 69.353 0 01-4.67 5.204z"
                        ></path>
                        <path
                          fill="#1F1F1F"
                          d="M35.33 31.946a69.138 69.138 0 01-5.156 4.703 64.565 64.565 0 01-3.293 2.535v-.222H11.12V7.908h-.264l.314-.319a71.799 71.799 0 011.91-1.854l.322-.299v.257h30.071v16.07h.174a68.518 68.518 0 01-.672 1 64.33 64.33 0 01-2.98 3.987 69.203 69.203 0 01-4.664 5.196z"
                        ></path>
                        <path
                          fill="#1E1E1E"
                          d="M35.317 31.932a68.969 68.969 0 01-5.15 4.696 64.154 64.154 0 01-3.289 2.532v-.198H11.12V7.922h-.249l.314-.318a69.535 69.535 0 012.228-2.15v.239h30.06v15.734h.367c-2.3 3.55-5.163 7.117-8.523 10.505z"
                        ></path>
                        <path
                          fill="#1D1D1D"
                          d="M35.303 31.917a68.802 68.802 0 01-5.143 4.691 64.32 64.32 0 01-3.285 2.529v-.175H11.12V7.936h-.233l.313-.318a71.1 71.1 0 012.226-2.147v.222h30.048v15.732h.342c-2.298 3.546-5.157 7.109-8.513 10.492z"
                        ></path>
                        <path
                          fill="#1C1C1C"
                          d="M35.285 31.903a68.634 68.634 0 01-5.136 4.685 64.27 64.27 0 01-3.282 2.526v-.152h-15.75V7.95h-.219l.313-.317a69.256 69.256 0 012.223-2.145v.205h30.037v15.73h.317c-2.295 3.542-5.151 7.1-8.503 10.48z"
                        ></path>
                        <path
                          fill="#1A1A1A"
                          d="M35.272 31.888a68.727 68.727 0 01-5.131 4.68 64.206 64.206 0 01-3.277 2.523v-.129H11.118V7.964h-.204l.312-.317a69.053 69.053 0 012.22-2.142v.188h30.026v15.728h.292c-2.292 3.538-5.145 7.092-8.493 10.467z"
                        ></path>
                        <path
                          fill="#191919"
                          d="M35.258 31.873a68.747 68.747 0 01-5.124 4.675 63.952 63.952 0 01-3.274 2.52v-.106H11.12V7.98h-.19a70.946 70.946 0 012.53-2.456v.17h30.013V21.42h.268c-2.29 3.534-5.14 7.084-8.483 10.454z"
                        ></path>
                        <path
                          fill="#181818"
                          d="M35.245 31.859a68.614 68.614 0 01-5.12 4.669 63.454 63.454 0 01-3.269 2.516v-.082H11.12V7.992h-.175l.312-.315a70.84 70.84 0 012.215-2.138v.153h30.001v15.725h.244c-2.287 3.53-5.133 7.075-8.472 10.442z"
                        ></path>
                        <path
                          fill="#171717"
                          d="M35.23 31.844a68.427 68.427 0 01-5.112 4.663 64.013 64.013 0 01-3.265 2.514v-.06H11.12V8.008h-.16l.312-.316a70.33 70.33 0 012.212-2.134v.136h29.99v15.722h.219c-2.284 3.525-5.127 7.067-8.462 10.43z"
                        ></path>
                        <path
                          fill="#161616"
                          d="M35.214 31.83a68.508 68.508 0 01-5.107 4.657 63.835 63.835 0 01-3.261 2.511v-.036H11.118V8.02h-.145l.31-.315a72.21 72.21 0 012.21-2.132v.12h29.978v15.719h.195c-2.282 3.52-5.12 7.058-8.452 10.417z"
                        ></path>
                        <path
                          fill="#151515"
                          d="M35.2 31.815a68.339 68.339 0 01-5.1 4.652 63.78 63.78 0 01-3.259 2.508v-.013H11.118V8.036h-.13l.31-.315a70.313 70.313 0 011.89-1.835l.318-.295v.102h29.966v15.718h.17c-2.28 3.516-5.115 7.05-8.442 10.404z"
                        ></path>
                        <path
                          fill="#141414"
                          d="M35.186 31.8a68.15 68.15 0 01-5.094 4.647 63.568 63.568 0 01-3.254 2.505l-.326.233v-.223H11.119V8.05h-.115l.31-.315a69.435 69.435 0 012.523-2.42v.378h29.636v15.716h.145c-2.276 3.513-5.109 7.041-8.432 10.392z"
                        ></path>
                        <path
                          fill="#131313"
                          d="M35.173 31.786a68.115 68.115 0 01-5.088 4.64 63.583 63.583 0 01-3.576 2.735v-.2H11.12V8.065h-.1a69.26 69.26 0 012.83-2.73v.36h29.624v15.713h.12c-2.273 3.508-5.102 7.033-8.421 10.379z"
                        ></path>
                        <path
                          fill="#121212"
                          d="M35.159 31.771a68.134 68.134 0 01-5.082 4.635 62.903 62.903 0 01-3.571 2.732v-.176H11.12V8.078h-.086l.31-.314a69.55 69.55 0 012.517-2.413v.342h29.612v15.712h.096c-2.27 3.504-5.096 7.024-8.411 10.366z"
                        ></path>
                        <path
                          fill="#121212"
                          d="M35.142 31.757a68.124 68.124 0 01-5.075 4.63 63.408 63.408 0 01-3.893 2.956v-.381H11.12V8.407h-.377a70.304 70.304 0 013.129-3.039v.325h29.601v15.71h.072c-2.268 3.5-5.09 7.016-8.401 10.354z"
                        ></path>
                        <path
                          fill="#111"
                          d="M35.129 31.742a67.808 67.808 0 01-5.07 4.624 62.572 62.572 0 01-3.888 2.953v-.357H11.12V8.42h-.362a69.197 69.197 0 013.125-3.035v.307h29.59v15.708h.047c-2.265 3.495-5.084 7.007-8.391 10.341z"
                        ></path>
                        <path
                          fill="#101010"
                          d="M35.115 31.728a67.888 67.888 0 01-5.063 4.618 62.954 62.954 0 01-3.884 2.95v-.334H11.12V8.434h-.347a69.826 69.826 0 013.122-3.03v.289h29.579V21.4h.022c-2.263 3.49-5.078 6.998-8.381 10.328z"
                        ></path>
                        <path
                          fill="#0F0F0F"
                          d="M35.1 31.713a67.81 67.81 0 01-5.056 4.613 62.73 62.73 0 01-3.88 2.946v-.31H11.12V8.448h-.33a69.957 69.957 0 013.117-3.027v.272h29.567v15.378h.207c-.07.108-.139.218-.21.326-2.259 3.487-5.07 6.99-8.37 10.316z"
                        ></path>
                        <path
                          fill="#0E0E0E"
                          d="M35.083 31.699a67.602 67.602 0 01-5.05 4.606 62.573 62.573 0 01-3.875 2.943v-.286h-15.04v-30.5H10.8a69.6 69.6 0 013.113-3.024v.255h29.557v15.376h.182l-.21.326c-2.256 3.482-5.065 6.98-8.36 10.304z"
                        ></path>
                        <path
                          fill="#0E0E0E"
                          d="M35.069 31.684a67.616 67.616 0 01-5.045 4.602 62.956 62.956 0 01-3.87 2.938v-.262H11.118V8.475h-.3a69.613 69.613 0 013.11-3.02v.237H43.47v15.05h.364c-.136.216-.275.434-.416.651-2.254 3.479-5.058 6.973-8.35 10.291z"
                        ></path>
                        <path
                          fill="#0D0D0D"
                          d="M35.055 31.67a67.536 67.536 0 01-5.038 4.595 63.13 63.13 0 01-3.864 2.935v-.238H11.117V8.489h-.286a69.258 69.258 0 013.106-3.016v.22H43.47V20.74h.34c-.137.217-.276.434-.416.652-2.25 3.474-5.053 6.964-8.34 10.278z"
                        ></path>
                        <path
                          fill="#0C0C0C"
                          d="M35.041 31.655a67.35 67.35 0 01-5.032 4.59 62.515 62.515 0 01-3.86 2.931v-.214H11.12V8.503h-.271a67.929 67.929 0 013.102-3.012v.202h29.522v15.046h.314c-.136.217-.275.433-.416.65-2.248 3.47-5.046 6.956-8.33 10.266h.001z"
                        ></path>
                        <path
                          fill="#0C0C0C"
                          d="M35.027 31.64A67.32 67.32 0 0130 36.225a62.445 62.445 0 01-3.855 2.928v-.191H11.119V8.517h-.256a67.584 67.584 0 013.099-3.009v.185h29.51v15.045h.29c-.137.216-.275.433-.415.65-2.246 3.465-5.04 6.947-8.32 10.253z"
                        ></path>
                        <path
                          fill="#0B0B0B"
                          d="M35.013 31.626a67.17 67.17 0 01-5.02 4.578 63.027 63.027 0 01-3.85 2.925v-.167H11.119V8.53h-.24a68.913 68.913 0 013.095-3.005v.168h29.499v15.044h.264c-.136.216-.274.432-.414.648-2.243 3.462-5.034 6.94-8.31 10.241z"
                        ></path>
                        <path
                          fill="#0B0B0B"
                          d="M34.999 31.612a67.16 67.16 0 01-5.014 4.573 62.496 62.496 0 01-3.846 2.92v-.143H11.12V8.544h-.224a68.555 68.555 0 013.091-3.001v.15h29.487v15.043h.239a55.59 55.59 0 01-.414.647c-2.24 3.457-5.028 6.93-8.299 10.229z"
                        ></path>
                        <path
                          fill="#0A0A0A"
                          d="M34.985 31.597a67.051 67.051 0 01-5.008 4.568 62.427 62.427 0 01-3.84 2.917v-.12H11.12V8.558h-.21a68.557 68.557 0 013.087-2.998v.133h29.477v15.041h.213c-.136.215-.273.431-.413.647-2.238 3.453-5.022 6.922-8.29 10.216z"
                        ></path>
                        <path
                          fill="#0A0A0A"
                          d="M34.971 31.583a66.993 66.993 0 01-5.001 4.562 62.295 62.295 0 01-3.837 2.913v-.096H11.121V8.572h-.195a68.668 68.668 0 013.083-2.994v.114h29.465v15.041h.189c-.135.215-.273.43-.412.646-2.235 3.449-5.016 6.914-8.28 10.203z"
                        ></path>
                        <path
                          fill="#090909"
                          d="M34.953 31.568a66.824 66.824 0 01-4.996 4.556 62.138 62.138 0 01-3.831 2.91v-.072H11.117V8.586h-.18a68.216 68.216 0 013.08-2.99v.097H43.47v15.039h.163c-.135.215-.273.43-.412.646-2.232 3.444-5.01 6.904-8.269 10.19z"
                        ></path>
                        <path
                          fill="#090909"
                          d="M34.939 31.553a66.905 66.905 0 01-4.99 4.552 61.894 61.894 0 01-3.826 2.906v-.049H11.118V8.6h-.165a67.932 67.932 0 013.076-2.987v.08H43.47V20.73h.138c-.135.215-.272.43-.411.645-2.23 3.44-5.003 6.896-8.258 10.178h-.001z"
                        ></path>
                        <path
                          fill="#080808"
                          d="M34.925 31.539a66.716 66.716 0 01-4.983 4.545 61.46 61.46 0 01-3.822 2.903v-.025H11.118V8.613h-.15c.2-.206.402-.411.604-.616a67.757 67.757 0 012.469-2.367v.063h29.43v15.036h.114c-.135.215-.272.43-.412.645-2.226 3.435-4.997 6.887-8.248 10.165z"
                        ></path>
                        <path
                          fill="#080808"
                          d="M34.911 31.524a66.699 66.699 0 01-4.976 4.54 62.015 62.015 0 01-3.817 2.9v-.002h-15V8.627h-.134a68.054 68.054 0 013.068-2.98v.046h29.42v15.035h.088c-.135.215-.272.429-.41.643-2.224 3.432-4.992 6.88-8.24 10.153h.002z"
                        ></path>
                        <path
                          fill="#070707"
                          d="M34.898 31.51a66.642 66.642 0 01-4.97 4.534 62.383 62.383 0 01-3.814 2.895c-.106.075-.212.148-.318.221v-.198H11.119V8.642H11a67.467 67.467 0 013.065-2.976v.027h29.408v15.034h.062c-.134.214-.271.428-.41.642-2.221 3.428-4.984 6.871-8.228 10.14z"
                        ></path>
                        <path
                          fill="#070707"
                          d="M34.883 31.495a66.475 66.475 0 01-4.964 4.528 61.853 61.853 0 01-3.808 2.892l-.318.221v-.174H11.119V8.655h-.103a67.16 67.16 0 013.06-2.972v.01h29.397v15.033h.038c-.135.214-.271.427-.41.641-2.218 3.423-4.978 6.862-8.218 10.128z"
                        ></path>
                        <path
                          fill="#060606"
                          d="M34.87 31.48a66.317 66.317 0 01-4.959 4.524 61.995 61.995 0 01-3.803 2.889c-.212.148-.424.293-.636.437v-.368H11.12V8.67h-.089A67.156 67.156 0 0114.088 5.7l.311-.282v.274h29.075v15.032h.012c-.134.213-.27.427-.41.64-2.215 3.42-4.971 6.854-8.207 10.116z"
                        ></path>
                        <path
                          fill="#060606"
                          d="M34.855 31.466a66.475 66.475 0 01-4.952 4.517 61.843 61.843 0 01-3.798 2.885 63.9 63.9 0 01-.636.438v-.344H11.12V8.682h-.073a66.982 66.982 0 013.363-3.245v.256h29.064v14.71h.187a55.904 55.904 0 01-.609.96c-2.213 3.415-4.966 6.846-8.197 10.103z"
                        ></path>
                        <path
                          fill="#060606"
                          d="M34.842 31.451a66.288 66.288 0 01-4.946 4.512 61.25 61.25 0 01-3.794 2.881c-.211.148-.423.294-.634.437v-.32H11.12V8.697h-.059a67.666 67.666 0 013.359-3.241v.238h29.053v14.39h.358c-.26.426-.527.852-.804 1.279-2.21 3.41-4.96 6.836-8.187 10.09z"
                        ></path>
                        <path
                          fill="#050505"
                          d="M34.823 31.437a66.227 66.227 0 01-4.94 4.506 62.144 62.144 0 01-3.788 2.878c-.211.147-.423.293-.634.436v-.295H11.117V8.71h-.043a68.058 68.058 0 013.355-3.238v.221h29.042v14.39h.332c-.26.425-.527.85-.803 1.276-2.207 3.406-4.954 6.828-8.177 10.078z"
                        ></path>
                        <path
                          fill="#050505"
                          d="M34.81 31.422a66.213 66.213 0 01-4.934 4.5 61.912 61.912 0 01-3.785 2.875c-.21.147-.422.292-.632.435v-.27H11.118V8.724h-.028a67.763 67.763 0 013.35-3.233v.202h29.031v14.39h.306c-.258.425-.526.85-.801 1.275-2.205 3.402-4.948 6.82-8.167 10.065z"
                        ></path>
                        <path
                          fill="#050505"
                          d="M34.796 31.408a66.024 66.024 0 01-4.927 4.494 61.23 61.23 0 01-3.78 2.87c-.21.148-.421.293-.632.436v-.246H11.118V8.737h-.013a66.252 66.252 0 013.038-2.95l.308-.28v.186h29.021v14.39h.28c-.258.424-.525.848-.8 1.273-2.202 3.397-4.942 6.81-8.157 10.052z"
                        ></path>
                        <path
                          fill="#050505"
                          d="M34.783 31.393a65.895 65.895 0 01-4.922 4.489 61.155 61.155 0 01-4.406 3.301v-.222H11.12V9.057h-.292a83.305 83.305 0 01.89-.915 67.4 67.4 0 012.746-2.617v.167h29.01v14.39h.254c-.258.424-.524.847-.8 1.272-2.198 3.393-4.935 6.802-8.145 10.04z"
                        ></path>
                        <path
                          fill="#040404"
                          d="M34.768 31.379a65.803 65.803 0 01-4.915 4.483 61.991 61.991 0 01-3.77 2.863c-.21.147-.42.292-.631.434v-.197H11.12V9.07h-.276l.293-.305a65.791 65.791 0 013.338-3.221v.149h28.998v14.389h.23c-.258.423-.524.846-.799 1.27-2.196 3.389-4.929 6.793-8.136 10.027z"
                        ></path>
                        <path
                          fill="#040404"
                          d="M34.754 31.364a65.548 65.548 0 01-4.91 4.478 61.3 61.3 0 01-3.765 2.86c-.21.146-.42.29-.63.433v-.173H11.12V9.084h-.26l.293-.306a66.585 66.585 0 013.334-3.217v.132h28.987v14.389h.203c-.258.422-.523.845-.797 1.268-2.194 3.385-4.924 6.785-8.127 10.015z"
                        ></path>
                        <path
                          fill="#040404"
                          d="M34.74 31.35a65.685 65.685 0 01-4.903 4.471 61.544 61.544 0 01-3.76 2.857c-.21.146-.42.29-.63.433v-.15H11.12V9.097h-.245l.293-.304a67.364 67.364 0 013.33-3.214v.114h28.976v14.388h.178c-.257.422-.522.844-.797 1.267-2.19 3.38-4.916 6.777-8.115 10.002z"
                        ></path>
                        <path
                          fill="#040404"
                          d="M34.725 31.335a65.516 65.516 0 01-4.896 4.466 61.228 61.228 0 01-3.756 2.853c-.21.146-.42.29-.628.432v-.125H11.12V9.111h-.23a81.633 81.633 0 01.887-.91 66.695 66.695 0 013.04-2.878v.37h28.657v14.388h.152c-.256.421-.522.843-.795 1.265-2.188 3.376-4.91 6.768-8.106 9.99v-.001z"
                        ></path>
                        <path
                          fill="#030303"
                          d="M34.71 31.32a65.582 65.582 0 01-4.89 4.462 61.239 61.239 0 01-3.751 2.849c-.21.146-.419.29-.628.432v-.1H11.12V9.122h-.214a91.268 91.268 0 01.885-.908 66.08 66.08 0 013.036-2.874v.352h28.647V20.08h.126a54.5 54.5 0 01-.794 1.263c-2.185 3.372-4.905 6.76-8.095 9.977z"
                        ></path>
                        <path
                          fill="#030303"
                          d="M34.696 31.306a65.541 65.541 0 01-4.884 4.455 61.458 61.458 0 01-3.747 2.846c-.209.146-.418.29-.626.431v-.076H11.12V9.137h-.198a74.669 74.669 0 01.884-.908 66.281 66.281 0 013.032-2.871v.334h28.636V20.08h.1c-.255.42-.52.84-.792 1.262-2.183 3.368-4.899 6.751-8.086 9.964z"
                        ></path>
                        <path
                          fill="#030303"
                          d="M34.682 31.291a65.374 65.374 0 01-4.878 4.45 61.302 61.302 0 01-3.742 2.841c-.209.146-.417.29-.626.431v-.052H11.12V9.151h-.182l.291-.303a66.187 66.187 0 013.62-3.471v.316h28.625V20.08h.075c-.255.419-.52.84-.792 1.26-2.18 3.363-4.892 6.742-8.075 9.951z"
                        ></path>
                        <path
                          fill="#030303"
                          d="M34.668 31.277a65.316 65.316 0 01-4.872 4.444 60.816 60.816 0 01-3.738 2.838c-.208.146-.416.289-.625.43v-.027H11.12V9.165h-.167a74.813 74.813 0 01.882-.906 66.887 66.887 0 013.024-2.864v.298h28.615v14.386h.05c-.255.42-.52.839-.791 1.259-2.177 3.359-4.886 6.734-8.065 9.939z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.653 31.262a65.167 65.167 0 01-4.865 4.438 60.318 60.318 0 01-4.358 3.265v-.003H11.12V9.178h-.151a81.273 81.273 0 01.88-.905 66.298 66.298 0 013.02-2.86v.28h28.605v14.386h.024c-.255.418-.518.837-.79 1.256-2.174 3.355-4.88 6.727-8.054 9.927z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.639 31.248a65.12 65.12 0 01-4.86 4.433 60.048 60.048 0 01-4.351 3.26l-.312.21v-.19H11.121V9.192h-.137a80.48 80.48 0 01.88-.904 66.178 66.178 0 013.016-2.856v.261h28.594v13.76h.374a51.331 51.331 0 01-1.164 1.881c-2.172 3.351-4.874 6.718-8.045 9.915z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.625 31.233c-3.167 3.193-6.503 5.914-9.822 8.1v-.37H11.12V9.204H11l.29-.302a65.57 65.57 0 012.992-2.906c.202-.184.406-.367.609-.548v.244h28.583v13.76h.349a54.053 54.053 0 01-1.163 1.88c-2.169 3.346-4.868 6.708-8.035 9.9z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.61 31.219c-3.163 3.188-6.494 5.906-9.81 8.09v-.347H11.12V9.218h-.104a80.696 80.696 0 01.877-.9 66.199 66.199 0 013.008-2.85v.225h28.573v13.76h.322a55.146 55.146 0 01-1.162 1.877c-2.166 3.342-4.861 6.7-8.024 9.889z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.593 31.204c-3.159 3.184-6.486 5.898-9.797 8.079v-.321H11.118V9.533h-.376a66.113 66.113 0 014.168-4.047v.207H43.47v13.76h.297a54.163 54.163 0 01-1.16 1.875c-2.164 3.338-4.856 6.692-8.015 9.876z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.579 31.19c-3.155 3.18-6.478 5.89-9.785 8.069v-.297H11.118V9.546h-.36a66.564 66.564 0 014.162-4.042v.19h28.552v13.76h.27a54.858 54.858 0 01-1.16 1.872c-2.16 3.333-4.848 6.683-8.003 9.863z"
                        ></path>
                        <path
                          fill="#020202"
                          d="M34.564 31.175c-3.151 3.177-6.47 5.884-9.772 8.059v-.272H11.118V9.56h-.345a66.358 66.358 0 014.157-4.037v.171h28.541v13.76h.244a49.997 49.997 0 01-1.158 1.871c-2.158 3.33-4.843 6.675-7.994 9.851z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.55 31.16c-3.147 3.173-6.461 5.876-9.76 8.049v-.247H11.117V9.572h-.328a65.537 65.537 0 014.152-4.031v.152h28.53v13.761h.218a49.847 49.847 0 01-1.157 1.868c-2.155 3.325-4.836 6.666-7.983 9.839z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.538 31.146c-3.142 3.168-6.452 5.868-9.747 8.038v-.222h-13.67V9.585h-.312a65.702 65.702 0 014.146-4.026v.134h28.52v13.762h.19a53.092 53.092 0 01-1.155 1.865c-2.151 3.321-4.83 6.658-7.972 9.826z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.523 31.132c-3.138 3.164-6.444 5.86-9.734 8.027v-.197H11.12V9.598h-.296a65.83 65.83 0 014.141-4.021v.117h28.51v13.76h.165a51.514 51.514 0 01-1.153 1.863c-2.15 3.318-4.825 6.65-7.964 9.815z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.51 31.117c-3.135 3.16-6.437 5.853-9.722 8.017v-.172H11.12V9.612h-.281a65.757 65.757 0 014.136-4.017v.098h28.498v13.762h.14a52.5 52.5 0 01-1.152 1.86c-2.147 3.313-4.818 6.642-7.953 9.801v.001z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.495 31.102c-3.131 3.157-6.428 5.846-9.71 8.007v-.147H11.12V9.624h-.264a65.712 65.712 0 014.13-4.011v.08h28.488v13.763h.113a53.19 53.19 0 01-1.15 1.858c-2.144 3.308-4.812 6.632-7.943 9.788z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.48 31.088c-3.127 3.152-6.42 5.838-9.697 7.996v-.122H11.12V9.637h-.249a65.009 65.009 0 013.524-3.465c.2-.182.4-.362.601-.54v.061h28.477v13.763h.088a53.05 53.05 0 01-1.149 1.856c-2.141 3.304-4.806 6.624-7.932 9.776z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.465 31.073c-3.123 3.148-6.411 5.831-9.685 7.986v-.097H11.12V9.65h-.233a66.609 66.609 0 013.52-3.46c.199-.183.4-.363.6-.54v.043h28.466v13.763h.061a52.938 52.938 0 01-1.147 1.854c-2.139 3.3-4.8 6.615-7.922 9.763z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.45 31.059c-3.118 3.143-6.403 5.823-9.672 7.976v-.073H11.119V9.663h-.217a66.779 66.779 0 013.515-3.456c.2-.182.4-.362.6-.54v.026h28.456v13.764h.035a52.78 52.78 0 01-1.146 1.85c-2.135 3.296-4.793 6.607-7.912 9.751z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.436 31.044c-3.115 3.14-6.395 5.816-9.66 7.966v-.048H11.119V9.676h-.201a66.214 66.214 0 013.51-3.451c.2-.182.399-.361.599-.54v.008h28.446v13.15h.365c-.467.818-.967 1.64-1.5 2.463-2.133 3.291-4.788 6.598-7.902 9.738z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.421 31.03c-3.11 3.136-6.387 5.808-9.647 7.955v-.023H11.119V9.689h-.185a66.073 66.073 0 013.505-3.447 63.907 63.907 0 01.898-.803v.254h28.135v13.152h.34a51.226 51.226 0 01-1.5 2.46c-2.13 3.286-4.78 6.59-7.891 9.725z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.406 31.015c-3.106 3.132-6.378 5.8-9.634 7.945a56.69 56.69 0 01-.305.2v-.198H11.119V9.702h-.17a64.247 64.247 0 013.502-3.442 70.759 70.759 0 01.896-.802v.235h28.125v13.153h.312a51.418 51.418 0 01-1.497 2.457c-2.127 3.282-4.774 6.58-7.88 9.713v-.001z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.391 31c-3.102 3.128-6.37 5.794-9.622 7.936-.203.133-.406.266-.61.395v-.37H11.12V9.716h-.154a64.59 64.59 0 014.393-4.24v.217h28.114v13.154h.286a51.698 51.698 0 01-1.495 2.454c-2.125 3.278-4.769 6.572-7.872 9.7z"
                        ></path>
                        <path
                          fill="#010101"
                          d="M34.377 30.986c-3.1 3.124-6.362 5.786-9.61 7.925-.203.133-.406.265-.61.395v-.344h-13.04V9.728h-.137a65.92 65.92 0 013.493-3.434 61.1 61.1 0 01.894-.8v.2h28.104v13.154h.26a51.165 51.165 0 01-1.494 2.45c-2.122 3.274-4.762 6.564-7.86 9.688z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.362 30.971c-3.095 3.12-6.354 5.779-9.598 7.915-.203.133-.405.264-.608.394v-.318H11.117V9.742h-.12a65.747 65.747 0 014.082-3.965l.298-.264v.18h28.094v13.156h.233a50.77 50.77 0 01-1.492 2.447c-2.119 3.27-4.756 6.556-7.85 9.675z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.347 30.957c-3.09 3.115-6.345 5.77-9.585 7.904-.202.133-.405.265-.607.394v-.293H11.117V9.754h-.105a64.606 64.606 0 014.375-4.223v.162H43.47V18.85h.206c-.463.812-.96 1.628-1.49 2.444-2.116 3.266-4.75 6.547-7.84 9.663z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.333 30.942c-3.087 3.112-6.337 5.763-9.573 7.894-.202.133-.405.265-.607.394v-.268H11.118V9.767h-.09a64.421 64.421 0 014.37-4.217v.143h28.074v13.159h.18c-.464.81-.96 1.625-1.488 2.44-2.114 3.262-4.744 6.54-7.83 9.65z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.322 30.928c-3.083 3.107-6.33 5.756-9.56 7.883-.202.133-.404.264-.606.393v-.242H11.121V9.78h-.074a65.203 65.203 0 014.066-3.95l.298-.262v.125h28.063v13.16h.154a51.13 51.13 0 01-1.486 2.437c-2.11 3.257-4.738 6.53-7.82 9.638z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.307 30.913c-3.078 3.103-6.32 5.748-9.548 7.873-.201.133-.403.264-.604.393v-.217H11.12V9.793h-.057a64.5 64.5 0 014.656-4.466v.366h27.755v13.161h.126a50.652 50.652 0 01-1.483 2.434c-2.108 3.253-4.732 6.522-7.81 9.625z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.292 30.898c-3.075 3.1-6.312 5.741-9.535 7.863-.201.133-.403.264-.604.392v-.192H11.12V9.807h-.042a63.809 63.809 0 014.65-4.46v.346h27.746v13.162h.1a50.6 50.6 0 01-1.482 2.432c-2.106 3.248-4.726 6.513-7.8 9.612z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.277 30.884c-3.07 3.095-6.304 5.733-9.522 7.853-.202.133-.403.263-.604.391v-.166H11.12V9.819h-.026a64.605 64.605 0 014.644-4.454v.328h27.735v13.163h.074c-.46.806-.955 1.617-1.48 2.428-2.102 3.245-4.72 6.505-7.79 9.6z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.263 30.87c-3.066 3.09-6.296 5.725-9.51 7.842-.201.132-.402.263-.603.39v-.14H11.12V9.832h-.01a64.408 64.408 0 014.638-4.448v.31h27.725v13.164h.047c-.46.805-.952 1.615-1.477 2.425-2.1 3.24-4.713 6.496-7.78 9.587z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.246 30.855c-3.062 3.087-6.288 5.718-9.498 7.832-.2.133-.402.263-.602.39v-.115H11.117V10.14h-.27A67.738 67.738 0 0112.25 8.68a64.197 64.197 0 013.505-3.277v.29H43.47v13.166h.02c-.46.804-.952 1.612-1.476 2.421-2.097 3.237-4.707 6.488-7.77 9.575z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.234 30.84c-3.058 3.084-6.279 5.71-9.485 7.823l-.6.39v-.091H11.12v-28.81h-.253l.275-.293a64.19 64.19 0 014.626-4.438v.271h27.706v12.566h.331a50.7 50.7 0 01-1.812 3.02c-2.094 3.231-4.7 6.479-7.759 9.562z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.22 30.826c-3.055 3.079-6.271 5.703-9.474 7.812-.2.131-.4.261-.6.389v-.065H11.12V10.164h-.237l.274-.292a63.708 63.708 0 014.62-4.431v.252h27.696V18.26h.305a49.565 49.565 0 01-1.81 3.016c-2.091 3.228-4.694 6.47-7.749 9.55z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.204 30.812c-3.05 3.075-6.263 5.695-9.46 7.801-.2.132-.4.261-.6.389v-.04H11.12V10.177h-.22a63.54 63.54 0 014.888-4.718v.233h27.685v12.57h.278a50.246 50.246 0 01-1.807 3.012c-2.089 3.223-4.688 6.462-7.738 9.537z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.19 30.797c-3.047 3.071-6.255 5.688-9.448 7.791-.2.132-.4.26-.599.388v-.014H11.118V10.189h-.204l.274-.291a63.73 63.73 0 014.607-4.42v.214h27.677v12.572h.25a49.999 49.999 0 01-1.805 3.008c-2.085 3.219-4.681 6.453-7.728 9.524v.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.174 30.782c-3.042 3.067-6.246 5.681-9.435 7.781-.4.263-.798.517-1.195.763v-.364H11.117v-28.76h-.187a63.592 63.592 0 014.875-4.705v.196H43.47v12.574h.224a50.269 50.269 0 01-1.802 3.004c-2.084 3.215-4.676 6.445-7.718 9.512h-.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.163 30.768c-3.039 3.063-6.238 5.673-9.423 7.77-.399.262-.796.517-1.193.763v-.339H11.12V10.215h-.17c.09-.097.18-.194.272-.29a65.109 65.109 0 013.425-3.368c.388-.355.778-.701 1.17-1.04v.176h27.657v12.575h.197a50.136 50.136 0 01-1.8 3c-2.08 3.21-4.67 6.437-7.708 9.5z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.148 30.753c-3.035 3.06-6.23 5.666-9.41 7.76-.398.262-.796.516-1.192.762v-.313H11.12V10.227h-.155l.272-.29a63.18 63.18 0 014.59-4.402v.158h27.647V18.27h.17a50.404 50.404 0 01-1.797 2.997c-2.079 3.206-4.665 6.427-7.7 9.486z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.133 30.739c-3.03 3.055-6.222 5.658-9.398 7.75-.397.262-.794.515-1.19.76v-.287H11.119V10.24h-.139l.272-.29a64.752 64.752 0 013.416-3.359c.387-.353.776-.699 1.167-1.037v.14h27.638v12.578h.142a49.748 49.748 0 01-1.795 2.992c-2.075 3.203-4.657 6.42-7.688 9.475h.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.118 30.724c-3.026 3.05-6.213 5.651-9.385 7.74-.397.261-.793.514-1.189.76v-.262H11.12v-28.71h-.123c.09-.096.18-.193.272-.289a64.222 64.222 0 013.41-3.354c.388-.353.776-.698 1.167-1.036v.12h27.627v12.58h.116a47.406 47.406 0 01-1.793 2.99c-2.073 3.197-4.651 6.41-7.677 9.46v.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.102 30.71c-3.022 3.046-6.205 5.643-9.373 7.73-.396.26-.791.513-1.187.758v-.236H11.117V10.556h-.375a64.049 64.049 0 013.946-3.93c.387-.352.775-.697 1.165-1.034v.101h27.618v12.583h.088a49.834 49.834 0 01-1.79 2.984c-2.07 3.194-4.645 6.403-7.667 9.45z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.09 30.695c-3.018 3.043-6.196 5.636-9.36 7.72-.395.26-.79.512-1.185.757v-.21H11.12V10.568h-.358a62.939 62.939 0 015.103-4.957v.083h27.609v12.585h.061a49.538 49.538 0 01-1.788 2.98c-2.066 3.19-4.638 6.393-7.656 9.436z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.075 30.68c-3.014 3.04-6.188 5.629-9.348 7.71-.395.26-.79.512-1.184.756v-.184H11.12V10.58h-.343a62.29 62.29 0 013.937-3.919c.385-.351.772-.695 1.16-1.032v.064h27.6v11.996h.354a49.42 49.42 0 01-2.107 3.568c-2.063 3.185-4.632 6.385-7.646 9.423z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.06 30.666c-3.01 3.034-6.18 5.62-9.336 7.698-.394.26-.788.512-1.182.756v-.158H11.119v-28.37h-.326a62.654 62.654 0 011.646-1.722 62.54 62.54 0 013.445-3.221v.045h27.588v11.998h.328a49.364 49.364 0 01-2.103 3.563c-2.062 3.18-4.627 6.377-7.637 9.412v-.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.044 30.651c-3.006 3.03-6.172 5.614-9.323 7.689-.394.26-.788.51-1.181.754v-.132H11.117V10.604h-.308a63.379 63.379 0 015.084-4.938v.027H43.47v12h.3c-.21.394-.427.787-.652 1.182a49.97 49.97 0 01-1.449 2.377c-2.058 3.177-4.62 6.369-7.626 9.4z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.033 30.637c-3.003 3.027-6.164 5.605-9.311 7.678-.394.26-.787.51-1.18.753v-.106H11.12V10.617h-.292a62.306 62.306 0 011.642-1.718 62.64 62.64 0 013.435-3.213v.008h27.569v12.004h.272a46.74 46.74 0 01-2.097 3.553c-2.056 3.173-4.614 6.36-7.616 9.387v-.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.017 30.622c-2.998 3.023-6.155 5.599-9.298 7.668-.393.259-.785.51-1.177.752v-.08H11.119V10.629h-.275a63.402 63.402 0 015.36-5.174v.238h27.269V17.7h.245a49.225 49.225 0 01-2.095 3.549c-2.053 3.167-4.608 6.35-7.606 9.373z"
                        ></path>
                        <path
                          fill="#000"
                          d="M34.002 30.608c-2.994 3.018-6.147 5.59-9.286 7.657-.392.259-.784.509-1.176.751v-.054H11.118v-28.32h-.259a60.706 60.706 0 011.637-1.714 62.59 62.59 0 013.716-3.454v.22h27.26v12.009h.218a49.492 49.492 0 01-2.092 3.544c-2.05 3.164-4.602 6.342-7.596 9.36z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.986 30.593c-2.99 3.014-6.139 5.583-9.273 7.648-.392.258-.784.508-1.175.75v-.029H11.117V10.654h-.242a61.842 61.842 0 011.635-1.712 62.107 62.107 0 013.422-3.2l.29-.249v.2H43.47v12.012h.19c-.208.391-.424.783-.648 1.176a49.658 49.658 0 01-1.44 2.364c-2.049 3.16-4.596 6.334-7.587 9.349z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.975 30.579c-2.986 3.01-6.13 5.575-9.261 7.637-.391.257-.782.507-1.173.749v-.003H11.12V10.666h-.225a62.901 62.901 0 015.05-4.905l.288-.249v.181h27.24v12.015h.164a49.716 49.716 0 01-2.086 3.535c-2.045 3.155-4.59 6.325-7.576 9.336z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.96 30.564c-2.983 3.007-6.123 5.568-9.25 7.627a49.396 49.396 0 01-1.755 1.104v-.333H11.12V10.678h-.209a60.146 60.146 0 011.63-1.706 62.629 62.629 0 013.413-3.192l.288-.249v.161h27.232V17.71h.136a48.693 48.693 0 01-2.084 3.53c-2.042 3.15-4.584 6.316-7.566 9.323v.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.944 30.55c-2.978 3.002-6.114 5.56-9.236 7.616a49.47 49.47 0 01-1.753 1.103v-.307H11.118V10.69h-.192a59.69 59.69 0 011.628-1.704A62.435 62.435 0 0116.25 5.55v.142h27.222v12.021h.108a48.96 48.96 0 01-2.08 3.525c-2.04 3.147-4.578 6.309-7.556 9.31v.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.929 30.535c-2.974 2.998-6.106 5.553-9.224 7.606a51.11 51.11 0 01-1.75 1.102v-.281H11.117v-28.26h-.176a61.23 61.23 0 011.627-1.701 62.24 62.24 0 013.98-3.676v.368H43.47v12.024h.08a48.854 48.854 0 01-2.078 3.52c-2.035 3.142-4.57 6.3-7.544 9.298z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.917 30.52c-2.97 2.994-6.098 5.546-9.211 7.596a50.667 50.667 0 01-1.748 1.1v-.254H11.12V10.715h-.16a59.758 59.758 0 011.625-1.7 62.084 62.084 0 013.974-3.67v.349h26.915v12.025h.053a48.798 48.798 0 01-2.075 3.515c-2.034 3.14-4.565 6.292-7.535 9.286z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.901 30.506c-2.965 2.99-6.089 5.538-9.198 7.585a50.53 50.53 0 01-1.746 1.1v-.23H11.12V10.728h-.142a66.777 66.777 0 011.621-1.697 61.907 61.907 0 013.969-3.666v.329h26.906v12.029h.025a48.678 48.678 0 01-2.072 3.51c-2.031 3.135-4.559 6.284-7.524 9.274z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.886 30.491c-2.962 2.986-6.081 5.53-9.186 7.575a50.613 50.613 0 01-1.744 1.097v-.201H11.118V10.739h-.126a65.042 65.042 0 011.62-1.695 61.832 61.832 0 013.963-3.66v.309h26.897v11.453h.301a48.116 48.116 0 01-.945 1.742c-.444.778-.92 1.56-1.427 2.342-2.028 3.13-4.553 6.275-7.514 9.261z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.87 30.477c-2.957 2.982-6.072 5.523-9.173 7.565a49.527 49.527 0 01-1.741 1.096v-.176H11.117v-28.21h-.11a62.978 62.978 0 013.863-3.846 58.362 58.362 0 011.714-1.503v.29H43.47V17.15h.273a46.61 46.61 0 01-.944 1.74c-.443.778-.919 1.558-1.425 2.34-2.025 3.125-4.546 6.266-7.504 9.248z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.859 30.462c-2.954 2.978-6.065 5.516-9.162 7.555a50 50 0 01-1.739 1.094v-.15H11.12V10.765h-.093a61.208 61.208 0 013.857-3.841 59.354 59.354 0 011.711-1.5v.27h26.878v11.46h.246a45.08 45.08 0 01-.942 1.738 48.652 48.652 0 01-1.424 2.335c-2.023 3.122-4.54 6.258-7.494 9.236z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.843 30.447c-2.95 2.974-6.056 5.509-9.149 7.545a49.647 49.647 0 01-1.736 1.092v-.122h-11.84V10.776h-.075a57.712 57.712 0 011.613-1.688 61.759 61.759 0 013.947-3.646v.251h26.87v11.463h.217a47.237 47.237 0 01-2.363 4.069c-2.02 3.117-4.534 6.25-7.483 9.223z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.828 30.433c-2.946 2.97-6.048 5.5-9.136 7.535a49.846 49.846 0 01-1.734 1.09v-.096h-11.84V10.789h-.06a59.224 59.224 0 011.61-1.686 61.139 61.139 0 013.943-3.641v.231h26.86V17.16h.19a42.032 42.032 0 01-.94 1.734c-.441.774-.915 1.55-1.42 2.33-2.017 3.112-4.527 6.24-7.473 9.21z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.813 30.419c-2.942 2.966-6.04 5.493-9.124 7.524a49.923 49.923 0 01-1.732 1.089v-.07h-11.84V10.8h-.043a58.635 58.635 0 011.609-1.684 61.425 61.425 0 013.936-3.636v.212h26.852v11.47h.162a41.878 41.878 0 01-.939 1.732 48.571 48.571 0 01-1.418 2.326c-2.014 3.109-4.521 6.232-7.463 9.198z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.801 30.404c-2.938 2.961-6.032 5.486-9.11 7.514a49.779 49.779 0 01-1.73 1.088v-.044H11.12V10.813h-.027A63.538 63.538 0 0112.7 9.131a61.168 61.168 0 013.93-3.63v.192h26.844v11.474h.134a44.286 44.286 0 01-.937 1.729c-.441.771-.913 1.546-1.416 2.322-2.011 3.105-4.515 6.224-7.453 9.186z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.786 30.39c-2.934 2.957-6.024 5.478-9.1 7.503a48.25 48.25 0 01-2.3 1.426v-.357H11.119V10.825h-.01a59.487 59.487 0 011.604-1.679A61.534 61.534 0 0116.64 5.52v.174h26.833V17.17h.107l-.3.574a48.399 48.399 0 01-2.05 3.473c-2.01 3.1-4.51 6.215-7.443 9.172z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.77 30.375c-2.93 2.953-6.015 5.471-9.086 7.493-.768.506-1.535.98-2.298 1.423v-.329H11.118V11.12h-.25a66.177 66.177 0 011.86-1.959 61.371 61.371 0 013.92-3.621v.153h26.824v10.91h.366a45.947 45.947 0 01-2.634 4.612c-2.007 3.096-4.504 6.207-7.433 9.16z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.759 30.36c-2.926 2.95-6.007 5.464-9.074 7.484a47.58 47.58 0 01-2.295 1.421v-.303H11.12v-27.83h-.233c.085-.095.171-.188.257-.282a59.156 59.156 0 011.6-1.675 60.785 60.785 0 013.915-3.616v.134h26.815v10.914h.338a45.848 45.848 0 01-2.63 4.605c-2.004 3.092-4.498 6.198-7.424 9.148h.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.743 30.346c-2.922 2.946-5.999 5.456-9.062 7.473-.765.504-1.53.977-2.291 1.419v-.276H11.12V11.143h-.218l.258-.28a64.213 64.213 0 011.598-1.673 61.038 61.038 0 013.91-3.612v.114h26.805v10.92h.31a45.714 45.714 0 01-2.628 4.598c-2 3.088-4.49 6.19-7.412 9.136z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.727 30.331c-2.918 2.942-5.991 5.448-9.05 7.463-.764.503-1.528.976-2.288 1.417v-.249H11.118V11.155h-.2a54.964 54.964 0 01.779-.84 61.258 61.258 0 014.979-4.716v.095H43.47v10.922h.282a45.69 45.69 0 01-2.624 4.593c-1.998 3.084-4.484 6.181-7.402 9.122z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.715 30.317c-2.913 2.937-5.982 5.44-9.036 7.452-.764.503-1.527.975-2.286 1.415v-.222H11.12V11.167h-.182A61.701 61.701 0 0116.122 6.1c.188-.163.376-.323.564-.482v.075h26.788V16.62h.253a45.7 45.7 0 01-2.62 4.586c-1.995 3.08-4.478 6.173-7.392 9.11z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.7 30.302c-2.91 2.934-5.974 5.434-9.024 7.442-.763.502-1.524.974-2.282 1.414v-.196H11.119V11.18h-.166l.256-.28a60.835 60.835 0 015.485-5.262v.056h26.779v10.932h.225a45.592 45.592 0 01-2.616 4.58c-1.993 3.075-4.473 6.164-7.383 9.097h.001z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.684 30.288c-2.906 2.93-5.966 5.425-9.012 7.431-.76.502-1.521.973-2.279 1.412v-.169H11.118V11.191h-.15l.257-.28a62.45 62.45 0 011.588-1.663 60.762 60.762 0 013.889-3.59v.035h26.77V16.63h.196a45.5 45.5 0 01-2.613 4.574c-1.99 3.07-4.465 6.156-7.371 9.085z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.672 30.273c-2.901 2.926-5.958 5.418-9 7.421-.76.5-1.519.971-2.275 1.41v-.142H11.12v-27.76h-.132a64.698 64.698 0 01.774-.835 62.456 62.456 0 013.271-3.216 57.852 57.852 0 011.68-1.475v.018h26.761v10.94h.169a45.488 45.488 0 01-2.61 4.567c-1.986 3.066-4.46 6.147-7.36 9.072z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.655 30.259c-2.898 2.92-5.95 5.41-8.987 7.41-.76.5-1.518.97-2.273 1.408v-.115H11.117V11.494h-.367a60.457 60.457 0 015.97-5.798l.28-.237v.234h26.471v10.945h.14a45.387 45.387 0 01-2.605 4.56c-1.984 3.063-4.454 6.139-7.351 9.06z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.643 30.244c-2.894 2.917-5.941 5.403-8.974 7.4-.759.5-1.516.969-2.27 1.406v-.088H11.12V11.506h-.35a61.18 61.18 0 014.286-4.32 59.01 59.01 0 011.957-1.706v.213h26.462v10.95h.112a45.359 45.359 0 01-2.602 4.554c-1.982 3.058-4.448 6.13-7.342 9.048z"
                        ></path>
                        <path
                          fill="#000"
                          d="M33.627 30.23c-2.89 2.912-5.934 5.395-8.962 7.39-.757.499-1.514.967-2.267 1.404v-.062h-11.28V11.517h-.333a60.19 60.19 0 015.953-5.782l.281-.236v.194h26.453v10.953h.083a45.33 45.33 0 01-2.598 4.549c-1.979 3.053-4.441 6.121-7.331 9.034z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <path
                  fill="#4646AF"
                  d="M24.57 5.685c-.2.249-.37.519-.508.804-.135.287-.24.588-.306.896a5.193 5.193 0 00-.04 1.882c.092.623.27 1.233.502 1.82.233.587.514 1.154.83 1.701a19.212 19.212 0 002.237 3.054 24.191 24.191 0 002.726 2.63c.971.807 2.005 1.54 3.094 2.173a16.215 16.215 0 003.436 1.534c.6.182 1.215.315 1.837.385.621.07 1.251.07 1.87-.025a5.346 5.346 0 001.773-.592c.276-.151.538-.329.782-.53.246-.2.474-.422.683-.663a5.142 5.142 0 01-1.414 1.283 5.39 5.39 0 01-1.794.682 7.28 7.28 0 01-1.923.087 10.158 10.158 0 01-1.893-.348 13.57 13.57 0 01-1.811-.647 18.031 18.031 0 01-1.719-.861 22.598 22.598 0 01-3.155-2.191 23.966 23.966 0 01-2.752-2.685c-.42-.486-.82-.99-1.194-1.513A16.105 16.105 0 0124.8 12.93c-.31-.565-.588-1.15-.806-1.758a8.426 8.426 0 01-.457-1.883c-.07-.643-.048-1.304.12-1.93.083-.312.205-.613.357-.897.155-.281.343-.542.556-.779v.002z"
                ></path>
                <g>
                  <g>
                    <path
                      fill="#6767E5"
                      d="M28.848 22.472c1.918 2.928 1.118 6.87-1.787 8.803-2.904 1.934-6.814 1.128-8.732-1.8-1.918-2.929-1.118-6.87 1.787-8.804 2.904-1.934 6.814-1.127 8.732 1.801z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#paint18_radial_21617_155783)"
                      d="M28.848 22.472c1.918 2.928 1.118 6.87-1.787 8.803-2.904 1.934-6.814 1.128-8.732-1.8-1.918-2.929-1.118-6.87 1.787-8.804 2.904-1.934 6.814-1.127 8.732 1.801z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#paint19_radial_21617_155783)"
                      d="M28.848 22.472c1.918 2.928 1.118 6.87-1.787 8.803-2.904 1.934-6.814 1.128-8.732-1.8-1.918-2.929-1.118-6.87 1.787-8.804 2.904-1.934 6.814-1.127 8.732 1.801z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#paint20_linear_21617_155783)"
                      d="M28.848 22.472c1.918 2.928 1.118 6.87-1.787 8.803-2.904 1.934-6.814 1.128-8.732-1.8-1.918-2.929-1.118-6.87 1.787-8.804 2.904-1.934 6.814-1.127 8.732 1.801z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#paint21_radial_21617_155783)"
                      d="M27.918 23.298c1.463 2.233.792 5.28-1.498 6.804s-5.331.948-6.794-1.285c-1.463-2.233-.793-5.28 1.497-6.804s5.332-.949 6.795 1.285z"
                    ></path>
                    <path
                      fill="url(#paint22_linear_21617_155783)"
                      d="M27.548 23.337c1.386 2.116.737 5.012-1.45 6.468-2.188 1.456-5.085.92-6.47-1.195-1.387-2.117-.738-5.012 1.45-6.469 2.187-1.455 5.084-.92 6.47 1.196z"
                    ></path>
                    <path
                      fill="url(#paint23_linear_21617_155783)"
                      d="M27.548 23.337c1.386 2.116.737 5.012-1.45 6.468-2.188 1.456-5.085.92-6.47-1.195-1.387-2.117-.738-5.012 1.45-6.469 2.187-1.455 5.084-.92 6.47 1.196z"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#FEDDA2"
                      d="M28.544 25.955c0 2.754-2.214 4.986-4.946 4.986-2.731 0-4.946-2.232-4.946-4.986 0-2.753 2.215-4.986 4.946-4.986 2.732 0 4.946 2.233 4.946 4.986z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#paint24_radial_21617_155783)"
                      d="M28.544 25.955c0 2.754-2.214 4.986-4.946 4.986-2.731 0-4.946-2.232-4.946-4.986 0-2.753 2.215-4.986 4.946-4.986 2.732 0 4.946 2.233 4.946 4.986z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#paint25_radial_21617_155783)"
                      d="M28.544 25.956a4.991 4.991 0 01-3.125 4.637 4.925 4.925 0 01-5.814-1.695 4.994 4.994 0 01-.952-2.942 4.983 4.983 0 013.57-4.79 4.873 4.873 0 011.376-.197 4.942 4.942 0 014.641 3.26c.197.538.305 1.12.305 1.726h-.001z"
                    ></path>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#paint26_linear_21617_155783)"
                      d="M28.544 25.956a4.991 4.991 0 01-3.125 4.637 4.925 4.925 0 01-5.814-1.695 4.994 4.994 0 01-.952-2.942 4.983 4.983 0 013.57-4.79 4.873 4.873 0 011.376-.197 4.942 4.942 0 014.641 3.26c.197.538.305 1.12.305 1.726h-.001z"
                    ></path>
                  </g>
                </g>
                <g style={{ mixBlendMode: "multiply" }}>
                  <path
                    fill="url(#paint27_linear_21617_155783)"
                    d="M45.963 10.359c0 3.724-.98 7.243-2.547 10.458-6.192 7.586-24.33-8.748-18.917-15.07 5.674-4.5 11.9-6.65 16.102-5.391.787.236 1.46.554 2.036.941.348.234.66.493.941.774 2.044 2.041 2.386 5.229 2.386 8.288z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_21617_155783"
          x1="10.186"
          x2="4.612"
          y1="46.177"
          y2="44.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.46"></stop>
          <stop offset="0.69" stopColor="#010101"></stop>
          <stop offset="0.77" stopColor="#080808"></stop>
          <stop offset="0.82" stopColor="#141414"></stop>
          <stop offset="0.87" stopColor="#252525"></stop>
          <stop offset="0.91" stopColor="#3B3B3B"></stop>
          <stop offset="0.94" stopColor="#565656"></stop>
          <stop offset="0.97" stopColor="#767676"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_21617_155783"
          x1="8.405"
          x2="12.523"
          y1="44.816"
          y2="50.448"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_21617_155783"
          x1="13.86"
          x2="6.965"
          y1="46.58"
          y2="43.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.46"></stop>
          <stop offset="0.69" stopColor="#010101"></stop>
          <stop offset="0.77" stopColor="#080808"></stop>
          <stop offset="0.82" stopColor="#141414"></stop>
          <stop offset="0.87" stopColor="#252525"></stop>
          <stop offset="0.91" stopColor="#3B3B3B"></stop>
          <stop offset="0.94" stopColor="#565656"></stop>
          <stop offset="0.97" stopColor="#767676"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_21617_155783"
          x1="9.218"
          x2="12.799"
          y1="43.941"
          y2="48.616"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_21617_155783"
          x1="8.888"
          x2="4.634"
          y1="20.806"
          y2="31.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <radialGradient
          id="paint5_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(21.0439 0 0 21.2148 19.551 23.938)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.84" stopColor="#fff"></stop>
          <stop offset="0.87" stopColor="#FBFBFB"></stop>
          <stop offset="0.9" stopColor="#EFEFF0"></stop>
          <stop offset="0.93" stopColor="#DBDDDE"></stop>
          <stop offset="0.95" stopColor="#C0C2C5"></stop>
          <stop offset="0.96" stopColor="#B4B7BA"></stop>
        </radialGradient>
        <radialGradient
          id="paint6_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(11.8934 0 0 11.9899 20.41 38.434)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.46"></stop>
          <stop offset="0.7" stopColor="#010101"></stop>
          <stop offset="0.79" stopColor="#080808"></stop>
          <stop offset="0.85" stopColor="#141414"></stop>
          <stop offset="0.9" stopColor="#252525"></stop>
          <stop offset="0.95" stopColor="#3B3B3B"></stop>
          <stop offset="0.98" stopColor="#555"></stop>
          <stop offset="1" stopColor="#636363"></stop>
        </radialGradient>
        <linearGradient
          id="paint7_linear_21617_155783"
          x1="16.488"
          x2="12.954"
          y1="34.198"
          y2="42.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <radialGradient
          id="paint8_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(5.05163 4.50877 -2.23543 2.50458 15.732 37.045)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.02" stopColor="#8EABC4"></stop>
          <stop offset="0.27" stopColor="#90ADC5"></stop>
          <stop offset="0.45" stopColor="#99B3CA"></stop>
          <stop offset="0.6" stopColor="#A9BFD2"></stop>
          <stop offset="0.74" stopColor="#BFCFDD"></stop>
          <stop offset="0.87" stopColor="#DAE4EC"></stop>
          <stop offset="0.99" stopColor="#FCFDFD"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </radialGradient>
        <linearGradient
          id="paint9_linear_21617_155783"
          x1="32.466"
          x2="29.79"
          y1="26.651"
          y2="42.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_21617_155783"
          x1="14.448"
          x2="9.597"
          y1="32.643"
          y2="30.791"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.46"></stop>
          <stop offset="0.69" stopColor="#010101"></stop>
          <stop offset="0.77" stopColor="#080808"></stop>
          <stop offset="0.82" stopColor="#141414"></stop>
          <stop offset="0.87" stopColor="#252525"></stop>
          <stop offset="0.91" stopColor="#3B3B3B"></stop>
          <stop offset="0.94" stopColor="#565656"></stop>
          <stop offset="0.97" stopColor="#767676"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_21617_155783"
          x1="20.132"
          x2="22.883"
          y1="37.057"
          y2="41.959"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.46"></stop>
          <stop offset="0.69" stopColor="#010101"></stop>
          <stop offset="0.77" stopColor="#080808"></stop>
          <stop offset="0.82" stopColor="#141414"></stop>
          <stop offset="0.87" stopColor="#252525"></stop>
          <stop offset="0.91" stopColor="#3B3B3B"></stop>
          <stop offset="0.94" stopColor="#565656"></stop>
          <stop offset="0.97" stopColor="#767676"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <radialGradient
          id="paint12_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(12.7087 0 0 12.8119 22.571 29.872)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.81"></stop>
          <stop offset="0.89" stopColor="#010101"></stop>
          <stop offset="0.91" stopColor="#080808"></stop>
          <stop offset="0.93" stopColor="#141414"></stop>
          <stop offset="0.95" stopColor="#252525"></stop>
          <stop offset="0.96" stopColor="#3B3B3B"></stop>
          <stop offset="0.98" stopColor="#565656"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </radialGradient>
        <linearGradient
          id="paint13_linear_21617_155783"
          x1="37.973"
          x2="4.574"
          y1="45.684"
          y2="27.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07"></stop>
          <stop offset="0.42" stopColor="#020202"></stop>
          <stop offset="0.56" stopColor="#090909"></stop>
          <stop offset="0.67" stopColor="#151515"></stop>
          <stop offset="0.76" stopColor="#272727"></stop>
          <stop offset="0.83" stopColor="#3D3D3D"></stop>
          <stop offset="0.9" stopColor="#5A5A5A"></stop>
          <stop offset="0.96" stopColor="#7A7A7A"></stop>
          <stop offset="0.99" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_21617_155783"
          x1="19.343"
          x2="19.528"
          y1="19.369"
          y2="45.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_21617_155783"
          x1="15.796"
          x2="22.358"
          y1="27.574"
          y2="41.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_21617_155783"
          x1="27.98"
          x2="36.844"
          y1="25.031"
          y2="30.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_21617_155783"
          x1="0.186"
          x2="43.505"
          y1="11.272"
          y2="30.896"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <radialGradient
          id="paint18_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-33.653 55.024 -26.07) scale(6.65434 6.67532)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.66"></stop>
          <stop offset="0.79" stopColor="#010101"></stop>
          <stop offset="0.84" stopColor="#080808"></stop>
          <stop offset="0.87" stopColor="#141414"></stop>
          <stop offset="0.89" stopColor="#252525"></stop>
          <stop offset="0.92" stopColor="#3B3B3B"></stop>
          <stop offset="0.94" stopColor="#565656"></stop>
          <stop offset="0.95" stopColor="#767676"></stop>
          <stop offset="0.97" stopColor="#A1A1A1"></stop>
        </radialGradient>
        <radialGradient
          id="paint19_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-33.653 53.964 -25.74) scale(6.77643 6.79779)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.84" stopColor="#fff"></stop>
          <stop offset="0.87" stopColor="#FBFBFB"></stop>
          <stop offset="0.9" stopColor="#EFEFF0"></stop>
          <stop offset="0.93" stopColor="#DBDDDE"></stop>
          <stop offset="0.95" stopColor="#C0C2C5"></stop>
          <stop offset="0.96" stopColor="#B4B7BA"></stop>
        </radialGradient>
        <linearGradient
          id="paint20_linear_21617_155783"
          x1="10.217"
          x2="33.405"
          y1="17.011"
          y2="32.303"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <radialGradient
          id="paint21_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(4.14561 -2.75991 2.68555 4.03392 23.768 26.052)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.68" stopColor="#FDC172"></stop>
          <stop offset="0.81" stopColor="#FDC173"></stop>
          <stop offset="0.86" stopColor="#FDC47A"></stop>
          <stop offset="0.9" stopColor="#FDCA86"></stop>
          <stop offset="0.92" stopColor="#FDD197"></stop>
          <stop offset="0.95" stopColor="#FDDBAD"></stop>
          <stop offset="0.97" stopColor="#FEE7C8"></stop>
          <stop offset="0.99" stopColor="#FEF4E8"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </radialGradient>
        <linearGradient
          id="paint22_linear_21617_155783"
          x1="20.63"
          x2="27.932"
          y1="24.278"
          y2="28.392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC172"></stop>
          <stop offset="1" stopColor="#D9A56A"></stop>
        </linearGradient>
        <linearGradient
          id="paint23_linear_21617_155783"
          x1="20.63"
          x2="27.932"
          y1="24.278"
          y2="28.392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC172"></stop>
          <stop offset="1" stopColor="#D9A56A"></stop>
        </linearGradient>
        <radialGradient
          id="paint24_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(4.9458 0 0 4.98596 25.26 23.877)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.19" stopColor="#FCFAF7"></stop>
          <stop offset="0.48" stopColor="#F7EDE3"></stop>
          <stop offset="0.82" stopColor="#EDD7C3"></stop>
          <stop offset="1" stopColor="#E8CBB0"></stop>
        </radialGradient>
        <radialGradient
          id="paint25_radial_21617_155783"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(5.43966 0 0 5.48383 23.896 26.156)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.64"></stop>
          <stop offset="0.71" stopColor="#030303"></stop>
          <stop offset="0.76" stopColor="#0C0C0C"></stop>
          <stop offset="0.8" stopColor="#1D1D1D"></stop>
          <stop offset="0.84" stopColor="#343434"></stop>
          <stop offset="0.88" stopColor="#525252"></stop>
          <stop offset="0.92" stopColor="#767676"></stop>
          <stop offset="0.94" stopColor="#979797"></stop>
        </radialGradient>
        <linearGradient
          id="paint26_linear_21617_155783"
          x1="24.632"
          x2="22.132"
          y1="22.945"
          y2="30.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.33" stopColor="#FDFBFD"></stop>
          <stop offset="0.53" stopColor="#F9EFF8"></stop>
          <stop offset="0.73" stopColor="#F3DBEF"></stop>
          <stop offset="0.93" stopColor="#E9C0E3"></stop>
          <stop offset="0.96" stopColor="#E8BBE1"></stop>
        </linearGradient>
        <linearGradient
          id="paint27_linear_21617_155783"
          x1="23.504"
          x2="45.963"
          y1="11.394"
          y2="11.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#fff"></stop>
          <stop offset="0.3" stopColor="#FDFBFD"></stop>
          <stop offset="0.48" stopColor="#F9EFF7"></stop>
          <stop offset="0.66" stopColor="#F2DBEE"></stop>
          <stop offset="0.84" stopColor="#E8C0E2"></stop>
          <stop offset="0.96" stopColor="#E0A8D7"></stop>
        </linearGradient>
        <clipPath id="clip0_21617_155783">
          <path fill="#fff" d="M0 0H46V56H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
