import { KeyboardEvent, useRef } from 'react'

const SPACE_KEY_CODE = 32

interface Props {
  onSelect: () => void
  disabled?: boolean
}

export const useKeyboardNavigable = ({
  onSelect,
  disabled = true
}: Props) => {
  function onKeyDown(event: KeyboardEvent) {
    console.log('KEY DOWN')

    if(disabled) {
      return
    }
    const isFocused = document.activeElement === event.target

    if(isFocused && event.keyCode === SPACE_KEY_CODE) {
      event.preventDefault()
      onSelect()
      // setIsChecked(isChecked => {
      //   if(onChange) {
      //     onChange({
      //       target: {
      //         value: !isChecked,
      //         name: rest?.name
      //       }
      //     })
      //   }
      //
      //   return !isChecked
      // })
    }
  }

  return {
    tabIndex: 0,
    onKeyDown
  }
}
