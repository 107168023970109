import cn from 'classnames'
import { memo, useCallback, useContext, useMemo } from 'react'
import { CalendarContext } from '../Calendar.context'
import styles from './Pane.module.scss'
import dayjs from '@roolz/sdk/plugins/dayjs'

type Unixtime = number

export interface Props {
  dateFrom: Unixtime | null
  dateTo: Unixtime | null
  month: number
  year: number
  dateHover: Unixtime | null
  onDaySelect?: (day: Unixtime) => void
  onDayHover?: (day: Unixtime | null) => void

  isDateAllowed?: (day: Unixtime) => boolean
}

export const Pane = memo(({
  dateFrom,
  dateTo,

  month,
  year,
  dateHover,
  onDaySelect,
  onDayHover,

  isDateAllowed
}: Props) => {
  const ctx = useContext<any>(CalendarContext)

  const baseDate = useMemo(() => {
    return dayjs.utc([year, month])
  }, [month, year])

  const daysOffset = useMemo(() => {
    const day = baseDate.day()

    return (day !== 0 ? day : 7) - 1
  }, [baseDate])

  const daysInMonth = useMemo(() => {
    return baseDate.daysInMonth()
  }, [baseDate])

  const datesTimestampRange: [number, number] | [] = useMemo(() => {
    if(!dateFrom) {
      return []
    }

    const from = dateFrom
    const to = (dateTo ?? dateHover ?? from)

    return [from, to].sort() as [number, number]
  }, [dateFrom, dateTo, dateHover])

  const handleDayClick = useCallback((day: number) => {
    const newDate = baseDate.clone().set('date', day)

    if(isDateAllowed && !isDateAllowed(newDate.valueOf())) {
      return
    }

    if(onDaySelect) {
      onDaySelect(newDate.valueOf())
    }
  }, [onDaySelect, isDateAllowed])

  function handleDayHover(day: number) {
    const newDate = baseDate.clone().set('date', day)

    if(isDateAllowed && !isDateAllowed(newDate.valueOf())) {
      return
    }

    if(onDayHover) {
      onDayHover(newDate.valueOf())
    }
  }

  function getItemClasses(day: number): string[] {
    const [from, to] = datesTimestampRange
    const classes = []
    const currDate = baseDate.clone().set('date', day)
    const currTimestamp = currDate.valueOf()

    if(currDate.isSame(dayjs.utc(), 'day')) {
      classes.push(styles.days__itemToday)
    }

    if(isDateAllowed && !isDateAllowed(currTimestamp)) {
      classes.push(styles.days__itemUnavailable)
    }

    if(from && to) {
      if(from <= currTimestamp && to >= currTimestamp) {
        classes.push(dateHover && currTimestamp !== dateFrom
          ? styles.days__itemActivePossibleRange
          : styles.days__itemActive)
      }

      if(from !== to) {
        if(from < currTimestamp && to > currTimestamp) {
          classes.push(styles.days__itemInRange)
        }

        if(currTimestamp === from) {
          classes.push(styles.days__itemActiveFirst)
        } else if(currTimestamp === to) {
          classes.push(styles.days__itemActiveLast)
        }
      }
    }

    return classes
  }


  const handlePointerLeave = () => {
    onDayHover?.(null)
  }

  return (
    <div
      className={styles.root}
      onPointerLeave={handlePointerLeave}
    >
      <div className={styles.header}>
        <div className={styles.weekDay}>{ctx.weekDays.monday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.tuesday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.wednesday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.thursday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.friday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.saturday}</div>
        <div className={styles.weekDay}>{ctx.weekDays.sunday}</div>
      </div>
      <div className={styles.days}>
        {Array(daysOffset).fill(1).map((item, i) => (
          <div
            className={styles.days__spacer}
            key={i}
          />
        ))}

        {Array(daysInMonth).fill(1).map((_, day) => (
          <button
            className={cn(
              styles.days__item,
              ...getItemClasses(day + 1))
            }
            key={year + day}
            onClick={() => handleDayClick(day + 1)}
            onPointerOver={() => handleDayHover(day + 1)}
          >
            {day + 1}
          </button>
        ))}
      </div>
    </div>
  )
})
