import { AuthDevice } from '@roolz/types/api/profiles'
// @ts-ignore
import ClientJS from 'clientjs/dist/client.base.min.js'
import sha256 from 'crypto-js/sha256'
import { detect } from 'detect-browser'
import { memoize } from 'lodash-es'
import { DeviceType, OS } from '@roolz/types/api'

const { ClientJS: ClientJSClass } = ClientJS as any
let is_mobile
let is_desktop
let is_safari
try {
  const client = new ClientJSClass()

  is_mobile = client.isMobile()
  is_desktop = !is_mobile
  is_safari = client.isSafari()
} catch(e) {
  is_mobile = false
}

export const IS_MOBILE = is_mobile
export const IS_DESKTOP = is_desktop
export const IS_SAFARI = is_safari

export const getDeviceInfo: () => {
  installation_id: string
  client_name: string,
  system_version: string,
  app_version: string,
  os_type: OS
} = memoize(function (): AuthDevice {
  const client = new ClientJSClass()
  let os: OS

  if (client.isMobile()) {
    os = client.isMobileIOS() ? OS.Ios : OS.Android
  } else {
    if (client.isMac()) {
      os = OS.MacOS
    } else if (client.isWindows()) {
      os = OS.Windows
    } else {
      os = OS.Linux
    }
  }
  return {
    installation_id: String(client.getFingerprint()) ?? '',
    client_name: client.getBrowser() ?? '',
    system_version: client.getBrowserVersion() ?? '',
    app_version: process.env.NEXT_PUBLIC_APP_VERSION || process.env.REACT_APP_APP_VERSION || '',
    os_type: os
  }
})

export const getFingerprintClientside = (() => {
  function hashString(str: string) {
    let encoder = new TextEncoder()
    let data = encoder.encode(str)
    return crypto.subtle.digest('SHA-256', data).then(function(hash) {
      return Array.from(new Uint8Array(hash)).map(function(byte) {
        return byte.toString(16).padStart(2, '0')
      }).join('')
    })
  }

  let fingerprint = ''

  // User Agent
  fingerprint += navigator.userAgent

  // Screen Resolution
  fingerprint += window.screen.width + 'x' + window.screen.height

  // Timezone Offset
  fingerprint += new Date().getTimezoneOffset()

  let canvas = document.createElement("canvas")
  let ctx = canvas.getContext("2d")

  if (ctx) {
    ctx.fillStyle = "rgb(255,0,255)"
    ctx.beginPath()
    ctx.rect(20, 20, 150, 100)
    ctx.fill()
    ctx.stroke()
    ctx.closePath()
    ctx.beginPath()
    ctx.fillStyle = "rgb(0,255,255)"
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true)
    ctx.fill()
    ctx.stroke()
    ctx.closePath()

    ctx.textBaseline = "top"
    ctx.font = '17px "Arial 17"'
    ctx.textBaseline = "alphabetic"
    ctx.fillStyle = "rgb(255,5,5)"
    ctx.rotate(.03)
    ctx.fillText('abz190#$%^@£éú', 4, 17)
    ctx.fillStyle = "rgb(155,255,5)"
    ctx.shadowBlur=8
    ctx.shadowColor="red"
    ctx.fillRect(20,12,100,5)
  }

  let src = canvas.toDataURL()
  let hash = 0

  for (let i = 0; i < src.length; i++) {
    let char = src.charCodeAt(i)
    hash = ((hash<<5)-hash) + char
    hash = hash & hash
  }

  // Canvas fingerprint
  fingerprint += hash

  // Random Number
  // fingerprint += Math.random()


  return hashString(fingerprint)


  // const client = new ClientJSClass()
  //
  // return client.getFingerprint()
})

/**
 * Required only for invite links, because fingerprints there are should be same on IOS and web.
 *
 * Used detect-browser lib instead of clientjs because just copied from old fully tested project
 */
export const getSimpleFingerprintClientside: () => string = memoize(() => {
  const browser = detect()

  return sha256(window.screen.width + ':' + window.screen.height + ':' + browser?.os).toString()
})

export const getDeviceType: () => DeviceType = memoize(function(): any {
  const client = new ClientJSClass()

  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)

  if(isTablet) {
    return DeviceType.TABLET
  }
  if(client.isMobile()) {
    return DeviceType.MOBILE
  }

  return DeviceType.DESKTOP
})
