import CreateOffersAppealImage from '@/assets/images/create-offers-appeal.png'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { fireClickButtonCreateOffer, fireCreateOffersAppealShown } from '@/config/events'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { Time } from '@roolz/icons/chats/Time'
import { Clarify } from '@roolz/icons/Clarify'
import { Close } from '@roolz/icons/Close'
import { Inventory } from '@roolz/icons/Inventory'
import { LocalShipping } from '@roolz/icons/LocalShipping'
import { Mail } from '@roolz/icons/Mail'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CreateOffersAppeal.module.scss'

interface CreateOffersAppealProps {
  open: boolean
  onClose: () => void
}

export const CreateOffersAppeal = (props: CreateOffersAppealProps) => {
  const { open, onClose } = props

  const { t } = useTranslation('modals')
  const globalModalsManager = useGlobalModals()

  const handleOpenOffer = (id: GLOBAL_MODALS_NAMES.CREATE_CARGO | GLOBAL_MODALS_NAMES.CREATE_TRANSPORT) => () => {
    fireClickButtonCreateOffer()
    globalModalsManager.open(id)
    onClose()
  }

  useEffect(() => {
    fireCreateOffersAppealShown()
  }, [])

  return (
    <Dialog classes={{ paper: styles.paper }} open={open} onClose={onClose} scroll='body'>
      <img className={styles.image} src={CreateOffersAppealImage} alt='Roolz'/>
      <CloseButton
        className={styles.close}
        onClick={onClose}
      />

      <h2 className={styles.title}>{t('create_offers_appeal.title')}</h2>

      <div className={styles.descriptionItem}>
        <Time size={20} color='#4778EE'/>
        {t('create_offers_appeal.description_time')}
      </div>
      <div className={styles.descriptionItem}>
        <Mail size={20}/>
        {t('create_offers_appeal.description_email')}
      </div>
      <div className={styles.descriptionItem}>
        <Clarify size={20} color='#4778EE'/>
        {t('create_offers_appeal.description_templates')}
      </div>

      <Button className={styles.button} onClick={handleOpenOffer(GLOBAL_MODALS_NAMES.CREATE_CARGO)}>
        <Inventory size={20} color='#fff'/>
        {t('create_offers_appeal.offer_cargo')}
      </Button>

      <Button className={styles.button} onClick={handleOpenOffer(GLOBAL_MODALS_NAMES.CREATE_TRANSPORT)}>
        <LocalShipping size={20} color='#fff'/>
        {t('create_offers_appeal.offer_transport')}
      </Button>
    </Dialog>
  )
}
