import { db, DEXIE_STORES } from '@/database/index'
import { MessageModelFactory } from '@/models/Message.model'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { uploadingStore } from '@/store/uploadings/uploading.store'
import { Chat } from '@roolz/types/api/chats'
import { liveQuery, Subscription } from 'dexie'
import { Profile } from '@roolz/types/api/profiles'
import { memo, ReactNode, useEffect, useLayoutEffect } from 'react'

const observingChatIds: Set<Chat['id']> = new Set()

interface Props {
  children?: ReactNode
}

export const IdbObservationsProvider = memo(({ children }: Props) => {
  useLayoutEffect(() => {
    const subscriptions: Subscription[] = []

    console.log('observer 1')

    db[DEXIE_STORES.MESSAGES].toArray()
      .then(items => {
        chatsStore.addOrUpdateMessages(items.map(MessageModelFactory))

        // TODO probably need to do more granular
        subscriptions.push(liveQuery(() => db[DEXIE_STORES.CHATS].toArray())
          .subscribe(chats => {
            console.log('live 1')
            chatsStore.addOrUpdateChats(chats)

            chats.forEach(chat => {
              const { id } = chat

              if(!observingChatIds.has(id)) {
                observeChatMessagesUpdates(id)
                // observeChatMemberProfiles(chat)

                observingChatIds.add(id)
              }
            })
          }))
      })


    console.log('observer 2')

    subscriptions.push(liveQuery(() => db[DEXIE_STORES.OWN_PCPS]
      .toArray())
      .subscribe(ownPcps => {
        console.log('live 2')

        chatsStore.addOrUpdateOwnPcps(ownPcps)
      }))

    console.log('observer 3')

    subscriptions.push(liveQuery(() => db[DEXIE_STORES.CLIENT_PROFILE_INFOS]
      .toArray())
      .subscribe(infos => {
        console.log('live 3')

        infos.forEach(info => profilesStore.addOrUpdateClientProfileInfo(info))
      }))

    console.log('observer 4')

    subscriptions.push(liveQuery(() => db[DEXIE_STORES.PCPS].toArray())
      .subscribe(async pcps => {
        console.log('live 4')

        chatsStore.addOrUpdatePcps(pcps)

        const profileIds = pcps.map(pcp => pcp.profile_id)

        try {
          const profiles = (await db[DEXIE_STORES.PROFILES].bulkGet(profileIds))
            .filter(profile => !!profile) as Profile[]

          profiles.map(profilesStore.addOrUpdateProfile)
        } catch(e) {
          console.error(e)
        }
      })
    )
    console.log('observer 5')

    subscriptions.push(liveQuery(() => db[DEXIE_STORES.PROFILES].toArray())
      .subscribe(profiles => {
        console.log('live 5')

        console.log('PROFILES SUB 1')

        profiles.map(profilesStore.updateProfileIfExists)
      })
    )
    console.log('observer 6')

    subscriptions.push(liveQuery(() => db[DEXIE_STORES.UPLOADINGS].toArray())
      .subscribe(items => {
        console.log('live 6')

        console.log('UPLOADINGS: get idb updates', items)
        uploadingStore.addOrUpdateUploadings(items)
      })
    )


    function observeChatMessagesUpdates(id: Chat['id']) {
      const subscription = liveQuery(() => db[DEXIE_STORES.MESSAGES]
        .where('chat_id')
        .equals(id)
        .toArray()
      )
        .subscribe(msgs => {
          console.log('live 7')

          chatsStore.addOrUpdateMessages(msgs.map(MessageModelFactory))
        })

      subscriptions.push(subscription)

      return subscription
    }

    // LOAD INITIAL DATA
    // TODO Temporary crunch. Remove when profiles store would be refactored
    db[DEXIE_STORES.PROFILES].toArray()
      .then(profiles => {
        profiles.forEach(profilesStore.addOrUpdateProfile)
      })

    return () => {
      subscriptions.map(sub => sub.unsubscribe())
    }
  }, [])

  return null
})
