interface Props {
  onOpen?: () => void
  onNotOpen?: () => void
}

const MAX_TIME = 500


export function useDetectBrowserAlertOpened() {
  return ({
    onOpen,
    onNotOpen
  }: Props) => {
    let detecting = true
    const onBlur = () => {
      if(detecting) {
        onOpen?.()
      }
      detecting = false
      window.removeEventListener('blur', onBlur)
    }

    window.addEventListener('blur', onBlur)
    setTimeout(() => {
      if(!detecting) {
        return
      }
      onNotOpen?.()

    }, MAX_TIME)
  }
}
