import { MiniChat } from '@/components/chats/MiniChat/MiniChat'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { Fade } from '@mui/material'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { ChatType } from '@roolz/types/api/chats'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef } from 'react'
import styles from './MiniChatContainer.module.scss'
import { FreeFocusInside } from 'react-focus-lock'
import { useLockScrolling } from '@roolz/sdk/hooks'
import cn from 'classnames'

interface Props {

}

export const MiniChatContainer = observer(function MiniChatContainer(props: Props) {
  const chat = chatsStore.activeChat

  const { lock, unlock } = useLockScrolling()

  const handlePositionChange = useCallback((pos: any) => {
    chatSessionsStore.setMiniChatPosition(pos)
  }, [])

  const handleSizeChange = useCallback((size: any) => {
    chatSessionsStore.setMiniChatSize(size)
  }, [])

  const handleClose = useCallback(() => {
   chatsService.setActiveChat(null)
  }, [])

  if(IS_MOBILE) {
    useEffect(() => {
      chat
        ? lock()
        : unlock()
    }, [chat])
  }

  if(!chat) {
    return null
  }

  return (
    <FreeFocusInside>
      <div className={cn(styles.root, {
        [styles.rootMobile]: IS_MOBILE
      })}>
        <Fade
          in={!!chat}
          appear={true}
          timeout={{
            enter: 200,
            appear: 200,
            exit: 300
          }}
        >
          <div>
            <MiniChat
              key={chat?.id}

              chat={chat}
              minHeight={400}
              width={chatSessionsStore.miniChatSize.width}
              height={chatSessionsStore.miniChatSize.height}
              position={chatSessionsStore.miniChatPosition}

              onClose={handleClose}
              onPositionChange={handlePositionChange}
              onSizeChange={handleSizeChange}
            />
          </div>
        </Fade>
      </div>
    </FreeFocusInside>
  )
})
