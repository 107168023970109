import { persistAndSyncStore } from '@/utils/mobx'
import { getCountrySubcategoryParent, isCountrySubcategory } from '@roolz/sdk/components/companies/utils'
import { keyBy } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { KnowledgeChecksums } from '@roolz/types/api/knowledge'
import { CategoryOptions, Country, SeoItem } from '@roolz/types/custom'

class KnowledgeStore {
  checksums: KnowledgeChecksums = {}
  countries: Country[] = []

  companyCategories: SeoItem[] = []
  companyFilters: CategoryOptions[] = []

  getCategoryOptions(slug: SeoItem['slug']) {
    return this.companyFiltersBySlug[getCountrySubcategoryParent(slug)]
  }

  get companyFiltersBySlug() {
    return keyBy(this.companyFilters, 'slug')
  }

  get companyCategoriesWithOptions() {
    return this.companyCategories.map(item => ({
      ...item,
      options: this.getCategoryOptions(item.slug)
    }))
  }

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    const instance = new KnowledgeStore()
    // @ts-ignore
    for(const [key, value] in Object.entries(instance)) {
      // @ts-ignore
      this[key] = value
    }
  }
}

const knowledgeStore = persistAndSyncStore(new KnowledgeStore(), 'knowledge')

export {
  KnowledgeStore,
  knowledgeStore
}
