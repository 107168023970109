import { ReactNode } from 'react'
import styles from './IconButton.module.scss'

interface Props {
  children: ReactNode

  [key: string]: any
}

export const IconButton = ({ children, ...rest }: Props) => {
  return (
    <button
      className={styles.root}
      {...rest}
    >
      {children}
    </button>
  )
}
