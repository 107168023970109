import { modalRoutes, NAME_PATH_MAP, ROUTE_NAMES, routes } from '@/config/routes'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { uiStore } from '@/store/ui/ui.store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation, matchRoutes } from 'react-router'
import styles from './Jivosite.module.scss'

// const VISIBLE_PAGE_NAMES = [
//   ROUTE_NAMES.PUBLIC_EXCHANGE,
//   ROUTE_NAMES.MY_OFFERS,
//   ROUTE_NAMES.COMPANIES,
//   ROUTE_NAMES.COMPANY_PAGE
// ]

const RU_UA_CODE = '//code.jivosite.com/widget/cauahG52a3'
const ETC_CODE = '//code.jivosite.com/widget/oeQvrdhTn0'

const TAG_ID = 'jivosite-widget'

export const Jivosite = observer(() => {
  const location = useLocation()

  // function show() {
  //   document.body.classList.remove(styles["jivo-hidden"])
  // }
  //
  // function hide() {
  //   document.body.classList.add(styles["jivo-hidden"])
  // }

  // function checkVisibility() {
  //   const matches = [
  //     ...matchRoutes(modalRoutes, location) || [],
  //     ...matchRoutes(routes, location) || []
  //   ]
  //
  //   for(const match of matches) {
  //     // if(VISIBLE_PAGE_NAMES.includes(match.route?.name ?? '')) {
  //       show()
  //       return
  //     // }
  //   }
  //   hide()
  // }

  // useEffect(() => {
    // checkVisibility()
  // }, [location])

  useEffect(() => {
    if(profilesStore?.my_profile?.lang_code) {
      const existing = document.querySelector('#' + TAG_ID)
      if(existing) {
        existing.remove()
      }

      const s = document.createElement('script')
      s.setAttribute('src',
        ["ru", "uk"].includes(profilesStore.my_profile?.lang_code)
          ? RU_UA_CODE
          : ETC_CODE
      )
      s.setAttribute('id', TAG_ID)
      // @ts-ignore
      document.querySelector('head').append(s)

      // @ts-ignore
      // window.jivo_onClose = () => hide()
      // // @ts-ignore
      // window.jivo_onOpen = () => show()
      // @ts-ignore
      window.jivo_onLoadCallback = () => {
        setTimeout(() => {
          systemStore.isJivositeLoaded = true
        }, 1000)
      }
    }
  }, [profilesStore?.my_profile?.lang_code])

  return null
})
