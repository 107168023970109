import { AppealModalsContainer } from '@/components/AppealModalsContainer'
import CompanyLayout from '@/components/layouts/CompanyLayout/CompanyLayout'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { useBackToAppFromCompanyAdmin } from '@/pages/home/companies/Admin/utils'
import { profilesStore } from '@/store/profiles/profiles.store'
import { observer } from 'mobx-react-lite'
import React, { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'

export const CompanyAdmin = observer(() => {
  const params = useParams()

  const back = useBackToAppFromCompanyAdmin()

  useEffect(() => {
    if(profilesStore.my_profile?.active_space_company_id !== params.company_id) {
      back()
      return
    }
  }, [profilesStore.my_profile?.active_space_company_id])

  return (
    <Suspense fallback={<PageLoading/>}>
      <AppealModalsContainer/>

      <CompanyLayout>
        <Suspense fallback={<PageLoading/>}>
          <Outlet/>
        </Suspense>
      </CompanyLayout>
    </Suspense>
  )
})
