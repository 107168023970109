import { ApiClients } from '@roolz/api/clients'
import { AxiosInstance } from 'axios'

export class NotificationsApi {
  notifications: AxiosInstance

  constructor(clients: ApiClients) {
    this.notifications = clients.notifications
  }
  async callbackProfileReady(profile_id: string) {
    return this.notifications.post('/v1/event/profile_created', { profile_id })
  }

  async unsubscribeFromOnborading(token: string) {
    return this.notifications.post('/v1/onboarding/unsubscribe/' + token)
  }
}
