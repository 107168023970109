import { ApiClients } from '@roolz/api/clients'
import { AxiosInstance } from 'axios'
import { Feedback } from 'static/src/types/api'

export class SupportApi {
  support: AxiosInstance

  constructor(clients: ApiClients) {
    this.support = clients.support
  }

  createFeedback(body: Feedback) {
    return this.support.post('/v1/feedback', body)
  }
}
