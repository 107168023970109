import Bag from '@/assets/images/bag.png'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CompanyCreateBanner.module.scss'

export const CompanyCreateBanner = memo(({
  onCreate
}: {
  onCreate: () => void
}) => {
  const { t } = useTranslation('modals')

  return (
    <div className={styles.root}>
      <img src={Bag} alt='' className={styles.image}/>

      <div className={styles.content}>
        <div className={styles.text}>
          {t('private_exchange_guide.create_company_banner.text')}
        </div>
        <Button
          className={styles.button}
          onClick={onCreate}
        >
          {t('private_exchange_guide.create_company_banner.button')}
        </Button>
      </div>
    </div>
  )
})
