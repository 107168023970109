import { useNavigateToModal } from '@/config/routes'
import { GlobalModalBackdrop } from '@/global-modals/components/GlobalModalBackdrop/GlobalModalBackdrop'
import { GlobalModalsContext } from '@/global-modals/GlobalModalsContext'
import { globalModalsHistory } from '@/global-modals/globalModalsHistory'
import { OptionsType } from '@/global-modals/GlobalModalsManager'
import { usePrevious } from '@roolz/sdk/hooks/helpers/usePrevious'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import FocusLock from 'react-focus-lock'
import { useLocation } from 'react-router'
import { ModalType } from '../const'

// const history: History = {
//   [ModalType.OVERLAY]: [],
//   [ModalType.POPUP]: []
// }

export type ModalVisibility = 'visible' | 'overlapped' | 'hidden'

export const GlobalModalContainer = observer(function GlobalModalContainer() {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const navigate = useNavigateToModal()

  const { modalManager } = useContext(GlobalModalsContext)
  const [mountedModals, setMountedModals] = useState(modalManager.mountedModals ?? [])


  useEffect(() => {
    modalManager && modalManager.subscribe(() => {
      console.log('1111', modalManager.mountedModals)
      setMountedModals([...modalManager.mountedModals])
    })
  }, [modalManager])

  useEffect(() => {
    if(prevLocation?.state?.backgroundLocation && !location?.state?.backgroundLocation) {
      if(modalManager.visibleModals.length) {
        modalManager.closeAll()
      }
    }
  }, [location, prevLocation])


  // function onOverlayBack() {
  //   if(visibleOverlayModals.length >= 2) {
  //     return visibleOverlayModals[visibleOverlayModals.length - 1]
  //   }
  // }

  const {
    overlayModals,
    popups,
    visibleOverlayModals,
    visiblePopups
  } = useMemo(() => {
    const overlayModals = mountedModals
      .filter(({ modal }) => modal?.meta?.type === ModalType.OVERLAY)
    const visibleOverlayModals = overlayModals.filter(({ isVisible }) => isVisible)

    const popups = mountedModals
      .filter(({ modal }) => modal?.meta?.type === ModalType.POPUP)
    const visiblePopups = popups.filter(({ isVisible }) => isVisible)

    return {
      overlayModals,
      popups,
      visibleOverlayModals,
      visiblePopups
    }
  }, [mountedModals])

  const visibleOverlayModalsRef = useStateRef(visibleOverlayModals)
  const visiblePopupsRef = useStateRef(visiblePopups)

  useEffect(() => {
    setTimeout(() => {
      if(!visibleOverlayModalsRef.current.length) {
        globalModalsHistory['overlay'].length = 0
      }
    }, 300)

  }, [visibleOverlayModals])

  useEffect(() => {
    setTimeout(() => {
      if(!visiblePopupsRef.current.length) {
        globalModalsHistory['popup'].length = 0
      }
    }, 300)
  }, [visiblePopups])

  function handleBackdropClick() {
    if(modalManager) {
      visibleOverlayModals.forEach(modal => {
        if(modal.config.onClose) {
          modal.config.onClose()
          // modalManager.close(modal.modal.id, { unmountOnClose: modal.config?.unmountOnClose })
        } else {
          modalManager.close(modal.modal.id)
        }
      })
      // if(modalManager.activeModal?.config.closeOnBackdropClick) {
      //   modalManager.close(modalManager.activeModal.id)
      // }
    }
  }

  function goBack(type: ModalType) {
    if(globalModalsHistory[type].length >= 2) {
      globalModalsHistory[type].splice(-1)
      const last = globalModalsHistory[type].at(-1)

      if(last) {
        navigate(last)
      }
    } else {
      console.log('CANT GET BACK ' + type + ' BECAUSE HISTORY HAS LESS THAN 2 ITEMS')
    }
  }

  return (
    <FocusLock disabled={visibleOverlayModals.length === 0}>
      <div style={{ zIndex: 1200, position: 'relative' }}>
        <GlobalModalBackdrop
          show={visibleOverlayModals?.length > 0 || visiblePopups?.length > 0}
          onClick={handleBackdropClick}
        />

        {overlayModals.map((modal, i) => {
          return (
            <Fragment key={modal.modal.id}>
              <modal.modal.Component
                // key={modal.key ?? modal.modal.id}
                transition={globalModalsHistory[ModalType.OVERLAY].length < 2}

                {...modal.modal.defaultProps}
                {...modal.props}

                isShown={modal.isVisible}
                setIsShown={(val: boolean, options?: OptionsType) => {
                  if(!val) {
                    modalManager.close(modal.modal.id, options)
                    if(modal.config?.onClose) {
                      modal.config.onClose()
                    }
                  }
                }}
                onBack={globalModalsHistory[ModalType.OVERLAY].length <= 1
                  ? undefined
                  : () => {
                    if(modal?.config?.onBack) {
                      modal.config.onBack()
                    }

                    goBack(ModalType.OVERLAY)
                  }
                }
                visibility={
                  modal.isVisible
                    ? overlayModals.length - 1 === i ? 'visible' : 'overlapped'
                    : 'hidden'
                }
              />
            </Fragment>
          )
        })}

        {popups.map((modal, i) => {
          return (
            <Fragment key={modal.modal.id}>
              <modal.modal.Component
                // key={modal.key ?? modal.modal.id}
                {...modal.modal.defaultProps}
                {...modal.props}

                isShown={modal.isVisible}
                setIsShown={(val: boolean, options?: OptionsType) => {
                  if(!val) {
                    modalManager.close(modal.modal.id, options)
                    if(modal.config?.onClose) {
                      modal.config.onClose()
                    }
                  }
                }}
                onBack={globalModalsHistory[ModalType.POPUP].length <= 1
                  ? undefined
                  : () => {
                    if(modal?.config?.onBack) {
                      modal.config.onBack()
                    }

                    goBack(ModalType.POPUP)
                  }
                }
                transition={globalModalsHistory[ModalType.OVERLAY].length < 2}
                visibility={popups.length !== i - 1 ? 'overlapped'
                  : modal.isVisible ? 'visible'
                    : 'hidden'}
              />
            </Fragment>
          )
        })}
      </div>
    </FocusLock>
  )
})
