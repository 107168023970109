import styles from './AsideContentWrapper.module.scss'
import cn from 'classnames'
import { CSSProperties, ReactNode } from 'react'

interface Props {
  style?: CSSProperties,
  children?: ReactNode,
  className?: string,
}
export const AsideContentWrapper = (props: Props) => {
  const {style, className, children} = props

  return (
    <div className={cn(className, styles.root)} style={style}>
      {children}
    </div>
  )
}
