import { fireViewCompanyProfile } from '@/config/events'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { companiesService } from '@/store/companies/companies.service'
import { companiesStore } from '@/store/companies/companies.store'
import { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

interface Props {

}

const CompanyView = OverlayModalPage<Props>(({ onClose }) => {
  const { company_id } = useParams()
  const { t: CompanyT } = useTranslation('company/create')

  const globalModals = useGlobalModals()

  async function loadIfNeeded(company_id: string) {
    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === GLOBAL_MODALS_NAMES.COMPANY_VIEW
      && highestVisibleOverlay?.key === company_id
    ) {
      return
    }

    if(globalModals.isVisible(GLOBAL_MODALS_NAMES.COMPANY_VIEW)) {
      const modal = globalModals.getMountedModal(GLOBAL_MODALS_NAMES.COMPANY_VIEW)
      // let offer = modal?.props?.offer
      // if(isPromise(offer)) {
      //   offer = await offer
      // }
      //
      // if(offer?._id === company_id) {
      //   load = false
      // }
    }

    let titleBeforeOpen = ''


    const getCompany = async () => {
      if (!company_id) {
        return
      }
      return companiesService.getCompanyByNickname({ nickname: company_id})
/*      const company = companiesStore.findCompany(company_id)
      if (['admin', 'owner'].includes(company?.my_role ?? '')) {
        return companiesService.loadMyCompany({ id: company_id })
      } else {
        return companiesService.loadPublicCompany({ id: company_id })
      }*/
    }

    globalModals.open(GLOBAL_MODALS_NAMES.COMPANY_VIEW, {
      key: company_id,

      props: {
        companyId: company_id,
        company: getCompany().then(res => {
          titleBeforeOpen = document.title

          const company = companiesStore.findCompany(res?.id || '')

          document.title = `${company?.name ?? ''} — ${
            CompanyT(`form.exchange_roles.${company?.exchange_role}`)
          }`

          fireViewCompanyProfile({
            is_spa_app: true,
            company_id
          })

          return company
        })
      },
      config: {
        onClose() {
          document.title = titleBeforeOpen

          onClose()
        }
      }
    })
  }

  useLayoutEffect(() => {
    if(company_id) {
      loadIfNeeded(company_id)
    }
  }, [company_id])

  return null
})

export default CompanyView
