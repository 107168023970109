import { clientSettings } from '@/store/client-settings/client-settings'
import { exchangeService } from '@/store/exchange/exchange.service'
import { OffersFilterStore } from '@/store/exchange/offers-filter.store'
import { PublicExchangeOffersStore } from '@/store/exchange/public-exchange.offers.store'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { OfferType } from '@roolz/types/api/exchange'
import { makeAutoObservable } from 'mobx'

export class PrivateExchangeOffersStore {
  incoming = { [OfferType.TRANSPORT]: new PublicExchangeOffersStore(), [OfferType.CARGO]: new PublicExchangeOffersStore() }
  outgoing = { [OfferType.TRANSPORT]: new PublicExchangeOffersStore(), [OfferType.CARGO]: new PublicExchangeOffersStore() }

  constructor() {
    makeAutoObservable(this)
  }

  get isNewIncomingOffers() {
    return (
      this.incoming[OfferType.CARGO].exact.newOfferIds.length > 0
      ||
      this.incoming[OfferType.TRANSPORT].exact.newOfferIds.length > 0
    )
  }

  async checkNewIncomingOffers(offerType: OfferType) {
    try {
      const response = await exchangeService.loadPrivateOffers({
        page: 1,
        sort: 'desc',
        records: 20,
        type_exact: true,
        private_incoming: true,
        only_private: true,
        offer_type: offerType
      })

      const offers = response?.result ?? []

      if(offers.length > 0) {
        const latestOffer = offers.reduce(
          (latest, offer) => dayjs(offer.published_at).isAfter(dayjs(latest.published_at))
            ? offer
            : latest
        )
        const localLastPublishedAt = clientSettings.lastPrivateIncomingOfferPublishedAt

        if(!localLastPublishedAt) {
          this.incoming[offerType].exact.newOfferIds = offers.map(offer => offer._id)
        } else {
          const newOffers = offers.filter(offer => dayjs(offer.published_at).unix() > dayjs(localLastPublishedAt).unix())

          newOffers.length > 0 && (this.incoming[offerType].exact.newOfferIds = newOffers.map(offer => offer._id))
        }
        clientSettings.setSettings({ lastPrivateIncomingOfferPublishedAt: latestOffer.published_at })
      }
    } catch(e) {
      console.error(e)
    }
  }
}

export const privateExchangeOffers = new PrivateExchangeOffersStore()
export const privateExchangeOffersFilter = new OffersFilterStore()

// autorun(() => {
//   console.log('TRANSPORT NEW OFFERS: ', toJS(privateExchangeOffers.incoming[OfferType.TRANSPORT].exact.newOfferIds))
//   console.log('CARGO NEW OFFERS: ', toJS(privateExchangeOffers.incoming[OfferType.CARGO].exact.newOfferIds))
// })
