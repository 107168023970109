import { ONLINE_LAST_ACTION_TIME_MINS, ONLINE_RECENTLY_LAST_ACTION_TIME_MINS } from '@/config/const'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { Profile, ProfileRelationships } from '@roolz/types/api/profiles'
import { ProfileModel } from '@/types/models/profile'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { clone } from 'lodash-es'
import { OnlineStatus } from '@roolz/types/custom'

export function ProfileModelFactory(profile: Profile): ProfileModel {
  return ({
    ...clone(profile),

    get onlineStatus() {
      return getProfileOnlineStatus(this.lastOnline)
    },

    get isActive() {
      return !this.deleted_at || !dayjs(this.deleted_at).isSameOrBefore(new Date())
    },

    get lastOnline() {
      if(!this.isActive) {
        return undefined
      }

      return getProfileLastOnline({
        id: this.id,
        last_action_time: this.last_action_time,
        is_banned_me: this.relationships?.is_banned_me
      })
    }
  })
}


export function getProfileLastOnline({ id, last_action_time, is_banned_me }: Pick<Profile, 'id' | 'last_action_time'> & Pick<ProfileRelationships, "is_banned_me">) {
  if(is_banned_me) {
    return undefined
  }

  const serverLastActionTime = new Date(last_action_time || 0).getTime()
  const clientLastActionTime = new Date(profilesStore.getClientInfo(id)?.last_action_time || 0).getTime()

  const maxLastActionTime = Math.max(serverLastActionTime, clientLastActionTime)

  if(maxLastActionTime > 0) {
    return new Date(maxLastActionTime).toString()
  }

  return null
}

export function getProfileOnlineStatus(lastOnline: string | null | undefined) {
  const diffMins = dayjs(systemStore.serverTime10secUpdate)
    .diff(lastOnline, 'minutes')

  if(diffMins < ONLINE_LAST_ACTION_TIME_MINS) {
    return OnlineStatus.Online
  } else if(diffMins < ONLINE_RECENTLY_LAST_ACTION_TIME_MINS) {
    return OnlineStatus.Recently
  }

  return OnlineStatus.Offline
}
