import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Back } from '../../icons/Back'
import { Forward } from '../../icons/Forward'
import { Breakpoint } from '../../../utils/Breakpoint'
import { CalendarContext, CalendarContextInterface } from './Calendar.context'
import styles from './Calendar.module.scss'
import { Pane } from './Pane/Pane'

type Unixtime = number

interface Props {
  range?: boolean

  isDateAllowed?: (date: Unixtime) => boolean
  onChange?: (from: Unixtime | null, to: Unixtime | null) => void
  value?: [from: Unixtime | null, to: Unixtime| null]
}

interface State {
  year: number
  month: number

  dateToHover: Unixtime | null
}

export const Calendar = ({
  value,
  isDateAllowed,
  range = false,
  onChange
}: Props) => {
  const [state, setState] = useState<State>({
    year: new Date().getFullYear(),
    month: new Date().getUTCMonth(),
    dateToHover: null
  })

  const nextMonth = useMemo(() => state.month < 11 ? (state.month + 1) : 0, [state.month])
  const nextMonthYear = useMemo(() => nextMonth > 0 ? state.year : state.year + 1, [state.year, nextMonth])

  // @ts-ignore?.
  const ctx = useContext<CalendarContextInterface>(CalendarContext)

  function handleDaySelect(date: number) {
    let newValue: Props["value"] = [null, null]
    let newState: State = state

    if(!range) {
      newValue = [date, date]
    } else if(value?.[0] === null) {
      newValue = [date, null]

      newState = {
        ...newState,
        dateToHover: null
      }
    } else if(value?.[1] === null) {
      if(date < value?.[0]) {
        newState = {
          ...newState,
          dateToHover: null
        }
        newValue = [date, value[0]]
      } else {
        newState = {
          ...newState,
          dateToHover: null
        }
        newValue = [value[0], date]
      }
    } else {
      newValue = [date, null]
    }

    setState(newState)
    onChange?.(...newValue)
  }

  function handleDayHover(date: number | null) {
    setState(state => {
      if(date) {
        if(value?.[0] !== null && value?.[1] === null) {
          return {
            ...state,
            dateToHover: date
          }
        }

        return state
      }

      return {
        ...state,
        dateToHover: date
      }
    })
  }

  function handleBackMonth() {
    setState(state => {
      if(state.month <= 0) {
        return {
          ...state,
          month: 11,
          year: state.year - 1
        }
      }

      return {
        ...state,
        month: state.month - 1
      }
    })
  }

  function handleForwardMonth() {
    setState(state => {
      if(state.month >= 11) {
        return {
          ...state,
          month: 0,
          year: state.year + 1
        }
      }

      return {
        ...state,
        month: state.month + 1
      }
    })
  }

  const monthText: string = useMemo(() => {
    return (Object.values(ctx.months)?.[state.month] ?? '') as string
  }, [state.month])

  return (
    <div
      className={styles.root}
      // onPointerLeave={handlePointerLeave}
    >
      <div className={styles.pane__container}>
        <div className={styles.pane__header}>
          <button
            className={styles.backButton}
            onClick={handleBackMonth}
          >
            <Back/>
          </button>
          <div className={styles.pane__header__month}>
            <>
              {monthText}, {state.year}
            </>
          </div>

          <Breakpoint minWidth={701}>
            <button className={styles.forwardButton}/>
          </Breakpoint>
          <Breakpoint maxWidth={700}>
            <button
              className={styles.forwardButton}
              onClick={handleForwardMonth}
            >
              <Forward/>
            </button>
          </Breakpoint>
        </div>
        <div className={styles.pane__body}>
          <Pane
            month={state.month}
            year={state.year}
            isDateAllowed={isDateAllowed}
            onDaySelect={handleDaySelect}
            onDayHover={handleDayHover}
            dateFrom={value?.[0] ?? null}
            dateTo={value?.[1] ?? null}
            dateHover={state.dateToHover}
          />
        </div>
      </div>


      <Breakpoint minWidth={700}>
        <div className={styles.pane__container}>
          <div className={styles.pane__header}>
            <div className={styles.backButton}></div>
            <div className={styles.pane__header__month}>
              <>
                {Object.values(ctx.months)[nextMonth]}, {nextMonthYear}
              </>
            </div>
            <button
              className={styles.forwardButton}
              onClick={handleForwardMonth}
            >
              <Forward/>
            </button>
          </div>
          <div className={styles.pane__body}>
            <Pane
              month={nextMonth}
              year={nextMonthYear}
              isDateAllowed={isDateAllowed}
              onDaySelect={handleDaySelect}
              onDayHover={handleDayHover}
              dateFrom={value?.[0] ?? null}
              dateTo={value?.[1] ?? null}
              dateHover={state.dateToHover}
            />
          </div>
        </div>
      </Breakpoint>
    </div>
  )
}
