import { api } from '@/api'
import stylesConfig from '@/assets/scss/config.module.scss'
import { InvitedModalAccept } from '@/components/modals/InvitedModalAccept/InvitedModalAccept'
import { companiesService } from '@/store/companies/companies.service'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { systemStore } from '@/store/system/system.store'
import { uiStore } from '@/store/ui/ui.store'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { useBreakpoint } from '@roolz/sdk/hooks/useBreakpoint'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'
import { getDeviceInfo } from '@roolz/sdk/utils/device'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { Company } from '@roolz/types/api/companies'
import { profilesStore } from '@/store/profiles/profiles.store'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '@/components/companies/ui/Invitation/Invitation.module.scss'

export const Invitations = observer(function Invitations() {
  const { t: companyT } = useTranslation('company/common')
  const { t: errorsT } = useTranslation('errors')
  const profile = profilesStore.my_profile
  const [invitingCompany, setInvitingCompany] = useState<Company | null>(null)
  const [loading, setLoading] = useState(false)

  if(!profile) {
    return null
  }

  const isDesktopVersion = useBreakpoint({
    minWidth: Number.parseFloat(stylesConfig.mobileSidebarBreakpointWidth)
  })

  const decline = async (
    companyId: string,
    invitationId: string
  ) => {
    setLoading(true)
    try {
      await api.companies.declineInvitation(companyId, invitationId)
      await profilesService.retrieveMyProfile()
    } catch(e: any) {
      const message = e?.response?.data?.error_msg
      if(message) {
        toastError(message)
      }
    } finally {
      setLoading(false)
    }
  }

  const accept = async (
    companyId: string,
    invitationId: string,
    magic_hash: string
  ) => {
    setLoading(true)
    try {
      const response = await api.companies.acceptInvitation(companyId, invitationId,
        {
          device: getDeviceInfo(),
          magic_hash,
          set_as_active: false
        })

      const { company } = response.data

      setInvitingCompany(company)
    } catch(e: any) {
      const message = e?.response?.data?.error_msg
      if(message) {
        toastError(message)
      }
    } finally {
      setLoading(false)
    }
  }

  const updateProfile = useCallback(async () => {
    systemStore.isAppealsModalControllerEnabled = false

    await profilesService.updateMyProfile({
      active_space_company_id: invitingCompany?.id ?? null
    })

    setInvitingCompany(null)
    await Promise.all([
      companiesService.loadMyCompanies()
      // profilesService.retrieveMyProfile()
    ])
  }, [invitingCompany])

  const countCompanies = myCompaniesStore?.companies?.length || 0

  // if(countCompanies >= MAX_COMPANIES_COUNT) {
  //   return null
  // }

  const invitations = profile.company_invitations
  const showScroll = !!invitations?.length && invitations?.length > 1

  return (
    <>
      {invitingCompany && <InvitedModalAccept company={invitingCompany} onClose={updateProfile}/>}

      {!!invitations?.length && (
        <div className={cn(styles.invites, {
          [styles.showScroll]: showScroll
        })}>
          {invitations?.map((invite) =>
            <div
              className={cn(styles.invite, {
                [styles.inviteCollapsed]: isDesktopVersion && uiStore.isSidebarCollapsed
              })}
              key={invite.id}
            >
              <div className={styles.invite__company}>
                <div className={styles.invite__companyWarning}>
                  <span>!</span>
                </div>
                <div className={styles.invite__companyAvatar}>
                  <Avatar
                    avatarUrl={invite.company.logo_path ?? ''}
                    first_name={invite.company.name}
                    color_code={invite.company.color}
                    width={40}
                    type='company'
                  />
                </div>
                <div className={styles.invite__companyRight}>
                  <p className={styles.invite__companyTitle}>
                    {companyT('invites.preview.title')}
                  </p>
                  <p className={styles.invite__companyName}>
                    {invite.company.name}
                  </p>
                </div>
              </div>
              <div className={styles.invite__buttons}>
                <Button
                  disabled={loading}
                  size='small'
                  variant='outlined'
                  onClick={() => decline(invite.company?.id, invite.id)}
                  className={cn(styles.invite__buttonsItem, styles.invite__buttons, styles.Decline)}
                >
                  {companyT('invites.preview.refuse')}
                </Button>
                <Button
                  disabled={loading}
                  size='small'
                  variant='outlined'
                  onClick={() => accept(invite.company?.id, invite.id, invite.magic_hash)}
                  className={cn(styles.invite__buttonsItem, styles.invite__buttons, styles.Accept)}
                >
                  {companyT('invites.preview.accept')}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
})

