import { ChatItemView } from '@/components/chats/ChatItemView/ChatItemView'
import { EnableNotificationsGuide } from '@/components/modals/EnableNotificationsGuide/EnableNotificationsGuide'
import { uiStore } from '@/store/ui/ui.store'
import { Notifications } from '@roolz/icons/Notifications'
import { useDetectBrowserAlertOpened } from '@roolz/sdk/hooks/useDetectBrowserAlertOpened'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './NotificationsAlert.module.scss'
import { Collapse } from '@mui/material'

export const NotificationsAlert = observer(() => {
  if(!('Notification' in window)) {
    return null
  }

  const [canShowNotifications, setCanShowNotifications] = useState(Notification.permission === 'granted')
  const [showInstruction, setShowInstruction] = useState(false)

  const { t } = useTranslation('chat/list')

  const detectBrowserAlert = useDetectBrowserAlertOpened()

  function handleClick() {
    detectBrowserAlert({
      onNotOpen: () => setShowInstruction(true)
    })
    Notification.requestPermission()
      .finally(() => {
        setCanShowNotifications(Notification.permission === 'granted')
      })

  }

  return (
    <Collapse in={!canShowNotifications} enter={false} unmountOnExit>
      <ChatItemView
        className={styles.root}

        showInfo={!uiStore.isSidebarCollapsed}
        avatarSlot={<div className={styles.avatar}>
          <Notifications/>
        </div>}
        nameSlot={
          <div className={styles.title} title={t('notifications.title')}>
            {t('notifications.title')}
          </div>
        }
        contentSlot={
          <div className={styles.content} title={t('notifications.allow')}>
            {t('notifications.allow')}
          </div>
        }
        onChatClick={handleClick}
      />


      <EnableNotificationsGuide
        open={showInstruction}
        onClose={() => setShowInstruction(false)}
      />
    </Collapse>
  )
})
