import { ProfileModel } from '@/types/models/profile'
import { Chat, Message, MessageType, OwnPcp, Pcp, SystemMessageEvent } from '@roolz/types/api/chats'
import {
  AudioContent, ContactContent, FileContent, GeolocationContent, ImageContent, PollContent, StickerContent,
  SystemAddedPcpContent,
  SystemAddedPcpsContent,
  SystemContent, SystemJoinedByInviteContent,
  SystemJoinedPcpContent, TextContent, VideoContent
} from '@roolz/types/api/messaging/message'
import { Uploading } from '@roolz/types/client/uploadings'

export interface ChatModel extends Chat {
  messages: MessageModel[]
  totalMessagesCount: number
  sentMessages: MessageModel[]
  nonSentMessages: MessageModel[]
  visibleMessages: MessageModel[]
  last_message: MessageModel | undefined
  pcps: PcpModel[]
  own_pcp: OwnPcpModel
  companionId?: ProfileModel["id"]
  companion?: ProfileModel | undefined
  onlineUsersCount: number

  draft: string

  // nonSendMessagesCount: number
  isPinned: boolean
  unreadMessagesCount: number
}

export interface PcpModel extends Pcp {
  chat: ChatModel | undefined
  profile: ProfileModel | undefined
}

export interface OwnPcpModel extends OwnPcp {
  chat: ChatModel | undefined
}

/**
 * Message types
 */

export interface MessageModel extends Message {
  chat: ChatModel | undefined

  owner: ProfileModel | undefined
  decodedContent: unknown

  uploading: Omit<Uploading, 'file'> | undefined

  isOwnMessage: boolean
  // totalMessagesCount: number
}

export interface TextMessageModel extends MessageModel {
  type: MessageType.TEXT
  decodedContent: TextContent
}
export interface AudioMessageModel extends MessageModel {
  type: MessageType.AUDIO
  decodedContent: AudioContent
}
export interface ImageMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: ImageContent
}
export interface VideoMessageModel extends MessageModel {
  type: MessageType.VIDEO
  decodedContent: VideoContent
}
export interface FileMessageModel extends MessageModel {
  type: MessageType.FILE,
  decodedContent: FileContent
}
export interface ContactMessageModel extends MessageModel {
  type: MessageType.CONTACT
  decodedContent: ContactContent
}
export interface StickerMessageModel extends MessageModel {
  type: MessageType.STICKER
  decodedContent: StickerContent
}
export interface PollMessageModel extends MessageModel {
  type: MessageType.POLL
  decodedContent: PollContent
}
export interface GeolocationMessageModel extends MessageModel {
  type: MessageType.GEOLOCATION
  decodedContent: GeolocationContent
}

export interface ReplyMessage extends MessageModel {
  reply_to: Message
  reply_to_id: Message["id"]
}

export interface ForwardMessage extends MessageModel {
  forward_from: Message
  forward_from_id: Message["id"]
}

/*
  System messages
 */
export interface SystemMessageModel extends MessageModel {
  type: MessageType.SYSTEM
  decodedContent: SystemContent
}
export interface AddedPcpsMessageModel extends SystemMessageModel {
  decodedContent: SystemAddedPcpsContent
}
export interface AddedPcpMessageModel extends SystemMessageModel {
  decodedContent: SystemAddedPcpContent
}
export interface JoinedPcpMessageModel extends SystemMessageModel {
  decodedContent: SystemJoinedPcpContent
}
export interface JoinedByInviteMessageModel extends SystemMessageModel {
  decodedContent: SystemJoinedByInviteContent
}
/**
 * End system messages
 */

/**
 * END Message types
 */


/**
 * TYPE GUARDS
 */

export function isTextMessage(message: MessageModel): message is TextMessageModel {
  return message?.type === MessageType.TEXT
}

export function isSystemMessage(message: MessageModel): message is SystemMessageModel {
  return message?.type === MessageType.SYSTEM
}

export function isImageMessage(message: MessageModel): message is ImageMessageModel {
  return message?.type === MessageType.IMAGE
}

export function isAudioMessage(message: MessageModel): message is AudioMessageModel {
  return message?.type === MessageType.AUDIO
}

export function isVideoMessage(message: MessageModel): message is VideoMessageModel {
  return message?.type === MessageType.VIDEO
}

export function isFileMessage(message: MessageModel): message is FileMessageModel {
  return message?.type === MessageType.FILE
}

export function isContactMessage(message: MessageModel): message is ContactMessageModel {
  return message?.type === MessageType.CONTACT
}

export function isStickerMessage(message: MessageModel): message is StickerMessageModel {
  return message?.type === MessageType.STICKER
}

export function isPollMessage(message: MessageModel): message is PollMessageModel {
  return message?.type === MessageType.POLL
}

export function isGeolocationMessage(message: MessageModel): message is GeolocationMessageModel {
  return message?.type === MessageType.GEOLOCATION
}

export function isAddedPcpsMessage(message: SystemMessageModel): message is AddedPcpsMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.ADDED_PCPS
}

export function isAddedPcpMessage(message: SystemMessageModel): message is AddedPcpMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.ADDED_PCP
}

export function isJoinedPcpMessage(message: SystemMessageModel): message is JoinedPcpMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.JOINED_PCP
}

export function isJoinedByInvitePcpMessage(message: SystemMessageModel): message is JoinedByInviteMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.JOINED_BY_INVITE
}

export function isReplyMessage(message: Message): message is ReplyMessage {
  return !!message.reply_to_id && !!message.reply_to
}

export function isForwardMessage(message: Message): message is ForwardMessage {
  return !!message.forward_from_id && !!message.forward_from
}
