import { MessageDate } from '@/components/chats/ui/MessageDate/MessageDate'
import { MessageDeliverStatus } from '@/components/chats/ui/MessageDeliverStatus/MessageDeliverStatus'
import { MessageModel } from '@/types/models/chat'
import { MessageState, MessageStatus, MessageType } from '@roolz/types/api/chats'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styles from './MessageAttributes.module.scss'

interface Props {
  message: MessageModel

  showDate?: boolean
  showStatus?: boolean
}

export const MessageAttributes = observer(function MessageAttributes({
  message,

  showDate = true,
  showStatus = true
}: Props) {
  const { t } = useTranslation('chat/message')

  const status = (() => {
    if(message.type === MessageType.FILE) {
      const isError = message.status === MessageStatus.ERROR
        || (message.status === MessageStatus.SENDING && !message.uploading)

      return isError ? MessageStatus.ERROR : message.status
    }

    return message.status
  })()

  return (
    <div className={styles.attributes}>
      {message.version > 0 && (
        <span className={styles.editLabel}>
          {t('message_state.edited')}
        </span>
      )}

      {showDate && (
        <MessageDate message={message}/>
      )}

      {showStatus && (
        // <Zoom
        //   in={message.status === MessageStatus.SENT}
        //   appear={true}
        // >
          <div className={styles.status}>
            <MessageDeliverStatus status={status}/>
          </div>
        // </Zoom>
      )}
    </div>
  )
})
