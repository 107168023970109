import { ProfileAvatar } from '@/components/profile/ui/ProfileAvatar'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { ChatType } from '@roolz/types/api/chats'
import { ChatModel } from '@/types/models/chat'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useState } from 'react'
import * as React from 'react'
import styles from './ChatAvatar.module.scss'
import { Bookmark } from '@roolz/icons/chats/Bookmark'

interface Props {
  chat: ChatModel

  children?: ReactNode
  // showUnreadMessagesCount?: boolean
}

export const ChatAvatar = observer(function ChatAvatar({
  chat,

  children

  // showUnreadMessagesCount
}: Props) {
  if(chat.type === ChatType.SELF_CHAT) {
    return (
      <SelfChatAvatar/>
    )
  }
  if(chat.type === ChatType.DIALOG && chat.companion) {
    return (
      <ProfileAvatar
        profile={chat.companion}
        is_deleted={!chat.companion?.isActive}
        width={40}
        showOnline
      >
        {children}
      </ProfileAvatar>
    )
  }

  if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
    // TODO change name forming
    return (
      <Avatar
        first_name={chat?.name ?? ''}
        last_name={''}
        avatarUrl={chat.avatar ?? ''}
        color_code={chat.color ?? ''}
        width={40}
        onlineStatus={chat.companion?.onlineStatus}
      >
        {children}
      </Avatar>
    )
  }

  return null
})

export function SelfChatAvatar() {
  return (
    <div className={styles.self}>
      <Bookmark/>
    </div>
  )
}
