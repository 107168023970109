import { authService } from '@/store/auth/auth.service'
import { LOGOUT_BACK_URL_PARAM } from '@roolz/sdk/config/const'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

export default function Logout() {
  const [params] = useSearchParams()
  const { i18n: { language } } = useTranslation()

  useEffect(() => {
    const backUrl = params.get(LOGOUT_BACK_URL_PARAM)

    authService.clientsideLogout()
      .then(() => {
        window.location.href = backUrl || process.env.REACT_APP_ROOLZ_STATIC_URL + '/' + language + location.pathname
      })
  }, [])

  return <></>
}
