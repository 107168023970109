interface Props {
  className?: string
}

export const Indeterminate = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.04102 8.62533H11.9577V7.37533H4.04102V8.62533ZM2.41602 15.0837C1.99935 15.0837 1.64518 14.9378 1.35352 14.6462C1.06185 14.3545 0.916016 14.0003 0.916016 13.5837V2.41699C0.916016 2.00033 1.06185 1.64616 1.35352 1.35449C1.64518 1.06283 1.99935 0.916992 2.41602 0.916992H13.5827C13.9994 0.916992 14.3535 1.06283 14.6452 1.35449C14.9368 1.64616 15.0827 2.00033 15.0827 2.41699V13.5837C15.0827 14.0003 14.9368 14.3545 14.6452 14.6462C14.3535 14.9378 13.9994 15.0837 13.5827 15.0837H2.41602Z'
        fill='currentColor'
      />
    </svg>
  )
}
