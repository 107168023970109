import { getFlagUrl } from '@roolz/sdk/utils/cdns'
import cn from 'classnames'
import * as React from 'react'
import { useMemo } from 'react'
import styles from './CountryTag.module.scss'

interface Props {
  alpha2: string
  showAlpha?: boolean

  [key: string]: any
}

export function CountryTag({
  alpha2,
  showAlpha = true,
  className,
  ...rest
}: Props) {
  const flagUrl = useMemo(() => {
    return getFlagUrl(alpha2)
  }, [alpha2])

  return (
    <div
      className={cn(styles.countryTag, className)}
      {...rest}
    >
      <img src={flagUrl} alt={alpha2} className={styles.countryTag__flag}/>
      {showAlpha && (
        <span className={styles.countryTag__label}>
          {alpha2.toUpperCase()}
        </span>
      )}
    </div>
  )
}
