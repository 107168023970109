import { ExpandButton } from '@roolz/sdk/components/ui/buttons/ExpandButton/ExpandButton'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  TextField,
  PopperPlacementType,
  AutocompleteProps
} from '@mui/material'
import { Delete } from '@roolz/icons/Delete'
import { Add } from '@roolz/icons/Add'
import { Search } from '@roolz/icons/Search'
import cn from 'classnames'
import * as React from 'react'
import { forwardRef, KeyboardEventHandler, MouseEvent, ReactNode, Ref, SyntheticEvent, useState } from 'react'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { Backspace } from '@roolz/icons/Backspace'
import style from './MultiSelect.module.scss'


interface OptionItemProps<T> extends React.HTMLAttributes<HTMLLIElement> {
  value: T
  items: T[]
  renderOptionContent: (item: T) => ReactNode
  compareValue: keyof T
  selected: T[keyof T][]
  add: (value: T) => void
  removeClick: (value: T) => void
  multiselect?: boolean
}

const OptionItem = <T, >({
  selected,
  items,
  renderOptionContent,
  compareValue,
  value,
  add,
  removeClick,
  multiselect,
  onClick,
  ...rest
}: OptionItemProps<T>) => {

  const toggle = (e: MouseEvent) => {
    e.stopPropagation()
    selected.includes(value[compareValue]) ? removeClick(value) : add(value)
  }

  const renderToggleIcons = () => {
    if(!multiselect) {
      return null
    }

    return selected.includes(value[compareValue])
      ? (
        <div className={style.option__toggle}>
          <Delete color='#8E8E93'/>
        </div>
      )
      : (
        <div className={style.option__toggle}>
          <Add color='#4778EE'/>
        </div>
      )
  }


  return (
    <Box
      component='li'
      sx={{ background: 'white' }}
      {...rest}
      onClick={multiselect ? toggle : onClick}
    >
      <div className={style.option}>
        <div className={style.optionName}>

          {renderOptionContent(value)}

          {renderToggleIcons()}
        </div>
      </div>
    </Box>
  )
}

const NoOptionsText = () => {
  const { t: muiT } = useSdkTranslation('errors')
  return <>{muiT('noOptions')}</>
}
const LoadingText = () => {
  const { t: muiT } = useSdkTranslation('mui')
  return <>{muiT('autocomplete.loading')}</>
}

type BaseItem = { name: string }

type P = boolean | undefined

type AutoCompleteCustom<T> = Omit<AutocompleteProps<T, P, P, P>,
  'onChange'
  | 'renderInput'
  | 'options'
>

export interface MultiSelectComponentProps<T> extends AutoCompleteCustom<T> {
  text?: string
  onChange?: (value: T[], e?: SyntheticEvent) => any | void | never
  renderOptionContent: (item: T) => ReactNode
  items: T[]
  value?: T[]
  compareValue: keyof T
  error?: boolean
  helperText?: string
  placeholder?: string
  search?: boolean
  showCountAsValue?: boolean
  isMobile?: boolean
  multiselect?: boolean
  highlightOnSelect?: boolean
  componentsProps?: any
  popperPlacement?: PopperPlacementType,
  className?: string
}

const MultiSelectComponent = forwardRef(
  function MultiSelect<T extends BaseItem>({
    text = '',
    onChange,
    renderOptionContent,
    compareValue,
    items,
    value,
    error,
    helperText,
    search = true,
    placeholder,
    multiselect = true,
    highlightOnSelect = false,
    showCountAsValue = false,
    isMobile = false,
    popperPlacement,
    componentsProps,
    className,
    ...rest
  }: MultiSelectComponentProps<T>, ref: any) {
    const [inputValue, setInputValue] = useState<string>('')
    const [opened, setOpened] = useState(false)
    const { t } = useSdkTranslation()

    const handleChange = (e: SyntheticEvent, selected: T[]) => {
      onChange?.(selected)
    }

    const removeValue = (selected: T) => {
      const newValues = value?.filter(item => selected[compareValue] !== item[compareValue])

      newValues && onChange?.(newValues)
    }

    const addValue = (selected: T) => {
      const newValues = [...(value ?? []), selected]

      onChange?.(newValues)
    }

    const handleClose = () => {
      setOpened(false)
      // value && onChange?.(value)
    }

    const autocompleteComparator = (a: T, b: T): boolean =>
      a?.[compareValue] === b?.[compareValue]

    const handleInputChange = (event: any, newValue: string) => {
      setInputValue(newValue)
    }

    const handleClear = () => {
      setInputValue('')
    }

    const filterOptions = (options: T[]) =>
      options.filter(option =>
        option?.name.toLowerCase().includes(
          inputValue.toLowerCase().trim()
        )
      )

    const showDefaultPlaceholder =
      !showCountAsValue
      || (!isMobile && !value?.length)
      || (isMobile && opened)
      || !value?.length

    const isPlaceholderBlack = showCountAsValue && !showDefaultPlaceholder

    const calculatedPlaceholder = showDefaultPlaceholder
      ? placeholder
      : `${t('ui:tree_select.selected')} ${value.length}`

    return (
      <>
        <Autocomplete
          ref={ref}
          classes={{endAdornment: style.popupIcon, popupIndicator: style.popupIndicator}}
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={items}
          autoHighlight
          clearOnBlur
          disableClearable
          getOptionLabel={(option: T) => option?.name}
          onChange={handleChange}
          multiple={multiselect}
          onClose={multiselect ? handleClose : undefined}
          onOpen={() => setOpened(true)}
          loadingText={<LoadingText/>}
          noOptionsText={<NoOptionsText/>}
          openOnFocus={true}
          selectOnFocus={false}
          disablePortal={true}
          value={value}
          componentsProps={{
            popper: {
              placement: popperPlacement
            },
            ...componentsProps
          }}
          renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: T) => (
            <OptionItem
              renderOptionContent={renderOptionContent}
              // @ts-ignore
              selected={value?.map(item => item[compareValue])}
              value={option}
              compareValue={compareValue}
              items={items}
              onClick={props.onClick}
              multiselect={multiselect}
              removeClick={removeValue}
              add={addValue}
              {...props}
            />
          )}
          isOptionEqualToValue={autocompleteComparator}
          popupIcon={<ExpandButton expanded={opened}/>}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              error={error ?? false}
              helperText={helperText ?? ''}
              placeholder={calculatedPlaceholder}
              variant='outlined'
              inputProps={{
                ...params.inputProps,
              }}
              InputProps={{
                ...params.InputProps,

                startAdornment: (
                  search && (
                    <InputAdornment
                      position='start'
                      sx={{
                        left: 7,
                        position: 'relative'
                      }}
                    >
                      <Search/>
                    </InputAdornment>
                  )
                ),
                endAdornment: inputValue?.length ? (
                  <InputAdornment
                    onClick={handleClear}
                    className={cn(style.clear, {
                      [style.clear__show]: inputValue?.length
                    })}
                    position='end'
                  >
                    <Backspace/>
                  </InputAdornment>
                ) : params.InputProps.endAdornment
              }}
            />
          )}
          {...rest}
          className={cn(
            style.textField,
            {
              [style.highlighted]: highlightOnSelect && value?.length,
              [style.blackPlaceholder]: isPlaceholderBlack
            },
            className
          )}
        />

      </>
    )
  })

export const MultiSelect = MultiSelectComponent as
  <T extends BaseItem>(
    props: MultiSelectComponentProps<T> & { ref?: Ref<any> }
  ) => JSX.Element

