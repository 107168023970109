import { Company } from '@roolz/types/api/companies'
import { OfferType } from '@roolz/types/api/exchange'

export enum FilterTypes {
  PublicCargo = OfferType.CARGO,
  PublicTransport = OfferType.TRANSPORT,
  MyAll = 'MY_ALL',
  MyCargo = `MY_${OfferType.CARGO}`,
  MyTransport = `MY_${OfferType.TRANSPORT}`,
}

interface FieldMeta {
  disabled?: boolean
}

export interface FilterConfig {
  companyId: Company['id'] | null
  offer_status?: FieldMeta
  responsible?: FieldMeta
  partners?: FieldMeta
  stock_exchange?: FieldMeta
}

export enum StockExchangeType {
  PrivateAndPublic = '0',
  Public = '1',
  Private = '2'
}
