import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { PopupModalPage } from '@/global-modals/HOC/PopupModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { useEffect } from 'react'
import { useParams } from 'react-router'

interface Props {

}

const ProfileView = PopupModalPage<Props>(({ onClose }) => {
  const { profile_id } = useParams()
  const globalModals = useGlobalModals()

  useEffect(() => {
    if(profile_id) {
      const profile = profilesService.loadProfile(profile_id, false)
        .then(({ id }) => profilesStore.findProfile(id))

      globalModals.open(GLOBAL_MODALS_NAMES.PROFILE_VIEW, {
        key: String(Math.random()),
        props: { profile },
        config: {
          onClose
        }
      })
    }

    return () => {
      globalModals.close(GLOBAL_MODALS_NAMES.PROFILE_VIEW)
    }
  }, [profile_id])

  return null
})

export default ProfileView
