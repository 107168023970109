import { Mimetypes } from '@roolz/types/custom'

interface CropProps {
  image: any,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
  scale: number,
  quality: number
}

/**
 * Cuts image relatively to center of the image.
 *
 * Algorithm:
 * takes the image.
 * Moves the image to @offsetX and @offsetY pixels.
 * Applies the scale to it.
 * Selects area in center of size @width and @height.
 * Scales total image to needed
 *
 * @param image
 * @param offsetX - offset by X axis from the center of image (px)
 * @param offsetY - offset by Y axis from the center of image (px)
 * @param width - size of the area to be cut
 * @param height - size of the area to be cut
 * @param scale
 * @param quality - multiplier of width and height for total image
 */
export function getCroppedImageCanvas({
  image,
  offsetX = 0,
  offsetY = 0,
  width,
  height,
  scale,
  quality
}: CropProps): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      canvas.width = width * quality
      canvas.height = height * quality
      const ctx = canvas.getContext('2d')

      const img = new Image()
      img.onload = async () => {
        if(!ctx) return
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          (canvas.width / 2) - ((img.width / 2) * scale - offsetX) * quality,// + (x * scale),
          (canvas.height / 2) - ((img.height / 2) * scale - offsetY) * quality,// + (y * scale),
          img.width * scale * quality,
          img.height * scale * quality
        )

        return resolve(canvas)
      }
      img.src = image

    } catch(e) {
      reject(e)
    }
  })
}

/**
 *
 * @param props
 * @param mimetype
 */
export function getCroppedImageBlob(props: CropProps, mimetype: Mimetypes): Promise<Blob | null> {
  return new Promise(resolve => {
    getCroppedImageCanvas(props)
      .then(canvas => {
        canvas.toBlob(resolve, mimetype, 1)
      })
  })
}

/**
 *
 * @param props
 * @param mimetype
 */
export async function getCroppedImageDataUrl(props: CropProps, mimetype: Mimetypes): Promise<string> {
  const canvas = await getCroppedImageCanvas(props)

  return canvas.toDataURL(mimetype)
}
