import { api } from '@/api'
import { AppealModalManager, MODAL_NAMES } from '@/components/AppealModalManager'
import { CreateCompanyAppeal } from '@/components/modals/companies/CreateCompanyAppeal/CreateCompanyAppeal'
import { CreateOffersAppeal } from '@/components/modals/CreateOffersAppeal/CreateOffersAppeal'
import { InvitedModalAccept } from '@/components/modals/InvitedModalAccept/InvitedModalAccept'
import { InviteMembersAppeal } from '@/components/modals/InviteMembersAppeal/InviteMembersAppeal'
import { resolvePathByName, ROUTE_NAMES, routes } from '@/config/routes'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { clientSettings } from '@/store/client-settings/client-settings'
import { companiesService } from '@/store/companies/companies.service'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { getNow } from '@/utils/date'
import { toastError } from '@roolz/sdk/components/snackbars'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { getAfterAuthURL, removeAfterAuthURL } from '@roolz/sdk/utils/afterAuthRedirect'
import { getCompanyAfterInvite, removeCompanyAfterInvite } from '@roolz/sdk/utils/afterEmailInviteRedirect'
import { CompanyFormSlug } from '@roolz/types/api/knowledge/companies'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchRoutes, useLocation, useNavigate } from 'react-router'

const COMPANY_OWNERS_COUNT = 1

export const AppealModalsContainer = observer(function GlobalRequestModalsContainer() {
  const { t: errorsT } = useTranslation('errors')
  const [activeModal, setActiveModal] = useState<ReactElement | null>(null)
  const [init, setInit] = useState(false)

  const isFilled = profilesStore.my_profile?.is_filled

  useEffect(() => {
    if(isFilled) {
      setInit(true)
    }
  }, [isFilled])

  const globalModalsManager = useGlobalModals()
  const navigate = useNavigate()
  const location = useLocation()
  const company = companiesStore.myCompany
  const companyId = company?.id
  const justSignedUp = clientSettings.isJustSignedUp

  const isCompanyAdminRoute = useMemo(() => {
    return !!matchRoutes(routes, location.pathname)
      ?.find(({ route }) => route.name === ROUTE_NAMES.COMPANY_ADMIN)
  }, [location])

  // const createCompanyModalController = useCallback((next?: () => void) => {
  //   const shouldShowCreateCompanyModal = profilesStore.isMyProfileFilled
  //     && profilesStore.my_profile
  //     && !profilesStore.my_profile.companies?.length
  //     && justSignedUp
  //
  //   const afterAuthCallback = () => {
  //     const redirectURL = getAfterAuthURL()
  //
  //     if(redirectURL) {
  //       removeAfterAuthURL()
  //       navigate(redirectURL)
  //     }
  //   }
  //
  //   if(shouldShowCreateCompanyModal) {
  //     globalModalsManager.open(GLOBAL_MODALS_NAMES.CREATE_COMPANY_NEW, {
  //       props: {
  //         afterAuthCallback
  //       }
  //     })
  //
  //     return
  //   } else {
  //     profilesStore.isMyProfileFilled && afterAuthCallback()
  //   }
  //
  //   next?.()
  // }, [
  //   profilesStore.isMyProfileFilled,
  //   myCompaniesStore.companies,
  //   profilesStore.my_profile,
  //   justSignedUp
  // ])

  const createCompanyAppealController = useCallback((next?: () => void) => {
    if(
      profilesStore.isMyProfileFilled
      && !myCompaniesStore.companies?.length
      && !profilesStore.my_profile?.company_invitations?.length
      && dayjs(profilesStore.my_profile?.created_at).diff(getNow(), 'minutes') < 20
      && AppealModalManager.isModalAllowedToOpen(MODAL_NAMES.createCompanyAppeal)
      && !justSignedUp
    ) {
      const handleClose = (open: boolean): void => {
        if(!open) {
          AppealModalManager.setIsModalAllowedToOpen(MODAL_NAMES.createCompanyAppeal, false)
          setActiveModal(null)
          return
        }
      }

      return setActiveModal(
        <CreateCompanyAppeal
          open={true}
          setOpen={handleClose}
        />
      )
    }

    next?.()
  }, [
    profilesStore.my_profile,
    profilesStore.isMyProfileFilled,
    myCompaniesStore.companies,
    profilesStore.my_profile?.company_invitations,
    justSignedUp
  ])

  const createOffersAppealController = useCallback((next?: () => void) => {
    if(
      profilesStore.isMyProfileFilled
      && !isCompanyAdminRoute
      && myCompaniesStore.companies?.length
      && AppealModalManager.isModalAllowedToOpen(MODAL_NAMES.createOffersAppeal)
    ) {

      return setActiveModal(
        <CreateOffersAppeal
          open={true}
          onClose={() => {
            AppealModalManager.setIsModalAllowedToOpen(MODAL_NAMES.createOffersAppeal, false)
            setActiveModal(null)
          }}
        />)
    }

    next?.()
  }, [
    isCompanyAdminRoute,
    profilesStore.my_profile,
    profilesStore.isMyProfileFilled,
    myCompaniesStore.companies,
  ])

  const invitedCompanyId = getCompanyAfterInvite()
  const invited = profilesStore.my_profile?.companies?.find(({ id }) => invitedCompanyId === id)

  const invitedCompanyController = useCallback(async (next?: () => void) => {
    if(invited && !activeModal) {
      return setActiveModal(
        <InvitedModalAccept
          company={invited}
          onClose={() => {
            removeCompanyAfterInvite()
            setActiveModal(null)
          }}
        />)
    }

    next?.()

  }, [invited])

  const inviteMembersAppealController = useCallback(async (next?: () => void) => {
    const isCompanyPublicForm = !!company?.company_form && CompanyFormSlug.self_employed !== company.company_form

    if(
      !companyId
      || !isCompanyAdminRoute
      || !isCompanyPublicForm
      || !AppealModalManager.getIsInviteMembersAllowed(company.id)
    ) {
      next?.()
      return
    }

    try {
      const { data: { total } } = await api.companies.getCompanyMembers(companyId, {
        limit: 1
      })
      const hasMembers = total > COMPANY_OWNERS_COUNT

      if(!hasMembers) {
        return setActiveModal(
          <InviteMembersAppeal
            open={true}
            setOpen={() => {
              companyId && AppealModalManager.setInviteMembersRestricted(companyId)
              setActiveModal(null)
            }}
          />)
      }
    } catch(e: any) {
      toastError(e.response?.data?.error_msg ?? errorsT('insufficient_request'))
    }
  }, [
    isCompanyAdminRoute,
    company
  ])

  const pipe =  [
    // createCompanyModalController,
    createCompanyAppealController,
    invitedCompanyController,
    createOffersAppealController,
    inviteMembersAppealController,
  ]

  useEffect(() => {
    if(!init || activeModal) return

    const next = (i: number) => {
      pipe[i]?.(() => next(i + 1))
    }
    next(0)
  }, [...pipe, activeModal, init])

  if(!systemStore.isAppealsModalControllerEnabled) {
    return null
  }

  return activeModal
})
