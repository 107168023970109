import { ApiClients } from '@roolz/api/clients'
import {

  Company

} from '@roolz/types/api/companies'

import {
  GetPartnershipInvitations, GetPartnershipInvitationsRequest, Partnership,
  PartnershipInvitation, PartnershipType,
  SendPartnershipInviteRequest
} from '@roolz/types/api/partnership'
import { AxiosInstance } from 'axios'

export class PartnershipApi {
  identity: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
  }

  getInvitations({
    companyId,
    params,
    body
  }: GetPartnershipInvitationsRequest) {
    return this.identity.post<GetPartnershipInvitations>(
      `/v1/private/companies/id/${companyId}/partnership/invitation/list`,
      body,
      {
        params
      }
    )
  }

  sendInvitation(companyId: Company['id'], body: SendPartnershipInviteRequest) {
    return this.identity.post<Company>(`/v1/private/companies/id/${companyId}/partnership/invitation`, body)
  }

  deletePartner(companyId: Company['id'], partnershipId: Partnership['id']) {
    return this.identity.delete<Company>(`/v1/private/companies/id/${companyId}/partnership/id/${partnershipId}`)
  }

  acceptInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    return this.identity.post<Company>(`/v1/private/companies/id/${companyId}/partnership/invitation/id/${inviteId}/accept`)
  }

  cancelInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    return this.identity.post<Company>(`/v1/private/companies/id/${companyId}/partnership/invitation/id/${inviteId}/cancel`)
  }

  rejectInvitation(companyId: Company['id'], inviteId: PartnershipInvitation['id']) {
    return this.identity.post<Company>(`/v1/private/companies/id/${companyId}/partnership/invitation/id/${inviteId}/reject`)
  }

  getPartners(companyId: Company['id']) {
    return this.identity.get<Partnership[]>(`/v1/private/companies/id/${companyId}/partnership/all`)
  }

  getIncomingInvitesCounter(companyId: Company['id']) {
    return this.identity.get<{ incoming_count: number }>(
      `/v1/private/companies/id/${companyId}/partnership/invitation/incoming_count`
    )
  }
}
