import { api } from '@/api'
import { ChatItem, ChatList, Loading, Placeholder, SearchChats } from '@/components/modals/SearchChats/SearchChats'
import { db, DEXIE_STORES } from '@/database'
import { ChatModelFactory } from '@/models/Chat.model'
import { chatsStore } from '@/store/chats/chats.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { contactListStore } from '@/store/contactList/contactList.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel } from '@/types/models/chat'
import { ProfileModel } from '@/types/models/profile'
import { isProfileMatches } from '@/utils/search'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { Chat, ChatType, PcpStatus } from '@roolz/types/api/chats'
import { Company } from '@roolz/types/api/companies'
import { Profile } from '@roolz/types/api/profiles'
import { SearchChatInfo, SearchProfileInfo } from '@roolz/types/api/search'
import { debounce, flatten, keyBy, pick, uniqWith } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '@/components/modals/SearchChats/GlobalChatsSearch/GlobalChatsSearch.module.scss'
import { Fragment, ReactNode, useCallback, useLayoutEffect, useMemo, useState } from 'react'

interface Props {
  search: string
  onChatSelect: (item: ChatItem) => void
}

type Results = {
  chat_list: SearchChatInfo[]
  contact_list: SearchProfileInfo[]
}

type LocalResults = {
  chat_list: Chat[]
  contact_list: Profile[]
}

const DEFAULT_RESULTS = (): Results => ({
  chat_list: [],
  contact_list: []
})

const MIN_GLOBAL_SEARCH_QUERY_LENGTH = 3

export const GlobalChatsSearch = observer(({
  search,
  onChatSelect
}: Props) => {
  const searchRef = useStateRef(search)

  const { t } = useTranslation('chat/common')

  const [results, setResults] = useState<Results>(DEFAULT_RESULTS())
  const [loading, setLoading] = useState<boolean>(false)

  const cleanQuery = (q: string) => q
    .replace(/^[@ ]+/, '')
    .replace(/[@ ]+$/, '')

  useLayoutEffect(() => {
    if(!open) {
      return
    }

    if(search?.length >= 1) {
      setLoading(true)
      runSearch(search)
    } else {
      setLoading(false)
      setResults(DEFAULT_RESULTS())
    }
  }, [search, open])


  const runSearch = useCallback(debounce(async (query: string) => {
    try {
      query = cleanQuery(query)

      query.length >= MIN_GLOBAL_SEARCH_QUERY_LENGTH
        ? await runGlobalSearch(query)
        : await runLocalSearch(query)
    } finally {
      setLoading(false)
    }
  }, 500), [])


  const runGlobalSearch = useCallback(async (query: string) => {
    console.log('GLOBAL')
    await runLocalSearch(query)
    console.log('GLOBAL 2')

    return api.search.global({ terms: query })
      .then(({ data }) => {
        if(cleanQuery(searchRef.current) !== query) {
          return
        }
        // Append to local results
        setResults(results => {
          return ({
            chat_list: [
              ...results.chat_list,
              ...data
                .filter(item => item.type === 'chat')
                .map(item => JSON.parse(item.data) as SearchChatInfo)
            ],
            contact_list: [
              ...results.contact_list,
              ...data
                .filter(item => item.type === 'profile')
                .map(item => JSON.parse(item.data) as SearchProfileInfo)
                .filter(item => item.id !== profilesStore.my_profile_id)
            ]
          })
        })
      })
      .catch((e) => {
        console.log(e)
        if(cleanQuery(searchRef.current) !== query) {
          return
        }
      })
  }, [])

  const runLocalSearch = useCallback(async (query: string) => {
    // if(!query.length && showAllLocalChatsByDefault) {
    //   return setLocalResults({
    //     chat_list: chatsStore.sortedVisibleChats,
    //     contact_list: []
    //   })
    // }

    try {
      const chat_list = Object.values(chatsStore.chats).filter((chat) => {
        const chatModel = chat
        const pcp = chatModel.own_pcp

        if(!pcp || pcp.status !== PcpStatus.ACTIVE || !chat.is_active) {
          return false
        }

        if(!query?.length) {
          return true
        }

        const nickname = chat.nickname || chatModel.companion?.nickname || ''

        if(/^@.*/.test(query)) {
          const pureQuery = query.replaceAll('@', '')

          return nickname.includes(pureQuery)
        }

        if(nickname.includes(query)) return true
        if(chat.name && chat.name.includes(query)) return true

        switch(chat.type) {
          case ChatType.SELF_CHAT:
            return query.length && t('name.bookmarks').toLowerCase().includes(query.toLowerCase())
          case ChatType.DIALOG: {
            const companion = chatModel.companion
            if(!companion || companion.deleted_at || !companion.is_filled || !companion.relationships) return false

            return isProfileMatches(query, companion)
          }
        }

        return false
      })

      const contact_list = [
        ...flatten(Object.values(contactListStore.contactsByCompanies)),
        ...contactListStore.personalContacts
      ].filter(profile => !profile.deleted_at
        && profile.is_filled
        && profile.relationships
        && isProfileMatches(query, profile))

      if(cleanQuery(searchRef.current) !== query) {
        return
      }

      setLocalResults({
        chat_list,
        contact_list
      })
    } catch(e) {
      console.error(e)
    }
  }, [])

  function setLocalResults(data: LocalResults) {
    const dialogCompanions = data.chat_list
      .filter(item => ChatType.DIALOG === item.type)
      .map(item => ChatModelFactory(item).companion)
      .filter(profile => !profile?.deleted_at && profile?.is_filled)
      .filter((profile): profile is ProfileModel => !!profile)

    setResults({
      chat_list: data.chat_list
        .filter(item => [ChatType.SELF_CHAT, ChatType.CHANNEL, ChatType.GROUP_CHAT].includes(item.type))
        .map(chat => pick(chat, [
          'id',
          'name',
          'avatar',
          'nickname',
          'type',
          'kind',
          'color',
          'count_members',
          'company_id'
        ])),
      contact_list: [...data.contact_list, ...dialogCompanions]
        .map(item => ({
          companies: [],
          ...pick(item, [
            'id',
            'phone',
            'email',
            'deleted_at',
            'nickname',
            'companies',
            'relationships',
            'color',
            'last_action_time',
            'profile_view_info'
          ])
        }))
    })
  }

  const [personalChats, chatsByCompanies, globalChats] = useMemo(() => {
    const personalChats: ChatItem[] = []
    const chatsByCompanies: Record<Company['id'], ChatItem[]> = {}
    const globalChats: ChatItem[] = []

    uniqWith(results.chat_list, (a, b) => a.id === b.id)
      .forEach(chat => {
        if(!!chat.company_id && myCompaniesStore.companyIds.includes(chat.company_id)) {
          chatsByCompanies[chat.company_id] ??= []
          chatsByCompanies[chat.company_id].push({ type: 'chat', chat })

          return
        }
        // TODO think if it should look to idb?
        const ownPcp = chatsStore.getOwnPcp(chat.id)
        if(ownPcp && ownPcp.status === PcpStatus.ACTIVE) {
          personalChats.push({ type: 'chat', chat })
        } else {
          globalChats.push({ type: 'chat', chat })
        }
      })

    uniqWith(results.contact_list, (a, b) => a.id === b.id)
      .forEach(profile => {
        if(profile.relationships?.is_banned_me) {
          return
        }

        const commonCompanies = (profile?.companies || [])
          .filter(company => myCompaniesStore.companyIds.includes(company.id))

        if(commonCompanies.length) {
          commonCompanies.forEach(company => {
            chatsByCompanies[company.id] ??= []
            chatsByCompanies[company.id].push({ type: 'profile', profile })
          })
        } else {
          // TODO think if it should look to idb?
          const dialog = chatsStore.getDialogWithUser(profile.id)
          const ownPcp = dialog && ChatModelFactory(dialog).own_pcp

          if((dialog && ownPcp && ownPcp?.status === PcpStatus.ACTIVE)
            || profile.relationships?.is_personal_contact) {
            personalChats.push({ type: 'profile', profile })
          } else {
            globalChats.push({ type: 'profile', profile })
          }
        }
      })

    return [personalChats, chatsByCompanies, globalChats]
  }, [search, results.chat_list, results.contact_list])

  const isResultEmpty = useMemo(() => {
    return !Object.values(chatsByCompanies).length && !Object.values(personalChats).length && !Object.values(globalChats).length
  }, [chatsByCompanies, personalChats, globalChats])

  const showNothingFoundPlaceholder = search.length
    && isResultEmpty

  const showAppealPlaceholder = isResultEmpty && !search.length


  if(loading) {
    return <Loading/>
  }

  if(showAppealPlaceholder) {
    return (
      <Placeholder
        title={t('search.appeal.title')}
        description={t('search.appeal.description')}
      />
    )
  }

  if(showNothingFoundPlaceholder) {
    return (
      <Placeholder
        title={t('search.no_results.title', { text: search })}
        description={t('search.no_results.description')}
      />
    )
  }

  return (
    <div className={styles.content}>
      {Object.entries(chatsByCompanies).map(([company_id, chats]) => (
        <Fragment key={company_id}>
          <BlockHeading>
            {myCompaniesStore.find(company_id)?.name ?? ''}
            {/*{t('search.sections.private')}*/}
          </BlockHeading>

          <ChatList
            chats={chats as any}
            onChatSelect={onChatSelect}
          />
        </Fragment>
      ))}

      {!!personalChats.length && (
        <>
          <BlockHeading>
            {t('search.sections.private')}
          </BlockHeading>
          <ChatList
            chats={personalChats as any}
            onChatSelect={onChatSelect}
          />
        </>
      )}

      {!!globalChats.length && (
        <>
          <BlockHeading>
            {t('search.sections.global')}
          </BlockHeading>

          <ChatList
            chats={globalChats as any}
            onChatSelect={onChatSelect}
          />
        </>
      )}
    </div>
  )
})

function BlockHeading({
  children
}: {
  children: ReactNode
}) {
  return (
    <div className={styles.blockHeading}>
      {children}
    </div>
  )
}


