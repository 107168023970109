import { Tooltip } from '@mui/material'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import cn from 'classnames'
import * as React from 'react'
import { useCallback, useState } from 'react'
import styles from './FieldErrorTooltip.module.scss'
import { FieldErrorTooltipIcon } from './FieldErrorTooltipIcon/FieldErrorTooltipIcon'

interface Props {
  message: any
  children: any
  fullWidth?: boolean
}

export const FieldErrorTooltip = (({
  message,
  children,
  fullWidth = false
}: Props) => {
  const [open, setOpen] = useState<boolean>(true)

  const handleOpen = useCallback(() => {
    if(message) {
      setOpen(true)
    }
  }, [message])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOnTouchEnd = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Tooltip
      title={message ?? ''}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      placement='top-end'
      describeChild
      disableInteractive={false}

      classes={{
        tooltip: cn(styles.tooltip, {
          [styles.tooltipHidden]: !message
        }),
        popper: styles.popper
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-end']
            }
          },
          {
            name: 'offset', options: {
              offset: ({ popper }: any) => {
                return [popper.width / 2, -5]
              }
            }
          }
          // {
          //   name: 'no-offset-on-overflow',
          //   enabled: true,
          //   phase: 'main',
          //   requiresIfExists: ['offset'],
          //   fn({ state }) {
          //     const overflow = detectOverflow(state, {})
          //
          //     if(overflow.right > 0) {
          //       if(state.modifiersData?.offset?.['top-end']) {
          //         state.modifiersData.offset['top-end'] = {x: 0, y: -5}
          //       }
          //     }
          //   }
          // }
        ],
        disablePortal: true
      }}
    >
      <div
        onTouchEnd={handleOnTouchEnd}
        className={cn(styles.wrapper, {
          [styles.wrapperFullWidth]: fullWidth
        })}
      >
        {children}
        {message && <FieldErrorTooltipIcon className={styles.icon}/>}
      </div>
    </Tooltip>
  )
})
