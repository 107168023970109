import { db, DEXIE_STORES } from '@/database'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ProfileModel } from '@/types/models/profile'
import { Profile } from '@roolz/types/api/profiles'

export async function addOrUpdateProfiles(profiles: Profile[]) {
  profiles.forEach(profile => profilesStore.addOrUpdateProfile(profile))
  await db[DEXIE_STORES.PROFILES].bulkPut(profiles)
}

export async function patchProfile(id: Profile['id'], data: Partial<Profile>) {
  profilesStore.patchProfile(id, data)
  return db[DEXIE_STORES.PROFILES].update(id, data)
}

export async function getProfileById(id: Profile['id'], loadIfNotExist = true): Promise<ProfileModel | undefined> {
  // check if already exist in memory
  let profile: Profile | undefined = profilesStore.findProfile(id)

  if(!profile) {
    try {
      profile = await db[DEXIE_STORES.PROFILES].get(id)

      if(profile) {
        profilesStore.addOrUpdateProfile(profile)
      }

    } catch(e) {
      console.log(e)
    }
  }

  if(!profile && loadIfNotExist) {
    await profilesService.loadProfile(id)
  }

  return profilesStore.findProfile(id)
}
