export const PrivateExchange = () => {
  return (
    <svg width='12' height='18' viewBox='0 0 12 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.08909 17.4267H9.9096C10.5364 17.4267 11.0122 17.2558 11.337 16.9139C11.6674 16.5777 11.8327 16.0706 11.8327 15.3925V9.50362C11.8327 8.83126 11.6674 8.32698 11.337 7.9908C11.0122 7.64892 10.5364 7.47798 9.9096 7.47798H2.08909C1.46231 7.47798 0.983679 7.64892 0.653195 7.9908C0.328409 8.32698 0.166016 8.83126 0.166016 9.50362V15.3925C0.166016 16.0706 0.328409 16.5777 0.653195 16.9139C0.983679 17.2558 1.46231 17.4267 2.08909 17.4267ZM2.13183 16.1361C1.94949 16.1361 1.80704 16.0791 1.70448 15.9652C1.60191 15.8569 1.55063 15.6974 1.55063 15.4865V9.4096C1.55063 9.19878 1.60191 9.03923 1.70448 8.93097C1.80704 8.82271 1.94949 8.76858 2.13183 8.76858H9.86687C10.0549 8.76858 10.1974 8.82271 10.2942 8.93097C10.3968 9.03923 10.4481 9.19878 10.4481 9.4096V15.4865C10.4481 15.6974 10.3968 15.8569 10.2942 15.9652C10.1974 16.0791 10.0549 16.1361 9.86687 16.1361H2.13183ZM1.66174 5.622H3.02072V5.25576C3.02072 4.53781 3.15747 3.93382 3.43097 3.44379C3.70448 2.95376 4.0663 2.58339 4.51644 2.33268C4.97228 2.08197 5.46516 1.95661 5.99507 1.95661C6.53069 1.95661 7.02357 2.08197 7.47371 2.33268C7.92955 2.58339 8.29422 2.95376 8.56772 3.44379C8.84693 3.93382 8.98653 4.53781 8.98653 5.25576V8.1361H10.337V5.43525C10.337 4.36402 10.129 3.47513 9.71302 2.76858C9.30277 2.06203 8.76715 1.53496 8.10619 1.18738C7.44522 0.839805 6.74151 0.666016 5.99507 0.666016C5.25433 0.666016 4.55348 0.839805 3.89251 1.18738C3.23154 1.53496 2.69308 2.06203 2.27713 2.76858C1.86687 3.47513 1.66174 4.36402 1.66174 5.43525V5.622Z'
        fill='#3667DD'
      />
    </svg>
  )
}
