import { isClient } from '@roolz/sdk/utils/ssr'
import { useEventListener } from 'ahooks'

export function useWindowVisibilityChange(callback: (value: DocumentVisibilityState) => void) {
  if(isClient()) {
    useEventListener('visibilitychange', callback, { target: document })
    useEventListener('pagehide', callback, { target: document })
    useEventListener('pageshow', callback, { target: document })
  }
}
